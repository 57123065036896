import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import locale from '../../locale';
import Circle from 'react-circle';
import { Redirect, Link } from 'react-router-dom';
import classnames from 'classnames';
import preventive_img from '../../assets/utils/images/Preventive_black.png';
import oc_img from '../../assets/utils/images/On Condition_black.png';
import corrective_img from '../../assets/utils/images/Corrective_black.png';
import periodic_img from '../../assets/utils/images/Periodic_black.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import ReactTooltip from 'react-tooltip';
import ReactFileReader from 'react-file-reader';

import {
	faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
	Row, Col,
	Alert,
	Container, TabContent, TabPane,
	Card, CardBody, CardHeader,
	Table,
	ButtonGroup,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroup, FormGroup, Label, FormText,
	ListGroupItem, CardFooter,
	CustomInput, Input,
	UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import { Loading, Tabs, Tree, Select, Dropdown, Button, Progress, Tooltip, Tag, Dialog } from 'element-react';

const cardstyles = { borderRight: '1px solid #545cd8' }

const loaddiv = { marginTop: '155px' }

export default class ViewTicket extends Component {
	constructor(props) {
		super(props);

		this.state = {
			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			ticket_filter_data: [],
			filters: [],
			searchcolumn: '',
			searchfor: '',
			searchvalue: '',
			dataLoading: false,
			tic_messages: '',
			isEquipLink: false,
			isNameFilter: false,
			tic_sla_timer_count: 0,
			ticketlistloading: false,
			searchDialog: false,
			tic_space_upid: '',
			tic_equip_upid: '',
			tic_mteam_upid: '',
			tic_asset_upid: '',
			tic_sla_timer: '',
			timelinelimit: 0,
			tic_cat_upid: '',
			subcatLoading: false,
			subcats: [],
			tic_name: '',
			audits: [],
			slarulesLoading: false,
			slarules: [],
			documentSingleLoad: false,
			documentId: '',
			statesLoading: false,
			states: [],
			tic_sla_timer_run: '',
			documentdata: [],
			auditLoading: false,
			sendMessage: false,
			ticketFilterScrollLoad: false,
			ticketlist: [],
			ticket_search_name: '',
			topbar_buttons: '',
			viewfilename: '',
			timesheetloading: false,
			isSpaceLink: false,
			toolsloading: false,
			movepartsloading: false,
			tic_number: '',
			movedpartsloading: false,
			documentSingleViewLoad: false,
			documentSingleImgViewLoad: false,
			pdfviewdialog: false,
			imgviewdialog: false,
			ord_move_parts: [],
			ord_moved_parts: [],
			moveparts: [],
			movedparts: [],
			ordertools: [],
			planpartsloading: false,
			planparts: [],
			timesheets: [],
			checklist: [],
			documents: [],
			documentsLoading: false,
			orderoffset: 30,
			left_style: '',
			formmode: false,
			search_query: '',
			content_card: '',
			is_search_enable: false,
			sticky_searchbar: '',
			savedtags: [],
			ticketlistFilterLoading: false,
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			activeTab: '1',
			ord_name: '',
			ord_checklists: [],
			equipments: [],
			selsearchtext: '',
			spaces: [],
			catLoading: false,
			cats: [],
			teamLoading: false,
			mteams: [],
			priorities: [],
			prtyLoading: false,
			spaceLoading: false,
			equipLoading: false,
			assetLoading: false,
			slaLoading: false,
			ticketLoading: false,
			slas: [],
			assets: [],
			files: '',
			photoname: '',
			fileSize: '',
			fileType: '',
			id: this.props.match.params.id

		};


		this.onTabChange = this.onTabChange.bind(this);
		this.getSLAOnRun = this.getSLAOnRun.bind(this);
		this.handlephotoRemove = this.handlephotoRemove.bind(this);
		this.onFormModeChange = this.onFormModeChange.bind(this);
		this.setcontactmode = this.setcontactmode.bind(this);
		this.handleSubCatChange = this.handleSubCatChange.bind(this);
		this.setcolumn = this.setcolumn.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.getTimeFromFloatTimer = this.getTimeFromFloatTimer.bind(this);
		this.setsearchvalue = this.setsearchvalue.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.onTicketChange = this.onTicketChange.bind(this);
		this.ticketfiltersclick = this.ticketfiltersclick.bind(this);
		this.handleCatChange = this.handleCatChange.bind(this);
		this.onSendMessage = this.onSendMessage.bind(this);
		this.ticketfilters = this.ticketfilters.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.setmt = this.setmt.bind(this);
		this.handleSlaChange = this.handleSlaChange.bind(this);
		this.handleSlaRuleChange = this.handleSlaRuleChange.bind(this);
		this.onFilterApply = this.onFilterApply.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.setsearchfor = this.setsearchfor.bind(this);
		this.setissuetype = this.setissuetype.bind(this);
		this.onTicketDataChange = this.onTicketDataChange.bind(this);
		this.getTicketListonScroll = this.getTicketListonScroll.bind(this);
		this.handleEquipChange = this.handleEquipChange.bind(this);
		this.handlePrtyChange = this.handlePrtyChange.bind(this);
		this.handleSpaceChange = this.handleSpaceChange.bind(this);
		this.handleTeamChange = this.handleTeamChange.bind(this);
		this.handleAssetChange = this.handleAssetChange.bind(this);
		this.onAddFields = this.onAddFields.bind(this);
		this.onSlaStatus = this.onSlaStatus.bind(this);
		this.updateTicket = this.updateTicket.bind(this);

	}

	setmt(column) {
		this.setState({ tic_mtype: column });
	}

	handlephotoRemove() {

		this.setState({ files: '', photoname: '' });

	}

	handleStateChange(column) {
		this.setState({ tic_status_id: column });
	}

	setissuetype(column) {
		this.setState({ tic_issue_type: column });
	}

	handleFiles(files) {
		console.log(files);
		this.setState({ files: files.base64, photoname: files.fileList[0]['name'], fileSize: files.fileList[0]['size'], fileType: files.fileList[0]['type'] });
		this.bytesToSize(this.state.fileSize);
	}

	bytesToSize(bytes) {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		var size = Math.round(bytes / Math.pow(1024, i), 2);
		var mes = sizes[i];
		if (parseInt(size) > 2 && mes == 'MB') {

			alert('File Size More than 2MB ');

		}
		else {



		}
	};

	async updateTicket() {
		this.setState({ ticketLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');

		
		var mteam = this.state.tic_mteam_id;

		var catid = this.state.tic_cat_id;

		var subid = this.state.tic_sub_cat_id;

		

		if (this.state.tic_mteam_upid != '') {

			mteam = this.state.tic_mteam_upid;
		}

		if (this.state.tic_cat_upid != '') {

			catid = this.state.tic_cat_upid;
		}

		if (this.state.tic_sub_cat_upid != '') {

			subid = this.state.tic_sub_cat_upid;
		}

		var formdata = { "subject": this.state.tic_name, "maintenance_team_id": parseInt(mteam), "channel": this.state.tic_channel, "issue_type": this.state.tic_issue_type, "priority_id": this.state.tic_priority_id, "category_id": parseInt(catid), "sub_category_id": parseInt(subid), "person_name": this.state.tic_person, "email": this.state.tic_mail, "description": this.state.tic_desc };

		var data = { "token": token, "eid": this.state.id, "values": formdata };
		await Reqst.axPost('updateticket', data).then((result) => {

			let res = result['data'];


			if (res) {

				if (this.state.files != '') {

					this.onDocumentCreate();
				}
				else {
					this.setState({ ticketLoading: false });
					this.setState({ formmode: false });
					swal("Success", "Data Updated Successfully..", "success");
					this.getdata();
				}
			}
			else {

				this.setState({ ticketLoading: false });
				this.setState({ formmode: false });
				swal("Error", "Unable to update..", "error");

			}
		});
	}

	onDocumentCreate() {

		var token = Sanitize.getStoreageItem('accessToken');
		var cat = 2;
		var date = this.state.cdate;
		var name = this.state.tic_name;
		var fname = date + '-' + name;
		var fdata = this.state.files;
		var tid = this.state.id;
		var cid = Sanitize.getStoreageItem('company_id');
		var type = this.state.fileType;
		var remfile = 'data:' + type + ';base64,';
		var filedata = fdata.replace(remfile, "");
		var values = { "datas": filedata, "datas_fname": this.state.photoname, "ir_attachment_categ": cat, "name": fname, "company_id": parseInt(cid), "res_model": "website.support.ticket", "res_id": parseInt(tid) };

		var data = { "token": token, "values": values };

		Reqst.axPost('documentcreate', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ ticketLoading: false });
				this.setState({ formmode: false, files: '', photoname: '', fileType: '', fileSize: '', isDocumentsLoaded: false, activeTab: '1' });
				swal("Success", "Data Updated Successfully..", "success");
				this.getdata();
			}
		});

	}

	handleCatChange(column) {
		this.setState({ tic_cat_id: '' });
		this.setState({ tic_sub_cat_id: '', subcats: [], tic_sub_cat_upid: '' });
		this.state.tic_sub_cat_id = '';
		this.state.tic_sub_cat_upid = '';
		this.state.subcats = [];
		$("#subcat").val('');
		this.setState({ tic_cat_upid: column });
		this.getsubcategories(column);
	}

	handleSubCatChange(column) {
		this.setState({ tic_sub_cat_id: '' });
		this.setState({ tic_sub_cat_upid: column });
	}

	handlePrtyChange(column) {
		this.setState({ tic_priority_id: column });
	}

	handleSlaRuleChange(column) {
		this.setState({ tic_sla_rule_id: column });
	}

	handleSlaChange(column) {
		this.setState({ tic_sla_id: column });
	}


	setcontactmode(column) {
		this.setState({ tic_channel: column });
	}

	onAddFields(type) {

		this.setState({ tic_type: type });
		this.state.tic_type = type;
		this.getlocations();
	}

	onSlaStatus(type) {

		if (type == 'yes') {

			this.setState({ tic_sla_active: true });

		}
		else {

			this.setState({ tic_sla_active: false });

		}

	}


	handleSpaceChange(column) {
		this.setState({ tic_space_id: '' });
		this.setState({ tic_space_upid: column });
	}

	handleEquipChange(column) {
		this.setState({ tic_equip_id: '' });
		this.setState({ tic_equip_upid: column });
	}

	handleTeamChange(column) {
		this.setState({ tic_mteam_id: '' });
		this.setState({ tic_mteam_upid: column });
	}

	handleAssetChange(column) {
		this.setState({ tic_asset_id: '' });
		this.setState({ tic_asset_upid: column });
	}

	onFilterApply(id, query, name) {

		query = query.substr(1).slice(0, -1);
		this.setState({ searchDialog: false });
		this.ordersavedfilters(query);

		var orgdata = [{ "key": id, "name": name, "query": query }];

		this.setState({ tags: [] });

		this.setState({ savedtags: orgdata });


		console.log(this.state.savedtags);

	}


	setsearchvalue(e) {

		this.setState({ searchvalue: e.target.value });
	}

	setcolumn(column) {
		this.setState({ searchfor: '' });
		this.setState({ searchvalue: '' });
		this.setState({ searchcolumn: column });
	}

	setselsearchvalue(value) {

		this.setState({ searchvalue: value });
	}

	onInputChange(e) {

		this.setState({ [e.target.name]: e.target.value });
	}

	onFormModeChange() {

		this.setState({ formmode: true });
		this.setState({ tic_space_upid: '', tic_equip_upid: '', tic_mteam_upid: '', tic_asset_upid: '' });
		//this.getequipments();
		//this.getlocations();
		this.getteams();
		this.getcategories();
		this.getsubcategories(this.state.tic_cat_id);
		this.getpriorities();
		//this.getslas();
		//this.getslarules();
		//this.getstates();


	}

	getcategories() {
		this.setState({ catLoading: true, loadingTxt: 'Loading...' });
		this.setState({ cats: [], subcats: [], sub_cat_id: '' });
		var token = Sanitize.getStoreageItem('accessToken');

		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}

		var seldata = [];
		var name = this.state.tic_cat;
		var id = this.state.tic_cat_id;
		seldata.push({ "id": id, "name": name });

		var data = { "token": token, "query": query };
		Reqst.axPost('getticketcategory', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ catLoading: false });
				if (this.state.tic_cat_id && this.state.tic_cat_id != '') {
					this.setState({ cats: seldata.concat(res) });
				}
				else {

					this.setState({ cats: res });
				}
			}
		});

	}

	getsubcategories(catid) {
		if (catid != '') {
			this.setState({ subcatLoading: true });
			this.setState({ subcats: [] });
			var token = Sanitize.getStoreageItem('accessToken');

			var searchtext = this.state.selsearchtext;
			//var catid = this.state.cat_id;
			console.log(catid);
			var query = '["parent_category_id", "=", ' + catid + ']';
			if (searchtext != '') {
				query = '"&",["name", "ilike","' + searchtext + '" ],["parent_category_id", "=", ' + catid + ']';
			}

			var seldata = [];
			var name = this.state.tic_sub_cat;
			var id = this.state.tic_sub_cat_id;
			seldata.push({ "id": id, "name": name });

			var data = { "token": token, "query": query };
			Reqst.axPost('getticketsubcategory', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ subcatLoading: false });
					if (this.state.tic_sub_cat_id && this.state.tic_sub_cat_id != '') {
						this.setState({ subcats: seldata.concat(res) });
					}
					else {

						this.setState({ subcats: res });
					}
					//this.setState({sub_cat_id:res[0].id});
				}
			});
		}

	}

	onSelSearch(type, query) {
		this.setState({ selsearchtext: '' });
		this.state.selsearchtext = '';
		if (type == 'equipment') {
			this.setState({ tic_equip_upid: '' });
			if (this.state.tic_equip_upid == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getequipments();
			}
		}
		else if (type == 'space') {
			this.setState({ tic_space_upid: '' });
			if (this.state.tic_space_upid == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getlocations();
			}
		}
		else if (type == 'mteam') {
			this.setState({ tic_mteam_upid: '' });
			if (this.state.tic_mteam_upid == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getteams();
			}
		}
		else if (type == 'asset') {
			this.setState({ tic_asset_upid: '' });
			if (this.state.tic_asset_upid == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getlocations();
			}
		}
		else if (type == 'cat') {
			this.setState({ subcats: [] });
			this.setState({ tic_sub_cat_upid: '' });
			this.setState({ tic_cat_upid: '' });
			if (this.state.tic_cat_upid == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getcategories();
			}
		}
		else if (type == 'subcat') {
			this.setState({ tic_sub_cat_upid: '' });
			if (this.state.tic_sub_cat_upid == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getsubcategories(this.state.tic_cat_upid);
			}
		}

	}

	getequipments() {

		this.setState({ equipLoading: true });
		this.setState({ equipments: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}

		var seldata = [];
		var name = this.state.tic_equip;
		var id = this.state.tic_equip_id;
		seldata.push({ "id": id, "name": name });

		var data = { "token": token, "query": query };
		Reqst.axPost('getticketequipment', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ equipLoading: false });
				if (this.state.tic_equip_id && this.state.tic_equip_id != '') {
					this.setState({ equipments: seldata.concat(res) });
				}
				else {

					this.setState({ equipments: res });
				}
			}
		});



	}

	getlocations() {


		var type = this.state.tic_type;


		if (type == 'equipment') {

			this.setState({ spaceLoading: true });
			this.setState({ spaces: [] });
			var token = Sanitize.getStoreageItem('accessToken');

			var searchtext = this.state.selsearchtext;
			var query = 'no';
			if (searchtext != '') {
				query = searchtext;
			}


			var seldata = [];
			var name = this.state.tic_space;
			var id = this.state.tic_space_id;
			seldata.push({ "id": id, "name": name });

			var data = { "token": token, "query": query };
			Reqst.axPost('getspacessearch', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ spaceLoading: false });
					if (this.state.tic_space_id && this.state.tic_space_id != '') {
						this.setState({ spaces: seldata.concat(res) });
					}
					else {

						this.setState({ spaces: res });
						this.setState({ assets: res });
					}
				}
			});

		}
		else {

			this.setState({ assetLoading: true });
			this.setState({ assets: [] });
			var token = Sanitize.getStoreageItem('accessToken');

			var searchtext = this.state.selsearchtext;
			var query = 'no';
			if (searchtext != '') {
				query = searchtext;
			}

			var seldata = [];
			var name = this.state.tic_asset;
			var id = this.state.tic_asset_id;
			seldata.push({ "id": id, "name": name });

			var data = { "token": token, "query": query };
			Reqst.axPost('getspacessearch', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ assetLoading: false });
					if (this.state.tic_asset_id && this.state.tic_asset_id != '') {
						this.setState({ assets: seldata.concat(res) });
					}
					else {

						this.setState({ assets: res });
					}
				}
			});

		}

	}

	getteams() {
		this.setState({ teamLoading: true });
		this.setState({ mteams: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}


		var seldata = [];
		var name = this.state.tic_mteam;
		var id = this.state.tic_mteam_id;
		seldata.push({ "id": id, "name": name });

		var data = { "token": token, "query": query };
		Reqst.axPost('getmaintenanceteamsearch', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ teamLoading: false });
				if (this.state.tic_mteam_id != '') {
					this.setState({ mteams: seldata.concat(res) });
				}
				else {

					this.setState({ mteams: res });
				}
			}
		});

	}

	getpriorities() {
		this.setState({ prtyLoading: true });
		this.setState({ priorities: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var data = { "token": token };
		Reqst.axPost('getprioritysearch', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ prtyLoading: false });
				this.setState({ priorities: res });
			}
		});

	}

	getstates() {
		this.setState({ statesLoading: true });
		this.setState({ states: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var data = { "token": token };
		Reqst.axPost('getticketstates', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ statesLoading: false });
				this.setState({ states: res });
			}
		});

	}

	getslas() {
		this.setState({ slaLoading: true });
		this.setState({ slas: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var data = { "token": token };
		Reqst.axPost('getslasearch', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ slaLoading: false });
				this.setState({ slas: res });
			}
		});

	}

	getslarules() {
		this.setState({ slarulesLoading: true });
		this.setState({ slarules: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var data = { "token": token };
		Reqst.axPost('getslarulesearch', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ slarulesLoading: false });
				this.setState({ slarules: res });
			}
		});

	}

	setsearchfor(e) {

		if (e.target.checked) {

			this.setState({ searchfor: e.target.value });
		}
		else {

			this.setState({ searchfor: '' });
		}
	}


	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}



	componentDidMount() {

		this.getdata();
		this.getTicketsList();
		this.getFilters();
		// setInterval(this.getSLAOnRun, 1000);

	}

	componentDidUpdate() {

		window.addEventListener('scroll', this.onTicketScroll());
		if (this.ticket_search_name) {
			this.ticket_search_name.focus();
			$("#search_text").focus();
		}

	}


	getFilters() {

		this.setState({ ticketlistloading: true });
		this.setState({ filters: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var data = { "token": token, "model": "website.support.ticket" };
		Reqst.axPost('getfilters', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ ticketlistloading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ ticketlistloading: false });

				this.setState({ filters: res });


			}
		});

	}



	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;
			return strTime;
		}
		else {

			return 'Not Assigned';
		}

	}

	getdateofdttime(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime + ' ' + strTime1;
		}
		else {

			return 'Not Assigned';
		}

	}

	getConfirmCheckLists() {


		var data = this.state.checklist;

		var result = 0;

		for (var i = 0; i < data.length; i++) {

			if (data[i].answer_common) {

				result += 1;
			}

		}

		return result;


	}


	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate();
			var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			var mname = mlist[date1.getMonth()];
			var strTime = day + ' ' + mname + ' ' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime;
		}
		else {

			return '- - - -';
		}

	}

	getdateoftimingsind(date) {

		var cdate = this.convertLoalTime(date);



		var date_future = new Date(cdate);



		var date_now = new Date();


		// get total seconds between the times
		var delta = Math.abs(date_future - date_now) / 1000;

		// calculate (and subtract) whole days
		var days = Math.floor(delta / 86400);
		delta -= days * 86400;

		// calculate (and subtract) whole hours
		var hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;

		// calculate (and subtract) whole minutes
		var minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;

		if (days != 0 || days > 2) {

			return this.getdateofdttime(date);
		}
		else if (days == 0 && hours != 0) {

			return hours + ' hours ago';

		}
		else {

			return minutes + ' minutes ago';

		}

	}


	getdateoftime(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime1;
		}
		else {

			return '- - - -';
		}

	}

	convertLoalTime(date_future) {

		var date_future = new Date(date_future);

		var year = date_future.getFullYear();
		var month = (date_future.getMonth() + 1);
		var day = date_future.getDate()

		var addhours = date_future.getHours() + 5;
		var mints = date_future.getMinutes();
		var secs = date_future.getSeconds();

		if (addhours == '13') {

			addhours = '01';
		}
		else if (addhours == '14') {

			addhours = '02';

		}
		else if (addhours == '15') {

			addhours = '03';

		}
		else if (addhours == '16') {

			addhours = '04';

		}
		else if (addhours == '17') {

			addhours = '05';

		}

		return year + '-' + month + '-' + day + ' ' + addhours + ':' + mints + ':' + secs

	}

	async onStateChange(state) {
		this.setState({ dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('setticketstate/' + token + '/' + state + '/' + this.state.id).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({ dataLoading: false });
					this.getdata();
					swal("Success", "State Changed Successfully..", "success");
				}
				else {

					swal("Error", "Unable to change the State", "error");
				}
			}
		});
	}

	getTimeFromFloatTimer() {

		var decimalTimeString = this.state.tic_sla_timer;
		var decimalTime = parseFloat(decimalTimeString);
		decimalTime = decimalTime * 60 * 60;
		var hours = Math.floor((decimalTime / (60 * 60)));
		decimalTime = decimalTime - (hours * 60 * 60);
		var minutes = Math.floor((decimalTime / 60));
		decimalTime = decimalTime - (minutes * 60);
		var seconds = Math.round(decimalTime);
		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		//var result = "" + hours + ":" + minutes;


		var tothours = hours * 3600;

		var totmints = minutes * 60;

		var count = tothours + totmints;
		//var counter = setInterval(1000); //1000 will  run it every 1 second

		this.setState({ tic_sla_timer_count: count });

		this.state.tic_sla_timer_count = count;


	}


	getSLAOnRun() {

		var ccount = this.state.tic_sla_timer_count - 1;

		this.state.tic_sla_timer_count = ccount;
		this.setState({ tic_sla_timer_count: ccount });

		var tseconds = ccount % 60;
		var tminutes = Math.floor(ccount / 60);
		var thours = Math.floor(tminutes / 60);
		tminutes %= 60;
		thours %= 60;

		if (thours < 10) {
			thours = "0" + thours;
		}
		if (tminutes < 10) {
			tminutes = "0" + tminutes;
		}
		if (tseconds < 10) {
			tseconds = "0" + tseconds;
		}

		if (ccount != -1) {

			var result = "" + thours + ":" + tminutes + ":" + tseconds;
			this.state.tic_sla_timer_run = result;
			this.setState({ tic_sla_timer_run: result });

		}

	}

	getSLATimer(ghours, gminutes) {

		var tothours = ghours * 3600;

		var totmints = gminutes * 60;

		var count = tothours + totmints;
		console.log(count);
		var counter = setInterval(1000); //1000 will  run it every 1 second

		count = count - 1;
		if (count == -1) {
			clearInterval();
			return;
		}

		var seconds = count % 60;
		var minutes = Math.floor(count / 60);
		var hours = Math.floor(minutes / 60);
		minutes %= 60;
		hours %= 60;

		//document.getElementById("timer").innerHTML = hours + "hours " + minutes + "minutes and" + seconds + " seconds left on this Sale!"; // watch for 
		//spelling



		var result = "" + hours + ":" + minutes + ":" + seconds;

		console.log(result);

		return result;

	}

	timer(count) {
		count = count - 1;
		if (count == -1) {
			clearInterval();
			return;
		}

		var seconds = count % 60;
		var minutes = Math.floor(count / 60);
		var hours = Math.floor(minutes / 60);
		minutes %= 60;
		hours %= 60;

		//document.getElementById("timer").innerHTML = hours + "hours " + minutes + "minutes and" + seconds + " seconds left on this Sale!"; // watch for 
		//spelling

		var result = "" + hours + ":" + minutes + ":" + seconds;

		return result;
	}



	getDateTimingsText(date, date1) {


		if (date != '') {

			if (date >= date1) {

				return <span className="text-success" style={{ verticalAlign: 'middle' }}>ON-TIME</span>;
			}
			else {
				return <span className="text-danger" style={{ verticalAlign: 'middle' }}>LATE BY</span>;

			}

		}
		else {

			return <span className="text-warning" style={{ verticalAlign: 'middle' }}>ELAPSED BY</span>;

		}


	}

	getDateTimingsStatus(date, date1) {

		var status = '';
		if (date != '') {

			if (date >= date1) {

				status = 'ontime';
			}
			else {
				status = 'late';

			}

		}
		else {

			status = 'elapse';

		}

		return status;
	}

	getDateTimingsColor(date, date1) {

		var color = '';

		if (date != '') {

			if (date >= date1) {

				color = 'text-success';
			}
			else {
				color = 'text-danger';

			}

		}
		else {

			color = 'text-warning';

		}

		return color;


	}

	getdateoftimings(date, date1, type) {


		var cdate = this.convertLoalTime(date);



		var date_future = new Date(cdate);



		var date_now = new Date();

		if (date1 && date != '') {

			date_now = this.convertLoalTime(date1);

		}

		var delta = '';

		if (date_future >= date_now) {
			// get total seconds between the times
			delta = Math.abs(date_future - date_now) / 1000;
		}
		else {

			delta = Math.abs(date_now - date_future) / 1000;
		}

		// calculate (and subtract) whole days
		var days = Math.floor(delta / 86400);
		delta -= days * 86400;

		// calculate (and subtract) whole hours
		var hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;

		// calculate (and subtract) whole minutes
		var minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;

		/* if(days!=0 || days>2){
			
			var isplural = 'Days';
			
			if(days==1){
				
				isplural = 'Day';
			}
			
			
			return days+'  '+hours+'  '+minutes;
		}
		else if(days==0 && hours!=0){
			
			return hours+'   '+minutes;
			
		}
		else{
			
			return minutes;
			
		}  */

		if (type == 'day') {

			return days;
		}
		else if (type == 'hour') {

			return hours;

		}
		else if (type == 'minute') {

			return minutes;

		}

	}



	getnovalues(str) {

		if (str != '' && str != null) {
			return str;
		}
		else {

			return 'Not Assigned';
		}

	}

	onTabChange(tab) {

		this.setState({ activeTab: tab });

		if (tab == '8') {
			this.state.timelinelimit = 0;
			this.state.audits = [];
			this.setState({ timelinelimit: 0, audits: [] });

			this.getAuditLogs();
			window.addEventListener('scroll', this.onScroll());

		}
		else if (tab == '9') {

			this.getDocuments();
			this.setState({ isDocumentsLoaded: true });
		}

	}

	pdffileview(type, filename, docid, name) {

		var text = '';

		this.setState({ documentSingleViewLoad: true });
		this.setState({ documentId: docid });
		this.setState({ viewfilename: name });
		this.setState({ documentviewdata: '' });
		this.setState({ pdfviewdialog: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getticketdocumentload/' + token + '/' + this.state.id + '/' + docid).then((result) => {

			let res = result['data'][0].datas;
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ documentSingleViewLoad: false });
				this.setState({ documentId: '' });
				var pdfdata = res;
				var pdf_src = 'data:application/pdf;base64,' + pdfdata + '';
				this.setState({ documentviewdata: pdf_src });

			}
		});

	}

	imagefileview(type, filename, docid, name) {

		var text = '';

		this.setState({ documentSingleImgViewLoad: true });
		this.setState({ documentId: docid });
		this.setState({ imgviewdialog: true });
		this.setState({ viewfilename: name });
		this.setState({ documentviewdata: '' });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getticketdocumentload/' + token + '/' + this.state.id + '/' + docid).then((result) => {

			let res = result['data'][0].datas;
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ documentSingleImgViewLoad: false });
				this.setState({ documentId: '' });
				var imgdata = res;
				var img_src = 'data:' + type + ';base64,' + imgdata + '';
				this.setState({ documentviewdata: img_src });
			}
		});

	}

	filedownlod(type, filename, docid) {

		var text = '';

		this.setState({ documentSingleLoad: true });
		this.setState({ documentId: docid });
		this.setState({ documentdata: '' });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getticketdocumentload/' + token + '/' + this.state.id + '/' + docid).then((result) => {

			let res = result['data'][0].datas;
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ documentSingleLoad: false });
				this.setState({ documentId: '' });
				this.setState({ documentdata: res });
				text = res;
				var element = document.createElement('a');
				element.setAttribute('href', 'data:' + type + ';base64,' + encodeURIComponent(text));
				element.setAttribute('download', filename);

				element.style.display = 'none';
				document.body.appendChild(element);

				element.click();

				document.body.removeChild(element);

			}
		});

	}

	onScroll() {

		$(window).scroll(() => {
			if ($(window).scrollTop() + $(window).height() == $(document).height()) {
				this.getAuditLogs(this.state.tic_messages);
			}
		});
	}

	async getAuditLogs() {
		var mids = this.state.tic_messages;
		if (mids != '') {

			var midslimit = [];

			var size = this.state.timelinelimit + 10;

			this.setState({
				timelinelimit: this.state.timelinelimit + 10
			})

			var length = size + 10;

			var isloaded = false;

			if (length == '20') {

				length = '10';
			}

			if (size == '10') {

				size = 0;
				isloaded = true;

			}

			for (var i = size; i < length; i++) {

				if (i < mids.length) {
					midslimit.push(mids[i]);
				}
			}

			if (midslimit.length > 0) {
				//if(!this.state.isAuditLogsLoaded){
				this.setState({ auditLoading: true });
				this.setState({ isAuditLogsLoaded: isloaded });
				//this.setState({audits : []});
				var token = Sanitize.getStoreageItem('accessToken');
				var data = { "token": token, "mids": midslimit };
				await Reqst.axPost('getcompanyactions', data).then((result) => {

					let res = result['data'];

					this.setState({ auditLoading: false });
					this.setState({ isAuditLogsLoaded: false });
					if (res) {

						this.sortaudits(res);
					}
				});
			}
		}
		//}
	}

	async getDocuments() {
		if (!this.state.isDocumentsLoaded) {
			this.setState({ documentsLoading: true });
			this.setState({ documents: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			await Reqst.axGet('getticketdocuments/' + token + '/' + this.state.id).then((result) => {

				let res = result['data'];

				this.setState({ documentsLoading: false });
				if (result['data'].code == '401') {

					this.getRefreshToken();

				}
				else {
					if (res) {
						this.setState({
							documents: res
						});
					}
				}
			});
		}
	}

	sortaudits(data) {
		if (data.length > 0) {
			var orgdata = data.sort(function (a, b) {
				var dateA = new Date(a.date), dateB = new Date(b.date);
				return dateB - dateA;
			});
			var joined = this.state.audits;
			var newdata = joined.concat(orgdata);
			this.setState({
				audits: newdata
			});
		}
		else {
			var joined = this.state.audits;
			var newdata = joined.concat(data);
			this.setState({
				audits: newdata
			});
		}
	}


	onTicketDataChange(id) {

		this.setState({ id: id });
		this.getdatabyid(id);
		this.setState({ activeTab: '1' });
		this.setState({ audits: [], isAuditLogsLoaded: false, documents: [], isDocumentsLoaded: false })


	}

	getdatabyid(id) {
		this.setState({ dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getticketdata/' + token + '/' + id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.state.tic_messages = res.message_ids;
					this.setState({
						tic_name: res.display_name,
						tic_number: res.ticket_number,
						tic_asset: res.asset_id[1],
						tic_asset_id: res.asset_id[0],
						tic_mtype: res.maintenance_type,
						tic_equip: res.equipment_id[1],
						tic_equip_id: res.equipment_id[0],
						tic_space: res.equipment_location_id[1],
						tic_space_id: res.equipment_location_id[0],
						tic_mo_status: res.mro_state,
						tic_priority: res.priority_id[1],
						tic_priority_id: res.priority_id[0],
						tic_mteam: res.maintenance_team_id[1],
						tic_mteam_id: res.maintenance_team_id[0],
						tic_technican: res.employee_id[1],
						tic_status: res.state_id[1],
						tic_status_id: res.state_id[0],
						tic_type: res.type_category,
						tic_channel: res.channel,
						tic_cat: res.category_id[1],
						tic_cat_id: res.category_id[0],
						tic_sub_cat: res.sub_category_id[1],
						tic_sub_cat_id: res.sub_category_id[0],
						tic_partner: res.partner_id[1],
						tic_person: res.person_name,
						tic_company: res.company_id[1],
						tic_mail: res.email,
						tic_approval: res.approval_id[1],
						tic_support_rating: res.support_rating,
						tic_support_comment: res.support_comment,
						tic_tags: res.tag_ids[1],
						tic_desc: res.description,
						tic_sla: res.sla_id[1],
						tic_sla_id: res.sla_id[0],
						tic_sla_status: res.sla_active,
						tic_sla_timer: res.sla_timer,
						tic_sla_rule: res.sla_rule_id[1],
						tic_sla_rule_id: res.sla_rule_id[0],
						tic_orders: res.mro_ord_count,
						tic_messages: res.message_ids,
						tic_issue_type: res.issue_type

					});
					//this.getTimeFromFloatTimer();
				}
			}
		});
	}

	getdata() {
		this.setState({ dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getticketdata/' + token + '/' + this.state.id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.state.tic_messages = res.message_ids;
					this.setState({
						tic_name: res.display_name,
						tic_number: res.ticket_number,
						tic_asset: res.asset_id[1],
						tic_mtype: res.maintenance_type,
						tic_equip: res.equipment_id[1],
						tic_equip_id: res.equipment_id[0],
						tic_space: res.equipment_location_id[1],
						tic_space_id: res.equipment_location_id[0],
						tic_asset_id: res.asset_id[0],
						tic_mo_status: res.mro_state,
						tic_mteam: res.maintenance_team_id[1],
						tic_mteam_id: res.maintenance_team_id[0],
						tic_technican: res.employee_id[1],
						tic_priority: res.priority_id[1],
						tic_priority_id: res.priority_id[0],
						tic_status: res.state_id[1],
						tic_status_id: res.state_id[0],
						tic_type: res.type_category,
						tic_channel: res.channel,
						tic_cat: res.category_id[1],
						tic_cat_id: res.category_id[0],
						tic_sub_cat: res.sub_category_id[1],
						tic_sub_cat_id: res.sub_category_id[0],
						tic_partner: res.partner_id[1],
						tic_person: res.person_name,
						tic_company: res.company_id[1],
						tic_mail: res.email,
						tic_approval: res.approval_id[1],
						tic_support_rating: res.support_rating,
						tic_support_comment: res.support_comment,
						tic_tags: res.tag_ids[1],
						tic_desc: res.description,
						tic_sla: res.sla_id[1],
						tic_sla_id: res.sla_id[0],
						tic_sla_status: res.sla_active,
						tic_sla_timer: res.sla_timer,
						tic_sla_rule: res.sla_rule_id[1],
						tic_sla_rule_id: res.sla_rule_id[0],
						tic_orders: res.mro_ord_count,
						tic_messages: res.message_ids,
						tic_issue_type: res.issue_type

					});

					//this.getTimeFromFloatTimer();
				}
			}
		});
	}

	async getTicketsList() {

		if (!this.state.isOrdersIsLoaded) {
			this.setState({ ticketlistloading: true });
			this.setState({ left_style: '' });
			this.setState({ sticky_searchbar: '' });
			this.setState({ topbar_buttons: '' });
			this.setState({ content_card: '' });
			this.setState({ isNameFilter: false });
			//this.setState({ticketlist : []});
			var token = Sanitize.getStoreageItem('accessToken');
			var limit = '30';
			var offset = '0';

			await Reqst.axGet('gettickets/' + token + '/' + limit + '/' + offset).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else if(result['data'].code == '500'){
				
				  this.setState({ ticketlistloading: false });
				   alert(result['data'].data);
			   }
				else {
					this.setState({ ticketlistloading: false });
					this.setState({ ticketlist: res });
					this.setState({ left_style: 'left_search_card' });
					this.setState({ sticky_searchbar: 'sticky_searchbar' });
					this.setState({ topbar_buttons: 'topbar_buttons' });
					this.setState({ content_card: 'content_card' });
					this.setState({ isOrdersIsLoaded: true });
				}
			});
		}
	}

	async getCheckOrdersList(cids) {

		if (cids != '') {
			this.setState({ dataLoading: true });
			this.setState({ checklist: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			var data = { "token": token, "cids": cids }
			await Reqst.axPost('getchecklists', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ dataLoading: false });
					this.setState({ checklist: res });
				}
			});
		}
	}

	async getTimeSheetList() {
		var sids = this.state.ord_timesheets;
		if (sids != '') {
			if (!this.state.isTimeSheetLoaded) {
				this.setState({ timesheetloading: true });
				this.setState({ timesheets: [] });
				var token = Sanitize.getStoreageItem('accessToken');
				var data = { "token": token, "sids": sids }
				await Reqst.axPost('gettimesheets', data).then((result) => {

					let res = result['data'];
					if (res.code == '401') {

						this.getRefreshToken();

					}
					else {
						this.setState({ timesheetloading: false });
						this.setState({ isTimeSheetLoaded: true });
						this.setState({ timesheets: res });
					}
				});
			}
		}
	}

	async getPlannedParts() {
		var parts = this.state.ord_planned_parts;
		if (parts != '') {
			if (!this.state.isPlanPartsLoaded) {
				this.setState({ planpartsloading: true });
				this.setState({ planparts: [] });
				var token = Sanitize.getStoreageItem('accessToken');
				var data = { "token": token, "parts": parts }
				await Reqst.axPost('getplannedparts', data).then((result) => {

					let res = result['data'];
					if (res.code == '401') {

						this.getRefreshToken();

					}
					else {
						this.setState({ planpartsloading: false });
						this.setState({ isPlanPartsLoaded: true });
						this.setState({ planparts: res });
					}
				});
			}
		}
	}

	async getOrderTools() {
		var tools = this.state.ord_tools;
		if (tools != '') {
			if (!this.state.isOrderToolsLoaded) {
				this.setState({ toolsloading: true });
				this.setState({ ordertools: [] });
				var token = Sanitize.getStoreageItem('accessToken');
				var data = { "token": token, "tools": tools }
				await Reqst.axPost('getordertools', data).then((result) => {

					let res = result['data'];
					if (res.code == '401') {

						this.getRefreshToken();

					}
					else {
						this.setState({ toolsloading: false });
						this.setState({ isOrderToolsLoaded: true });
						this.setState({ ordertools: res });
					}
				});
			}
		}
	}

	async getMoveParts() {
		var parts = this.state.ord_move_parts;
		if (parts != '') {
			if (!this.state.isMovePartsLoaded) {
				this.setState({ movepartsloading: true });
				this.setState({ moveparts: [] });
				var token = Sanitize.getStoreageItem('accessToken');
				var data = { "token": token, "parts": parts }
				await Reqst.axPost('getmoveparts', data).then((result) => {

					let res = result['data'];
					if (res.code == '401') {

						this.getRefreshToken();

					}
					else {
						this.setState({ movepartsloading: false });
						this.setState({ isMovePartsLoaded: true });
						this.setState({ moveparts: res });
					}
				});
			}
		}
	}

	async getMovedParts() {
		var parts = this.state.ord_moved_parts;
		if (parts != '') {
			if (!this.state.isMovedPartsLoaded) {
				this.setState({ movedpartsloading: true });
				this.setState({ movedparts: [] });
				var token = Sanitize.getStoreageItem('accessToken');
				var data = { "token": token, "parts": parts }
				await Reqst.axPost('getmoveparts', data).then((result) => {

					let res = result['data'];
					if (res.code == '401') {

						this.getRefreshToken();

					}
					else {
						this.setState({ movedpartsloading: false });
						this.setState({ isMovedPartsLoaded: true });
						this.setState({ movedparts: res });
					}
				});
			}
		}
	}

	onTicketScroll() {

		const that = this;
		/*  $(window).scroll(() => { 
		 var container = $(".left_tree_card");
			 if(container.scrollTop+container.clientHeight == container.scrollHeight) {
			 	
				 this.getTicketListonScroll();
			 	
			 }
		  }); */


		$('#left_search_card_id').scroll(function () {

			if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
				if (!that.state.ticketlistloading) {
					that.getTicketListonScroll();
				}

			}
		});
	}

	getUnique() {
		var uniqueArray = [];
		var orders = this.state.ticketlist;
		// Loop through array values
		for (var i = 0; i < orders.length; i++) {
			if (uniqueArray.indexOf(orders[i]) === -1) {
				uniqueArray.push(orders[i]);
			}
		}
		return uniqueArray;
	}

	async getTicketListonScroll() {

		//if(!this.state.isEquipmentIsLoaded){
		//this.setState({isNameFilter:false});
		//this.setState({equiplist : []});
		var token = Sanitize.getStoreageItem('accessToken');
		var limit = '10';
		var offset = this.state.orderoffset + 10;
		this.setState({ orderoffset: this.state.orderoffset + 10 });
		/* if(offset=='80'){
			offset = '0';
		} */

		var isnamefilter = this.state.isNameFilter;

		console.log(isnamefilter);

		if (!isnamefilter) {

			this.setState({ ticketlistloading: true });
			await Reqst.axGet('gettickets/' + token + '/' + limit + '/' + offset).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ ticketlistloading: false });
					var orderdata = this.state.ticketlist.concat(res);
					this.setState({ ticketlist: orderdata });
					//console.log(orderdata);
				}
			});
		}
		else {

			var query = this.state.search_query;
			this.setState({ ticketFilterScrollLoad: true });
			var data = { "token": token, "limit": limit, "offset": offset, "query": query };
			await Reqst.axPost('getticketsfiterdata', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ ticketFilterScrollLoad: false });
					var orderdata = this.state.ticket_filter_data.concat(res);
					this.setState({ ticket_filter_data: orderdata });
				}
			});

		}
		//}
	}


	resetFilter() {

		this.setState({ isNameFilter: false });
		this.setState({ ticket_search_name: '' });
		this.setState({ search_query: '' });
		this.setState({ is_search_enable: false });
		$("#search_text").val('');

	}

	handleCloseSavedTags(tag) {

		this.setState({ isNameFilter: false });
		this.setState({ ticket_search_name: '', savedtags: [] });
		this.setState({ search_query: '' });
		this.setState({ is_search_enable: false });
		$("#search_text").val('');

	}

	getTimeFromFloat(decimalTimeString) {

		var isimagine = '';

		if (decimalTimeString < 0) {

			decimalTimeString = Math.abs(decimalTimeString);
			isimagine = '-';
		}
		var decimalTime = parseFloat(decimalTimeString);
		decimalTime = decimalTime * 60 * 60;
		var hours = Math.floor((decimalTime / (60 * 60)));
		decimalTime = decimalTime - (hours * 60 * 60);
		var minutes = Math.floor((decimalTime / 60));
		decimalTime = decimalTime - (minutes * 60);
		var seconds = Math.round(decimalTime);
		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		var result = isimagine + "" + hours + ":" + minutes;

		return result;


	}


	getstatelabel(staten) {


		if (staten == 'Open') {

			return <span className="badge badge-danger custom_view_head_badge">Open</span>;

		}
		else if (staten == 'In Progress') {

			return <span className="badge badge-info custom_view_head_badge">In Progress</span>;

		}
		else if (staten == 'Staff Closed') {

			return <span className="badge badge-success custom_view_head_badge">Staff Closed</span>;

		}

		else {

			return '';

		}

	}

	getstatename(staten) {

		var orgstate = '';
		if (staten == 'draft') {

			orgstate = 'DRAFT';

		}
		else if (staten == 'released') {

			orgstate = 'WAITING PARTS';

		}
		else if (staten == 'ready') {

			orgstate = 'READY TO MAINTENANCE';

		}
		else if (staten == 'assigned') {

			orgstate = 'ASSIGNED';

		}
		else if (staten == 'in_progress') {

			orgstate = 'IN PROGRESS';

		}
		else if (staten == 'pause') {

			orgstate = 'PAUSE';

		}
		else if (staten == 'done') {

			orgstate = 'DONE';

		}
		else if (staten == 'cancel') {

			orgstate = 'CANCELED';

		}

		return orgstate;

	}


	getstatenamelabel(staten) {


		if (staten == 'mn') {

			return 'Maintenance';

		}
		else if (staten == 'op') {

			return 'Operative';

		}
		else if (staten == 'wh') {

			return 'Warehouse';

		}
		else if (staten == 'br') {

			return 'Breakdown';

		}
		else if (staten == 'sc') {

			return 'Scrapped';

		}

	}

	defclassgen(index) {

		var defclass = 'custom_col_left';

		var cval = 1;

		cval = cval + index;


		this.state.doccardcount = cval;

		var val = index % 2;

		if (index == 0) {

			defclass = 'custom_col_left';


		}
		else if (val == 1) {

			var val2 = cval % 4;

			defclass = 'custom_col_left custom_col_right';

			if (val2 == 0) {

				defclass = 'custom_col_right';
			}

		}
		else if (val == 0) {

			defclass = 'custom_col_left custom_col_right';

			var val1 = index % 4;

			if (val1 == 0) {

				defclass = 'custom_col_left';
			}

		}

		return defclass;


	}

	getproducttype(pr) {


		if (pr == 'consu') {

			return 'Consumable';

		}
		else if (pr == 'service') {

			return 'Service';

		}
		else if (pr == 'product') {

			return 'Storable Product';

		}
		else {

			return '';

		}

	}

	gettypename(tp) {


		if (tp == 'asset') {

			return 'Space';

		}
		else if (tp == 'general') {

			return 'General';

		}
		else if (tp == 'equipment') {

			return 'Equipment';

		}
		else {

			return '';

		}

	}

	getCritical(cr) {

		var orgcr = '';
		if (cr == '0') {

			return <span className="text-primary">General</span>;

		}
		else if (cr = '1') {

			return <span className="text-info">Important</span>;

		}
		else if (cr = '2') {

			return <span className="text-warning">Very Important</span>;

		}
		else if (cr = '3') {

			return <span className="text-danger">Critical</span>;

		}


	}

	getprioritynamebyname(pr) {

		if (pr == 'Low') {

			return <div><span className="badge badge-dot badge-dot-sm badge-info">&nbsp;</span><span>&nbsp;Low</span></div>;

		}
		else if (pr == 'Normal') {

			return <div><span className="badge badge-dot badge-dot-sm badge-warning">&nbsp;</span><span>&nbsp;Normal</span></div>;

		}
		else if (pr == 'High') {

			return <div><span className="badge badge-dot badge-dot-sm badge-orange">&nbsp;</span><span>&nbsp;High</span></div>;

		}
		else if (pr == 'Breakdown') {

			return <div><span className="badge badge-dot badge-dot-sm badge-danger">&nbsp;</span><span>&nbsp;Breakdown</span></div>;

		}



	}




	getRefreshToken() {

		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.getdata();
				this.getTicketsList();
				this.getFilters();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}

	getmtname(mt) {

		var orgstate = '';
		if (mt == 'bm') {

			orgstate = 'Corrective';

		}
		else if (mt == 'pm') {

			orgstate = 'Preventive';

		}
		else if (mt == 'oc') {

			orgstate = 'On Condition';

		}
		else if (mt == 'pr') {

			orgstate = 'Periodic';

		}

		return orgstate;

	}

	directtoanother() {

		this.setState({ isDirect: true });
		console.log(this.props.location.state);

	}


	filterdata() {

		var dat = [];
		for (var i = 0; i < this.state.ticketlist.length; i++) {


			if (this.state.ticket_search_name != '') {
				if (this.state.ticketlist[i].name.toLowerCase().search(this.state.ticket_search_name.toLowerCase()) != -1) {

					dat.push(this.state.ticketlist[i]);
				}
			}


		}

		return dat;

	}

	ticketfiltersclick() {

		this.setState({ is_search_enable: true });

	}

	ordersavedfilters(query) {

		try {

			this.setState({ isNameFilter: true });
			this.setState({ ticketlistFilterLoading: true });
			this.setState({ ticket_filter_data: [] });
			this.setState({ search_query: '' });
			var token = Sanitize.getStoreageItem('accessToken');
			//var searchval = this.state.ticket_search_name;
			//console.log(searchval);
			//var query = '["name", "ilike","'+searchval+'" ]';
			this.setState({ search_query: query });
			var rpp = '30';
			var offset = '0';
			var datasearch = [];

			/* datasearch = this.filterdata();
			
			if(datasearch.length>0){
				
				this.setState({ticket_filter_data : datasearch});
				this.setState({ticketlistFilterLoading:false});
					
			}
			else{ */
			var data = { "token": token, "limit": rpp, "offset": offset, "query": query };
			Reqst.axPost('getticketsfiterdata', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ ticketlistFilterLoading: false });
					this.setState({ ticket_filter_data: res });
				}
			});
			//}
		}
		catch (error) {
			console.log(error);
		}

	}

	ticketfilters(searchval) {

		try {

			this.setState({ isNameFilter: true });
			this.setState({ ticketlistFilterLoading: true });
			this.setState({ ticket_filter_data: [] });
			this.setState({ search_query: '' });
			var token = Sanitize.getStoreageItem('accessToken');
			//var searchval = this.state.ticket_search_name;
			console.log(searchval);
			var query = '["ticket_number", "ilike","' + searchval + '" ]';
			this.setState({ search_query: '["ticket_number", "ilike","' + searchval + '" ]' });
			var rpp = '30';
			var offset = '0';
			var datasearch = [];

			/* datasearch = this.filterdata();
			
			if(datasearch.length>0){
				
				this.setState({ticket_filter_data : datasearch});
				this.setState({ticketlistFilterLoading:false});
					
			}
			else{ */
			var data = { "token": token, "limit": rpp, "offset": offset, "query": query };
			Reqst.axPost('getticketsfiterdata', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ ticketlistFilterLoading: false });
					this.setState({ ticket_filter_data: res });
				}
			});
			//}
		}
		catch (error) {
			console.log(error);
		}

	}

	async onSendMessage() {
		this.setState({ sendMessage: true });
		var token = Sanitize.getStoreageItem('accessToken');
		var msg = $("#msg_body").val();
		var formdata = { "model": "website.support.ticket", "message_type": "comment", "res_id": parseInt(this.state.id), "body": msg };
		var data = { "token": token, "values": formdata };
		await Reqst.axPost('sendmessage', data).then((result) => {

			let res = result['data'];


			if (res) {

				this.setState({ sendMessage: false });
				$("#msg_body").val('');
				swal("Success", "Message Send Successfully..", "success");
				this.getdata();
			}
			else {

				this.setState({ sendMessage: false });
				swal("Error", "Unable to Send..", "error");

			}
		});
	}

	onTicketChange(e) {

		this.setState({ ticket_search_name: e.target.value });

		if (e.key === 'Enter' && e.target.value.length > 2) {
			this.ticketfilters(e.target.value);
		}

		if (e.target.value.length > 2) {
			this.ticketfilters(e.target.value);
		}

	}

	newpercalculate(total, given) {
		var total = parseInt(total);
		var given = parseInt(given);

		var perc = "";
		if (isNaN(total) || isNaN(given)) {
			perc = 0;
		} else {

			if (total == 0) {
				perc = 0;
			}
			else {
				perc = ((given / total) * 100);
			}
		}

		perc = Math.floor(perc);



		return perc;
	}

	upperCase(str) {
		if (str == '' || str == null || str == ' ')
			return '';
		else
			return str.toString().toUpperCase();
	}





	render() {


		if (this.state.isSpaceLink) {

			var spaceid = '';
			var spacename = '';

			if (this.state.tic_space_id) {

				spaceid = this.state.tic_space_id;
				spacename = this.state.tic_space;
			}
			else {
				spaceid = this.state.tic_asset_id;
				spacename = this.state.tic_asset;

			}

			var treenodes = [spaceid];



			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid: 'ticketview', value: spacename, uid: spaceid, treenodes: treenodes, ticketname: this.state.tic_number, ticketid: this.state.id }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'space') {


			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid: 'orderview', value: this.props.location.state.value, uid: this.props.location.state.uid, treenodes: this.props.location.state.treenodes, ordername: this.state.ord_name, orderid: this.state.id }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'equipmentview') {


			return (<Redirect to={{
				pathname: '/assets/equipment/view/' + this.props.location.state.uid,
				state: { modelkeyid: 'ticketview', value: this.state.tic_number, uid: this.state.id }
			}}
			/>);

		}

		if (this.state.isEquipLink) {


			return (<Redirect to={{
				pathname: '/assets/equipment/view/' + this.state.tic_equip_id,
				state: { modelkeyid: 'ticketview', value: this.state.tic_number, uid: this.state.id }
			}}
			/>);

		}

		var checklistper = this.newpercalculate(this.state.ord_checklists.length, this.getConfirmCheckLists());


		let equimg = '';

		if (this.state.tic_mtype == 'pm') {

			equimg = preventive_img;
		}
		else if (this.state.tic_mtype == 'bm') {

			equimg = corrective_img;

		}
		else if (this.state.tic_mtype == 'oc') {

			equimg = oc_img;

		}
		else if (this.state.tic_mtype == 'pr') {

			equimg = periodic_img;

		}

		// var savedfilters = this.state.filters;

		const issuetypes = [
			{
				value: 'request',
				label: 'Request'
			},
			{
				value: 'complaint',
				label: 'Complaint'
			},
			{
				value: 'incident',
				label: 'Incident'
			},
			{
				value: 'EHS',
				label: 'EHS'
			}
		];

		const selcolumns = [
			{
				value: '',
				label: 'Select'
			},
			{
				value: 'equip_asset_common',
				label: 'Equipment/Asset'
			},
			{
				value: 'name',
				label: 'Name'
			},
			{
				value: 'sequence',
				label: 'Asset Number'
			},
			{
				value: 'asset_id',
				label: 'Space'
			},
			{
				value: 'maintenance_team_id',
				label: 'Maintenance Team'
			},
			{
				value: 'company_id',
				label: 'Company'
			},
			{
				value: 'state',
				label: 'Status'
			},
			{
				value: 'maintenance_type',
				label: 'Maintenance'
			}
		];

		const selstates = [
			{
				value: 'draft',
				label: 'DRAFT'
			},
			{
				value: 'released',
				label: 'WAITING PARTS'
			},
			{
				value: 'ready',
				label: 'READY TO MAINTENANCE'
			},
			{
				value: 'assigned',
				label: 'ASSIGNED'
			},
			{
				value: 'in_progress',
				label: 'IN PROGRESS'
			},

			{
				value: 'pause',
				label: 'PAUSE'
			},
			{
				value: 'done',
				label: 'DONE'
			},
			{
				value: 'cancel',
				label: 'CANCELED'
			}
		];

		const selmtypes = [
			{
				value: 'bm',
				label: 'Corrective'
			},
			{
				value: 'pm',
				label: 'Preventive'
			},
			{
				value: 'oc',
				label: 'On Condition'
			},
			{
				value: 'pr',
				label: 'Periodic'
			}

		];

		const modes = [
			{
				value: 'web',
				label: 'Web'
			},
			{
				value: 'email',
				label: 'Email'
			},
			{
				value: 'phone',
				label: 'Phone'
			},
			{
				value: 'mobile app',
				label: 'Mobile app'
			}
		];





		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Ticket | {this.state.tic_number} </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>


					{!this.state.isOrdersIsLoaded ?

						<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
						:

						<div className="main_data">
							<div className={this.state.sticky_searchbar}>

								<FormGroup className={this.state.topbar_buttons}>


									{this.state.is_search_enable ?
										<Input id="search_text" className="mat_form" name="ticket_search_name" placeholder="Ticket Number" autofocus="true" ref={(input) => { this.ticket_search_name = input; }} onKeyDown={this.onTicketChange.bind(this)} />
										:
										<Button type="text" size="large" onClick={this.ticketfiltersclick}>
											<i className="pe-7s-search" style={{ fontSize: '20px' }} />
										</Button>

									}
									{!this.state.is_search_enable && !this.state.isNameFilter ?
										<span style={{ marginLeft: 'auto' }}>
											<Button type="text" onClick={() => this.setState({ searchDialog: true })}>
												<i className="lnr-funnel font-size-lg" />
											</Button>
										</span>
										: ''}

									{this.state.savedtags.length == 1 && !this.state.is_search_enable && this.state.isNameFilter ?
										<div style={{ marginLeft: 'auto', marginTop: '0px', marginRight: 'auto', textAlign: 'center' }}>
											{

												this.state.savedtags.map(tag => {
													return (
														<Tag
															key={tag.key}
															closable={true}
															type="primary"
															closeTransition={false}
															onClose={this.handleCloseSavedTags.bind(this, tag)}>{tag.name}</Tag>
													)
												})
											}
										</div>
										: ''}

									{this.state.is_search_enable ?
										<span style={{ marginLeft: 'auto' }}>
											<Tooltip className="item" content='Reset' placement="top-start" >
												<Button type="text" size="large" onClick={this.resetFilter}>
													<i className="pe-7s-close" style={{ fontSize: '20px' }} />
												</Button>
											</Tooltip>
										</span>
										: ''}
								</FormGroup>

							</div>


							<Modal isOpen={this.state.searchDialog} slide="right" size="sm" className="right1" backdrop={true}>
								<ModalHeader>
									Filters
												  <span className="modal_pull_right1"><button className="close" onClick={() => this.setState({ searchDialog: false, searchcolumn: '', searchfor: '', searchvalue: '' })}>&times;</button></span>
								</ModalHeader>

								<ModalBody className="right1_body">

									<div>


										{this.state.filtersLoading ?

											<div><center><Loader type="ball-clip-rotate" /></center></div>

											:
											this.state.filters.map((item, index) =>

												<div>
													<div style={{ padding: '4px 1px', display: 'flex', alignItems: 'center' }}>
														<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onFilterApply(item.id, item.domain, item.display_name)}>
															<div className="list_center_equal">
																<Tooltip className="item" content={item.display_name} placement="top-center" >
																	<span className="text-info"><i className="pe-7s-filter font-size-sm" style={{ fontWeight: 'bold', fontSize: '15px' }}> </i></span>&nbsp;<span style={{ fontSize: '15px' }} >{item.display_name}</span>
																</Tooltip>
															</div>
														</a>

													</div>

													<hr style={{ marginBottom: '2px', marginTop: '2px', borderTop: '1px solid #d1dbe5', width: '268px', marginLeft: '-15px' }} />
												</div>

											)
										}
									</div>

								</ModalBody>

							</Modal>

							<div className={this.state.left_style} id="left_search_card_id">

								{this.state.ticketlistFilterLoading ?

									<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>

									:
									<span>
										{this.state.isNameFilter ?
											<div>
												<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
												{this.state.ticket_filter_data.length > 0 ?
													<span>
														{this.state.ticket_filter_data.map((item, index) =>
															<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onTicketDataChange(item.id)}>
																<div style={{ padding: '1px 5px' }} >
																	<Tooltip className="item" content={item.ticket_number} placement="top-center" >
																		<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>{item.ticket_number}</b></span>
																	</Tooltip>
																	<div className="nowrap_content">
																		<span className="text-capitalize" style={{ fontSize: '11px' }}>{this.getmtname(item.maintenance_type)}&nbsp;</span>
																		{item.equipment_location_id[1] ?

																			<span className="text-capitalize" style={{ fontSize: '11px', whiteSpace: 'nowrap' }}>|&nbsp;{item.equipment_location_id[1]}</span>

																			: ''}
																	</div>

																</div>
																<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
															</a>
														)}
														{this.state.ticketFilterScrollLoad ?

															<div><center><Loader type="ball-clip-rotate" /></center></div>
															: ''}
													</span>

													:

													<div className="text-center"><img src={nodata} width="165" /> <br /><b>No Tickets Found..</b></div>

												}
											</div>


											:
											<span>
												<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
												{this.state.ticketlist.map((item, index) =>
													<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onTicketDataChange(item.id)} >
														<div style={{ padding: '1px 5px' }}>
															<Tooltip className="item" content={item.ticket_number} placement="top-center" >
																<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>{item.ticket_number}</b></span>
															</Tooltip>
															<div className="nowrap_content">
																<span className="text-capitalize" style={{ fontSize: '11px' }}>{this.getmtname(item.maintenance_type)} &nbsp;</span>
																{item.equipment_location_id[1] ?
																	<span className="text-capitalize" style={{ fontSize: '11px', whiteSpace: 'nowrap' }}>|&nbsp;{item.equipment_location_id[1]}</span>
																	: ''}
															</div>

														</div>
														<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
													</a>
												)}
												{this.state.ticketlistloading && this.state.left_style != '' ?

													<div><center><Loader type="ball-clip-rotate" /></center></div>
													: ''}
											</span>


										}
									</span>

								}


							</div>


							<div className={this.state.content_card}>

								<div className="drawer_left_menu pull-left">


									<PageTitleAlt3
										heading=""
										subheading=""
										icon=""
										menuLevel1={this.props.location.state ? this.props.location.state.value : locale.t('el.menus.tickets')}
										menuLevel2={this.props.location.state ? locale.t('el.menus.tickets') : this.state.tic_number}
										menuLevel3={this.props.location.state ? this.state.tic_number : ''}
										menuLevel2link={this.props.location.state ? '#/helpdesk/tickets' : false}
										menuLevel1click={() => this.props.location.state ? this.directtoanother() : ''}
										menuLevel1link={this.props.location.state ? 'javascript:;' : '#/helpdesk/tickets'}
										isbreadcumb={true}
									/>
								</div>


								{this.state.dataLoading ?
									<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
									:
									<div>

										<Card className="main-card mb-2 custom_card_view">

											<CardBody>
												{!this.state.formmode ?
													<Row>
														<Col lg="2" sm="12" xs="12">

															<div className="text-center">
																<img src={equimg} />
																<br />
																<span className="custom_view_head">{this.getmtname(this.state.tic_mtype)}</span>
															</div>

														</Col>
														<Col lg="4" sm="12" xs="12">
															<span className="text-info">#{this.state.tic_number}<br /></span>

															<span><b>{this.getnovalues(this.state.tic_name)}</b></span>&nbsp;&nbsp;{this.getstatelabel(this.state.tic_status)}<br />

															<Tooltip className="item" content="Type" placement="left" >
																<span><span className="text-info"><i className="pe-7s-box1 font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;{this.gettypename(this.state.tic_type)}</span>
															</Tooltip>
															{this.state.tic_type == 'equipment' ?

																<Tooltip className="item" content="Equipment" placement="left" >
																	<span><span className="text-info"><i className="pe-7s-tools font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;{this.state.tic_equip ? <a href="javascript:;" onClick={() => this.setState({ isEquipLink: true })} style={{ textDecoration: 'none', color: '#495057' }}>{this.getnovalues(this.state.tic_equip)}</a> : this.getnovalues(this.state.tic_equip)} </span>
																</Tooltip>

																: ''}

															<Tooltip className="item" content={this.state.tic_space ? 'Location' : 'Space'} placement="left" >
																<span><span className="text-info"><i className="lnr-map-marker font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>{this.state.tic_space ? <a href="javascript:;" onClick={() => this.setState({ isSpaceLink: true })} style={{ textDecoration: 'none', color: '#495057' }}>{this.getnovalues(this.state.tic_space)}</a> : <a href="javascript:;" onClick={() => this.setState({ isSpaceLink: true })} style={{ textDecoration: 'none', color: '#495057' }}>{this.getnovalues(this.state.tic_asset)}</a>} </span>
															</Tooltip>

														</Col>

														<Col lg="3" sm="12" xs="12" className="">

															<Tooltip className="item" content="Channel" placement="left" >
																<span><span className="text-info"><i className="lnr-screen font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>{this.getnovalues(this.state.tic_channel)}</span>
															</Tooltip>
															<Tooltip className="item" content="Category" placement="left" >
																<span><span className="text-info"><i className="lnr-bookmark font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>{this.getnovalues(this.state.tic_cat)}</span>
															</Tooltip>
															<Tooltip className="item" content="Priority" placement="left" >
																<span><span className="text-info"><i className="lnr-line-spacing font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>{this.state.tic_priority}</span>
															</Tooltip>


														</Col>

														<Col lg="3" sm="12" xs="12" className="mobile_form_buttons">

															<div className="btn-actions-pane-right text-capitalize actions-icon-btn pull-right">

																<Dropdown trigger="click" menu={(
																	<Dropdown.Menu>
																		<span className="el-select-group__title text-center">Manage</span>
																		<Dropdown.Item command="a" >
																			<div style={{ width: '90px' }} onClick={this.onFormModeChange}>
																				<Button type="text">
																					Edit
																   </Button>

																			</div>

																		</Dropdown.Item>

																	</Dropdown.Menu>
																)}>
																	<span className="el-dropdown-link">
																		<i className="el-icon-more" />
																	</span>
																</Dropdown>

															</div>



														</Col>
													</Row>
													:
													<Row>
														<Col lg="6" sm="12" xs="12">

														<FormGroup>
																<Label className="text-capitalize">Subject <span className="text-danger"></span></Label>
																<Input type="text" className="mat_form" name="tic_name" value={this.state.tic_name} onChange={this.onInputChange.bind(this)} bsSize="sm" />
															</FormGroup>

															<FormGroup>
																<Label className="text-capitalize">Channel <span className="text-danger"></span></Label>

																<Select onChange={this.setcontactmode} value={this.state.tic_channel} >
																	{
																		modes.map(el => {
																			return <Select.Option key={el.value} label={el.label} value={el.value} />
																		})
																	}
																</Select>
															</FormGroup>

															<FormGroup>
																<Label className="text-capitalize">Category <span className="text-danger"></span></Label>
																<Select onChange={this.handleCatChange} value={this.state.tic_cat_id} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this, 'cat')} loading={this.state.catLoading}>
																	{
																		this.state.cats.map(el => {
																			return <Select.Option key={el.id} label={el.name} value={el.id} />
																		})
																	}
																</Select>
															</FormGroup>


														</Col>
														<Col lg="6" sm="12" xs="12" style={{ marginTop: '-5px' }}>
															<FormGroup>
																<Label className="text-capitalize">Priority<span className="text-danger"></span></Label>
																<Select onChange={this.handlePrtyChange} value={this.state.tic_priority_id} clearable={true} loading={this.state.prtyLoading}>
																	{
																		this.state.priorities.map(el => {
																			return <Select.Option key={el.id} label={el.name} value={el.id} />
																		})
																	}
																</Select>
															</FormGroup>

															<FormGroup>
																<Label className="text-capitalize">Issue Type <span className="text-danger"></span></Label>
																<Select onChange={this.setissuetype} value={this.state.tic_issue_type}>
																	{
																		issuetypes.map(el => {
																			return <Select.Option key={el.value} label={el.label} value={el.value} />
																		})
																	}
																</Select>
															</FormGroup>
															
															<FormGroup>
																<Label className="text-capitalize">Sub Category <span className="text-danger"></span></Label>
																 <Select onChange={this.handleSubCatChange} value={this.state.tic_sub_cat_id ? this.state.tic_sub_cat_id : false} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this, 'subcat')} loading={this.state.subcatLoading} id="subcat">
																					{
																						this.state.subcats.map(el => {
																							return <Select.Option key={el.id} label={el.name} value={el.id} />
																						})
																					}
																</Select>
															</FormGroup>
															
														
														</Col>


														<Col lg="12" sm="12" xs="12" className="">

															<span className="pull-right">

																<span>
																	<Button type="primary" disabled={this.state.ticketLoading} onClick={this.updateTicket}>
																		{this.state.ticketLoading ? 'Updating...' : 'Update'}
																	</Button>
																	&nbsp;
																			<Button type="danger" onClick={() => this.setState({ formmode: false })} >
																		Cancel
																			</Button>
																</span>

															</span>
														</Col>



													</Row>

												}


											</CardBody>
										</Card>

										<Card className="main-card mb-2 custom_card_view1">
											<CardBody className="custom_card_body1">

												<Nav>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '1' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('1')} >{locale.t('el.tickets.summary')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '8' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('8')} >{locale.t('el.tickets.auditlog')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '9' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('9')} >{locale.t('el.tickets.documents')}</NavLink>
												</Nav>


											</CardBody>
										</Card>
										<div>
											{this.state.activeTab == '1' ?
												<Row>
													<Col md="12" lg="6" className="custom_col_left">
														<Card className="main-card mb-2">

															<CardBody>
																<Row>
																	<Col lg="6" sm="12" xs="12">
																	{!this.state.formmode ?
																		<FormGroup>
																			<Label className="text-capitalize">{locale.t('el.tickets.subCat')}</Label>
																			
																				<p><b>{this.getnovalues(this.state.tic_sub_cat)}</b></p>
																			
																		</FormGroup>
																		:
																			''
																		}
																		<FormGroup>
																			<Label className="text-capitalize">{locale.t('el.tickets.email')}</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.tic_mail)}</b></p>
																				:
																				<Input type="text" className="mat_form" name="tic_mail" value={this.state.tic_mail} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																		</FormGroup>
																		{!this.state.formmode ?
																			<div>
																				<FormGroup>
																					<Label className="text-capitalize">{locale.t('el.tickets.approval')}</Label>
																					<p><b>{this.getnovalues(this.state.tic_approval)}</b></p>
																				</FormGroup>
																				<FormGroup>
																					<Label className="text-capitalize">{locale.t('el.tickets.tags')}</Label>
																					<p><b>{this.getnovalues(this.state.tic_tags)}</b></p>
																				</FormGroup>
																			</div>
																			: ''}

																		<FormGroup>
																			<Label className="text-capitalize">{locale.t('el.tickets.maintenanceTeam')}</Label>

																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.tic_mteam)}</b></p>
																				:
																				<Select onChange={this.handleTeamChange} value={this.state.tic_mteam_id ? this.state.tic_mteam_id : false} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this, 'mteam')} loading={this.state.teamLoading}>
																					{
																						this.state.mteams.map(el => {
																							return <Select.Option key={el.id} label={el.name} value={el.id} />
																						})
																					}
																				</Select>
																			}
																		</FormGroup>

																		{!this.state.formmode ?
																			<div>
																				<FormGroup>
																					<Label className="text-capitalize">{locale.t('el.tickets.maintenanceOrder')}</Label>
																					<p><b><span className="badge badge-pill badge-primary">{this.state.tic_orders}</span></b></p>
																				</FormGroup>
																			</div>
																			: ''}



																	</Col>

																	<Col lg="6" sm="12" xs="12">

																		<FormGroup>
																			<Label className="text-capitalize">{locale.t('el.tickets.partner')}</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.tic_partner)}</b></p>
																				:
																				<Input type="text" className="mat_form" name="tic_partner" value={this.state.tic_partner} onChange={this.onInputChange.bind(this)} bsSize="sm" readOnly style={{ marginTop: '5px' }} />
																			}
																		</FormGroup>
																		<FormGroup>
																			<Label className="text-capitalize">{locale.t('el.tickets.personName')}</Label>
																			{!this.state.formmode ?

																				<p><b>{this.getnovalues(this.state.tic_person)}</b></p>
																				:
																				<Input type="text" className="mat_form" name="tic_person" value={this.state.tic_person} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																		</FormGroup>
																		{!this.state.formmode ?
																			<div>
																				<FormGroup>
																					<Label className="text-capitalize">{locale.t('el.tickets.supportRating')}</Label>
																					<p><b>{this.getnovalues(this.state.tic_support_rating)}</b></p>
																				</FormGroup>

																				<FormGroup>
																					<Label className="text-capitalize">{locale.t('el.tickets.supportComment')}</Label>
																					<p><b>{this.getnovalues(this.state.tic_support_comment)}</b></p>
																				</FormGroup>

																				<FormGroup>
																					<Label className="text-capitalize">{locale.t('el.tickets.technician')}</Label>
																					<p><b>{this.getnovalues(this.state.tic_technican)}</b></p>
																				</FormGroup>

																				<FormGroup>
																					<Label className="text-capitalize">{locale.t('el.tickets.orderStatus')}</Label>
																					<p><b>{this.getstatename(this.state.tic_mo_status)}</b></p>
																				</FormGroup>
																			</div>
																			: ''}



																	</Col>

																	<Col lg="12" sm="12" xs="12">

																		<FormGroup>
																			<Label className="text-capitalize">{locale.t('el.tickets.description')}</Label>
																			{!this.state.formmode ?
																				<p><b dangerouslySetInnerHTML={{ __html: this.getnovalues(this.state.tic_desc) }} /></p>
																				:
																				<Input type="text" className="mat_form" name="tic_desc" value={this.state.tic_desc} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																		</FormGroup>

																	</Col>



																</Row>

															</CardBody>
														</Card>
													</Col>
													<Col md="12" lg="6" className="custom_col_right">
														<Card className="main-card mb-2 " style={{ minHeight: '98%' }}>
															<CardBody>
																<div className="card-title text-uppercase">
																	SLA
									 							</div>
																<Row>
																	<Col lg="6" sm="12" xs="12">
																		<FormGroup>
																			<Label className="text-capitalize">SLA</Label>
																			
																				<p><b>{this.getnovalues(this.state.tic_sla)}</b></p>
																				
																		</FormGroup>

																		<FormGroup>
																			<Label className="text-capitalize">{locale.t('el.tickets.rule')}</Label>
																		
																				<p><b>{this.getnovalues(this.state.tic_sla_rule)}</b></p>
																				
																		</FormGroup>


																	</Col>

																	<Col lg="6" sm="12" xs="12">

																		<FormGroup>
																			<Label className="text-capitalize">{locale.t('el.tickets.status')}</Label><br />
																			
																				<p><b>{this.state.tic_sla_active ? <span className="badge badge-success custom_view_head_badge">Active</span> : <span className="badge badge-danger custom_view_head_badge">Inactive</span>}</b></p>
																				
																		</FormGroup>
																		<FormGroup>

																			<Label className="text-capitalize">SLA Time Remaining</Label>
																			
																				<p><b id="timer" style={{ fontSize: '18px' }}>{this.getTimeFromFloat(this.state.tic_sla_timer)}</b></p>
																				
																		</FormGroup>

																	</Col>



																</Row>

															</CardBody>

														</Card>
													</Col>

													<Col md="12" lg="12" >
														<Card className="main-card mb-2">
															<CardBody>
																<div className="card-title text-uppercase">
																	{locale.t('el.equipment.sendMessage')}
																</div>
																<br />

																<FormGroup>
																	<Label className="text-capitalize"></Label>
																	<Input type="text" className="mat_form" name="msg_body" id="msg_body" placeholder={locale.t('el.equipment.writeSomething')} bsSize="sm" />
																</FormGroup>
																<span className="pull-right">
																	<Button type="primary" disabled={this.state.sendMessage} onClick={this.onSendMessage}>
																		<i className="pe-7s-paper-plane"> </i>&nbsp;{this.state.sendMessage ? 'Sending...' : 'Send'}
																	</Button>
																</span>

															</CardBody>

														</Card>
													</Col>
												</Row>
												: ''}
										</div>

										<div>
											{this.state.activeTab == '8' ?
												<Row>
													<Col md="12" lg="12">
														<Card className="main-card mb-2 custom_card_view">

															<CardBody>

																{this.state.isAuditLogsLoaded ?
																	<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
																	:
																	<Col lg="12">
																		{this.state.audits.length > 0 ?

																			<VerticalTimeline animate={false} className="vertical-time-icons" layout="1-column">
																				{this.state.audits.map((item, index) =>
																					<VerticalTimelineElement
																						className="vertical-timeline-item"
																						icon={<div className="timeline-icon border-primary"><i
																							className="pe-7s-culture icon-gradient bg-night-fade" /></div>}
																					>

																						<h4 className="timeline-title">{item.author_id[1]} &nbsp; <span style={{ fontSize: '13px', fontWeight: '450', textTransform: 'lowercase' }}>{this.getdateoftimingsind(item.date)}</span></h4>
																						<p dangerouslySetInnerHTML={{ __html: item.body }} />
																						<ul>
																							{item.tracking_value_ids.map((item1, index1) =>
																								<li>{item1.old_value}&nbsp;<i className="lnr-arrow-right" />&nbsp;{item1.new_value}</li>
																							)}
																						</ul>
																					</VerticalTimelineElement>
																				)}
																				{this.state.auditLoading ?
																					<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
																					: ''}
																			</VerticalTimeline>
																			:
																			<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Audit Logs Found..</b></div>
																		}
																	</Col>

																}
															</CardBody>

														</Card>
													</Col>

												</Row>
												: ''
											}
										</div>

										<div>
											{this.state.activeTab == '9' ?

												<Card className="main-card mb-2 custom_card_view">
													<CardBody>

														{this.state.documentsLoading ?
															<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
															:
															this.state.documents.length > 0 ?
																<Row>

																	{this.state.documents.map((item, index) =>
																		<Col md="6" lg="3" className={this.defclassgen(index)}>
																			<Card className="widget-chart widget-chart2 text-center mb-2  card-shadow-primary">
																				<div className="widget-chat-wrapper-outer">
																					<div className="widget-chart-content">
																						<div className="widget-title opacity-5 text-uppercase">
																							{item.name}
																						</div>
																						<br />
																						{item.mimetype == 'application/pdf' ?
																							<div>
																								<div className="text-danger">
																									<FontAwesomeIcon icon={faFilePdf} size="5x" />
																								</div>
																								<br />
																								<span className="text-left"><b style={{ textTransform: 'capitalize' }}>{item.ir_attachment_categ ? item.ir_attachment_categ[1] : ''}</b></span>
																								<div className="pull-right">

																									<span onClick={() => this.pdffileview(item.mimetype, item.datas_fname, item.id, item.name)}>{this.state.documentSingleViewLoad && this.state.documentId == item.id ? 'Downloading..' : <i className="lnr-eye font-size-lg"> </i>}</span>&nbsp;
																				  <span onClick={() => this.filedownlod(item.mimetype, item.datas_fname, item.id)}>{this.state.documentSingleLoad && this.state.documentId == item.id ? 'Downloading..' : <i className="lnr-download font-size-lg"> </i>}</span>

																								</div>

																							</div>
																							:
																							<div>
																								<div className="text-info">
																									<FontAwesomeIcon icon={faFileImage} size="5x" />
																								</div>
																								<br />
																								<span className="text-left"><b style={{ textTransform: 'capitalize' }}>{item.ir_attachment_categ ? item.ir_attachment_categ[1] : ''}</b></span>

																								<div className="pull-right">

																									<span onClick={() => this.imagefileview(item.mimetype, item.datas_fname, item.id, item.name)}>{this.state.documentSingleImgViewLoad && this.state.documentId == item.id ? 'Downloading..' : <i className="lnr-eye font-size-lg"> </i>}</span>&nbsp;
																				  <span onClick={() => this.filedownlod(item.mimetype, item.datas_fname, item.id)}>{this.state.documentSingleLoad && this.state.documentId == item.id ? 'Downloading..' : <i className="lnr-download font-size-lg"> </i>}</span>

																								</div>

																							</div>

																						}
																					</div>
																				</div>
																			</Card>
																		</Col>
																	)}

																	{this.state.formmode ?

																		<Col md="6" lg="3" className={this.defclassgen(this.state.documents.length + 1)}>
																			<Card className="widget-chart widget-chart2 text-center mb-2  card-shadow-primary">
																				<div className="widget-chat-wrapper-outer">
																					<div className="widget-chart-content">
																						<div className="widget-title opacity-5 text-uppercase">

																						</div>
																						<br />

																						<div>
																							<div className="text-primary">
																								<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
																									{this.state.files != '' ?
																										<img src={this.state.files} style={{ border: '1px solid #cccccc', width: '97px', cursor: 'pointer' }} /> :

																										<i className="pe-7s-cloud-upload" style={{ fontSize: '126px', cursor: 'pointer' }} ></i>

																									}

																								</ReactFileReader>
																								<div className="el-upload__tip"></div>
																								{this.state.files != '' ?
																									<ul className="el-upload-list el-upload-list--text">
																										<li className="el-upload-list__item is-success">
																											<a className="el-upload-list__item-name">
																												<i className="el-icon-document"></i> &nbsp; {this.state.photoname}
																											</a>
																											<label className="el-upload-list__item-status-label">
																												<i className="el-icon-upload-success el-icon-circle-check"></i>
																											</label>
																											<i className="el-icon-close" onClick={this.handlephotoRemove}></i>
																										</li>
																									</ul>
																									: ''}
																							</div>


																						</div>

																					</div>
																				</div>
																			</Card>
																		</Col>
																		: ''}

																</Row>
																:
																!this.state.formmode ?
																	<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Documents Found..</b></div>
																	:

																	<Col md="6" lg="3" className={this.defclassgen(0)}>
																		<Card className="widget-chart widget-chart2 text-center mb-2  card-shadow-primary">
																			<div className="widget-chat-wrapper-outer">
																				<div className="widget-chart-content">
																					<div className="widget-title opacity-5 text-uppercase">

																					</div>
																					<br />

																					<div>
																						<div className="text-primary">
																							<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
																								{this.state.files != '' ?
																									<img src={this.state.files} style={{ border: '1px solid #cccccc', width: '145px', cursor: 'pointer' }} /> :

																									<i className="pe-7s-cloud-upload" style={{ fontSize: '145px', cursor: 'pointer' }} ></i>

																								}

																							</ReactFileReader>
																							<div className="el-upload__tip"></div>
																							{this.state.files != '' ?
																								<ul className="el-upload-list el-upload-list--text">
																									<li className="el-upload-list__item is-success">
																										<a className="el-upload-list__item-name">
																											<i className="el-icon-document"></i> &nbsp; {this.state.photoname}
																										</a>
																										<label className="el-upload-list__item-status-label">
																											<i className="el-icon-upload-success el-icon-circle-check"></i>
																										</label>
																										<i className="el-icon-close" onClick={this.handlephotoRemove}></i>
																									</li>
																								</ul>
																								: ''}
																						</div>


																					</div>

																				</div>
																			</div>
																		</Card>
																	</Col>
														}

														<Dialog
															title={this.state.viewfilename}
															size="small"
															visible={this.state.pdfviewdialog}
															onCancel={() => this.setState({ pdfviewdialog: false })}
															lockScroll={false}
														>
															<Dialog.Body>

																{this.state.documentSingleViewLoad ?

																	<div className="text-center"><Loader type="ball-clip-rotate" /></div>

																	:
																	<iframe width="640" height="400" frameborder="1" src={this.state.documentviewdata}></iframe>
																}
															</Dialog.Body>

														</Dialog>

														<Dialog
															title={this.state.viewfilename}
															size="tiny"
															visible={this.state.imgviewdialog}
															onCancel={() => this.setState({ imgviewdialog: false })}
															lockScroll={false}
														>
															<Dialog.Body>
																{this.state.documentSingleImgViewLoad ?

																	<div className="text-center"><Loader type="ball-clip-rotate" /></div>

																	:
																	<div className="text-center">
																		<img src={this.state.documentviewdata} width="300" height="300" />
																	</div>

																}
															</Dialog.Body>

														</Dialog>
													</CardBody>

												</Card>

												: ''
											}
										</div>



									</div>
								}
							</div>
						</div>
					}
				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
