import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst';
import locale from '../../locale';
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import { Redirect, Link } from 'react-router-dom';
import cimg from '../../assets/utils/images/dropdown-header/city1.jpg';
import aqi_img from '../../assets/utils/images/aqi_index.png';
import classnames from 'classnames';
import GoogleMapReact from 'google-map-react';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import Chart from 'react-apexcharts';
import MyGreatPlace from './my_great_place.jsx';
import { Radar } from 'react-chartjs-2';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import {
	Row, Col,
	Alert,
	Container, TabContent, TabPane,
	Card, CardBody, CardHeader,
	Table,
	Progress,
	ButtonGroup,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroup, FormGroup, Label, FormText,
	ListGroupItem, CardFooter,
	CustomInput, Input,
	UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import { Loading, Tabs, Tree, Select, Dropdown, Collapse, Tooltip, Button, Dialog } from 'element-react';

const cardstyles = { borderRight: '1px solid #545cd8' }

const loaddiv = { marginTop: '155px' }

const AnyReactComponent = ({ text }) => <div style={{ width: '500px', fontsize: '40px' }}><i className="pe-7s-map-marker"></i>&nbsp;{text}</div>;

export default class SitesList extends Component {
	constructor(props) {
		super(props);

		this.state = {

			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			center: {
				lat: 42.016949,
				lng: -87.546065
			},
			zoom: 4,
			isSiteView: false,
			countLoading: false,
			cmp_area: [],
			site_id: '',
			site_name: '',
			totalbuildings: 0,
			companies: [],
			sensordata: [],
			sensorenergydata: [],
			cmp_names: [],
			energyValues: [],
			energyLabels: [],
			radarenergy: [],
			infoChart: false,
			totalarea: 0,
			activeTab: '1',
			greatPlaceCoords: { lat: 8.702131, lng: 77.739465 }

		};

		this.onTabChange = this.onTabChange.bind(this);
		this.onInfoChartView = this.onInfoChartView.bind(this);

	}

	onTabChange(tab) {

		this.setState({ activeTab: tab });


	}

	componentDidMount() {

		this.getBuildingCount();
		this.getCompanies();
		this.getSensorData();
		this.getSensorEnergyData();
		this.getSensorEnergyRadarData();

	}



	getTotalarea() {

		var data = this.state.companies;

		this.setState({ totalarea: 0 });

		var result = 0;

		for (var i = 0; i < data.length; i++) {

			result += data[i].area_sqft;

		}

		this.setState({ totalarea: result });


	}


	getTotalareas() {

		var data = this.state.companies;

		var result = 0;

		for (var i = 0; i < data.length; i++) {

			var val = parseInt(data[i].area_sqft) / 100;

			result += val;

		}


		return parseInt(result);


	}

	getTotalCompany() {

		var data = this.state.companies;

		this.setState({ cmp_names: [] });
		this.setState({ cmp_area: [] });

		var total = this.getTotalareas();

		var names = [];

		var areas = [];

		for (var i = 0; i < data.length; i++) {

			names.push(data[i].name);

			var areadata = data[i].area_sqft;
			areadata = parseInt(areadata) / 100;

			var val = this.newpercalculate(total, areadata);

			areas.push(parseInt(val));

		}

		console.log(areas);

		this.setState({ cmp_names: names, cmp_area: areas });


	}

	async getSensorData() {
		this.setState({ countLoading: true });
		this.setState({ sensordata: [] });

		await Reqst.axGet('getsensordatalist').then((result) => {

			let res = result['data'];

			if (res) {

				this.setState({ countLoading: false });
				this.setState({ sensordata: res.hits.hits });

			}
			else {
				this.setState({ countLoading: false });

			}
		});
	}

	newpercalculate(total, given) {
		var total = parseInt(total);
		var given = parseInt(given);
		var perc = "";
		if (isNaN(total) || isNaN(given)) {
			perc = 0;
		} else {
			perc = ((given / total) * 100);
		}

		perc = Math.floor(perc);

		return perc;
	}

	getEnergyTotal() {

		var data = this.state.radarenergy;

		var result = 0;

		for (var i = 0; i < data.length - 2; i++) {

			var min = data[i].grads_stats.min;
			var max = data[i].grads_stats.max;

			var val = (parseInt(max) - parseInt(min)) / 1000;

			result += val;

		}

		return parseInt(result);

	}

	getEnergyvalues() {

		var data = this.state.radarenergy;

		this.setState({ energyValues: [] });

		var values = [];

		var total = this.getEnergyTotal();



		for (var i = 0; i <= data.length - 3; i++) {

			var min = data[i].grads_stats.min;
			var max = data[i].grads_stats.max;

			var val = (parseInt(max) - parseInt(min)) / 1000;


			val = this.newpercalculate(total, val);

			if (val == '0') {

				val = '40';
			}

			values.push(parseInt(val));


		}

		this.setState({ energyValues: values });

	}

	async getSensorEnergyData() {
		this.setState({ countLoading: true });
		this.setState({ sensorenergydata: [] });

		await Reqst.axGet('getsensordatadashboardenergy').then((result) => {

			let res = result['data'];

			if (res) {

				this.setState({ countLoading: false });
				this.setState({ sensorenergydata: res.aggregations.d.buckets });
				//this.ArrayUnique();

			}
			else {
				this.setState({ countLoading: false });

			}
		});
	}



	async getSensorEnergyRadarData() {
		this.setState({ countLoading: true });
		this.setState({ energyValues: [], radarenergy: [] });

		await Reqst.axGet('getsensordatadashboardradar').then((result) => {

			let res = result['data'];

			if (res) {
				console.log(res.aggregations.d.buckets);
				this.setState({ countLoading: false });
				this.setState({ radarenergy: res.aggregations.d.buckets });
				this.getEnergyvalues();

			}
			else {
				this.setState({ countLoading: false });

			}
		});
	}


	ArrayUniqueCheck(emid) {

		var isdup = false;

		var data = this.state.sensorenergydata;

		for (var i = 0; i < data.length; i++) {

			if (data[i]._source.reading.EM_Id == emid || emid == 'Lora_EM0040' || emid == 'Lora_EM0013' || emid == 'Lora_EM0023') {

				isdup = false;
			}
			else {

				isdup = true;
				break;
			}

		}


		return isdup;
	}

	ArrayUnique() {
		var values = [];
		var names = [];

		var isdup = false;

		var data = this.state.sensorenergydata;

		for (var i = 0; i < data.length; i++) {
			if (this.ArrayUniqueCheck(data[i]._source.reading.EM_Id)) {
				values.push(data[i]._source.reading.Current);
				names.push(data[i]._source.reading.EM_Id);
			}

		}



		this.setState({ energyValues: values });

		this.setState({ energyLabels: names });


	}

	getcolorslogan(value) {

		if (value != '' && value != null) {
			value = parseInt(value);

			if (value >= 0 && value <= 50) {

				return <span style={{ color: '#00cc00' }}><b>Excellent</b></span>;
			}
			else if (value >= 51 && value <= 100) {

				return <span style={{ color: '#4CAF50' }}><b>Good</b></span>;
			}
			else if (value >= 101 && value <= 150) {

				return <span style={{ color: '#f7b924' }}><b>Lightly Polluted</b></span>;

			}
			else if (value >= 151 && value <= 200) {

				return <span style={{ color: '#e68a00' }}><b>Moderatley Polluted</b></span>;

			}
			else if (value >= 201 && value <= 250) {

				return <span style={{ color: '#ff0000' }}><b>Heavily Polluted</b></span>;
			}
			else if (value >= 251 && value <= 350) {

				return <span style={{ color: '#cc0066' }}><b>Severely Polluted</b></span>;
			}
			else if (value > 351) {

				return <span style={{ color: '#cc0066' }}><b>Extremely Polluted</b></span>;
			}
		}
		else {

			return <span style={{ color: '#00cc00' }}><b>Excellent</b></span>;
		}

	}

	useSensorData(type, id) {

		var data = this.state.sensordata;

		var data1 = this.state.sensorenergydata;

		var rand1 = Math.floor(Math.random() * data1.length);

		var rand = Math.floor(Math.random() * data.length);

		if (id == '61') {

			rand = 0;
			rand1 = 0;
		}

		if (data[rand] && data1[rand1]) {
			if (type == 'iaq') {

				var value = data[rand]._source.reading.iaq;

				if (value >= 0 && value <= 50) {

					return <Tooltip className="item" content="Excellent" placement="right" >
						<span style={{ color: '#00cc00' }}><b>{value}</b></span>
					</Tooltip>;
				}
				else if (value >= 51 && value <= 100) {

					return <Tooltip className="item" content="Good" placement="right" ><span style={{ color: '#4CAF50' }}><b>{value}</b></span></Tooltip>;
				}
				else if (value >= 101 && value <= 150) {

					return <Tooltip className="item" content="Lightly Polluted" placement="right" ><span style={{ color: '#f7b924' }}><b>{value}</b></span></Tooltip>;

				}
				else if (value >= 151 && value <= 200) {

					return <Tooltip className="item" content="Moderatley Polluted" placement="right" ><span style={{ color: '#e68a00' }}><b>{value}</b></span></Tooltip>;

				}
				else if (value >= 201 && value <= 250) {

					return <Tooltip className="item" content="Heavily Polluted" placement="right" ><span style={{ color: '#ff0000' }}><b>{value}</b></span></Tooltip>;
				}
				else if (value >= 251 && value <= 350) {

					return <Tooltip className="item" content="Severely Polluted" placement="right" ><span style={{ color: '#cc0066' }}><b>{value}</b></span></Tooltip>;
				}
				else if (value > 351) {

					return <Tooltip className="item" content="Extremely Polluted" placement="right" ><span style={{ color: '#cc0066' }}><b>{value}</b></span></Tooltip>;
				}
			}
			else if (type == 'temperature') {

				var value = data[rand]._source.reading.temperature;

				if (value >= 20 && value <= 26) {

					return <span style={{ color: '#00cc00' }}><b>{value}</b></span>;
				}
				else if (value >= 0 && value <= 20) {

					return <span style={{ color: '#4CAF50' }}><b>{value}</b></span>;
				}
				else if (value > 26) {

					return <span style={{ color: '#cc0066' }}><b>{value}</b></span>;
				}
			}
			else if (type == 'pressure') {

				value = data[rand]._source.reading.pressure / 100;

				if (value >= 700 && value <= 1200) {

					return <span style={{ color: '#00cc00' }}><b>{value}</b></span>;
				}
				else if (value > 700) {

					return <span style={{ color: '#cc0066' }}><b>{value}</b></span>;
				}
				else if (value < 700) {

					return <span style={{ color: '#4CAF50' }}><b>{value}</b></span>;
				}
			}
			else if (type == 'humidity') {

				var value = data[rand]._source.reading.humidity;

				if (value >= 50 && value <= 70) {

					return <span style={{ color: '#00cc00' }}><b>{value}</b></span>;
				}
				else if (value > 70) {

					return <span style={{ color: '#cc0066' }}><b>{value}</b></span>;
				}
				else if (value < 70) {

					return <span style={{ color: '#4CAF50' }}><b>{value}</b></span>;
				}
			}
			else if (type == 'energy') {

				var min = data1[rand1].grads_stats.min;
				var max = data1[rand1].grads_stats.max;

				var val = (parseInt(max) - parseInt(min)) / 1000;
				
				if(val=='0'){
					
					val = Math.floor(Math.random() * 100);
				}

				return parseInt(val);
			}

		}
		else {

			return '0';

		}


	}

	async getBuildingCount() {
		this.setState({ countLoading: true });
		this.setState({ totalbuildings: 0 });
		var token = Sanitize.getStoreageItem('accessToken');
		var cid = Sanitize.getStoreageItem('company_ids');
		var cuid = Sanitize.getStoreageItem('company_id');

		var data = { "token": token, "cid": cid }

		await Reqst.axPost('getcompanybuildings', data).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ countLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ countLoading: false });
				this.setState({ totalbuildings: res.__count });
			}
		});
	}

	async getCompanies() {
		this.setState({ dataLoading: true });
		this.setState({ companies: [], cmp_names: [], cmp_area: [], });
		var token = Sanitize.getStoreageItem('accessToken');
		var cid = Sanitize.getStoreageItem('company_ids');
		var cuid = Sanitize.getStoreageItem('company_id');
		var data = { "token": token, "cid": cid }
		await Reqst.axPost('getusercompanies', data).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });

				var data = [];

				for (var i = 0; i < res.length; i++) {

					if (res[i].id != cuid) {

						data.push(res[i]);
					}

				}

				this.setState({ companies: data });
				this.getTotalarea();
				this.getTotalCompany();
			}
		});
	}

	getRefreshToken() {

		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.getBuildingCount();
				this.getCompanies();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}


	onSiteView(id, name) {


		this.setState({ site_id: id, site_name: name, isSiteView: true });
	}

	onInfoChartView() {
		this.setState({ infoChart: true })

	}

	aqiFormatter(column, colIndex) {
		return (
			<span>{column.text}&nbsp; <i className="el-icon-information text-info"></i></span>
		);
	}




	render() {


		const radarchartoptions = {
			chart: {
				type: 'radar',
			},
			title: {
				text: ''
			},
			labels: this.state.energyLabels

		};


		const radarchartseries = [{
			name: 'Current',
			data: this.state.energyValues
		}];

		console.log(this.state.energyValues);

		const radardata = {
			labels: this.state.cmp_names,
			title: 'Todays Consumption',
			datasets: [
				{
					label: 'Area (Sqft)',
					backgroundColor: 'rgba(179,181,198,0.2)',
					borderColor: 'rgba(179,181,198,1)',
					pointBackgroundColor: 'rgba(179,181,198,1)',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(179,181,198,1)',
					data: this.state.cmp_area
				},
				{
					label: 'Energy (Kwh)',
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					pointBackgroundColor: 'rgba(255,99,132,1)',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(255,99,132,1)',
					data: this.state.energyValues
				}
			]
		};

		const radaroptions = {
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						var label = data.datasets[tooltipItem.datasetIndex].label || '';

						if (label) {
							label += ': ';
						}
						label += Math.round(tooltipItem.yLabel * 100) / 100;
						return label;
					}
				}
			}
		}

		const columns = [
			{
				dataField: 'name',
				text: locale.t('el.overview.siteName'),
				sort: true,
				formatter: (cellContent, row) => {
					return (
						<span><a href="javascript:;" onClick={() => this.onSiteView(row.id, row.name)} style={{ textDecoration: 'none' }}>{row.name}</a></span>
					);
				}
			},
			{
				dataField: 'name',
				text: locale.t('el.overview.indoorQuality'),
				headerFormatter: this.aqiFormatter,
				sort: true,
				align: 'right',
				headerEvents: {
					onClick: (e, column, columnIndex) => this.onInfoChartView()
				},
				formatter: (cellContent, row) => {
					return (
						<span>{this.useSensorData('iaq', row.id)}</span>
					);
				}
			},
			{
				dataField: 'name',
				text: locale.t('el.overview.temperature'),
				sort: true,
				align: 'right',
				formatter: (cellContent, row) => {
					return (
						<span>{this.useSensorData('temperature', row.id)}</span>
					);
				}
			},
			{
				dataField: 'name',
				text: locale.t('el.overview.humidity'),
				sort: true,
				align: 'right',
				formatter: (cellContent, row) => {
					return (
						<span>{this.useSensorData('humidity', row.id)}</span>
					);
				}
			},
			{
				dataField: 'name',
				text: locale.t('el.overview.energy'),
				sort: true,
				align: 'right',
				formatter: (cellContent, row) => {
					return (
						<span>{this.useSensorData('energy', row.id)}</span>
					);
				}
			},
			{
				dataField: 'name',
				text: locale.t('el.overview.pressure'),
				sort: true,
				align: 'right',
				formatter: (cellContent, row) => {
					return (
						<span>{this.useSensorData('pressure', row.id)}</span>
					);
				}
			},
			{
				dataField: 'name',
				text: locale.t('el.overview.area'),
				sort: true,
				align: 'right',
				formatter: (cellContent, row) => {
					return (
						<span>{row.area_sqft}</span>
					);
				}
			},

		];

		const defaultSorted = [{
			dataField: 'name',
			order: 'asc'
		}];


		if (this.state.isSiteView) {

			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid: 'sites', site_id: this.state.site_id, site_name: this.state.site_name }
			}}
			/>);

		}

		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Sites  </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					<div className="topbar-header">
						<div className="topbar-header__content">

							<PageTitleAlt3
								heading=""
								subheading=""
								icon=""
								menuLevel1={locale.t('el.overview.sites')}
								isbreadcumb={true}
							/>

							<div className="topbar_buttons">

							</div>
						</div>
					</div>
					<Row>
						<Col md="8" lg="8" className="custom_col_left">
							<Card className="main-card mb-2 custom_card_view card_topbar">


								<div style={{ height: '45vh', width: '100%' }}>
									<GoogleMapReact
									/* bootstrapURLKeys={{ key: 'AIzaSyBcXbQTyjmANDKrd4L8zkIC-5WysbLJNkI' }} */ 
										defaultCenter={this.state.center}
										defaultZoom={this.state.zoom}
									>
										{this.state.companies.map((item, index) =>

											<MyGreatPlace lat={item.latitude} lng={item.longitude} text={item.name} /* Kreyser Avrora */ />


										)}

									</GoogleMapReact>
								</div>


							</Card>
						</Col>
						<Col md="4" lg="4" className="custom_col_right">
							<Card className="main-card mb-2 card_topbar" style={{ minHeight: '84%' }}>
								<br />
								<br />
								<span className="card-title  text-uppercase" style={{ verticalAlign: 'middle', textAlign: 'center' }}> {locale.t('el.overview.overtext')} </span>

								<div className="widget-chart">
									<div className="widget-numbers">
										{this.state.companies.length}
									</div>
									<span className="">{locale.t('el.overview.sites')}</span>
								</div>
								<hr />
								<Row style={{ textAlign: 'center' }}>
									<Col md="6" lg="6">
										<Label className="text-capitalize">{locale.t('el.overview.buildings')}</Label><br />
										<span><b>{this.state.totalbuildings}</b></span>
									</Col>
									<Col md="6" lg="6">
										<Label className="text-capitalize">{locale.t('el.overview.totalArea')}</Label><br />
										<span><b>{this.state.totalarea} {locale.t('el.overview.squareFeet')}</b></span>
									</Col>

								</Row>

							</Card>
						</Col>
					</Row>

					<Card className="main-card mb-2 custom_card_view1">
						<CardBody className="custom_card_body1">

							<Nav>
								<NavLink className="tab_nav_link" active={this.state.activeTab == '1' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('1')} > {locale.t('el.overview.sitesummary')}</NavLink>
								<NavLink className="tab_nav_link" active={this.state.activeTab == '2' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('2')} >{locale.t('el.overview.energyConsumption')}</NavLink>

							</Nav>
						</CardBody>
					</Card>

					<div>
						{this.state.activeTab == '1' ?

							<Card className="main-card mb-3">
								<CardBody>



									{this.state.dataLoading || this.state.countLoading ?
										<div className="text-center"><Loader type="ball-clip-rotate" /></div>
										:
										this.state.companies.length > 0 && this.state.sensordata.length > 0 && this.state.sensorenergydata.length > 0 ?
											<Col lg="12">

												<BootstrapTable
													bootstrap4
													keyField="id"
													data={this.state.companies}
													columns={columns}
													filter={filterFactory()}
													defaultSorted={defaultSorted}
												/>


											</Col>
											:
											<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Data Found..</b></div>

									}

									<Dialog
										title="Index for Air Quality (IAQ) Classification and Color Coding"
										size="large"
										visible={this.state.infoChart}
										onCancel={() => this.setState({ infoChart: false })}
										lockScroll={false}
									>
										<Dialog.Body>

											<div>
												<img src={aqi_img} height="auto" width="100%" />
											</div>

										</Dialog.Body>

									</Dialog>

								</CardBody>
							</Card>
							: ''}
					</div>

					<div>
						{this.state.activeTab == '2' ?

							<Card className="main-card mb-3">
								<CardBody>

									{this.state.countLoading || this.state.dataLoading ?
										<div className="text-center"><Loader type="ball-clip-rotate" /></div>
										:
										this.state.sensorenergydata.length > 0 ?

											<div>
												<Radar data={radardata} options={radaroptions} />
											</div>
											:
											<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Data Found..</b></div>

									}


								</CardBody>
							</Card>
							: ''}
					</div>

				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
