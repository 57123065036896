import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import {Helmet} from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import {Redirect,Link} from 'react-router-dom';
import classnames from 'classnames';
import preventive_img from '../../assets/utils/images/Preventive_black.png';
import oc_img from '../../assets/utils/images/On Condition_black.png';
import corrective_img from '../../assets/utils/images/Corrective_black.png';
import periodic_img from '../../assets/utils/images/Periodic_black.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import {Doughnut} from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import ReactTooltip from 'react-tooltip';
import locale from '../../locale';

import {
    faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import {
    Row, Col,
	Alert,
    Container,TabContent,TabPane,
	Card,CardBody,CardHeader,
	Table,
    ButtonGroup,
	Modal,ModalHeader,ModalBody,ModalFooter,
    ListGroup,FormGroup,Label,FormText,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import {Loading,Tabs,Tree,Select,Dropdown,Button,Progress,Tooltip,Tag,DatePicker,Dialog} from 'element-react';

const cardstyles = {borderRight: '1px solid #545cd8'}

const loaddiv = {marginTop: '155px' }

export default class ViewOrder extends Component {
	constructor(props) {
        super(props);

        this.state = {
			accTok : Sanitize.getStoreageItem('accessToken'),
			reTok : Sanitize.getStoreageItem('refreshToken'),
			order_filter_data  : [],
			filters : [],
			searchcolumn : '',
			searchfor : '',
			searchvalue : '',
			equipments : [],
			selsearchtext : '',
			spaces : [],
			teamLoading : false,
			mteams : [],
			selToolsLoading : false,
			seltools : [],
			spaceLoading : false,
			partAddLoading : false,
			equipLoading : false,
			ord_asset_upid : '',
			assetLoading : false,
			parts_add : false,
			assets : [],
			ord_equip_upid : '',
			dataLoading : false,
			isEquipLink : false,
			isNameFilter : false,
			orderlistloading : false,
			searchDialog :  false,
			documentviewdata : '',
			timelinelimit : 0,
			audits : [],
			auditLoading : false,
			sendMessage : false,
			orderFilterScrollLoad : false,
			orderlist : [],
			order_search_name : '',
			topbar_buttons : '',
			timesheetloading : false,
			ord_space_upid : '',
			isSpaceLink : false,
			toolsloading : false,
			movepartsloading : false,
			movedpartsloading :false,
			ord_move_parts : [],
			ord_moved_parts : [],
			moveparts : [],
			movedparts : [],
			ordertools : [],
			planpartsloading : false,
			planparts : [],
			timesheets : [],
			checklist : [],
			orderoffset : 30,
			left_style : '',
			part_id : '',
			parts : [],
			formmode : false,
			search_query : '',
			content_card : '',
			ord_mteam_upid : '',
			is_search_enable : false,
			tools_add : false,
			sticky_searchbar : '',
			savedtags : [],
			orderlistFilterLoading : false,
			updateLoading : false,
			cdate : (new Date().getFullYear())+'-'+(this.addzero(new Date().getMonth()+1))+'-'+(this.addzero(new Date().getDate()))+' '+(this.addzero(new Date().getHours()))+':'+(this.addzero(new Date().getMinutes()))+':'+(this.addzero(new Date().getSeconds())),
			activeTab: '1',
			ord_name : '',
			ord_checklists : [],
			id : this.props.match.params.id
			
        };
		
		
		this.onTabChange = this.onTabChange.bind(this);
		this.onPartMod = this.onPartMod.bind(this);
		this.onToolMod = this.onToolMod.bind(this);
		this.onFormModeChange = this.onFormModeChange.bind(this);
		this.setpart = this.setpart.bind(this);
		this.setcolumn = this.setcolumn.bind(this);
		this.handleEquipChange = this.handleEquipChange.bind(this);
		this.handleToolChange = this.handleToolChange.bind(this);
		this.handleSpaceChange = this.handleSpaceChange.bind(this);
		this.handleTeamChange = this.handleTeamChange.bind(this);
		this.handleAssetChange = this.handleAssetChange.bind(this);
		this.setsearchvalue = this.setsearchvalue.bind(this);
		this.onOrderChange = this.onOrderChange.bind(this);
		this.orderfiltersclick = this.orderfiltersclick.bind(this);
		this.onSendMessage = this.onSendMessage.bind(this);
		this.orderfilters = this.orderfilters.bind(this);
		this.updateOrder = this.updateOrder.bind(this);
		this.addParts = this.addParts.bind(this);
		this.addTools = this.addTools.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onAddFields = this.onAddFields.bind(this);
		this.onFilterApply = this.onFilterApply.bind(this);
		this.setmt = this.setmt.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
		this.setpriority = this.setpriority.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.setsearchfor = this.setsearchfor.bind(this);
		this.onOrderDataChange = this.onOrderDataChange.bind(this);
		this.getWorkOrdersListonScroll = this.getWorkOrdersListonScroll.bind(this);
	
    }
	
	async addParts(){
				this.setState({partAddLoading:true});
				var token = Sanitize.getStoreageItem('accessToken');
				
				var formdata = {"parts_id":this.state.part_id,"parts_qty":parseFloat(this.state.part_qty),"parts_type":this.state.part_cat,"parts_categ_id":parseInt(this.state.part_type_id),"parts_uom":parseInt(this.state.part_uom),"maintenance_id":parseInt(this.state.id)};
				
				var data = {"token":token,"values":formdata};
				await Reqst.axPost('addparts',data).then((result) => {
					
					let res = result['data'];
			
						
						if(res){
							
							this.setState({partAddLoading:false});
							this.setState({parts_add:false});
							this.setState({isPlanPartsLoaded:false});
							this.setState({part_id:'',part_qty:'',part_cat:'',parts_uom:''});
							swal("Success","Data Added Successfully..","success");
							this.getPlannedParts();
						}
						else{
							
							this.setState({partAddLoading:false});
							this.setState({parts_add:false});
							swal("Error","Unable to add..","error");
							
						}
				});
	}
	
	async addTools(){
				this.setState({toolAddLoading:true});
				var token = Sanitize.getStoreageItem('accessToken');
				
				var formdata = {"tool_id":this.state.tool_id,"order_id":parseInt(this.state.id),"name":this.state.tname};
				
				var data = {"token":token,"values":formdata};
				await Reqst.axPost('addtools',data).then((result) => {
					
					let res = result['data'];
			
						
						if(res){
							
							this.setState({toolAddLoading:false});
							this.setState({tools_add:false});
							this.setState({isOrderToolsLoaded:false});
							this.setState({tool_id:'',other_mo:'',other_mo_id:''});
							swal("Success","Data Added Successfully..","success");
							this.getdata();
						}
						else{
							
							this.setState({toolAddLoading:false});
							this.setState({tools_add:false});
							swal("Error","Unable to add..","error");
							
						}
				});
	}
	
	async updateOrder(){
				this.setState({updateLoading:true});
				var token = Sanitize.getStoreageItem('accessToken');
				
				var eqid = this.state.ord_equip_id;
				
				var eqlocid = this.state.ord_space_id;
				
				var assetid = this.state.ord_asset_id;
				
				var mteam = this.state.ord_mteam_id; 
				
				if(this.state.ord_equip_upid!=''){
					
					eqid = this.state.ord_equip_upid;
					
				}
				
				if(this.state.ord_space_upid!=''){
					
					eqlocid = this.state.ord_space_upid;
				}
				
				if(this.state.ord_asset_upid!=''){
					
					assetid = this.state.ord_asset_upid;
				}
				
				if(this.state.ord_mteam_upid!=''){
					
					mteam = this.state.ord_mteam_upid;
				}
	
				var formdata = {"type_category":this.state.ord_type,"equipment_id":parseInt(eqid),"equipment_location_id":parseInt(eqlocid),"asset_id":parseInt(assetid),"cause":this.state.ord_desc,"maintenance_team_id": mteam,"maintenance_type":this.state.ord_mtype,"date_start_scheduled":this.state.ord_act_date,"date_scheduled":this.state.ord_act_enddate,"date_start_execution":this.state.ord_exe_date,"date_execution":this.state.ord_exe_enddate,"worked_hours":this.state.ord_wrk_duration,"order_duration":this.state.ord_act_duration,"std_mat_cost":parseFloat(this.state.ord_plan_mc),"std_tool_cost":parseFloat(this.state.ord_plan_tc),"std_labour_cost":parseFloat(this.state.ord_plan_lc),"act_mat_cost":this.state.ord_actual_mc,"act_tool_cost":this.state.ord_actual_lc,"act_labour_cost":this.state.ord_actual_tc,"priority":this.state.ord_prty};
				
				var data = {"token":token,"eid":this.state.id,"values":formdata};
				await Reqst.axPost('updateorder',data).then((result) => {
					
					let res = result['data'];
			
						
						if(res){
							
							this.setState({updateLoading:false});
							this.setState({formmode:false});
							swal("Success","Data Updated Successfully..","success");
							this.getdata();
						}
						else{
							
							this.setState({updateLoading:false});
							this.setState({formmode:false});
							swal("Error","Unable to update..","error");
							
						}
				});
	}
	
	onPartMod(){
		this.setState({parts_add: true});
		this.getParts();
	
	}
	
	onToolMod(){
		this.setState({tools_add: true});
		this.gettools();
	
	}
	
	handleEquipChange(column) {
		this.setState({ord_equip_id: ''});
		this.setState({ord_equip_upid: column});
	}
	
	handleTeamChange(column) {
		this.setState({ord_mteam_id: ''});
		this.setState({ord_mteam_upid: column});
	}
	
	
	handleToolChange(column) {
		this.setState({other_mo: '',other_mo_id:'',tname:''});
		this.setState({tool_id: column});
		this.storeOtherMr(column);
	}
	
	setpart(column) {
		this.setState({part_type: '',part_cat:'',part_type_id:'',part_uom:''});
		this.setState({part_id: column});
		this.storeTypeCat(column);
	}
	
	storeOtherMr(column){
		
		var data = this.state.seltools;
		
		var mo = '';
		
		var moid = '';
		
		var tname = '';
	
		for(var i=0;i<data.length;i++){
			
			if(data[i].id==column){
				
				mo = data[i].order_id[1];
				moid = data[i].order_id[0];
				tname = data[i].display_name;
				break;
			}
			
		}
		
		this.setState({other_mo: mo,other_mo_id:moid,tname:tname});
	
	}
	
	storeTypeCat(column){
		
		var data = this.state.parts;
		
		var type = '';
		
		var cat = '';
		
		var typeid = '';
		
		var uomid = '';
		
		for(var i=0;i<data.length;i++){
			
			if(data[i].id==column){
				
				type = data[i].categ_id[1];
				typeid = data[i].categ_id[0];
				cat = data[i].type;
				uomid = data[i].uom_id[0];
				break;
			}
			
		}
		
		this.setState({part_type: type,part_cat:cat,part_type_id: typeid,part_uom:uomid});
	
	}
	
	handleAssetChange(column) {
		this.setState({ord_asset_id: ''});
		this.setState({ord_asset_upid: column});
	}
	
	handleSpaceChange(column) {
		this.setState({ord_space_id: ''});
		this.setState({ord_space_upid: column});
	}
	
	onAddFields(type){
		
			this.setState({ord_type: type});
			this.state.ord_type = type;
			this.getlocations();
			
			if(type=='others'){
				
			
			}
			else{
		
				
			
			}

	}
	
	onFilterApply(id,query,name){
		  
		 query = query.substr(1).slice(0, -1);
		 this.setState({searchDialog: false});
		 this.ordersavedfilters(query);
	
		var orgdata = [{"key":id,"name":name,"query":query}];
		
		this.setState({tags:[]});
		
		this.setState({savedtags: orgdata});

		
		console.log(this.state.savedtags);
					
	  }
	
	
	setsearchvalue(e) {
		
		this.setState({searchvalue: e.target.value});
	}
	
	setcolumn(column) {
		this.setState({searchfor: ''});
		this.setState({searchvalue: ''});
		this.setState({searchcolumn: column});
	}
	
	setselsearchvalue(value) {
		
		this.setState({searchvalue: value});
	}
	
	setpriority(column) {
		this.setState({ord_prty: column});
	}
	
	setmt(column) {
		this.setState({ord_mtype: column});
	}
	
	onInputChange(e){
		
		this.setState({[e.target.name]: e.target.value});
	}
	
	onFormModeChange(){
		
		this.setState({formmode:true});
		this.setState({ord_space_upid:'',ord_equip_upid:'',ord_mteam_upid:''});
		this.getequipments();
		this.getlocations();
		this.getteams();
		
		
	}
	
	onSelSearch(type,query) {
		this.setState({selsearchtext: ''});

	  if(type=='equipment'){
			this.setState({ord_equip_upid : ''});
			if (this.state.ord_equip_upid=='') {
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getequipments();
			}
	  }
	  else if(type=='space'){
			this.setState({ord_space_upid : ''});
			if (this.state.ord_space_upid=='') {
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getlocations();
			}
	  }
	   else if(type=='mteam'){
			this.setState({ord_mteam_upid : ''});
			if (this.state.ord_mteam_upid=='') {
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getteams();
			}
	  }
	  else if(type=='asset'){
			this.setState({ord_asset_upid : ''});
			if (this.state.ord_asset_upid=='') {
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getlocations();
			}
	  }
	   else if(type=='tools'){
			this.setState({tool_id : ''});
			if (this.state.tool_id == '') {
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.gettools();
			}
	  }

	
  }
	
	getequipments(){
		
		this.setState({equipLoading:true});
		this.setState({equipments : []});
		var token = Sanitize.getStoreageItem('accessToken');
		
		var searchtext = this.state.selsearchtext;
			var query = 'no';
			if(searchtext!=''){
			  query = searchtext;
			}
			
			var seldata = [];
			var name = this.state.ord_equip;
			var id = this.state.ord_equip_id;
		     seldata.push({"id":id,"name":name});
		
		 var data = {"token":token,"query":query};
			Reqst.axPost('getticketequipment',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({equipLoading:false});
					if(this.state.ord_equip_id && this.state.ord_equip_id!='') {
					   this.setState({equipments : seldata.concat(res)});
					}
					else{
						
						 this.setState({equipments : res});
					}
				}
			});
			
		
		
	}
	
	getlocations(){
		
		
		var type = this.state.ord_type;
		
		
	  if(type=='equipment'){
		
		this.setState({spaceLoading:true});
		this.setState({spaces : []});
		var token = Sanitize.getStoreageItem('accessToken');
		
		var searchtext = this.state.selsearchtext;
			var query = 'no';
			if(searchtext!=''){
			  query = searchtext;
			}

			
			var seldata = [];
			var name = this.state.ord_space;
			var id = this.state.ord_space_id;
		     seldata.push({"id":id,"name":name});
		
		 var data = {"token":token,"query":query};
			Reqst.axPost('getspacessearch',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({spaceLoading:false});
					if(this.state.ord_space_id && this.state.ord_space_id!='') {
					   this.setState({spaces : seldata.concat(res)});
					}
					else{
						
						 this.setState({spaces : res});
						 this.setState({assets : res});
					}
				}
			});
			
		}
		else{
			
			this.setState({assetLoading:true});
			this.setState({assets : []});
			var token = Sanitize.getStoreageItem('accessToken');
		
		    var searchtext = this.state.selsearchtext;
			var query = 'no';
			if(searchtext!=''){
			  query = searchtext;
			}
			
			var seldata = [];
			var name = this.state.ord_asset;
			var id = this.state.ord_asset_id;
		     seldata.push({"id":id,"name":name});
		
		    var data = {"token":token,"query":query};
			Reqst.axPost('getspacessearch',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({assetLoading:false});
					if(this.state.ord_asset_id && this.state.ord_asset_id!='') {
					   this.setState({assets : seldata.concat(res)});
					}
					else{
						
						 this.setState({assets : res});
					}
				}
			});
			
		}
		
	}
	
	getteams(){
		this.setState({teamLoading:true});
		this.setState({mteams : []});
		var token = Sanitize.getStoreageItem('accessToken');
		
		var searchtext = this.state.selsearchtext;
			var query = 'no';
			if(searchtext!=''){
			  query = searchtext;
			}

			
			var seldata = [];
			var name = this.state.ord_mteam;
			var id = this.state.ord_mteam_id;
		     seldata.push({"id":id,"name":name});
		
		 var data = {"token":token,"query":query};
			Reqst.axPost('getmaintenanceteamsearch',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({teamLoading:false});
					if(this.state.ord_mteam_id!='') {
					   this.setState({mteams : seldata.concat(res)});
					}
					else{
						
						 this.setState({mteams : res});
					}
				}
			});
		
	}
	
	gettools(){
		this.setState({selToolsLoading:true});
		this.setState({seltools : []});
		var token = Sanitize.getStoreageItem('accessToken');
		
		var searchtext = this.state.selsearchtext;
			var query = 'no';
			if(searchtext!=''){
			  query = searchtext;
			}

		 var data = {"token":token,"query":query};
			Reqst.axPost('gettoolssearch',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({selToolsLoading:false});
					this.setState({seltools : res});
				}
			});
		
	}
	
	setsearchfor(e){
		
		if(e.target.checked){
			
			this.setState({searchfor : e.target.value });
		}
		else {
			
			this.setState({searchfor : '' });
		} 
	}
	
	
	addzero(day){
				
				var temp = ''+day;
				if(temp.length == 1){

				temp = "0"+temp;
				return temp;
				}
				else{

				return temp;
				}

		}
	

   
   componentDidMount() {
       
	   this.getdata();
	   this.getWorkOrdersList();
	   this.getFilters();
   }
   
   componentDidUpdate(){
	   
	   window.addEventListener('scroll', this.onOrderScroll());
	   if(this.order_search_name){
	    this.order_search_name.focus();
		$("#search_text").focus();
	   }
	   
   }
   
   
   getParts(){
	
		this.setState({partsLoading:true});
		this.setState({parts:[]});
		var token = Sanitize.getStoreageItem('accessToken');
	
			Reqst.axGet('getpartssearch/'+token).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({partsLoading:false});
					
				    this.setState({parts:res});
						
				
				}
			});

	}
   
   
   getFilters(){
	
		this.setState({orderlistloading:true});
		this.setState({filters:[]});
		var token = Sanitize.getStoreageItem('accessToken');
		
		 var data = {"token":token,"model":"mro.order"};
			Reqst.axPost('getfilters',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else if(result['data'].code == '500'){
				
					this.setState({ orderlistloading: false });
					alert(result['data'].data);
			    }
				else{
					this.setState({orderlistloading:false});
					
				    this.setState({filters:res});
						
				
				}
			});

	}
  
   
   
     getdateofdt(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			  var strTime = day + '/' + month + '/' + year;
			  return strTime;
		 }
		 else{
			 
			 return '- - - -';
		 }
			
	  }
	  
	   getdateofdttime(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			  var strTime = day + '/' + month + '/' + year;
			  
			  var hours = date1.getHours();
			  var minutes = date1.getMinutes();
			  var ampm = hours >= 12 ? 'pm' : 'am';
			  hours = hours % 12;
			  hours = hours ? hours : 12; // the hour '0' should be '12'
			  minutes = minutes < 10 ? '0'+minutes : minutes;
			  var strTime1 = hours + ':' + minutes + ' ' + ampm;
  
			  return strTime+' '+strTime1;
		 }
		 else{
			 
			 return 'Not Assigned';
		 }
			
	  }
	  
	  getConfirmCheckLists(){
		  
		  
		var data = this.state.checklist;

			var result=0;

			for(var i=0;i<data.length;i++){

				if(data[i].answer_common){
					
					result+=1;
				}

		   }

			return result;
	
		  
	  }
	  
	  
	   getdateofdt(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate();
			  var  mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
			  var mname = mlist[date1.getMonth()];
			  var strTime = day + ' ' + mname + ' ' + year;
			  
			  var hours = date1.getHours();
			  var minutes = date1.getMinutes();
			  var ampm = hours >= 12 ? 'pm' : 'am';
			  hours = hours % 12;
			  hours = hours ? hours : 12; // the hour '0' should be '12'
			  minutes = minutes < 10 ? '0'+minutes : minutes;
			  var strTime1 = hours + ':' + minutes + ' ' + ampm;
  
			  return strTime;
		 }
		 else{
			 
			 return '- - - -';
		 }
			
	  }
	  
	   getdateoftimingsind(date){
			
			var cdate = this.convertLoalTime(date);
			
			
			
	      var date_future = new Date(cdate);
		  
		     
	   
	      var date_now = new Date();
		  

		  // get total seconds between the times
			var delta = Math.abs(date_future - date_now) / 1000;

			// calculate (and subtract) whole days
			var days = Math.floor(delta / 86400);
			delta -= days * 86400;

			// calculate (and subtract) whole hours
			var hours = Math.floor(delta / 3600) % 24;
			delta -= hours * 3600;

			// calculate (and subtract) whole minutes
			var minutes = Math.floor(delta / 60) % 60;
			delta -= minutes * 60;
			
		    if(days!=0 || days>2){
				
				return this.getdateofdttime(date);
			}
			else if(days==0 && hours!=0){
				
				return hours+' hours ago';
				
			}
			else{
				
				return minutes+' minutes ago';
				
			} 
			
	   }
	  
	  
	  getdateoftime(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			  var strTime = day + '/' + month + '/' + year;
			  
			  var hours = date1.getHours();
			  var minutes = date1.getMinutes();
			  var ampm = hours >= 12 ? 'pm' : 'am';
			  hours = hours % 12;
			  hours = hours ? hours : 12; // the hour '0' should be '12'
			  minutes = minutes < 10 ? '0'+minutes : minutes;
			  var strTime1 = hours + ':' + minutes + ' ' + ampm;
  
			  return strTime1;
		 }
		 else{
			 
			 return '- - - -';
		 }
			
	  }
	  
	  handleCloseSavedTags(tag){
		
		this.setState({isNameFilter:false});
		this.setState({ticket_search_name:'',savedtags:[]});
		this.setState({search_query:''});
		this.setState({is_search_enable:false});
		$("#search_text").val('');
		
	}
	
	  convertLoalTime(date_future){
		  
		  var date_future = new Date(date_future);
		  
		     var year = date_future.getFullYear();
			  var month = (date_future.getMonth()+1);
			  var day   = date_future.getDate()
		
			  var addhours = date_future.getHours()+5;
			  var mints = date_future.getMinutes();
			  var secs = date_future.getSeconds();
			  
				if(month < 10)
				{
					month = "0" + month;
				}
				if(addhours < 10)
				{
					addhours = "0" + addhours;
				}
				if(mints < 10)
				{
					mints = "0" + mints;
				}
				if(secs < 10)
				{
					secs = "0" + secs;
				}
			  
			  if(addhours=='13'){
		
				  addhours = '01';
			  }
			  else if(addhours=='14'){
				  
				   addhours = '02';
				  
			  }
			  else if(addhours=='15'){
				  
				   addhours = '03';
				  
			  }
			  else if(addhours=='16'){
				  
				   addhours = '04';
				  
			  }
			  else if(addhours=='17'){
				  
				   addhours = '05';
				  
			  }
			  
			  return year+'-'+month+'-'+day+' '+addhours+':'+mints+':'+secs
		  
	  }
	  
	  getTimeFromFloat(decimalTimeString){
		
		var decimalTime = parseFloat(decimalTimeString);
		decimalTime = decimalTime * 60 * 60;
		var hours = Math.floor((decimalTime / (60 * 60)));
		decimalTime = decimalTime - (hours * 60 * 60);
		var minutes = Math.floor((decimalTime / 60));
		decimalTime = decimalTime - (minutes * 60);
		var seconds = Math.round(decimalTime);
		if(hours < 10)
		{
			hours = "0" + hours;
		}
		if(minutes < 10)
		{
			minutes = "0" + minutes;
		}
		if(seconds < 10)
		{
			seconds = "0" + seconds;
		}
		var result = "" + hours + ":" + minutes;
		
		return result;
		
		
	}
	
	
	getDateTimingsText(date,date1){
		
		
		if(date!=''){
			
			if(date>=date1){
				
				return <span className="text-success" style={{verticalAlign:'middle'}}>ON-TIME</span>;
			}
			else{
				return <span className="text-danger" style={{verticalAlign:'middle'}}>LATE BY</span>;
				
			}
			
		}
		else{
			
			return <span className="text-warning" style={{verticalAlign:'middle'}}>ELAPSED BY</span>;
			
		}
		
		
	}
	
	getDateTimingsStatus(date,date1){
		
		var status = '';
		if(date!=''){
			
			if(date>=date1){
				
				status = 'ontime';
			}
			else{
				status = 'late';
				
			}
			
		}
		else{
			
			status = 'elapse';
			
		}
		
		return status;
	}
	
	
	
	
	getDateTimingsColor(date,date1){
		
		var color = '';
		
		if(date!=''){
			
			if(date>=date1){
				
				color = 'text-success';
			}
			else{
				color = 'text-danger';
				
			}
			
		}
		else{
			
			color = 'text-warning'; 
			
		}
		
		return color;
		
		
	}
	  
	  getdateoftimings(date,date1,type){
		  
			var cdate = this.convertLoalTime(date);
			
			
			
	      var date_future = new Date(cdate);
		  
		     
	   
	      var date_now = new Date();
		  
		  if(date1 && date!=''){
			  
			  var date_now1 = this.convertLoalTime(date1);
			  
			  date_now = new Date(date_now1);
			 
		  }
		  
		  var delta = '';
		  
		  if(date_future>=date_now){
		  // get total seconds between the times
			 delta = Math.abs(date_future - date_now) / 1000;
		  }
		  else{
			  
			  delta = Math.abs(date_now - date_future) / 1000;
		  }

			// calculate (and subtract) whole days
			var days = Math.floor(delta / 86400);
			delta -= days * 86400;

			// calculate (and subtract) whole hours
			var hours = Math.floor(delta / 3600) % 24;
			delta -= hours * 3600;

			// calculate (and subtract) whole minutes
			var minutes = Math.floor(delta / 60) % 60;
			delta -= minutes * 60;
			
		    /* if(days!=0 || days>2){
				
				var isplural = 'Days';
				
				if(days==1){
					
					isplural = 'Day';
				}
				
				
				return days+'  '+hours+'  '+minutes;
			}
			else if(days==0 && hours!=0){
				
				return hours+'   '+minutes;
				
			}
			else{
				
				return minutes;
				
			}  */
			
			if(type=='day'){
				
				return days;
			}
			else if(type=='hour'){
				
				return hours;
				
			}
			else if(type=='minute'){
				
				return minutes;
				
			}
			
	   }

	 
	  
	  getnovalues(str){
		      
			  if(str!='' && str!=null){		
			   return str;
		     }
			 else{
				 
				 return 'Not Assigned';
			 }
			
	  }
	  
	  onTabChange(tab){
		  
		 this.setState({activeTab:tab});
					
		if(tab=='4'){
			
			this.getTimeSheetList();
		}
		else if(tab=='3'){
			
			this.getPlannedParts();
			this.getOrderTools();
			this.getMoveParts();
			this.getMovedParts();
		}
		else if(tab=='5'){
				this.state.timelinelimit =  0;
				this.state.audits = [];
				this.setState({timelinelimit:0,audits:[]});
			
				this.getAuditLogs();
				window.addEventListener('scroll', this.onScroll());
			   
		}
		  
	  }
	  
	  onScroll(){

        $(window).scroll(() => { 
            if($(window).scrollTop() + $(window).height() == $(document).height()) {
                this.getAuditLogs(this.state.ord_messages);
            }
         });
       }
	   
	   async getAuditLogs(){
		var mids = this.state.ord_messages;
		if(mids!=''){
			
			var midslimit = [];
			
			var size = this.state.timelinelimit + 10;
			
			this.setState({
               timelinelimit: this.state.timelinelimit + 10 
              })
			
			var length = size + 10;
			
			var isloaded = false;
			
			if(length=='20'){
				
				length='10';
			}
			
			if(size=='10'){
				
				size=0;
				isloaded = true; 
				
			}
			
			for(var i=size;i<length;i++){
				
				if(i<mids.length){
				   midslimit.push(mids[i]);
				}
			}
		
			if(midslimit.length>0){
			//if(!this.state.isAuditLogsLoaded){
				this.setState({auditLoading:true});
				 this.setState({isAuditLogsLoaded:isloaded});
				//this.setState({audits : []});
				var token = Sanitize.getStoreageItem('accessToken');
				var data = {"token":token,"mids":midslimit};
				await Reqst.axPost('getcompanyactions',data).then((result) => {
					
					let res = result['data'];
			
						this.setState({auditLoading:false});
						 this.setState({isAuditLogsLoaded:false});
						if(res){
							
							this.sortaudits(res);
						}
				});
		  }
		  }
		//}
	}
	
	sortaudits(data) {
		if(data.length>0){
		   var orgdata = data.sort(function(a, b){
			   var dateA = new Date(a.date), dateB = new Date(b.date);
			   return dateB - dateA;
			});
			var joined = this.state.audits;		
			var newdata = joined.concat(orgdata);
		   this.setState({
				audits : newdata
			});
		}
		else{
			var joined = this.state.audits;		
			var newdata = joined.concat(data);
			this.setState({
				audits : newdata
			});
		}
	}
	  
	  
	  onOrderDataChange(id){
		  
		 this.setState({id:id});
		 this.getdatabyid(id);
		 this.setState({activeTab:'1'});
		 this.setState({checklist:[],timesheets:[],planparts:[],ordertools:[],moveparts:[],movedparts:[],isTimeSheetLoaded:false,isAuditLogsLoaded:false,isPlanPartsLoaded:false,isOrderToolsLoaded:false,isMovePartsLoaded:false,isMovedPartsLoaded:false,formmode:false})
				
			
	  }
	  
	  getdatabyid(id){
		this.setState({dataLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getworkorderdata/'+token+'/'+id).then((result) => {
			
			let res = result['data'][0];
			if(result['data'].code=='401'){
				
				this.getRefreshToken();
				
			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else{
			    this.setState({dataLoading:false});
				if(res){
				   this.state.ord_messages = res.message_ids;
					this.setState({
						ord_name : res.name,
						ord_sequence : res.sequence,
						ord_mtype : res.maintenance_type,
						ord_desc : res.cause,
						ord_asset : res.asset_id[1],
						ord_asset_id : res.asset_id[0],
						ord_type : res.type_category,
						ord_equip : res.equipment_id[1],
						ord_equip_id : res.equipment_id[0],
						ord_space : res.equipment_location_id[1],
						ord_space_id : res.equipment_location_id[0],
						ord_status : res.state,
						ord_mteam : res.maintenance_team_id[1],
						ord_mteam_id : res.maintenance_team_id[0],
						ord_technican : res.employee_id[1],
						ord_act_duration : res.order_duration,
						ord_wrk_duration : res.worked_hours,
						ord_act_date : res.date_start_scheduled,
						ord_act_enddate : res.date_scheduled,
						ord_exe_date : res.date_start_execution,
						ord_exe_enddate : res.date_execution,
						ord_checklists : res.check_list_ids,
						ord_messages : res.message_ids,
						ord_planned_parts : res.parts_lines,
						ord_prty : res.priority,
						ord_tools : res.tool_ids,
						ord_warehouse : res.warehouse_id[1],
						ord_part_location : res.location_parts_id[1],
						ord_move_parts : res.parts_move_lines,
						ord_moved_parts : res.parts_moved_lines,
						ord_plan_mc : res.std_mat_cost,
						ord_plan_tc : res.std_tool_cost,
						ord_plan_lc : res.std_labour_cost,
						ord_actual_mc : res.act_mat_cost,
						ord_actual_lc : res.act_tool_cost,
						ord_actual_tc : res.act_labour_cost,
						ord_resources : res.n_resources,
						
					});
					
					this.getCheckOrdersList(res.check_list_ids);
				}
			}
		});
	}
	 
	getdata(){
		this.setState({dataLoading:true});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getworkorderdata/'+token+'/'+this.state.id).then((result) => {
			
			let res = result['data'][0];
			if(result['data'].code=='401'){
				
				this.getRefreshToken();
				
			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else{
			    this.setState({dataLoading:false});
				if(res){
				    this.state.ord_messages = res.message_ids;
					this.setState({
						ord_name : res.name,
						ord_sequence : res.sequence,
						ord_asset : res.asset_id[1],
						ord_asset_id : res.asset_id[0],
						ord_prty : res.priority,
						ord_type : res.type_category,
						ord_mtype : res.maintenance_type,
						ord_desc : res.cause,
						ord_equip : res.equipment_id[1],
						ord_equip_id : res.equipment_id[0],
						ord_space : res.equipment_location_id[1],
						ord_mteam_id : res.maintenance_team_id[0],
						ord_warehouse : res.warehouse_id[1],
						ord_part_location : res.location_parts_id[1],
						ord_space_id : res.equipment_location_id[0],
						ord_status : res.state,
						ord_mteam : res.maintenance_team_id[1],
						ord_technican : res.employee_id[1],
						ord_act_duration : res.order_duration,
						ord_wrk_duration : res.worked_hours,
						ord_act_date : res.date_start_scheduled,
						ord_act_enddate : res.date_scheduled,
						ord_exe_date : res.date_start_execution,
						ord_exe_enddate : res.date_execution,
						ord_checklists : res.check_list_ids,
						ord_timesheets : res.mro_timesheet_ids,
						ord_messages : res.message_ids,
						ord_planned_parts : res.parts_lines,
						ord_tools : res.tool_ids,
						ord_move_parts : res.parts_move_lines,
						ord_moved_parts : res.parts_moved_lines,
						ord_plan_mc : res.std_mat_cost,
						ord_plan_tc : res.std_tool_cost,
						ord_plan_lc : res.std_labour_cost,
						ord_actual_mc : res.act_mat_cost,
						ord_actual_lc : res.act_tool_cost,
						ord_actual_tc : res.act_labour_cost,
						ord_resources : res.n_resourse,
						
					});
					this.getCheckOrdersList(res.check_list_ids);
				}
			}
		});
	}
	
	async getWorkOrdersList(){
		 
		if(!this.state.isOrdersIsLoaded){
			this.setState({orderlistloading:true});
			this.setState({left_style:''});
			this.setState({sticky_searchbar:''});
			this.setState({topbar_buttons:''});
			this.setState({content_card:''});
			this.setState({isNameFilter:false});
			//this.setState({orderlist : []});
			var token = Sanitize.getStoreageItem('accessToken');
			var limit = '30';
			var offset = '0';
			
			await Reqst.axGet('getworkorders/'+token+'/'+limit+'/'+offset).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else if(result['data'].code == '500'){
				
				   this.setState({ orderlistloading: false });
				   alert(result['data'].data);
			    }
				else{
					this.setState({orderlistloading:false});
					this.setState({orderlist : res});
					this.setState({left_style:'left_search_card'});
					this.setState({sticky_searchbar:'sticky_searchbar'});
					this.setState({topbar_buttons:'topbar_buttons'});
					this.setState({content_card:'content_card'});
					this.setState({isOrdersIsLoaded : true});
				}
			});
		}
	}
	
	async getCheckOrdersList(cids){
		 
		 if(cids!=''){
			this.setState({dataLoading:true});
			this.setState({checklist : []});
			var token = Sanitize.getStoreageItem('accessToken');
			var data = {"token":token,"cids":cids}
			await Reqst.axPost('getchecklists',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({dataLoading:false});
					this.setState({checklist : res});
				}
			});
		 }
	}
	
	async getTimeSheetList(){
		 var sids = this.state.ord_timesheets;
		 if(sids!=''){
			 if(!this.state.isTimeSheetLoaded){
				this.setState({timesheetloading:true});
				this.setState({timesheets : []});
				var token = Sanitize.getStoreageItem('accessToken');
				var data = {"token":token,"sids":sids}
				await Reqst.axPost('gettimesheets',data).then((result) => {
					
					let res = result['data'];
					if(res.code=='401'){
						
						this.getRefreshToken();
						
					}
					else{
						this.setState({timesheetloading:false});
						this.setState({isTimeSheetLoaded:true});
						this.setState({timesheets : res});
					}
				});
			 }
		 }
	}
	
	async getPlannedParts(){
		 var parts = this.state.ord_planned_parts;
		 if(parts!=''){
			 if(!this.state.isPlanPartsLoaded){
				this.setState({planpartsloading:true});
				this.setState({planparts : []});
				var token = Sanitize.getStoreageItem('accessToken');
				var data = {"token":token,"parts":parts}
				await Reqst.axPost('getplannedparts',data).then((result) => {
					
					let res = result['data'];
					if(res.code=='401'){
						
						this.getRefreshToken();
						
					}
					else{
						this.setState({planpartsloading:false});
						this.setState({isPlanPartsLoaded:true});
						this.setState({planparts : res});
					}
				});
			 }
		 }
	}
	
	async getOrderTools(){
		 var tools = this.state.ord_tools;
		 if(tools!=''){
			 if(!this.state.isOrderToolsLoaded){
				this.setState({toolsloading:true});
				this.setState({ordertools : []});
				var token = Sanitize.getStoreageItem('accessToken');
				var data = {"token":token,"tools":tools}
				await Reqst.axPost('getordertools',data).then((result) => {
					
					let res = result['data'];
					if(res.code=='401'){
						
						this.getRefreshToken();
						
					}
					else{
						this.setState({toolsloading:false});
						this.setState({isOrderToolsLoaded:true});
						this.setState({ordertools : res});
					}
				});
			 }
		 }
	}
	
	async getMoveParts(){
		 var parts = this.state.ord_move_parts;
		 if(parts!=''){
			 if(!this.state.isMovePartsLoaded){
				this.setState({movepartsloading:true});
				this.setState({moveparts : []});
				var token = Sanitize.getStoreageItem('accessToken');
				var data = {"token":token,"parts":parts}
				await Reqst.axPost('getmoveparts',data).then((result) => {
					
					let res = result['data'];
					if(res.code=='401'){
						
						this.getRefreshToken();
						
					}
					else{
						this.setState({movepartsloading:false});
						this.setState({isMovePartsLoaded:true});
						this.setState({moveparts : res});
					}
				});
			 }
		 }
	}
	
	async getMovedParts(){
		 var parts = this.state.ord_moved_parts;
		 if(parts!=''){
			 if(!this.state.isMovedPartsLoaded){
				this.setState({movedpartsloading:true});
				this.setState({movedparts : []});
				var token = Sanitize.getStoreageItem('accessToken');
				var data = {"token":token,"parts":parts}
				await Reqst.axPost('getmoveparts',data).then((result) => {
					
					let res = result['data'];
					if(res.code=='401'){
						
						this.getRefreshToken();
						
					}
					else{
						this.setState({movedpartsloading:false});
						this.setState({isMovedPartsLoaded:true});
						this.setState({movedparts : res});
					}
				});
			 }
		 }
	}
	
 onOrderScroll(){
	
	const that = this;
       /*  $(window).scroll(() => { 
		var container = $(".left_tree_card");
            if(container.scrollTop+container.clientHeight == container.scrollHeight) {
				
                this.getWorkOrdersListonScroll();
				
            }
         }); */
		 
		 
		$('#left_search_card_id').scroll(function() {
			
			if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
				if(!that.state.orderlistloading){
			      that.getWorkOrdersListonScroll();
				}
				
			}
		}); 
   }
   
    getUnique(){
        var uniqueArray = [];
         var orders = this.state.orderlist;
        // Loop through array values
        for(var i=0; i < orders.length; i++){
            if(uniqueArray.indexOf(orders[i]) === -1) {
                uniqueArray.push(orders[i]);
            }
        }
        return uniqueArray;
    }
	
	async getWorkOrdersListonScroll(){
		 
		//if(!this.state.isEquipmentIsLoaded){
			//this.setState({isNameFilter:false});
			//this.setState({equiplist : []});
			var token = Sanitize.getStoreageItem('accessToken');
			var limit = '10';
			var offset = this.state.orderoffset + 10;
			this.setState({orderoffset: this.state.orderoffset + 10 });
			/* if(offset=='80'){
				offset = '0';
			} */
		
		   var isnamefilter = this.state.isNameFilter;
		   
		   console.log(isnamefilter);
		   
		if(!isnamefilter){
			
			this.setState({orderlistloading:true});
			await Reqst.axGet('getworkorders/'+token+'/'+limit+'/'+offset).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({orderlistloading:false});
					var orderdata = this.state.orderlist.concat(res);
					this.setState({orderlist : orderdata});
					//console.log(orderdata);
				}
			});
		}
			else{
				
				var query = this.state.search_query;
				this.setState({orderFilterScrollLoad:true});
				var data = {"token":token,"limit":limit,"offset":offset,"query":query};
				await Reqst.axPost('getworkorderfiterdata',data).then((result) => {
					
					let res = result['data'];
					if(res.code=='401'){
						
						this.getRefreshToken();
						
					}
					else{
						this.setState({orderFilterScrollLoad:false});
						var orderdata = this.state.order_filter_data.concat(res);
						this.setState({order_filter_data : orderdata});
					}
				});
				
			}
		//}
	}
	
	async onStateChange(state){
		 this.setState({dataLoading:true});
			var token = Sanitize.getStoreageItem('accessToken');
			await Reqst.axGet('setorderstate/'+token+'/'+state+'/'+this.state.id).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					if(res){
						this.setState({dataLoading:false});
						this.getdata();
						swal("Success","State Changed Successfully..","success");
					}
					else{
						
						swal("Error","Unable to change the State","error");
					}
				}
			});
	}
	
	resetFilter(){
		
		this.setState({isNameFilter:false});
		this.setState({order_search_name:''});
		this.setState({search_query:''});
		this.setState({is_search_enable:false});
		$("#search_text").val('');
		
	}
	
	
		
	getstatename(staten){
		
		var orgstate = '';
		if(staten == 'draft'){
			
			orgstate = 'DRAFT';
			
		}
		else if(staten == 'released'){
			
			orgstate = 'WAITING PARTS';
			
		}
		else if(staten == 'ready'){
			
			orgstate = 'READY TO MAINTENANCE';
			
		}
		else if(staten == 'assigned'){
			
			orgstate = 'ASSIGNED';
			
		}
		else if(staten == 'in_progress'){
			
			orgstate = 'IN PROGRESS';
			
		}
		else if(staten == 'pause'){
			
			orgstate = 'PAUSE';
			
		}
		else if(staten == 'done'){
			
			orgstate = 'DONE';
			
		}
		else if(staten == 'cancel'){
			
			orgstate = 'CANCELED';
			
		}
		
		return orgstate;
		
	}
	
	
	getstatenamelabel(staten){
		
	
		if(staten=='mn'){
			
			return 'Maintenance';
			
		}
		else if(staten == 'op'){
			
			return 'Operative';
			
		}
		else if(staten == 'wh'){
			
			return 'Warehouse';
			
		}
		else if(staten == 'br'){
			
			return 'Breakdown';
			
		}
		else if(staten == 'sc'){
			
			return 'Scrapped';
			
		}
	
	}
	
	getproducttype(pr){
		
	
		if(pr=='consu'){
			
			return 'Consumable';
			
		}
		else if(pr == 'service'){
			
			return 'Service';
			
		}
		else if(pr == 'product'){
			
			return 'Storable Product';
			
		}
		else{
			
			return '';
			
		}
		
	}
	
	getCritical(cr){
		
		var orgcr = '';
		if(cr=='0'){
			
			return <span className="text-primary">General</span>;
			
		}
		else if(cr = '1'){
			
			return <span className="text-info">Important</span>;
			
		}
		else if(cr = '2'){
			
			return <span className="text-warning">Very Important</span>;
			
		}
		else if(cr = '3'){
			
			return <span className="text-danger">Critical</span>;
			
		}
		
		
	}
	
	

	
	getRefreshToken(){
		
		this.setState({dataLoading:true});
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id='+reftoken).then((result) => {
			let res = result['data'];
			if(res){
				this.setState({dataLoading:false});
				Sanitize.setStorage('accessToken',res.access_token);
				Sanitize.setStorage('refreshToken',res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.getdata();
				this.getWorkOrdersList();
	            this.getFilters();
			}
		}).catch(error => {
				this.setState({dataLoading:false});
				  //console.log(error.response.status);
				 
			  });
		
	}
	
	getmtname(mt){
		
		var orgstate = '';
		if(mt == 'bm'){
			
			orgstate = 'Corrective';
			
		}
		else if(mt == 'pm'){
			
			orgstate = 'Preventive';
			
		}
		else if(mt == 'oc'){
			
			orgstate = 'On Condition';
			
		}
		else if(mt == 'pr'){
			
			orgstate = 'Periodic';
			
		}

		return orgstate;
		
	}
	
	directtoanother(){
		
		this.setState({isDirect:true});
		console.log(this.props.location.state);
		
	}
	
	
	filterdata(){
		
		var dat = [];
		for(var i=0; i<this.state.orderlist.length; i++){
			
			
			if(this.state.order_search_name!=''){
				if(this.state.orderlist[i].name.toLowerCase().search(this.state.order_search_name.toLowerCase()) !=-1){
					
					dat.push(this.state.orderlist[i]);
				}
			}
			

		}
		
		return dat;
		
	}
	
	orderfiltersclick(){
		
		this.setState({is_search_enable:true});
		
	}
	
	ordersavedfilters(query){
		
		try{
			
			this.setState({isNameFilter:true});
			this.setState({orderlistFilterLoading:true});
		    this.setState({order_filter_data : []});
			this.setState({search_query : ''});
		    var token = Sanitize.getStoreageItem('accessToken');
			//var searchval = this.state.order_search_name;
			//console.log(searchval);
			//var query = '["name", "ilike","'+searchval+'" ]';
			this.setState({search_query : query});
			var rpp = '30';
			var offset = '0';
			var datasearch = [];
			
			/* datasearch = this.filterdata();
			
			if(datasearch.length>0){
				
				this.setState({order_filter_data : datasearch});
				this.setState({orderlistFilterLoading:false});
					
			}
			else{ */
				 var data = {"token":token,"limit":rpp,"offset":offset,"query":query};
				Reqst.axPost('getworkorderfiterdata',data).then((result) => {
					
					let res = result['data'];
					if(res.code=='401'){
						
						this.getRefreshToken();
						
					}
					else{
						this.setState({orderlistFilterLoading:false});
						this.setState({order_filter_data : res});
					}
				});
			//}
		}
		catch(error){
			console.log(error);
		}
		
	}
	
	orderfilters(searchval){
		
		try{
			
			this.setState({isNameFilter:true});
			this.setState({orderlistFilterLoading:true});
		    this.setState({order_filter_data : []});
			this.setState({search_query : ''});
		    var token = Sanitize.getStoreageItem('accessToken');
			//var searchval = this.state.order_search_name;
			console.log(searchval);
			var query = '["name", "ilike","'+searchval+'" ]';
			this.setState({search_query : '["name", "ilike","'+searchval+'" ]'});
			var rpp = '30';
			var offset = '0';
			var datasearch = [];
			
			/* datasearch = this.filterdata();
			
			if(datasearch.length>0){
				
				this.setState({order_filter_data : datasearch});
				this.setState({orderlistFilterLoading:false});
					
			}
			else{ */
				 var data = {"token":token,"limit":rpp,"offset":offset,"query":query};
				Reqst.axPost('getworkorderfiterdata',data).then((result) => {
					
					let res = result['data'];
					if(res.code=='401'){
						
						this.getRefreshToken();
						
					}
					else{
						this.setState({orderlistFilterLoading:false});
						this.setState({order_filter_data : res});
					}
				});
			//}
		}
		catch(error){
			console.log(error);
		}
		
	}
	
	async onSendMessage(){
				this.setState({sendMessage:true});
				var token = Sanitize.getStoreageItem('accessToken');
				var msg = $("#msg_body").val();
				var formdata = {"model":"mro.order","message_type":"comment","res_id":parseInt(this.state.id),"body":msg};
				var data = {"token":token,"values":formdata};
				await Reqst.axPost('sendmessage',data).then((result) => {
					
					let res = result['data'];
			
						
						if(res){
							
							this.setState({sendMessage:false});
							$("#msg_body").val('');
							swal("Success","Message Send Successfully..","success");
							this.getdata();
						}
						else{
							
							this.setState({sendMessage:false});
							swal("Error","Unable to Send..","error");
							
						}
				});
	}
	
	 onOrderChange(e){
		
		this.setState({order_search_name: e.target.value});
		
		if (e.key === 'Enter' && e.target.value.length>2) {
			this.orderfilters(e.target.value);
        }
		
		if (e.target.value.length>2) {
		    this.orderfilters(e.target.value);
		}
		
	}
	
	newpercalculate(total,given){
        var total = parseInt(total); 
        var given = parseInt(given);
		
        var perc="";
        if(isNaN(total) || isNaN(given)){
            perc=0;
           }else{
			   
			   if(total==0){
				   perc=0;
			   }
			   else{
                 perc = ((given/total) * 100);
			   }
           }
		   
		   perc = Math.floor(perc);
		   
		   

        return perc;
    }
	
	gettypename(tp){
		
	
		if(tp=='asset'){
			
			return 'Space';
			
		}
		else if(tp == 'general'){
			
			return 'General';
			
		}
		else if(tp == 'equipment'){
			
			return 'Equipment';
			
		}
		else{
			
			return '';
			
		}
		
	}
	
	upperCase(str) {
		if(str=='' || str==null || str==' ') 
			return '';
		else
			return  str.toString().toUpperCase();
     }
	 
	 
  
 
	
 render() {
	 
	 
	 if(this.state.isSpaceLink){
		 
		 var spaceid = '';
		 var spacename = '';
		 
		 if(this.state.ord_space_id){
			 
			 spaceid = this.state.ord_space_id;
			 spacename = this.state.ord_space;
		 }
		 else{
			 spaceid = this.state.ord_asset_id;
			 spacename = this.state.ord_asset;
			 
		 }
		 
		 var treenodes = [spaceid];
		  
	
		  
		return (<Redirect to={{
            pathname: '/assets/spaces',
            state: { modelkeyid:'orderview',value: spacename,uid:spaceid,treenodes:treenodes,ordername:this.state.ord_name,orderid:this.state.id }
        }}
        />);

	  }
	  
	  if(this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid=='space'){
		 
		
			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid:'orderview',value: this.props.location.state.value,uid:this.props.location.state.uid,treenodes:this.props.location.state.treenodes,ordername:this.state.ord_name,orderid:this.state.id }
			}}
			/>);

	  }
	  
	  if(this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid=='equipmentview'){
		 
		
			return (<Redirect to={{
				pathname: '/assets/equipment/view/'+this.props.location.state.uid,
				state: { modelkeyid:'orderview',value: this.state.ord_name,uid:this.state.id}
			}}
			/>);

	  }
	  
	  if(this.state.isEquipLink){
		 
		
			return (<Redirect to={{
				pathname: '/assets/equipment/view/'+this.state.ord_equip_id,
				state: { modelkeyid:'orderview',value: this.state.ord_name,uid:this.state.id}
			}}
			/>);

	  }
	 
	 var checklistper = this.newpercalculate(this.state.ord_checklists.length,this.getConfirmCheckLists());
	 
	
	 let equimg = '';
	 
	 if(this.state.ord_mtype=='pm'){
		 
		 equimg = preventive_img;
	 }
	 else if(this.state.ord_mtype=='bm'){
		 
		  equimg = corrective_img;
		 
	 }
	 else if(this.state.ord_mtype=='oc'){
		 
		  equimg = oc_img;
		 
	 }
	 else if(this.state.ord_mtype=='pr'){
		 
		  equimg = periodic_img;
		 
	 }
	 
	 var savedfilters = this.state.filters;
	 
	 
	  const selcolumns = [
		{
		  value: '',
		  label: 'Select'
		},
	    {
		  value: 'equip_asset_common',
		  label: 'Equipment/Asset'
		}, 
		{
		  value: 'name',
		  label: 'Name'
		},
		{
		  value: 'sequence',
		  label: 'Asset Number'
		},
		{
		  value: 'asset_id',
		  label: 'Space'
		},
		{
		  value: 'maintenance_team_id',
		  label: 'Maintenance Team'
		},
		{
		  value: 'company_id',
		  label: 'Company'
		},
		{
		  value: 'state',
		  label: 'Status'
		},
		{
		  value: 'maintenance_type',
		  label: 'Maintenance'
		}
	 ];
	 
	  const selstates = [
	    {
		  value: 'draft',
		  label: 'DRAFT'
		}, 
		{
		  value: 'released',
		  label: 'WAITING PARTS'
		},
		{
		  value: 'ready',
		  label: 'READY TO MAINTENANCE'
		},
		{
		  value: 'assigned',
		  label: 'ASSIGNED'
		},
		{
		  value: 'in_progress',
		  label: 'IN PROGRESS'
		},
		
		{
		  value: 'pause',
		  label: 'PAUSE'
		},
		{
		  value: 'done',
		  label: 'DONE'
		},
		{
		  value: 'cancel',
		  label: 'CANCELED'
		}
	 ];
	
	 const selmtypes = [
	    {
		  value: 'bm',
		  label: 'Corrective'
		}, 
		{
		  value: 'pm',
		  label: 'Preventive'
		},
		{
		  value: 'oc',
		  label: 'On Condition'
		},
		{
		  value: 'pr',
		  label: 'Periodic'
		}

	 ];
	 
	 const priorities = [
	    {
		  value: '0',
		  label: 'Low'
		}, 
		{
		  value: '1',
		  label: 'Normal'
		},
		{
		  value: '2',
		  label: 'High'
		},
		{
		  value: '3',
		  label: 'Breakdown'
		}
	 ];
	 
	 const {ord_act_date} = this.state;
		
	
		
        return (
            <Fragment>
			<Helmet>
					<title>{GC.APPTITLE} | Work Order | {this.state.ord_name} </title>
				</Helmet>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
					
					
					  {!this.state.isOrdersIsLoaded ?
					
							 <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
					    :
			 			
				          <div className="main_data">
					           <div className={this.state.sticky_searchbar}> 
										   
											   <FormGroup className={this.state.topbar_buttons}>
											   
											       
											   {this.state.is_search_enable ?
												 <Input id="search_text" className="mat_form" name="order_search_name" placeholder="Search Name" autofocus="true"  ref={(input) => { this.order_search_name = input; }}  onKeyDown={this.onOrderChange.bind(this)} />
											     : 
												 <Button type="text" size="large" onClick={this.orderfiltersclick}>
																<i className="pe-7s-search" style={{fontSize:'20px'}}/>
													</Button>
													
											      }
												  {!this.state.is_search_enable && !this.state.isNameFilter ? 
												   <span style={{marginLeft:'auto'}}>
													  <Button type="text" onClick={() => this.setState({searchDialog:true}) }>
													   <i className="lnr-funnel font-size-lg"/>
													  </Button>
												   </span>
												  :'' }
												  
												  
												  {this.state.savedtags.length==1 && !this.state.is_search_enable && this.state.isNameFilter ?
													<div style={{marginLeft: 'auto',marginTop: '0px',marginRight:'auto',textAlign:'center'}}>
													 {
														  
														this.state.savedtags.map(tag => {
														  return (
															<Tag
															  key={tag.key}
															  closable={true}
															  type="primary"
															  closeTransition={false}
															   onClose={this.handleCloseSavedTags.bind(this, tag)}>{tag.name}</Tag>
														  )
														})
													  }
													  </div>
							                      : '' }
												 
													{this.state.is_search_enable ?
													<span style={{marginLeft:'auto'}}>
														 <Tooltip className="item" content='Reset' placement="top-start" >
														   <Button type="text" size="large"  onClick={this.resetFilter}>
																		<i className="pe-7s-close" style={{fontSize:'20px'}}/> 
															</Button>
														</Tooltip>
													</span>
													: '' }
											   </FormGroup>
											
										</div>
										
										 
												<Modal isOpen={this.state.searchDialog} slide="right" size="sm" className="right1" backdrop={true}>
												  <ModalHeader>
												   Filters
												  <span className="modal_pull_right1"><button className="close" onClick={() => this.setState({ searchDialog: false,searchcolumn:'',searchfor:'',searchvalue:'' })}>&times;</button></span>
												  </ModalHeader>
												   
												  <ModalBody className="right1_body">
													
														 <div>
														    
															 
															{this.state.filtersLoading ?
									  
										                       <div><center><Loader type="ball-clip-rotate"/></center></div>
									  
										                      :
															this.state.filters.map((item,index) =>
														
															<div>
															<div style={{padding: '4px 1px',display:'flex',alignItems:'center'}}>
															   <a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={()=> this.onFilterApply(item.id,item.domain,item.display_name)}>
																<div className="list_center_equal">
																 <Tooltip className="item" content={item.display_name} placement="top-center" >
																     <span className="text-info"><i className="pe-7s-filter font-size-sm" style={{fontWeight: 'bold',fontSize: '15px'}}> </i></span>&nbsp;<span style={{fontSize: '15px'}} >{item.display_name}</span>
																 </Tooltip>
																  </div>
																 </a>
																 
															</div>
															
															  <hr style={{marginBottom:'2px',marginTop:'2px',borderTop:'1px solid #d1dbe5',width: '268px',marginLeft: '-15px'}}/>
															 </div>
															
															)
														 }
														</div>
														
												  </ModalBody>
												  
												</Modal>
											
				                <div className={this.state.left_style} id="left_search_card_id">
							      
										{this.state.orderlistFilterLoading ?
									  
										  <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
									  
										   :
										   <span>
										   {this.state.isNameFilter ?
												<div>
												  <hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												{this.state.order_filter_data.length>0 ?
												<span>
												{this.state.order_filter_data.map((item,index) =>
														<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={()=> this.onOrderDataChange(item.id)}>
														<div style={{padding: '1px 5px'}} >
															<Tooltip className="item" content={item.equip_asset_common} placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>{item.equip_asset_common}</b></span>
															</Tooltip>
														<div className="nowrap_content">
															<span className="text-capitalize" style={{fontSize: '11px'}}>{item.name}&nbsp;</span><span className="text-capitalize" style={{fontSize: '11px'}}>|&nbsp;{this.getmtname(item.maintenance_type)}&nbsp;</span>
															{item.equipment_location_id[1] ? 
															
															  <span className="text-capitalize" style={{fontSize: '11px',whiteSpace:'nowrap'}}>|&nbsp;{item.equipment_location_id[1]}</span>
															
															: '' }
														</div>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
														</a>
												)}
												{this.state.orderFilterScrollLoad ?
										  
														  <div><center><Loader type="ball-clip-rotate"/></center></div>
												: '' }
												</span>
												
												 :
												 
												  <div className="text-center"><img src={nodata} width="165"/> <br/><b>No Work Orders Found..</b></div>
												  
												}
												</div>
												  
										   
											   :
											   <span>
											   <hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
											   {this.state.orderlist.map((item,index) =>
													<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}}  onClick={()=> this.onOrderDataChange(item.id)} >
														<div style={{padding: '1px 5px'}}>
															<Tooltip className="item" content={item.equip_asset_common} placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>{item.equip_asset_common}</b></span>
															</Tooltip>
														<div className="nowrap_content">
															<span className="text-capitalize" style={{fontSize: '11px'}}>{item.name}&nbsp;</span><span className="text-capitalize" style={{fontSize: '11px'}}>|&nbsp;{this.getmtname(item.maintenance_type)} &nbsp;</span>
															{item.equipment_location_id[1] ? 
															<span className="text-capitalize" style={{fontSize: '11px',whiteSpace:'nowrap'}}>|&nbsp;{item.equipment_location_id[1]}</span>
															: '' }
														</div>	
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
													</a>
											  )}
											  {this.state.orderlistloading && this.state.left_style!='' ?
										  
														  <div><center><Loader type="ball-clip-rotate"/></center></div>
												: '' }
											   </span>
											   
												
											}
											</span>
												
										}
										
								
							   </div>
						
						
					<div className={this.state.content_card}>
					 
					 <div className="drawer_left_menu pull-left">
                  
				
						<PageTitleAlt3
							heading=""
							subheading=""
							icon=""
							menuLevel1={this.props.location.state  ? this.props.location.state.value : 'Work Orders' }
							menuLevel2={this.props.location.state ? 'Work Orders' : this.state.ord_name }
							menuLevel3={this.props.location.state ? this.state.ord_name : '' }
							menuLevel2link = {this.props.location.state ? '#/helpdesk/workorders' : false}
							menuLevel1click = { () => this.props.location.state ? this.directtoanother() : ''}
						    menuLevel1link = {this.props.location.state ? 'javascript:;' : '#/helpdesk/workorders'} 
							isbreadcumb={true}
						/>
		           </div>

					 
					  {this.state.dataLoading ?
				          <center><Loader type="ball-clip-rotate" style={loaddiv}/></center>
				       :
					   <div>
			
					   <Card className="main-card mb-2 custom_card_view">
					
					   <CardBody>
					   {!this.state.formmode ?
						<Row>
						    <Col lg="2" sm="12" xs="12">
												 
										<div className="text-center">		 
										  <img src={equimg} />
										  <br/>
										   <span className="custom_view_head">{this.getmtname(this.state.ord_mtype)}</span>
										 </div>
													
							</Col>
						   <Col lg="4" sm="12" xs="12">
						     
							<span className="text-capitalize">{this.state.ord_name}&nbsp;|</span><span className="text-capitalize">&nbsp;#{this.state.ord_sequence}<br/></span>
							
							<span><b>{this.getnovalues(this.state.ord_desc)}</b><br/></span>
							
							<Tooltip className="item" content="Type" placement="left" >
					          <span><span className="text-info"><i className="pe-7s-box1 font-size-sm" style={{fontWeight: 'bold',fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.gettypename(this.state.ord_type)}</span>
							 </Tooltip>
							 
						{this.state.ord_type=='equipment' ?
						
							<Tooltip className="item" content="Equipment" placement="left" >
							   <span><span className="text-info"><i className="pe-7s-tools font-size-sm" style={{fontWeight: 'bold',fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.state.ord_equip ? <a href="javascript:;" onClick={()=>this.setState({isEquipLink:true})} style={{textDecoration:'none',color:'#495057'}}>{this.getnovalues(this.state.ord_equip)}</a> : this.getnovalues(this.state.ord_equip) } </span>
							</Tooltip>
							
						: '' }
							
							<Tooltip className="item" content={this.state.ord_space ? 'Location' : 'Space'} placement="left" >
							  <span><span className="text-info"><i className="lnr-map-marker font-size-sm" style={{fontWeight: 'bold',fontSize: '18px'}}> </i></span>{this.state.ord_space ? <a href="javascript:;" onClick={()=>this.setState({isSpaceLink:true})} style={{textDecoration:'none',color:'#495057'}}>{this.getnovalues(this.state.ord_space)}</a> : <a href="javascript:;" onClick={()=>this.setState({isSpaceLink:true})} style={{textDecoration:'none',color:'#495057'}}>{this.getnovalues(this.state.ord_asset)}</a> } </span>
							</Tooltip>
							
						
						   </Col>
					  
					   <Col lg="3" sm="12" xs="12" className="">
							<Tooltip className="item" content="Status" placement="left" >
								<span><span className="text-info"><i className="lnr-layers font-size-sm" style={{fontWeight: 'bold',fontSize: '18px'}}> </i></span>{this.getstatename(this.state.ord_status)}</span>
					    	</Tooltip>
							<Tooltip className="item" content="Maintenance Team" placement="left" >
								<span><span className="text-info"><i className="pe-7s-users font-size-sm" style={{fontWeight: 'bold',fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.state.ord_mteam)}</span>
							</Tooltip>
							<Tooltip className="item" content="Technician" placement="left" >
								<span><span className="text-info"><i className="pe-7s-user font-size-sm" style={{fontWeight: 'bold',fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.state.ord_technican)}</span>
							</Tooltip>
							
					   </Col>
					   
					    <Col lg="3" sm="12" xs="12" className="mobile_form_buttons">
				   
				   
						 <div className="btn-actions-pane-right text-capitalize actions-icon-btn pull-right">
						 
						  <Dropdown trigger="click" menu={(
							  <Dropdown.Menu>
							   <span className="el-select-group__title text-center">Manage</span>
								<Dropdown.Item command="a" >
								 <div style={{width:'90px'}} onClick={this.onFormModeChange}>
																<Button type="text">
																			  Edit
																   </Button>
									
								   </div>
											
								   </Dropdown.Item>
								   
									 <span className="el-select-group__title text-center">Action</span>
									      {this.state.ord_status!='ready' ?
                                               <Dropdown.Item command="b" >
											    <div style={{width:'90px'}} onClick={()=> this.onStateChange('action_unassign')}>
                                                   
															<Button type="text">
																			 Unassign 
																   </Button>
												</div>	
                                               </Dropdown.Item>
				                                 : '' }
								              {this.state.ord_status!='assigned' || this.state.ord_status=='ready' || this.state.ord_status=='draft' ?
                                               <Dropdown.Item command="c" >
                                                   <div style={{width:'90px'}} onClick={()=> this.onStateChange('assgined_request_order')}>
															<Button type="text">
																			 Assign
																   </Button>
													</div>	
                                               </Dropdown.Item>
				                                 : '' }
												  {this.state.ord_status!='pause' || this.state.ord_status=='done' ?
                                                <Dropdown.Item command="d" >
                                                   <div style={{width:'90px'}} onClick={()=> this.onStateChange('action_pause')}>
															<Button type="text">
																			  Pause
																   </Button>
													</div>	
                                                </Dropdown.Item>
				                                 : '' }
												 
												 {this.state.ord_status!='done' || this.state.ord_status=='assigned' ?
                                                  <Dropdown.Item command="e" >
                                                     <div style={{width:'90px'}} onClick={()=> this.onStateChange('do_record')}>
															<Button type="text">
																			 Done
																   </Button>
													</div>	
                                               </Dropdown.Item>
				                                : '' }
												
												{this.state.ord_status!='done' || this.state.ord_status=='assigned' || this.state.ord_status=='pause' ?
                                                  <Dropdown.Item command="e" >
                                                     <div style={{width:'90px'}} onClick={()=> this.onStateChange('action_start')}>
															<Button type="text">
																			 Start
																   </Button>
													</div>	
                                               </Dropdown.Item>
				                                : '' }
												
												{this.state.ord_status!='cancel' || this.state.ord_status=='ready' || this.state.ord_status=='assigned' || this.state.ord_status=='draft' || this.state.ord_status=='pause' ?
                                                  <Dropdown.Item command="e" >
                                                     <div style={{width:'90px'}} onClick={()=> this.onStateChange('reject_request_order')}>
															<Button type="text">
																			 Cancel
																   </Button>
													</div>	
                                               </Dropdown.Item>
				                                : '' }
												
												{this.state.ord_status!='draft' || this.state.ord_status=='ready' ?
                                                  <Dropdown.Item command="e" >
                                                     <div style={{width:'90px'}} onClick={()=> this.onStateChange('action_set_to_draft')}>
															<Button type="text">
																			 Draft
																   </Button>
													</div>	
                                               </Dropdown.Item>
				                                : '' }
												
												{this.state.ord_status!='ready' || this.state.ord_status=='done' || this.state.ord_status=='assigned' || this.state.ord_status=='draft' || this.state.ord_status=='cancel' ?
                                                  <Dropdown.Item command="e" >
                                                     <div style={{width:'90px'}} onClick={()=> this.onStateChange('action_reopen')}>
															<Button type="text">
																			 Reopen
																   </Button>
													</div>	
                                               </Dropdown.Item>
				                                : '' }
							
							  </Dropdown.Menu>
							)}>
							  <span className="el-dropdown-link">
								<i className="el-icon-more"/>
							  </span>
							</Dropdown>
                                        
                           </div>
						
				     </Col>
					   </Row>
					   : 
					   
					   <Row>
						    <Col lg="2" sm="12" xs="12">
												 
										<div className="text-center">		 
										  <img src={equimg} />
										  <br/>
										   <span className="custom_view_head">{this.getmtname(this.state.ord_mtype)}</span>
										 </div>
													
							</Col>
						   <Col lg="5" sm="12" xs="12">
						     
							             <FormGroup>
											  <Label className="text-capitalize">Type</Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												 <Button.Group>
												  <Button type={this.state.ord_type == 'asset' ? 'primary' : ''} onClick={() => this.onAddFields('asset')}>Space</Button>
												  <Button type={this.state.ord_type == 'equipment' ? 'primary' : ''} onClick={ () => this.onAddFields('equipment')}>Equipment</Button>
                                                 </Button.Group>
											 </FormGroup>
											 
											
							 
						{this.state.ord_type=='equipment' ?
										<div>
							                  <FormGroup>
													<Label className="text-capitalize">Equipment<span className="text-danger"></span></Label>
													 <Select onChange={this.handleEquipChange} value={this.state.ord_equip_id ? this.state.ord_equip_id : false} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this,'equipment')}  loading={this.state.equipLoading}>
															{
															  this.state.equipments.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
													   </Select>
												</FormGroup>
												
												<FormGroup>
													<Label className="text-capitalize">Location<span className="text-danger"></span></Label>
													 <Select onChange={this.handleSpaceChange} value={this.state.ord_space_id ? this.state.ord_space_id : false} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this,'space')}  loading={this.state.spaceLoading}>
															{
															  this.state.spaces.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
													   </Select>
												</FormGroup>
											</div>
						: 
											<div>
												<FormGroup>
													<Label className="text-capitalize">Space<span className="text-danger"></span></Label>
													 <Select onChange={this.handleAssetChange} value={this.state.ord_asset_id ? this.state.ord_asset_id : false} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this,'asset')}  loading={this.state.assetLoading}>
															{
															  this.state.assets.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
													   </Select>
												</FormGroup>
											</div>
				
						
						}
												 <FormGroup>
												   <Label className="text-capitalize">Priority<span className="text-danger"></span></Label>
													<Select onChange={this.setpriority} value={this.state.ord_prty}>
																  {
																	priorities.map(el => {
																	  return <Select.Option key={el.value} label={el.label} value={el.value} />
																	})
																  }
													</Select>
												</FormGroup>
										
						   </Col>
					  
					   <Col lg="5" sm="12" xs="12" className="">
										<div>
					                          <FormGroup>
												   <Label className="text-capitalize">Description <span className="text-danger"></span></Label>
													<Input type="text" className="mat_form" name="ord_desc" value={this.state.ord_desc} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
												</FormGroup>
												
												<FormGroup>
													<Label className="text-capitalize">Maintenance Team<span className="text-danger"></span></Label>
													 <Select onChange={this.handleTeamChange} value={this.state.ord_mteam_id ? this.state.ord_mteam_id : false} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this,'mteam')}  loading={this.state.teamLoading}>
															{
															  this.state.mteams.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
													   </Select>
												</FormGroup>
												
												 <FormGroup>
												   <Label className="text-capitalize">Maintenance Type<span className="text-danger"></span></Label>
													<Select onChange={this.setmt} value={this.state.ord_mtype}>
																  {
																	selmtypes.map(el => {
																	  return <Select.Option key={el.value} label={el.label} value={el.value} />
																	})
																  }
													</Select>
												</FormGroup>
											</div>	
						
					
							<span className="pull-right">
												 
																	<span>
																	 <Button type="primary" disabled={this.state.updateLoading}  onClick={this.updateOrder}>
																		 {this.state.updateLoading ? 'Updating...' : 'Update' }
																		</Button>
																		&nbsp;
																		<Button type="danger" onClick={() => this.setState({formmode:false})} >
																			  Cancel
																		</Button>
																	</span>
														
									   </span>
							
					   </Col>
					 
					   </Row>
					   }
						
					  </CardBody>
					 </Card>
				
				   <Card className="main-card mb-2 custom_card_view1">
				   <CardBody className="custom_card_body1">				   
						<Nav>
						<NavLink className="tab_nav_link" active={this.state.activeTab=='1' ? true : false } href="javascript:void(0);" onClick={ ()=> this.onTabChange('1')} >{locale.t('el.tickets.summary')}</NavLink>
						<NavLink className="tab_nav_link" active={this.state.activeTab=='2' ? true : false } href="javascript:void(0);" onClick={ ()=> this.onTabChange('2')} >{locale.t('el.space.checklists')}</NavLink>
						<NavLink className="tab_nav_link" active={this.state.activeTab=='3' ? true : false } href="javascript:void(0);" onClick={ ()=> this.onTabChange('3')} >{locale.t('el.workOrders.parts')} & {locale.t('el.workOrders.tools')}</NavLink>
						<NavLink className="tab_nav_link" active={this.state.activeTab=='4' ? true : false } href="javascript:void(0);" onClick={ ()=> this.onTabChange('4')} >{locale.t('el.workOrders.timeSheet')}</NavLink>
						<NavLink className="tab_nav_link" active={this.state.activeTab=='5' ? true : false } href="javascript:void(0);" onClick={ ()=> this.onTabChange('5')} >{locale.t('el.workOrders.auditLog')}</NavLink>						
					</Nav>
					 </CardBody>
					</Card>
					        <div>
								     {this.state.activeTab=='1' ?
									 <Row>
										<Col md="12" lg="6" className="custom_col_left">
					                       <Card className="mb-2">
												<CardBody>														
														<div className="">
															<span className="card-title  text-uppercase " style={{verticalAlign: 'middle'}}> Scheduled Vs Actual </span>
															  <div style={{textTransform:'capitalize',marginLeft:'auto',textAlign:'center'}} className="pull-right equally_center_div_nocenter">
															  <span style={{fontSize: '12px'}}>{this.getDateTimingsText(this.state.ord_act_enddate,this.state.ord_exe_enddate)}</span>&nbsp;&nbsp;
															
															  {this.getdateoftimings(this.state.ord_act_enddate,this.state.ord_exe_enddate,'day')!=0 ?
																  <div className={this.getDateTimingsColor(this.state.ord_act_enddate,this.state.ord_exe_enddate)} style={{marginRight: 'auto'}}>
																		<b>{this.getdateoftimings(this.state.ord_act_enddate,this.state.ord_exe_enddate,'day')}</b>&nbsp;
																		<span style={{fontSize:'11px'}}>Days</span>
																		 &nbsp;
																   </div>
															  
															    : '' }
															   
															   <div className={this.getDateTimingsColor(this.state.ord_act_enddate,this.state.ord_exe_enddate)} >
																    <b>{this.getdateoftimings(this.state.ord_act_enddate,this.state.ord_exe_enddate,'hour')}</b>&nbsp;
																	<span style={{fontSize:'11px'}}>Hours</span>
																	
															   </div>
															    &nbsp;
															   <div className={this.getDateTimingsColor(this.state.ord_act_enddate,this.state.ord_exe_enddate)} style={{marginLeft: 'auto'}}>
																    <b>{this.getdateoftimings(this.state.ord_act_enddate,this.state.ord_exe_enddate,'minute')}</b>&nbsp;
																	<span style={{fontSize:'11px'}}>Minutes</span>
															   </div>
															
															   
															</div>
														</div>
														<br/>
														   <div className="equally_center_div">
															
																<div className="" style={{marginRight: 'auto'}}>
																		<span className="card_custom_content opacity-8"><b>Scheduled Start</b></span><br/>
																		{!this.state.formmode ? 
																			<span><b>{this.getdateofdttime(this.state.ord_act_date)}</b></span>
																		:
																		
																		<DatePicker
																		  value={new Date(ord_act_date)}
																		  format="yyyy-MM-dd HH:mm:ss"
																		  placeholder="Pick a day"
																		  onChange={date=>{
																			console.debug('DatePicker1 changed: ', date)
																			this.setState({ord_act_date: date})
																		  }}
																		  disabledDate={time=>time.getTime() < Date.now() - 8.64e7}
																		  />
																		  
																		}
																		
																	</div>
																	
																	<div className="">
																		<span className="card_custom_content opacity-8"><b>End</b></span><br/>
																		{!this.state.formmode ? 
																		<span><b>{this.getdateofdttime(this.state.ord_act_enddate)}</b></span>
																		:
																		
																		<DatePicker
																		  value={new Date(this.state.ord_act_enddate)}
																		  format="yyyy-MM-dd HH:mm:ss"
																		  placeholder="Pick a day"
																		  onChange={date=>{
																			console.debug('DatePicker1 changed: ', date)
																			this.setState({ord_act_enddate: date})
																		  }}
																		  disabledDate={time=>time.getTime() < Date.now() - 8.64e7}
																		  />
																		  
																		}
																	</div>
																	
																	<div className="" style={{marginLeft: 'auto'}}>
																		<span className="card_custom_content opacity-8"><b>Duration</b></span><br/>
																		{!this.state.formmode ? 
																		<span><b>{this.getTimeFromFloat(this.state.ord_act_duration)}</b></span>
																		
																		:
																		
																		  <Input type="number" className="mat_form" name="ord_act_duration" value={this.state.ord_act_duration} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
																		}
																	</div>
																
															 </div>
															
															<br/>
															
															{this.state.ord_exe_date!='' || this.state.formmode ?
															
															 <div className="equally_center_div">
															   <div className="" style={{marginRight: 'auto'}}>
																	<span className="card_custom_content opacity-8"><b>Actual Start</b></span><br/>
																	{!this.state.formmode ? 
																	<span><b>{this.getdateofdttime(this.state.ord_exe_date)}</b></span>
																	:
																		
																		<DatePicker
																		  value={this.state.ord_exe_date ? new Date(this.state.ord_exe_date) : new Date()}
																		  format="yyyy-MM-dd HH:mm:ss"
																		  placeholder="Pick a day"
																		  onChange={date=>{
																			console.debug('DatePicker1 changed: ', date)
																			this.setState({ord_exe_date: date})
																		  }}
																		  disabledDate={time=>time.getTime() < Date.now() - 8.64e7}
																		  />
																		  
																	}
																	
																</div>
																
																<div className="">
																	<span className="card_custom_content opacity-8"><b>End</b></span><br/>
																	{!this.state.formmode ? 
																	<span><b>{this.getdateofdttime(this.state.ord_exe_enddate)}</b></span>
																	:
																	<DatePicker
																		  value={this.state.ord_exe_enddate ? new Date(this.state.ord_exe_enddate) : new Date()}
																		  format="yyyy-MM-dd HH:mm:ss"
																		  placeholder="Pick a day"
																		  onChange={date=>{
																			console.debug('DatePicker1 changed: ', date)
																			this.setState({ord_exe_enddate: date})
																		  }}
																		  disabledDate={time=>time.getTime() < Date.now() - 8.64e7}
																		  />
																		  
																	}
																</div>
																
																<div className="" style={{marginLeft: 'auto'}}>
																	<span className="card_custom_content opacity-8"><b>Duration</b></span><br/>
																{!this.state.formmode ? 
																	<span><b>{this.getTimeFromFloat(this.state.ord_wrk_duration)}</b></span>
																	:
																	 <Input type="number" className="mat_form" name="ord_wrk_duration" value={this.state.ord_wrk_duration} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
															      }
																</div>
															</div>
															
															:
															  <div className="equally_center_div"><img src={nodata} width="55" height="42"/>&nbsp;<b>Oops! Work order is not started yet.</b></div>
															}
																
														    
														 
														
												</CardBody>
											</Card>
								</Col>		 
								<Col md="12" lg="6"  className="custom_col_right">			
								 <Card className="mb-2" style={{ minHeight: '96%'}}>
				                   <CardBody>
														
														<div className="">
															<span className="card-title text-uppercase " style={{verticalAlign: 'middle'}}> Checklist  </span>
															
														</div>
														<br/>
														{this.state.ord_checklists.length>0 ?
														   <div className="text-left">
														   
															 <div>
																<span style={{fontSize:'20px'}}><b>{this.getConfirmCheckLists()}/{this.state.ord_checklists.length}</b></span>&nbsp;<span>Completed</span><br/>
																 <Progress strokeWidth={18} percentage={checklistper} textInside />
																
						                                      </div>
															
														  </div>
														  
														   :
															  
															  <div className="text-center"><img src={nodata} width="60"/><b>No Checklist Found..</b></div>
									                         }
														
												 </CardBody>
											</Card>
								  </Col>
								  <Col md="12" lg="6" className="custom_col_left">
					                       <Card className="mb-2">
												<CardBody>
														
														<div className="">
															<span className="card-title text-uppercase " style={{verticalAlign: 'middle'}}> Costing </span>
															  <div style={{textTransform:'capitalize',marginLeft:'auto',textAlign:'center'}} className="pull-right equally_center_div_nocenter">
															  <span className="text-success" style={{fontSize: '13px'}}>Allocated Resources</span>&nbsp;<b className="text-success">{this.state.ord_resources}</b>
															
														
															</div>
														</div>
														<br/>
														   <div className="equally_center_div">
															
																<div className="" style={{marginRight: 'auto'}}>
																		<span className="card_custom_content opacity-8" style={{fontSize:'12px'}}><b>Planned Material Cost</b></span><br/>
																		{!this.state.formmode ? 
																		<span><b>{this.state.ord_plan_mc}.00</b></span>
																		:
																		 <Input type="number" className="mat_form" name="ord_plan_mc" style={{fontSize:'13px'}} value={this.state.ord_plan_mc} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
																		}
																		
																	</div>
																	
																	<div className="" style={{marginLeft: 'auto',marginRight: 'auto'}}>
																		<span className="card_custom_content opacity-8" style={{fontSize:'12px'}}><b>Tool Cost</b></span><br/>
																		{!this.state.formmode ? 
																		<span><b>{this.state.ord_plan_tc}.00</b></span>
																		:
																		 <Input type="number" className="mat_form" name="ord_plan_tc" style={{fontSize:'13px'}} value={this.state.ord_plan_tc} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
																		}
																	</div>
																	
																	<div className="" style={{marginLeft: 'auto'}}>
																		<span className="card_custom_content opacity-8" style={{fontSize:'12px'}}><b>Labour Cost</b></span><br/>
																		{!this.state.formmode ? 
																		<span><b>{this.state.ord_plan_lc}.00</b></span>
																		:
																		 <Input type="number" className="mat_form" name="ord_plan_lc" style={{fontSize:'13px'}}  value={this.state.ord_plan_lc} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
																		}
																	</div>
																
															 </div>
															
															<br/>
															
													
															 <div className="equally_center_div">
															   <div className="" style={{marginRight: 'auto'}}>
																		<span className="card_custom_content opacity-8" style={{fontSize:'12px'}}><b>Actual Material Cost</b></span><br/>
																		{!this.state.formmode ? 
																		<span><b>{this.state.ord_actual_mc}.00</b></span>
																		:
																		 <Input type="number" className="mat_form" name="ord_actual_mc" style={{fontSize:'13px'}} value={this.state.ord_actual_mc} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
																		}
																		
																	</div>
																	
																	<div className="">
																		<span className="card_custom_content opacity-8" style={{fontSize:'12px'}}><b>Tool Cost</b></span><br/>
																		{!this.state.formmode ? 
																		<span><b>{this.state.ord_actual_tc}.00</b></span>
																		:
																		 <Input type="number" className="mat_form" name="ord_actual_tc" style={{fontSize:'13px'}} value={this.state.ord_actual_tc} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
																		}
																	</div>
																	
																	<div className="" style={{marginLeft: 'auto'}}>
																		<span className="card_custom_content opacity-8" style={{fontSize:'12px'}}><b>Labour Cost</b></span><br/>
																		{!this.state.formmode ? 
																		<span><b>{this.state.ord_actual_lc}.00</b></span>
																		:
																		 <Input type="number" className="mat_form" name="ord_actual_lc" style={{fontSize:'13px'}}  value={this.state.ord_actual_lc} onChange={this.onInputChange.bind(this)} bsSize="sm"  />
																		}
																	</div>
															</div>
															
												</CardBody>
											</Card>
								</Col>		 
								<Col md="12" lg="6"  className="custom_col_right">			
								 <Card className="mb-2" style={{ minHeight: '96%'}}>
				                   <CardBody>
														
													<div className="">
															<span className="card-title  text-uppercase " style={{verticalAlign: 'middle'}}> Parts to Consume Vs Consumed Parts </span>
															  <div style={{textTransform:'capitalize',marginLeft:'auto',textAlign:'center'}} className="pull-right equally_center_div_nocenter">
															 
															</div>
														</div>
														<br/>
														   
															
																<div className="text-center"><img src={nodata} width="60"/> <br/><b>No Data Found..</b></div>
																
															
												 </CardBody>
											</Card>
								  </Col>
							 <Col md="12" lg="12" >			
								<Card className="main-card mb-2">
				                   <CardBody>
									<div className="card-title  text-uppercase ">
											Send Message
									 </div>
									 <br/>
									 
									              <FormGroup>
													 <Label className="text-capitalize"></Label>
													 <Input type="text" className="mat_form"  name="msg_body" id="msg_body" placeholder="Write Something.."  bsSize="sm"/>
												 </FormGroup>
											<span className="pull-right">
											  <Button type="primary" disabled={this.state.sendMessage} onClick={this.onSendMessage}>
																		<i className="pe-7s-paper-plane"> </i>&nbsp;{this.state.sendMessage ? 'Sending...' : 'Send' }
											 </Button>
										  </span>
																
								      </CardBody>
									    
									</Card>
								  </Col>
								  
								</Row>
							 : '' }
					         </div>
							 <div>
							 {this.state.activeTab=='2' ?
								  <Card className="main-card mb-2 custom_card_view">
											     <CardBody> 
													{this.state.checklist.length>0?
														 <Col lg="12">
														  <Table responsive className="mb-0">
															<thead>
																<th>{locale.t('el.workOrders.questionGroup')}</th>
																<th>{locale.t('el.workOrders.question')}</th>
																<th>{locale.t('el.workOrders.answer')}</th>
																<th>{locale.t('el.workOrders.answerBy')}</th>
																<th>{locale.t('el.workOrders.answerOn')}</th>
																{this.state.formmode ?  <th>{locale.t('el.equipment.manage')}</th> : '' }
															</thead>
															<tbody>
											
													
																{this.state.checklist.map((item,index) =>
																			 <tr key = {item}>
																				<td>{item.mro_quest_grp_id[1]}</td>
																				<td>{item.mro_activity_id[1]}</td>
																				<td>{item.answer_common}</td>
																				<td>{item.answer_common ? item.user_id[1] : ''}</td>
																				<td>{item.answer_common ? this.getdateofdttime(item.write_date) : ''}</td>
																			{this.state.formmode ? 
																				<td>
																					<Button type="primary" size="small">
																					 <i className="lnr-pencil"> </i>
																					</Button>
																					
																					<Button type="danger" size="small">
																					 <i className="pe-7s-trash"> </i>
																					</Button>
																				</td>
																			:  '' }
																				
																			</tr>
																)}
																
																
																{this.state.formmode ? 
																	<tr>
																	  <td colSpan="6">
																	  <center>
																	    <Button type="text" size="large">
																					 <i className="lnr-plus-circle"> </i>&nbsp;Add
																		</Button>
																	  </center>
																	 </td>
																	</tr>
																: '' }
																
																	<tr><td colSpan="6"></td></tr>
																</tbody>
															</Table>
													    </Col>
														:
														
															!this.state.formmode ?  
															
															  <div className="text-center"><img src={nodata} width="170"/> <br/><b>No CheckLists Found..</b></div>
															  
															  :
															  
															  <Table responsive className="mb-0">
																<thead>
																	<th>Question Group</th>
																	<th>Question</th>
																	<th>Answer</th>
																	<th>Answered By</th>
																	<th>Answered On</th>
																	<th>Manage</th>
																</thead>
																<tbody>
												
																		<tr>
																		  <td colSpan="6">
																		  <center>
																			<Button type="text" size="large">
																						 <i className="lnr-plus-circle"> </i>&nbsp;Add
																			</Button>
																		  </center>
																		 </td>
																		</tr>
																		<tr><td colSpan="6"></td></tr>
																	</tbody>
																</Table>
															
												
														}
											   </CardBody>
											 
										  </Card>
							 : '' }
					         </div>
							  <div>
							 {this.state.activeTab=='3' ?
								 
													<Row>
													  <Col lg="12" sm="12" xs="12" className="">
													   <Card className="main-card mb-2">
											            <CardBody>
														  <div><span className="card-title text-uppercase ">Planned Parts</span> </div>
														 <br/>
														 {this.state.planpartsloading ?
															  <div className="text-center"><Loader type="ball-clip-rotate"/></div>
														  :
														   this.state.planparts.length>0?
														  <Table responsive className="mb-0">
															<thead>
																<th>Parts</th>
																<th>Quantity</th>
																<th>Type</th>
																<th>Category</th>
																<th>Warehouse</th>
																<th>Location</th>
																{this.state.formmode ?  <th>Manage</th> : '' }
															</thead>
															<tbody>
											
													
																{this.state.planparts.map((item,index) =>
																			 <tr key = {item}>
																				<td>{item.parts_id[1]}</td>
																				<td>{item.parts_qty}.00</td>
																				<td>{this.getproducttype(item.parts_type)}</td>
																				<td>{item.parts_categ_id[1]}</td>
																				<td>{this.state.ord_warehouse}</td>
																				<td>{this.state.ord_part_location}</td>
																				{this.state.formmode ? 
																				<td>
																					<Button type="primary" size="small">
																					 <i className="lnr-pencil"> </i>
																					</Button>
																					
																					<Button type="danger" size="small">
																					 <i className="pe-7s-trash"> </i>
																					</Button>
																				</td>
																			:  '' }
																	
																			</tr>
																)}
																	{this.state.formmode ? 
																		<tr>
																		  <td colSpan="7">
																		  <center>
																			<Button type="text" size="large"  onClick={() => this.onPartMod()}>
																						 <i className="lnr-plus-circle"> </i>&nbsp;Add
																			</Button>
																		  </center>
																		 </td>
																		</tr>
																     : '' }
																	<tr><td colSpan="7"></td></tr>
																</tbody>
															</Table>
															:
															
															!this.state.formmode ? 
															
														     <div className="text-center"><img src={nodata} width="100"/> <br/><b>No Planned Parts Found..</b></div>
															 
															 :
															  
															  <Table responsive className="mb-0">
																<thead>
																	<th>Parts</th>
																	<th>Quantity</th>
																	<th>Type</th>
																	<th>Category</th>
																	<th>Warehouse</th>
																	<th>Location</th>
																	<th>Manage</th>
																</thead>
																<tbody>
												
																		<tr>
																		  <td colSpan="7">
																		  <center>
																			<Button type="text" size="large" onClick={() => this.onPartMod()}>
																						 <i className="lnr-plus-circle"> </i>&nbsp;Add
																			</Button>
																		  </center>
																		 </td>
																		</tr>
																		<tr><td colSpan="7"></td></tr>
																	</tbody>
																</Table>
														  }
														  
														  
															<Dialog
																title="Add Planned Part"
																size="large"
																visible={ this.state.parts_add }
																onCancel={ () => this.setState({ parts_add: false }) }
																lockScroll={ false }
															 >
															<Dialog.Body>
															<div>
																<Row>
																	<Col lg="3" sm="12" xs="12">
																		 <FormGroup>
																			 <Label className="text-capitalize">Parts</Label>
																			 <Select onChange={this.setpart} value={this.state.part_id} loading={this.state.partsLoading}>
																				  {
																					this.state.parts.map(el => {
																					  return <Select.Option key={el.id} label={el.display_name} value={el.id} />
																					})
																				  }
																			 </Select>
																		 </FormGroup> 
														
																	 </Col>
																	 
																	 <Col lg="3" sm="12" xs="12">
																		 <FormGroup>
																			 <Label className="text-capitalize">Quantity Description</Label>
																			 <Input type="number" className="mat_form" value={this.state.part_qty} name="part_qty" onChange={this.onInputChange.bind(this)} bsSize="sm"/>
																		 </FormGroup> 
														
																	 </Col>
																	 
																	 <Col lg="3" sm="12" xs="12">
																		 <FormGroup>
																			 <Label className="text-capitalize">Product Type</Label>
																			 <Input type="text" className="mat_form" value={this.state.part_type} name="part_type" onChange={this.onInputChange.bind(this)} bsSize="sm" readOnly />
																		 </FormGroup> 
														
																	 </Col>
																	 
																	 <Col lg="3" sm="12" xs="12">
																		 <FormGroup>
																			 <Label className="text-capitalize">Product Category</Label>
																			 <Input type="text" className="mat_form" value={this.state.part_cat} name="part_cat" onChange={this.onInputChange.bind(this)} bsSize="sm" readOnly />
																		 </FormGroup> 
														
																	 </Col>
																</Row>
																
																<span className="pull-right">
																		 <Button type="primary" disabled={this.state.partAddLoading}  onClick={this.addParts}>
																			 {this.state.partAddLoading ? 'Saving...' : 'Save' }
																			</Button>
																			&nbsp;
																			<Button type="danger" onClick={() => this.setState({parts_add:false})} >
																				  Cancel
																			</Button>
																</span>
																<br/><br/>
															</div>
															
															</Dialog.Body>
															
														   </Dialog>
											   
														   </CardBody>
										                 </Card>
													    </Col>
														
													<Col lg="12" sm="12" xs="12" className="">
														<Card className="main-card mb-2" >
											               <CardBody>
														 <div><span className="card-title text-uppercase">Tools</span></div>
														 <br/>
														 {this.state.toolsloading ?
															  <div className="text-center"><Loader type="ball-clip-rotate"/></div>
														  :
														   this.state.ordertools.length>0?
														  <Table responsive className="mb-0">
															<thead>
																<th>Tools</th>
																<th>Other Maintenance Order</th>
																{this.state.formmode ?  <th>Manage</th> : '' }
															</thead>
															<tbody>
											
													
																{this.state.ordertools.map((item,index) =>
																			 <tr key = {item}>
																				<td>{item.tool_id[1]}</td>
																				<td>{item.order_id[1]}</td>
																				{this.state.formmode ? 
																				<td>
																					<Button type="primary" size="small">
																					 <i className="lnr-pencil"> </i>
																					</Button>
																					
																					<Button type="danger" size="small">
																					 <i className="pe-7s-trash"> </i>
																					</Button>
																				</td>
																			:  '' }
																			</tr>
																)}
																	{this.state.formmode ? 
																		<tr>
																		  <td colSpan="3">
																		  <center>
																			<Button type="text" size="large" onClick={() => this.onToolMod()}>
																						 <i className="lnr-plus-circle"> </i>&nbsp;Add
																			</Button>
																		  </center>
																		 </td>
																		</tr>
																     : '' }
																	<tr><td colSpan="3"></td></tr>
																</tbody>
															</Table>
															:
															
															!this.state.formmode ?
															
														      <div className="text-center"><img src={nodata} width="100"/> <br/><b>No Tools Found..</b></div>
															 
															 :
															 
															  <Table responsive className="mb-0">
																<thead>
																	<th>Tools</th>
																    <th>Other Maintenance Order</th>
																	<th>Manage</th>
																</thead>
																<tbody>
												
																		<tr>
																		  <td colSpan="3">
																		  <center>
																			<Button type="text" size="large" onClick={() => this.onToolMod()}>
																						 <i className="lnr-plus-circle"> </i>&nbsp;Add
																			</Button>
																		  </center>
																		 </td>
																		</tr>
																		<tr><td colSpan="3"></td></tr>
																	</tbody>
																</Table>
																
														  }
														  
														  <Dialog
																title="Add Tools"
																size="large"
																visible={ this.state.tools_add }
																onCancel={ () => this.setState({ tools_add: false }) }
																lockScroll={ false }
															 >
															<Dialog.Body>
															<div>
																<Row>
																	<Col lg="6" sm="12" xs="12">
																		 <FormGroup>
																			 <Label className="text-capitalize">Tools</Label>
																			  <Select onChange={this.handleToolChange}  filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this,'tools')}  loading={this.state.selToolsLoading}>
																				{
																				  this.state.seltools.map(el => {
																					 return <Select.Option key={el.id} label={el.display_name} value={el.id} />
																					})
																				}
																		   </Select>
																		 </FormGroup> 
														
																	 </Col>
																	 
																
																	 <Col lg="6" sm="12" xs="12">
																		 <FormGroup>
																			 <Label className="text-capitalize">Other Maintenance Order</Label>
																			 <Input type="text" className="mat_form" value={this.state.other_mo} name="other_mo" onChange={this.onInputChange.bind(this)} bsSize="sm" readOnly />
																		 </FormGroup> 
														
																	 </Col>
																	
																</Row>
																
																<span className="pull-right">
																		 <Button type="primary" disabled={this.state.toolAddLoading}  onClick={this.addTools}>
																			 {this.state.toolAddLoading ? 'Saving...' : 'Save' }
																			</Button>
																			&nbsp;
																			<Button type="danger" onClick={() => this.setState({tools_add:false})} >
																				  Cancel
																			</Button>
																</span>
																<br/><br/>
															</div>
															
															</Dialog.Body>
															
														   </Dialog>
														   
														   </CardBody>
										                 </Card>
													    </Col>
														
													</Row>
											
							 : '' }
					         </div>
							 <div>
							 {this.state.activeTab=='4' ?
								  <Card className="main-card mb-2 custom_card_view">
											     <CardBody> 
												     {this.state.timesheetloading ?
															  <div className="text-center col-md-12"><Loader type="ball-clip-rotate"/></div>
														:
													this.state.timesheets.length>0?
														 <Col lg="12">
														  <Table responsive className="mb-0">
															<thead>
																<th>Start Date</th>
																<th>End Date</th>
																<th>Reason</th>
																<th>Description</th>
																<th>Location</th>
																<th>Total Hours</th>
																{this.state.formmode ?  <th>Manage</th> : '' }
															</thead>
															<tbody>
											
													
																{this.state.timesheets.map((item,index) =>
																			 <tr key = {item}>
																				<td>{this.getdateofdttime(item.start_date)}</td>
																				<td>{this.getdateofdttime(item.end_date)}</td>
																				<td>{item.reason}</td>
																				<td>{item.description}</td>
																				<td>{item.address}</td>
																				<td>{item.total_hours}</td>
																				{this.state.formmode ? 
																				<td>
																					<Button type="primary" size="small">
																					 <i className="lnr-pencil"> </i>
																					</Button>
																					
																					<Button type="danger" size="small">
																					 <i className="pe-7s-trash"> </i>
																					</Button>
																				</td>
																			:  '' }
																				
																			</tr>
																)}
																	{this.state.formmode ? 
																		<tr>
																		  <td colSpan="7">
																		  <center>
																			<Button type="text" size="large">
																						 <i className="lnr-plus-circle"> </i>&nbsp;Add
																			</Button>
																		  </center>
																		 </td>
																		</tr>
																     : '' }
																	<tr><td colSpan="7"></td></tr>
																</tbody>
															</Table>
													    </Col>
														:
														
														!this.state.formmode ?
														
														   <div className="text-center"><img src={nodata} width="170"/> <br/><b>No Timesheets Found..</b></div>
														  
														  :
															 
															  <Table responsive className="mb-0">
																<thead>
																	<th>Start Date</th>
																	<th>End Date</th>
																	<th>Reason</th>
																	<th>Description</th>
																	<th>Location</th>
																	<th>Total Hours</th>
																	<th>Manage</th>
																</thead>
																<tbody>
												
																		<tr>
																		  <td colSpan="7">
																		  <center>
																			<Button type="text" size="large">
																						 <i className="lnr-plus-circle"> </i>&nbsp;Add
																			</Button>
																		  </center>
																		 </td>
																		</tr>
																		<tr><td colSpan="7"></td></tr>
																	</tbody>
																</Table>
												
														}
											   </CardBody>
											 
										  </Card>
							 : '' }
					         </div>
							 <div>	
									 {this.state.activeTab=='5' ?	
										<Row>
										  <Col md="12" lg="12">
										  <Card className="main-card mb-2 custom_card_view">
												
											   <CardBody>
											     
													 {this.state.isAuditLogsLoaded ?
															 <div className="text-center col-md-12"><Loader type="ball-clip-rotate"/></div>
															 :
														 <Col lg="12">
														{this.state.audits.length>0 ?
															
														<VerticalTimeline animate={false} className="vertical-time-icons" layout="1-column">
														{this.state.audits.map((item,index) =>
															<VerticalTimelineElement
																className="vertical-timeline-item"					
																icon={<div className="timeline-icon border-primary"><i
																	className="pe-7s-culture icon-gradient bg-night-fade"/></div>}
															  >
															 
																<h4 className="timeline-title">{item.author_id[1]} &nbsp; <span style={{fontSize:'13px',fontWeight:'450',textTransform: 'lowercase'}}>{this.getdateoftimingsind(item.date)}</span></h4>
																<p dangerouslySetInnerHTML={{__html: item.body}} />
																<ul>
																   {item.tracking_value_ids.map((item1,index1) =>
																	 <li>{item1.old_value}&nbsp;<i className="lnr-arrow-right"/>&nbsp;{item1.new_value}</li>
																	)}
																</ul>
															</VerticalTimelineElement>
														)}
														{this.state.auditLoading ?
															 <div className="text-center col-md-12"><Loader type="ball-clip-rotate"/></div>
														: '' }
                                                         </VerticalTimeline>
														   :
														   <div className="text-center"><img src={nodata} width="170"/> <br/><b>No Audit Logs Found..</b></div>
														}
													    </Col>
												
														}
											   </CardBody>
											 
										  </Card>
										 </Col>
									
									   </Row>
									 :''
								}
							</div>
						   </div>
					        }
					    </div>
					</div>
					}
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}
