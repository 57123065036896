import React, {Component, Fragment} from 'react';
import ReactTooltip from 'react-tooltip';
export default class SVGGenerator extends Component {

	
	render() {
		 let {
            paths
			
        } = this.props;
		
		return  <svg width="2462" height="1307">
		         <g>
				 {paths}
				 </g>
			  </svg>;
	}
}