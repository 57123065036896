import React, {Component, Fragment} from 'react';
import ReactTooltip from 'react-tooltip';
export default class FloatingMenuItem extends Component {

	handleClick() {
		this.props.action();
	}
	
	render() {
		let buttonStyle = {
			backgroundImage: `url(${this.props.icon})`
		}
		
		let label;
		
		if (this.props.label) {
			label = <label>{this.props.label}</label>;
		}
		
		return <div
					onClick={this.handleClick.bind(this)}
					className="floating-menu-item">
				  <div className="floating-menu-icon" ><span data-tip={this.props.tooltip}><i className={this.props.icon} id="font_fab"></i><ReactTooltip /></span></div>
			  </div>;
	}
}