import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import { Redirect, Link } from 'react-router-dom';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import Circle from 'react-circle';
import CountUp from 'react-countup';
import Select from 'react-select';
import locale from '../../locale';

import {
	Row, Col,
	Container,
	Card, CardBody, CardHeader,
	CardTitle
} from 'reactstrap';


const loaddiv = { marginTop: '100px', marginBottom: '100px', verticalAlign: 'middle' }
const options = [
	{ value: '1', label: 'Today' },
	{ value: '2', label: 'Last Week' },
	{ value: '3', label: 'Last 30 Days' },
	{ value: '4', label: 'Last 3 Months' },
	{ value: '5', label: 'Last Year' },
];

export default class OrdersOnlyDash extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pacount: 0,
			pmcount: 0,
			occount: 0,
			bmcount: 0,
			orderLoading: false,
			equipLoading: false,
			maintain_teams: [],
			maintain_teams_data: [],
			maintain_spaces: [],
			maintain_spaces_data: [],
			spacedata: [],
			spaceLoading: false,
			equipdata: [],
			selectedOption: '',
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			orderLateLoading: false,
			ordersdata: [],
			ordersLatedata: []
		};

	}


	componentDidMount() {

		this.getmaintainorderdata();
		this.getmaintainorderlatedata();
		//this.getmaintainequipdata();
		this.getmaintainspacedata();
	}

	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
	};

	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}


	getmaintainOrderStateCount(status) {

		var data = this.state.ordersdata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'open') {
				if ((((data[i].state == 'released') || (data[i].state == 'ready'))))
					result += data[i].__count;
			}
			else if (status == 'progress') {
				if ((((data[i].state == 'assigned') || (data[i].state == 'in_progress') || (data[i].state == 'pause'))))
					result += data[i].__count;
			}
			else if (status == 'done') {
				if (((data[i].state == 'done')))
					result += data[i].__count;
			}
		}

		return result;
	}


	getmaintainOrderStateLateCount(mt) {

		var data = this.state.ordersLatedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (mt != 'oc') {
				if (((data[i].maintenance_type == mt)))
					result += data[i].__count;
			}
			else if (mt == 'oc') {
				if ((((data[i].maintenance_type == mt) || (data[i].maintenance_type == 'pr'))))
					result += data[i].__count;
			}
		}

		return result;
	}

	getmaintainOrderStateLateCountByState() {

		var data = this.state.ordersLatedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			result += data[i].__count;
		}

		return result;
	}

	getmaintainOrdersCount() {

		var data = this.state.ordersdata;

		var pa = 0; var bm = 0; var pm = 0; var oc = 0;

		for (var i = 0; i < data.length; i++) {
			if (((data[i].maintenance_type == 'pr') || (data[i].maintenance_type == 'oc')))
				oc += data[i].__count;
			else if ((data[i].maintenance_type == 'bm'))
				bm += data[i].__count;
			else if ((data[i].maintenance_type == 'pm'))
				pm += data[i].__count;
			else if ((data[i].maintenance_type == 'pr'))
				pa += data[i].__count;

		}

		this.setState({ pacount: pa, bmcount: bm, pmcount: pm, occount: oc });
	}

	getmaintainEquipCount(status) {

		var data = this.state.equipdata;

		var result = 0;

		for (var i = 0; i < data.length; i++) {
			if ((((data[i].state == status))))
				result += data[i].__count;
		}
		return result;
	}

	getmaintainSpaceCount() {

		var data = this.state.spacedata;

		var count = []; var space = [];

		for (var i = 0; i < data.length; i++) {

			if (data[i].mroord_count != 0) {

				count.push(data[i].mroord_count);
				space.push(data[i].space_name);
			}

		}

		this.setState({ maintain_spaces: space, maintain_spaces_data: count });
	}




	async getmaintainorderdata() {
		this.setState({ orderLoading: true });
		this.setState({ ordersdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.order';
		var groupby = '["maintenance_type", "state"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ orderLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						ordersdata: res
					});
					this.getmaintainOrdersCount();
				}
			}
		});
	}

	async getmaintainequipdata() {
		this.setState({ equipLoading: true });
		this.setState({ equipdata: [], maintain_teams: [], maintain_teams_data: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.equipment';
		var groupby = '["state"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ equipLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						equipdata: res
					});
					//this.getmaintainTeamCount();
				}
			}
		});
	}

	async getmaintainspacedata() {
		this.setState({ spaceLoading: true });
		this.setState({ spacedata: [], maintain_spaces: [], maintain_spaces_data: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.equipment.location';
		var groupby = '["space_name"]';
		var domain = '["asset_category_id","ilike","Building"]';
		//var domain = '';
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getdashboardspaces', data).then((result) => {

			let res = result['data'];

			this.setState({ spaceLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						spacedata: res
					});
					this.getmaintainSpaceCount();
				}
			}
		});
	}

	async getmaintainorderlatedata() {
		this.setState({ orderLateLoading: true });
		this.setState({ ordersLatedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.order';
		var groupby = '["maintenance_type", "state"]';
		var cdate = this.state.cdate;
		var domain = '["date_scheduled","<=","' + cdate + '"],"&","&",["state","!=","draft"],["state","!=","done"],["state","!=","cancel"]';
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getdashbaordlatecharts', data).then((result) => {

			let res = result['data'];

			this.setState({ orderLateLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						ordersLatedata: res
					});
				}
			}
		});
	}




	getRefreshToken() {

		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.getmaintainorderdata();
				this.getmaintainorderlatedata();
				this.getmaintainequipdata();
				this.getmaintainspacedata();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}

	newpercalculate(total, given) {
		var total = parseInt(total);
		var given = parseInt(given);
		var perc = "";
		if (isNaN(total) || isNaN(given)) {
			perc = 0;
		} else {
			perc = ((given / total) * 100);
		}

		perc = Math.floor(perc);

		return perc;
	}

	upperCase(str) {
		if (str == '' || str == null || str == ' ')
			return '';
		else
			return str.toString().toUpperCase();
	}



	render() {

		const { selectedOption } = this.state;

		var pacount = this.state.pacount;
		var bmcount = this.state.bmcount;
		var pmcount = this.state.pmcount;
		var occount = this.state.occount;

		var totpiecount = parseInt(pacount) + parseInt(bmcount) + parseInt(pmcount) + parseInt(occount);

		var paperc = this.newpercalculate(totpiecount, pacount);
		var bmperc = this.newpercalculate(totpiecount, bmcount);
		var pmperc = this.newpercalculate(totpiecount, pmcount);
		var ocperc = this.newpercalculate(totpiecount, occount);


		const piedata = {
			labels: [
				'Reactive' + ' (' + bmcount + ')',
				'Preventive' + ' (' + pmcount + ')',
				'On-Condition' + ' (' + occount + ')',
				'Predictive' + ' (' + pacount + ')'
			],
			datasets: [{
				data: [bmcount, pmcount, occount, pacount],
				backgroundColor: [
					'#80d4ff',
					'#ef869e',
					'#ff8566',
					'#8dace7'
				],
				hoverBackgroundColor: [
					'#80d4ff',
					'#ef869e',
					'#ff8566',
					'#8dace7'
				],
				hoverLabels: [
					'Reactive',
					'Preventive',
					'On-Condition',
					'Predictive'
				]
			}]
		};

		const pieoptions = {

			responsive: true,
			legend: {
				position: 'top',
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						var dataset = data.datasets[tooltipItem.datasetIndex];

						var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
							return previousValue + currentValue;
						});
						var currentValue = dataset.data[tooltipItem.index];
						var currentValueName = dataset.hoverLabels[tooltipItem.index];
						var percentage = Math.floor(((currentValue / total) * 100) + 0.5);
						return currentValueName + ' ' + percentage + "%";
					}
				}
			},
			toolbar: {
				show: true,
				tools: {
					download: true,
					selection: true,
					zoom: true,
					zoomin: true,
					zoomout: true,
					pan: true,
					customIcons: []
				},
				autoSelected: 'zoom'
			},

		};


		var pabarcount = this.getmaintainOrderStateLateCount('pr');
		var bmbarcount = this.getmaintainOrderStateLateCount('bm');
		var pmbarcount = this.getmaintainOrderStateLateCount('pm');
		var ocbarcount = this.getmaintainOrderStateLateCount('oc');

		const barchartoptions2 = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: ['Reactive', 'Preventive', 'On-Condition', 'Predictive']
			},

		};


		const barchartoptions3 = {
			chart: {
				id: 'apexchart-example-3',

			},
			dataLabels: {
				enabled: false,

			},
			plotOptions: {
				bar: {
					vertical: true
				},

			},

			xaxis: {
				categories: ['Maintenance', 'Operative', 'Warehouse', 'Breakdown', 'Scrapped']
			},

		};

		const barchartoptions4 = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: this.state.maintain_spaces
			},

		};



		const barchartseries2 = [{
			name: 'Overdue',
			data: [bmbarcount, pmbarcount, ocbarcount, pabarcount]
		}];


		var whcount = this.getmaintainEquipCount('wh');
		var brcount = this.getmaintainEquipCount('br');
		var mncount = this.getmaintainEquipCount('mn');
		var opcount = this.getmaintainEquipCount('op');
		var sccount = this.getmaintainEquipCount('sc');

		const barchartseries3 = [{
			name: 'Equipments',
			data: [mncount, opcount, whcount, brcount, sccount],
			colors: ['#80d4ff', '#ef869e', '#80d4ff', '#80d4ff', '#80d4ff'],
		}];

		const barchartseries4 = [{
			name: 'Workorders',
			data: this.state.maintain_spaces_data
		}];



		const piechartseries = [bmcount, pmcount, occount, pacount];

		const dougnoutoptins = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},

			labels: ['Reactive', 'Preventive', 'On-Condition', 'Predictive'],
			colors: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			],
			hover: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			]

		};

		var opencount = this.getmaintainOrderStateCount('open');
		var progresscount = this.getmaintainOrderStateCount('progress');
		var closedcount = this.getmaintainOrderStateCount('done');
		var lateordcount = this.getmaintainOrderStateLateCountByState();

		var totordcount = parseInt(opencount) + parseInt(progresscount) + parseInt(closedcount)

		var openperc = this.newpercalculate(totordcount, opencount);
		var progressperc = this.newpercalculate(totordcount, progresscount);
		var closedperc = this.newpercalculate(totordcount, closedcount);
		var lateperc = this.newpercalculate(totordcount, lateordcount);

		const piechartseries1 = [opencount, progresscount, closedcount];

		const dougnoutoptins1 = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},
			labels: ['Open', 'Inprogress', 'Completed'],
			colors: [
				'#ff6666',
				'#80d4ff',
				'#b4e34f'
			],
			hover: [
				'#ff6666',
				'#80d4ff',
				'#b4e34f'
			]

		};



		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Dashboard </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					<div>


						<Row>
							<Col lg="6" sm="12" xs="12" className="custom_col_left" >

								<Card className="widget-chart mb-2" style={{ minHeight: '75%', maxHeight: '96.6%' }}>
									<div className="widget-chat-wrapper-outer">
										<div className="widget-chart-content">

											<div className="text-left">
												<span className="card-title text-uppercase"> {locale.t('el.dashboard.workStatus')}  </span>
												{this.getmaintainOrderStateLateCountByState() != 0 ? <span style={{ textTransform: 'capitalize' }} className="pull-right text-warning">Late <b>{this.getmaintainOrderStateLateCountByState()}</b></span> : ''}
											</div>
											{this.state.orderLoading ?
												<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
												:

												<div className="text-left">
													{opencount == 0 && progresscount == 0 && closedcount == 0 ?

														<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

														:
														<div className="pie" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
															<Chart options={dougnoutoptins1} series={piechartseries1} type="pie" id="apexchart-example-3" width="100%" />

														</div>
													}
												</div>
											}
										</div>
									</div>
								</Card>
							</Col>

							<Col lg="6" sm="12" xs="12" className="custom_col_right" >

								<Card className="widget-chart mb-2" style={{ minHeight: '70%', maxHeight: '96.6%' }} >
									<div className="widget-chat-wrapper-outer">
										<div className="widget-chart-content">
											<div className="card-title text-uppercase text-left">
												{locale.t('el.dashboard.workType')}
											</div>
											{this.state.orderLoading ?
												<div style={loaddiv} style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
												:

												<div className="text-left">
													{pmcount == 0 && bmcount == 0 && occount == 0 && pacount == 0 ?

														<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

														:
														<div className="pie" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
															<Chart options={dougnoutoptins} series={piechartseries} type="pie" id="apexchart-example-3" width="100%" />

														</div>
													}
												</div>
											}
										</div>
									</div>
								</Card>
							</Col>

							<Col lg="6" sm="12" xs="12" className="custom_col_left" >

								<Card className="widget-chart mb-2" style={{ minHeight: '98.6%', maxHeight: '98.6%', marginTop: '-7px' }}>
									<div className="widget-chat-wrapper-outer">
										<div className="widget-chart-content">
											<div className="card-title text-uppercase text-left">
												{locale.t('el.dashboard.workOverdues')}
											</div>
											{this.state.orderLateLoading ?
												<div style={loaddiv}><center><Loader type="ball-clip-rotate" style={loaddiv} /></center></div>
												:

												<div>
													{pmbarcount == 0 && bmbarcount == 0 && ocbarcount == 0 && pabarcount == 0 ?

														<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

														:
														<div className="bar" >
															<Chart options={barchartoptions2} series={barchartseries2} type="bar" width="100%" />
														</div>
													}
												</div>
											}
										</div>
									</div>
								</Card>
							</Col>

							<Col lg="6" sm="12" xs="12" className="custom_col_right">

								<Card className="widget-chart mb-2" style={{ minHeight: '98.6%', maxHeight: '98.6%', marginTop: '-7px' }}>
									<div className="widget-chat-wrapper-outer">
										<div className="widget-chart-content">
											<div className="card-title text-uppercase text-left">
												{locale.t('el.dashboard.workBlock')}
											</div>
											{this.state.spaceLoading ?
												<div style={loaddiv}><center><Loader type="ball-clip-rotate" style={loaddiv} /></center></div>
												:

												<div style={{ width: '100%', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
													{this.state.spacedata.length > 0 ?

														<div className="bar">
															<Chart options={barchartoptions4} series={barchartseries4} type="bar" width="100%" />
														</div>

														:

														<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

													}
												</div>
											}
										</div>
									</div>
								</Card>
							</Col>


						</Row>


					</div>

				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
