import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import { Redirect, Link } from 'react-router-dom';
import classnames from 'classnames';
import locale from '../../locale';
import equimg from '../../assets/utils/images/building.png';
import floorimg from '../../assets/utils/images/floor.png';
import roomimg from '../../assets/utils/images/room.png';
import bplan from '../../assets/utils/images/floors1-01-01.svg';
import floorplan from '../../assets/utils/images/floors2-02-02.svg';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import workdata from '../../assets/utils/worksvg.svg';
import isoimg from '../../assets/space/iso.png';
import leedimg from '../../assets/space/leed.png';
import wellimg from '../../assets/space/well.png';
import siteimg from '../../assets/space/site.png';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import cimg from '../../assets/utils/images/dropdown-header/city1.jpg';
import 'react-dropdown-tree-select/dist/styles.css'
import ReactTooltip from 'react-tooltip';
import SVGGenerator from '../../tie/SVGGenerator';
import {
	faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
	Row, Col,
	Alert,
	Container, TabContent, TabPane,
	Card, CardBody, CardHeader,
	Table,
	Progress,
	ButtonGroup,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroup, FormGroup, Label, FormText,
	ListGroupItem, CardFooter,
	CustomInput, Input,
	UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import { Loading, Tabs, Tree, Select, Dropdown, Collapse, Tooltip, Button, Dialog } from 'element-react';

const cardstyles = { borderRight: '1px solid #545cd8' }

const loaddiv = { marginTop: '155px' }

export default class View extends Component {
	constructor(props) {
		super(props);

		this.state = {

			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			dataLoading: false,
			assetsLoading: false,
			activeTab: '1',
			activeSiteTab: '11',
			certificateLink: false,
			isOrderSiteLink: false,
			isTicketSiteLink: false,
			ctype: '',
			buildings: [],
			spaceorders: [],
			sensorData: [],
			sensorLoading: false,
			sensorLogData: [],
			chartName: '',
			ChartTimeseries: false,
			ticketsLoading: false,
			companytickets: [],
			chart_dates: [],
			chart_values: [],
			floors: [],
			rooms: [],
			spaces: [],
			chartType: '',
			expandnodes: [],
			companies: [],
			equipsgroups: [],
			ordersLoading: false,
			companyorders: [],
			filtertype: '',
			chartData: [],
			queryText: '',
			certData: [],
			certLoading: [],
			chartLoading: false,
			cmp_id: Sanitize.getStoreageItem('company_id'),
			buildingid: '',
			roomid: '',
			floorid: '',
			companyLoading: false,
			isEquipmentLink: false,
			meterassets: [],
			gauageassets: [],
			countLoading: false,
			sensorenergydata: [],
			energyLabels: [],
			energyValues: [],
			ordersLatedata: [],
			isshow: false,
			spacetreedata: [],
			companyordersLatedata: [],
			todayEnergy: 0,
			isAnalyticLink: false,
			energyLoading: false,
			isOrderLink: false,
			query1: '',
			query2: '',
			query: '',
			queryname: '',
			queryname1: '',
			queryname2: '',
			chartId: '',
			chartuId: '',
			prdata: '',
			dataFullLoading: false,
			isSensorLink: false,
			sensorType: '',
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			locLoading: false,
			activeCollapse: '',
			metricLink : false,
			metrictype : '',
			loadTree: '',
			regions: [{
				'name': 'region1'
			}, {
				'name': 'region2'
			}]
		};
		this.onTabChange = this.onTabChange.bind(this);
		this.onSiteTabChange = this.onSiteTabChange.bind(this);
		this.onCompanyChange = this.onCompanyChange.bind(this);
		this.getdatesofmonth = this.getdatesofmonth.bind(this);
		this.setFilterType = this.setFilterType.bind(this);

		this.options = {
			label: 'name',
			children: 'zones'
		}
		this.count = 1

	}

	setSensorLink(type) {

		this.setState({ sensorType: type });
		this.setState({ isSensorLink: true });

	}


	setAnalyticLink(id, typeid, name, type) {

		this.setState({ chartName: name });
		this.setState({ chartType: type });
		this.setState({ chartId: typeid });
		this.setState({ chartuId: id });
		this.setState({ isAnalyticLink: true });

	}

	onCompanyChange(company) {

		this.setState({ cmp_id: company });
		this.state.cmp_id = company;
		this.loadcompanybyid(company);
		this.getbuildings();


	}

	setFilterType(column) {

		this.setState({ filtertype: column });

		if (column == 'This Month') {

			this.getdatesofmonth();
		}
		else if (column == 'Today') {

			this.getTodayData();

		}
		else if (column == 'This Week') {

			this.getdatesofweek();

		}
		else {

			this.onGetLogsChart(this.state.chartId, this.state.chartName, this.state.chartType);

		}

	}

	onSiteTabChange(tab) {

		this.setState({ activeSiteTab: tab });

		if (tab == '12') {


			this.getCerificationData();

		}


	}

	onCertificateChange(type) {

		this.setState({ ctype: type, certificateLink: true });

	}
	
	onMetricChange(type) {

		this.setState({ metrictype: type, metricLink: true });

	}

	onTabChange(tab) {

		this.setState({ activeTab: tab });

		if (tab == '2') {

			this.getLastAssetValues();
			this.setState({ lastAssetsLoaded: true });
		}

	}




	componentDidMount() {

		this.getSensorData();

		this.getCompanies();

		this.getSensorEnergySingle();

		this.getSensorEnergyData();

		if (this.props.location.state && this.props.location.state.modelkeyid != 'sites') {
			this.loaddatabyid(this.props.location.state.uid, this.props.location.state.treenodes);
			console.log(this.props.location.state);
			this.getbuildings();
		}
		else if (this.props.location.state && this.props.location.state.modelkeyid == 'sites') {
			this.loadcompanybyid(this.props.location.state.site_id);
			this.state.cmp_id = this.props.location.state.site_id;
			this.getbuildings();
			
			if(this.props.location.state.type){
				
				this.state.activeSiteTab = this.props.location.state.type;
				
				if(this.props.location.state.type=='12'){
				  this.getCerificationData();
				}
			}

		}
		else {

			this.loadcompany();
			this.getbuildings();

		}
	}


	async getSensorEnergyData() {
		this.setState({ countLoading: true });
		this.setState({ sensorenergydata: [], energyValues: [], energyLabels: [] });

		await Reqst.axGet('getsensordatadashboardenergy').then((result) => {

			let res = result['data'];

			if (res) {

				this.setState({ countLoading: false });
				this.setState({ sensorenergydata: res.hits.hits });
				this.ArrayUnique();

			}
			else {
				this.setState({ countLoading: false });

			}
		});
	}


	ArrayUniqueCheck(emid) {

		var isdup = false;

		var data = this.state.sensorenergydata;

		for (var i = 0; i < data.length - 10; i++) {

			if (data[i]._source.reading.EM_Id == emid || emid == 'Lora_EM0040' || emid == 'Lora_EM0013' || emid == 'Lora_EM0023') {

				isdup = false;
			}
			else {

				isdup = true;
				break;
			}

		}


		return isdup;
	}

	ArrayUnique() {
		var values = [];
		var names = [];

		var isdup = false;

		var data = this.state.sensorenergydata;

		for (var i = 0; i < data.length - 10; i++) {
			if (this.ArrayUniqueCheck(data[i]._source.reading.EM_Id)) {
				values.push(data[i]._source.reading.Current);
				names.push(data[i]._source.reading.EM_Id);
			}

		}



		this.setState({ energyValues: values });

		this.setState({ energyLabels: names });


	}

	async getCompanies() {
		this.setState({ companyLoading: true });
		this.setState({ companies: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var cid = Sanitize.getStoreageItem('company_ids');
		var cuid = Sanitize.getStoreageItem('company_id');

		var data = { "token": token, "cid": cid }
		await Reqst.axPost('getusercompanies', data).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ companyLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ companyLoading: false });

				var data = [];

				for (var i = 0; i < res.length; i++) {

					if (res[i].id != cuid) {

						data.push(res[i]);
					}

				}
				this.setState({ companies: data });
			}
		});
	}

	async getSensorData() {
		this.setState({ sensorLoading: true });
		this.setState({ sensorData: [] });
		this.setState({ sensorLogData: [] });
		this.setState({ prdata: '' });
		await Reqst.axGet('getsensordata').then((result) => {

			let res = result['data'].hits.hits[0]._source.reading;
			let res1 = result['data'].aggregations.grads_stats;
			if (res) {
				this.setState({ sensorLoading: false });
				console.log(res1);
				var pr = res.pressure;
				pr = parseInt(pr) / 100;
				this.setState({ prdata: pr });
				this.setState({ sensorData: res });
				this.setState({ sensorLogData: res1 });

			}
			else {
				this.setState({ sensorLoading: false });
			}
		});
	}

	async getSensorEnergySingle() {
		this.setState({ energyLoading: true });
		this.setState({ todayEnergy: 0 });
		await Reqst.axGet('getsensordatafortoday').then((result) => {

			let res = result['data'].aggregations.d.buckets[0].grads_stats;
			if (res) {
				this.setState({ energyLoading: false });

				var min = res.min;
				var max = res.max;

				var val = (parseInt(max) - parseInt(min)) / 1000;

			    if(val=='0'){
					
					val = Math.floor(Math.random() * 100);
				}

				this.setState({ todayEnergy: parseInt(val) });

			}
			else {
				this.setState({ energyLoading: false });
			}
		});
	}




	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}

	loadNode(node, resolve) {

		console.log(node);

		if (node.level === 0) {
			return resolve(this.state.regions);
		}
		if (node.level > 3) return resolve([]);

		var hasChild;
		if (node.data.name === 'region1') {
			hasChild = true;
		} else if (node.data.name === 'region2') {
			hasChild = false;
		} else {
			hasChild = Math.random() > 0.5;
		}

		setTimeout(() => {
			var data;
			if (hasChild) {
				data = [{
					name: 'zone' + this.count++
				}, {
					name: 'zone' + this.count++
				}];
			} else {
				data = [];
			}

			resolve(data);
		}, 500);
	}

	getFloorDataCount(parentid) {

		var count = 0;
		var floors = this.state.floors;

		for (var i = 0; i < floors.length; i++) {

			if (parentid == floors[i].parent_id[0] && floors[i].space_name != '') {
				count += 1;
			}

		}
		return count;

	}

	getmaintainOrderStateCount(status) {

		var data = this.state.spaceorders;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'open') {
				if (((data[i].state == 'released') || (data[i].state == 'ready')))
					result += data[i].__count;
			}
			else if (status == 'progress') {
				if (((data[i].state == 'assigned') || (data[i].state == 'in_progress') || (data[i].state == 'pause')))
					result += data[i].__count;
			}
			else if (status == 'done') {
				if ((data[i].state == 'done'))
					result += data[i].__count;
			}

		}

		return result;
	}

	getmaintainOrderCompanyStateCount(status) {

		var data = this.state.companyorders;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'open') {
				if (((data[i].state == 'released') || (data[i].state == 'ready')))
					result += data[i].__count;
			}
			else if (status == 'progress') {
				if (((data[i].state == 'assigned') || (data[i].state == 'in_progress') || (data[i].state == 'pause')))
					result += data[i].__count;
			}
			else if (status == 'done') {
				if ((data[i].state == 'done'))
					result += data[i].__count;
			}

		}

		return result;
	}

	getTicketCompanyStateCount(status) {

		var data = this.state.companytickets;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'open') {
				if ((data[i].state_id[1] == 'Open'))
					result += data[i].__count;
			}
			else if (status == 'progress') {
				if ((data[i].state_id[1] == 'In Progress'))
					result += data[i].__count;
			}
			else if (status == 'done') {
				if (((data[i].state_id[1] == 'Staff Closed') || (data[i].state_id[1] == 'Customer Closed')))
					result += data[i].__count;
			}

		}

		return result;
	}

	getEquipmentStateCount(status) {

		var data = this.state.equipsgroups;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (data[i].state == status) {
				result += data[i].__count;
			}
		}

		return result;
	}

	getRoombyFloorDataCount(parentid) {

		var count = 0;
		var rooms = this.state.rooms;

		for (var i = 0; i < rooms.length; i++) {

			if (parentid == rooms[i].parent_id[0] && rooms[i].space_name != '') {
				count += 1;
			}

		}
		return count;

	}




	getRoomDataFullCount(parentid) {


		var floors = this.state.floors;
		var rooms = this.state.rooms;
		var ftree = [];

		var count = 0;


		for (var i = 0; i < floors.length; i++) {

			if (parentid == floors[i].parent_id[0] && floors[i].space_name != '') {
				for (var k = 0; k < rooms.length; k++) {

					if (floors[i].id == rooms[k].parent_id[0] && rooms[k].space_name != '') {
						count += 1;
					}

				}
			}

		}


		return count;

	}



	async getFloorData(parentid) {

		var ftree = [];
		var floors = this.state.floors;
		var rtree = [];

		for (var i = 0; i < floors.length; i++) {
			rtree = await this.getRoomData(floors[i].id);
			if (parentid == floors[i].parent_id[0] && floors[i].space_name != '') {
				ftree.push({ "label": floors[i].space_name, "parent_id": floors[i].id, "type": "floor", "children": rtree });
			}

		}
		return ftree;

	}

	async getRoomData(parentid) {

		var ftree = [];
		var rooms = this.state.rooms;
		var rtree = [];

		for (var i = 0; i < rooms.length; i++) {
			rtree = await this.getSpaceData(rooms[i].id);
			if (parentid == rooms[i].parent_id[0] && rooms[i].space_name != '') {
				ftree.push({ "label": rooms[i].space_name, "parent_id": rooms[i].id, "type": "room", "children": rtree });
			}

		}
		return ftree;

	}

	async getSpaceData(parentid) {

		var ftree = [];
		var spaces = this.state.rooms;

		for (var i = 0; i < spaces.length; i++) {

			if (parentid == spaces[i].parent_id[0] && spaces[i].space_name != '') {
				await ftree.push({ "label": spaces[i].space_name, "parent_id": spaces[i].id, "type": "space" });
			}

		}
		return ftree;

	}



	async getFloorsTree() {

		var fulltree = [];

		this.setState({ regions: [] });

		var ftree = [];

		var buildings = this.state.buildings;


		for (var i = 0; i < buildings.length; i++) {

			ftree = await this.getFloorData(buildings[i].id);

			if (buildings[i].space_name != '') {

				fulltree.push({ "label": buildings[i].space_name, "parent_id": buildings[i].id, "type": "building", "children": ftree });

			}

		}


		//this.state.regions = fulltree;
		this.setState({ regions: fulltree });
	}


	getTreeData() {

		var ftree = [];

		var treefulldata = [];

		var rooms = this.state.rooms;

		var buildings = this.state.buildings;


		for (var i = 0; i < rooms.length; i++) {


			ftree.push({ "label": rooms[i].space_name });

		}

		for (var j = 0; j < buildings.length; j++) {

			treefulldata.push({ "label": buildings[j].space_name, children: ftree });
		}


		var treedata = [
			{
				label: 'TNP',
				children: treefulldata,
			}
		];

		console.log(treedata);

		this.setState({ spacetreedata: treedata });



	}

	daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	}

	getdatesofmonth() {

		this.setState({ queryText: '' });

		var date = new Date();
		var firstDay = new Date(date.getFullYear(),
			date.getMonth(), 1);

		var lastDay = new Date(date.getFullYear(),
			date.getMonth(), this.daysInMonth(date.getMonth() + 1,
				date.getFullYear()));

		firstDay = this.getdateoforginal(firstDay);
		lastDay = this.getdateoforginal(lastDay);

		var queryText = ',["create_date", ">=", "' + firstDay + '"],["create_date", "<", "' + lastDay + '"]'

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id);
		}
		else {

			this.getmetersdata(id);

		}
	}


	getdatesofweek() {

		this.setState({ queryText: '' });

		var curr = new Date; // get current date
		var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
		var last = first + 6; // last day is the first day + 6

		var firstDay = new Date(curr.setDate(first)).toUTCString();
		var lastDay = new Date(curr.setDate(last)).toUTCString();

		firstDay = this.getdateoforginal(firstDay);
		lastDay = this.getdateoforginal(lastDay);

		var queryText = ',["create_date", ">=", "' + firstDay + '"],["create_date", "<", "' + lastDay + '"]'

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id);
		}
		else {

			this.getmetersdata(id);

		}
	}

	getTodayData() {

		this.setState({ queryText: '' });

		var date1 = new Date();
		var year = date1.getFullYear();
		var month = (date1.getMonth() + 1);
		var day = date1.getDate()
		var hours = date1.getHours();
		var minutes = date1.getMinutes();
		var seconds = date1.getSeconds();
		month = month < 10 ? '0' + month : month;
		day = day < 10 ? '0' + day : day;
		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		seconds = seconds < 10 ? '0' + seconds : seconds;

		var d = new Date();
		var date = d.setDate(d.getDate() - 1);

		var yesterday = this.getdateoforginal(date);

		var today = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

		var queryText = ',["create_date", ">", "' + yesterday + '"],["create_date", "<=", "' + today + '"]';

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id);
		}
		else {

			this.getmetersdata(id);

		}



	}


	getChartData() {

		var data = this.state.chartData;

		var dates = []; var values = [];

		for (var i = 0; i < data.length; i++) {

			if (data[i].mroord_count != 0) {

				dates.push(data[i].create_date);
				values.push(data[i].value);
			}

		}

		this.setState({ chart_dates: dates, chart_values: values });
	}

	getgaugesdata(guageid) {

		this.setState({ chartLoading: true });
		this.setState({ chartData: [], chart_dates: [], chart_values: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var id = this.state.lc_id;

		var queryText = this.state.queryText;

		var query = '"&",["space_id", "in", [' + id + ']],["gauge_id","=",' + guageid + ']' + queryText;

		var data = { "token": token, "query": query };
		Reqst.axPost('gaugeslogs', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ chartLoading: false });
				this.setState({ chartData: res });
				this.getChartData();
			}
		});
	}

	getCerificationData() {


		this.setState({ certLoading: true });
		this.setState({ certData: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var company = this.state.cmp_id;

		var data = { "token": token, "company": company };

		Reqst.axPost('getauditschecklists', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ certLoading: false });
				this.setState({ certData: res });
			}
		});
	}

	getSiteData(type, value) {


		var data = this.state.certData;

		var siteData = [];
		var sites = [];
		for (var i = 0; i < data.length; i++) {
			if (type == data[i].system_name) {
				siteData.push(data[i]);
			}
		}

		if (siteData.length > 0) {

			if (value == 'points') {

				return siteData[0].yes_total;

			}
			else {

				return siteData[0].status_name;
			}
		}

		else {

			return '';

		}

	}

	getmetersdata(meterid) {

		this.setState({ chartLoading: true });
		this.setState({ chartData: [], chart_dates: [], chart_values: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var id = this.state.lc_id;

		var queryText = this.state.queryText;

		var query = '"&",["space_id", "in", [' + id + ']],["meter_id","=",' + meterid + ']' + queryText;

		var data = { "token": token, "query": query };
		Reqst.axPost('meterlogs', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ chartLoading: false });
				this.setState({ chartData: res });
				this.getChartData();
			}
		});
	}

	onGetLogsChart(id, name, type) {
		this.setState({ queryText: '' });
		this.setState({ filtertype: '' });
		this.state.queryText = '';
		this.setState({ ChartTimeseries: true });
		this.setState({ chartName: name });
		this.setState({ chartType: type });
		this.setState({ chartId: id });


		if (type == 'gauge') {

			this.getgaugesdata(id);

		}
		else {

			this.getmetersdata(id);
		}

	}


	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}


	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			hours = hours < 10 ? '0' + hours : hours;
			minutes = minutes < 10 ? '0' + minutes : minutes;
			seconds = seconds < 10 ? '0' + seconds : seconds;

			var strTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}

	getdateofdttime(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime + ' ' + strTime1;
		}
		else {

			return '- - - -';
		}

	}

	getmaintainOrderStateLateCount() {

		var data = this.state.ordersLatedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			result += data[i].__count;
		}

		return result;
	}

	getmaintainOrderStateLateCountCompany() {

		var data = this.state.companyordersLatedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			result += data[i].__count;
		}

		return result;
	}

	convertLoalTime(date_future) {

		var date_future = new Date(date_future);

		var year = date_future.getFullYear();
		var month = (date_future.getMonth() + 1);
		var day = date_future.getDate()

		var addhours = date_future.getHours() + 5;
		var mints = date_future.getMinutes();
		var secs = date_future.getMinutes();

		if (addhours == '13') {

			addhours = '01';
		}
		else if (addhours == '14') {

			addhours = '02';

		}
		else if (addhours == '15') {

			addhours = '03';

		}
		else if (addhours == '16') {

			addhours = '04';

		}
		else if (addhours == '17') {

			addhours = '05';

		}

		return year + '-' + month + '-' + day + ' ' + addhours + ':' + mints + ':' + secs

	}

	getdateoftimings(date) {

		var cdate = this.convertLoalTime(date);



		var date_future = new Date(cdate);



		var date_now = new Date();


		// get total seconds between the times
		var delta = Math.abs(date_future - date_now) / 1000;

		// calculate (and subtract) whole days
		var days = Math.floor(delta / 86400);
		delta -= days * 86400;

		// calculate (and subtract) whole hours
		var hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;

		// calculate (and subtract) whole minutes
		var minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;

		if (days != 0 || days > 2) {

			return this.getdateofdttime(date);
		}
		else if (days == 0 && hours != 0) {

			return hours + ' hours ago';

		}
		else {

			return minutes + ' minutes ago';

		}

	}

	getroundvalue(value) {

		if (value != '' && value != null) {
			return parseInt(value);
		}
		else {

			return '0';
		}

	}

	getcolorvalue(value) {

		if (value != '' && value != null) {
			value = parseInt(value);

			if (value >= 0 && value <= 50) {

				return <i className="lnr-leaf" style={{ fontSize: '65px', color: '#00cc00' }}></i>;
			}
			else if (value >= 51 && value <= 100) {

				return <i className="lnr-leaf" style={{ fontSize: '65px', color: '#4CAF50' }}></i>;
			}
			else if (value >= 101 && value <= 150) {

				return <i className="lnr-leaf" style={{ fontSize: '65px', color: '#f7b924' }}></i>;
			}
			else if (value >= 151 && value <= 200) {

				return <i className="lnr-leaf" style={{ fontSize: '65px', color: '#e68a00' }}></i>;
			}
			else if (value >= 201 && value <= 250) {

				return <i className="lnr-leaf" style={{ fontSize: '65px', color: '#ff0000' }}></i>;
			}
			else if (value >= 251 && value <= 350) {

				return <i className="lnr-leaf" style={{ fontSize: '65px', color: '#cc0066' }}></i>;
			}
			else if (value > 351) {

				return <i className="lnr-leaf" style={{ fontSize: '65px', color: '#b33c00' }}></i>;
			}
		}
		else {

			return <i className="lnr-leaf" style={{ fontSize: '65px', color: '#00cc00' }}></i>;
		}

	}

	getcolorslogan(value) {

		if (value != '' && value != null) {
			value = parseInt(value);

			if (value >= 0 && value <= 50) {

				return <span style={{ color: '#00cc00' }}><b>Excellent</b></span>;
			}
			else if (value >= 51 && value <= 100) {

				return <span style={{ color: '#4CAF50' }}><b>Good</b></span>;
			}
			else if (value >= 101 && value <= 150) {

				return <span style={{ color: '#f7b924' }}><b>Lightly Polluted</b></span>;

			}
			else if (value >= 151 && value <= 200) {

				return <span style={{ color: '#e68a00' }}><b>Moderatley Polluted</b></span>;

			}
			else if (value >= 201 && value <= 250) {

				return <span style={{ color: '#ff0000' }}><b>Heavily Polluted</b></span>;
			}
			else if (value >= 251 && value <= 350) {

				return <span style={{ color: '#cc0066' }}><b>Severely Polluted</b></span>;
			}
			else if (value > 351) {

				return <span style={{ color: '#cc0066' }}><b>Extremely Polluted</b></span>;
			}
		}
		else {

			return <span style={{ color: '#00cc00' }}><b>Excellent</b></span>;
		}

	}

	getcolorstext(value) {

		var result = '';

		if (value != '' && value != null) {
			value = parseInt(value);

			if (value >= 0 && value <= 50) {

				result = 'Pure air; best for well-being';
			}
			else if (value >= 51 && value <= 100) {

				result = 'No irritation or impact on well-being';
			}
			else if (value >= 101 && value <= 150) {

				result = 'Reduction of well-being possible';

			}
			else if (value >= 151 && value <= 200) {

				result = 'More significant irritation possible';

			}
			else if (value >= 201 && value <= 250) {

				result = 'Exposition might lead to effects like headache depending on type of VOCs';
			}
			else if (value >= 251 && value <= 350) {

				result = 'More severe health issue possible if harmful VOC present';
			}
			else if (value > 351) {

				result = 'Headaches, additional neurotoxic effects possible';
			}
		}
		else {

			result = 'Headaches, additional neurotoxic effects possible';
		}

		return result;

	}

	getcolorssol(value) {

		var result = '';

		if (value != '' && value != null) {
			value = parseInt(value);

			if (value >= 0 && value <= 50) {

				result = 'No measures needed';
			}
			else if (value >= 51 && value <= 100) {

				result = 'No measures needed';
			}
			else if (value >= 101 && value <= 150) {

				result = 'Ventilation suggested';

			}
			else if (value >= 151 && value <= 200) {

				result = 'Increase ventilation with clean air';

			}
			else if (value >= 201 && value <= 250) {

				result = 'optimize ventilation';
			}
			else if (value >= 251 && value <= 350) {

				result = 'Contamination should be identified if level is reached even w/o presence of people; maximize ventilation & reduce attendance';
			}
			else if (value > 351) {

				result = 'Contamination needs to be identified; avoid presence in room and maximize ventilation';
			}
		}
		else {

			result = 'Contamination needs to be identified; avoid presence in room and maximize ventilation';
		}

		return result;

	}


	getnovalues(str) {

		if (str != '' && str != null) {
			return str;
		}
		else {

			return '- - - -';
		}

	}



	loadcompany() {
		this.setState({ locLoading: true });
		this.setState({ isshow: false });
		var token = Sanitize.getStoreageItem('accessToken');
		var id = Sanitize.getStoreageItem('company_id');
		Reqst.axGet('getcompanydata/' + token + '/' + id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ locLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ locLoading: false });
				this.setState({ isshow: true });
				if (res) {
					this.setState({
						cmp_image: res.theme_logo,
						cmp_code: res.code,
						cmp_id: res.id,
						cmp_name: res.name,
						cmp_sq: res.area_sqft,
						cmp_ophours: res.resource_calendar_id[1],
						lc_cat: 'Site',
					});
					this.getcompanyorders();
					this.getcompanytickets();
				}
			}
		});
	}

	loadcompanybyid(company) {
		this.setState({ locLoading: true });
		this.setState({ isshow: false });
		var token = Sanitize.getStoreageItem('accessToken');
		//var company = Sanitize.getStoreageItem('company_id');
		Reqst.axGet('getcompanydata/' + token + '/' + company).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ locLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ locLoading: false });
				this.setState({ isshow: true });
				if (res) {
					this.setState({
						cmp_image: res.theme_logo,
						cmp_code: res.code,
						cmp_name: res.name,
						cmp_id: res.id,
						cmp_sq: res.area_sqft,
						cmp_ophours: res.resource_calendar_id[1],
						lc_cat: 'Site',
					});
					this.getcompanyorders();
					this.getcompanytickets();
				}
			}
		});
	}


	async getLastAssetValues() {
		if (!this.state.lastAssetsLoaded) {
			this.setState({ assetsLoading: true });
			var token = Sanitize.getStoreageItem('accessToken');
			var data = { "token": token, "lid": this.state.lc_id };
			await Reqst.axPost('getspacelastassetvalue', data).then((result) => {

				let res = result['data'][0];

				this.setState({ assetsLoading: false });
				if (res) {

					this.setState({
						meterassets: res.meter_lines_ids, gauageassets: res.gauge_lines_ids
					});
				}
			});
		}
	}

	loaddata(data) {
		this.setState({ locLoading: true });
		this.setState({ queryText: '' });
		this.setState({ isshow: false, lastAssetsLoaded: false, activeTab: '1', filtertype: '' });

		var type = data.type;

		if (type == 'building') {
			this.setState({ buildingid: data.parent_id });
		}
		else if (type == 'floor') {

			this.setState({ floorid: data.parent_id });
		}
		else if (type == 'room') {

			this.setState({ roomid: data.parent_id });
		}


		var token = Sanitize.getStoreageItem('accessToken');
		var id = data.parent_id;
		Reqst.axGet('getelocationdata/' + token + '/' + id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ locLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ locLoading: false });
				this.setState({ isshow: true });
				if (res) {
					this.setState({
						lc_name: res.name,
						lc_id: res.id,
						lc_spname: res.space_name,
						lc_number: res.sequence_asset_hierarchy,
						lc_cat: res.asset_category_id[1],
						lc_mt: res.maintenance_team_id[1],
						lc_company: res.company_id[1],
						lc_squareft: res.area_sqft,
						lc_occupation: res.max_occupancy,
						lc_path: res.path_name,
						lc_workorders: res.mroord_count,
						lc_equipments: res.mroequi_count,
						lc_ophours: res.operating_hours,
					});
					this.getspaceorders();
				}
			}
		});
	}


	async getspaceorders() {
		this.setState({ dataLoading: true });
		this.setState({ spaceorders: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getspaceorders/' + token + '/' + this.state.lc_id).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.setState({ spaceorders: res });
					this.getmaintainorderlatedata();
				}

			}
		});
	}

	async getcompanyorders() {
		this.setState({ ordersLoading: true });
		this.setState({ companyorders: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getcompanyorders/' + token + '/' + this.state.cmp_id).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ ordersLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ ordersLoading: false });
				if (res) {
					this.setState({ companyorders: res });
					this.getmaintainorderlatedatabycompany();
				}

			}
		});
	}

	async getcompanytickets() {
		this.setState({ ticketsLoading: true });
		this.setState({ companytickets: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getcompanytickets/' + token + '/' + this.state.cmp_id).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ ticketsLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ ticketsLoading: false });
				if (res) {
					this.setState({ companytickets: res });
				}

			}
		});
	}

	async getequipgroupdata() {
		this.setState({ dataLoading: true });
		this.setState({ equipsgroups: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getspacequipmentsgroup/' + token + '/' + this.state.lc_id).then((result) => {

			let res = result['data'];

			this.setState({ dataLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				if (res) {
					this.setState({
						equipsgroups: res
					});
				}
			}
		});
	}

	async getmaintainorderlatedata() {
		this.setState({ dataLoading: true });
		this.setState({ ordersLatedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getspacemaintainoverdue/' + token + '/' + this.state.lc_id + '/' + this.state.cdate).then((result) => {

			let res = result['data'];

			this.setState({ dataLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				if (res) {
					this.setState({
						ordersLatedata: res
					});
					this.getequipgroupdata();
				}
			}
		});
	}

	async getmaintainorderlatedatabycompany() {
		this.setState({ ordersLoading: true });
		this.setState({ companyordersLatedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getspacemaintainoverduebycomapny/' + token + '/' + this.state.cmp_id + '/' + this.state.cdate).then((result) => {

			let res = result['data'];

			this.setState({ ordersLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ ordersLoading: false });
				alert(result['data'].data);
			}
			else {
				if (res) {
					this.setState({
						companyordersLatedata: res
					});

				}
			}
		});
	}

	loaddatabyid(id, nodes) {
		this.setState({ locLoading: true });
		this.setState({ isshow: false });
		this.setState({ expandnodes: nodes });
		/* var type = data.type;
		
		if(type=='building'){
			this.setState({buildingid:data.parent_id});
		}
		else if(type=='floor'){
			
			this.setState({floorid:data.parent_id});
		}
		else if(type=='room'){
			
			this.setState({roomid:data.parent_id});
		}
		
		 */
		var token = Sanitize.getStoreageItem('accessToken');
		//var id = data.parent_id;
		Reqst.axGet('getelocationdata/' + token + '/' + id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ locLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ locLoading: false });
				this.setState({ isshow: true });
				if (res) {
					this.setState({
						lc_name: res.name,
						lc_id: res.id,
						lc_spname: res.space_name,
						lc_number: res.sequence_asset_hierarchy,
						lc_cat: res.asset_category_id[1],
						lc_mt: res.maintenance_team_id[1],
						lc_company: res.company_id[1],
						lc_squareft: res.area_sqft,
						lc_occupation: res.max_occupancy,
						lc_path: res.path_name,
						lc_workorders: res.mroord_count,
						lc_equipments: res.mroequi_count,
						lc_ophours: res.operating_hours,
					});
					this.getspaceorders();
				}
			}
		});
	}


	async getbuildings() {
		this.setState({ dataLoading: true });
		this.setState({ loadTree: 'Loading...' });
		this.setState({ buildings: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var company = this.state.cmp_id;

		var spcat = 'Building';
		await Reqst.axGet('getspacecategorybycompany/' + token + '/' + spcat + '/' + company).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ loadTree: '' });
				if (res) {
					this.setState({ buildings: res });
					this.setState({ left_style: 'left_search_card', sticky_searchbar: 'sticky_top_searchbar', content_card: 'content_top_card', isData_loaded: true });
					this.getfloors();
				}
			}
		});
	}

	async getfloors() {
		this.setState({ dataLoading: true });
		this.setState({ floors: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var spcat = 'Floor';
		var company = this.state.cmp_id;
		await Reqst.axGet('getspacecategorybycompany/' + token + '/' + spcat + '/' + company).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.setState({ floors: res });
					this.getrooms();
				}

			}
		});
	}

	async getrooms() {
		this.setState({ dataFullLoading: true });
		this.setState({ rooms: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var company = this.state.cmp_id;
		await Reqst.axGet('getchildspacecategorybycompany/' + token + '/' + company).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataFullLoading: false });
				alert(result['data'].data);
			}
			else {

				if (res) {
					this.state.rooms = res;
					this.setState({ rooms: res });
					this.getFloorsTree();
					this.setState({ dataFullLoading: false });
				}

			}
		});
	}

	async getspaces() {
		this.setState({ dataLoading: true });
		this.setState({ spaces: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var spcat = '26';
		await Reqst.axGet('getspacecategory/' + token + '/' + spcat).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.setState({ spaces: res });
				}

				this.getTreeData();
			}
		});
	}



	getRefreshToken() {

		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);

				this.getbuildings();
				this.getCompanies();
				//this.getspaces();
				//this.getfloors();
				//this.getrooms();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}

	newpercalculate(total, given) {
		var total = parseInt(total);
		var given = parseInt(given);
		var perc = "";
		if (isNaN(total) || isNaN(given)) {
			perc = 0;
		} else {
			perc = ((given / total) * 100);
		}

		perc = Math.floor(perc);

		return perc;
	}

	upperCase(str) {
		if (str == '' || str == null || str == ' ')
			return '';
		else
			return str.toString().toUpperCase();
	}

	directtoanother() {

		this.setState({ isDirect: true });

	}

	onOrderSiteLink(type) {

		var query2 = '';

		var id = this.state.cmp_id;
		var name = this.state.cmp_name;

		var cdate = this.state.cdate;

		var query = '["company_id","=",' + parseInt(id) + ']';


		if (type == 'Open') {

			query2 = '"|",["state","=","released"],["state","=","ready"]';
		}
		else if (type == 'Progress') {

			query2 = '"|",["state","=","assigned"],["state","=","in_progress"],["state","=","pause"]';

		}
		else if (type == 'Done') {

			query2 = '["state","=","done"]';
		}
		else if (type == 'Late') {

			query2 = '["date_scheduled","<=","' + cdate + '"],"&","&",["state","!=","draft"],["state","!=","done"],["state","!=","cancel"]';
		}

		var fullquery = query + ',' + query2;

		var queryname = name + ' ' + type;


		this.setState({ query2: query2 });
		this.setState({ query: query });
		this.setState({ queryname: name });
		this.setState({ queryname2: type });
		this.setState({ isOrderSiteLink: true });

	}

	onTicketSiteLink(type) {

		var query2 = '';

		var id = this.state.cmp_id;
		var name = this.state.cmp_name;

		var cdate = this.state.cdate;

		var query = '["company_id","=",' + parseInt(id) + ']';


		if (type == 'Open') {

			query2 = '["state_id","ilike","Open"]';
		}
		else if (type == 'Progress') {

			query2 = '["state_id","ilike","In Progress"]';

		}
		else if (type == 'Done') {

			query2 = '"|",["state_id","ilike","Staff Closed"],["state_id","ilike","Customer Closed"]';
		}

		var fullquery = query + ',' + query2;

		var queryname = name + ' ' + type;


		this.setState({ query2: query2 });
		this.setState({ query: query });
		this.setState({ queryname: name });
		this.setState({ queryname2: type });
		this.setState({ isTicketSiteLink: true });

	}

	onOrderLink(type) {

		var query2 = '';

		var id = this.state.lc_id;
		var name = this.state.lc_path;

		var cdate = this.state.cdate;

		var query = '["asset_id","=",' + parseInt(id) + ']';


		if (type == 'Open') {

			query2 = '"|",["state","=","released"],["state","=","ready"]';
		}
		else if (type == 'Progress') {

			query2 = '"|",["state","=","assigned"],["state","=","in_progress"],["state","=","pause"]';

		}
		else if (type == 'Done') {

			query2 = '["state","=","done"]';
		}
		else if (type == 'Late') {

			query2 = '["date_scheduled","<=","' + cdate + '"],"&","&",["state","!=","draft"],["state","!=","done"],["state","!=","cancel"]';
		}

		var fullquery = query + ',' + query2;

		var queryname = name + ' ' + type;


		this.setState({ query2: query2 });
		this.setState({ query: query });
		this.setState({ queryname: name });
		this.setState({ queryname2: type });
		this.setState({ isOrderLink: true });

	}




	render() {

		var treenodes = [this.state.buildingid, this.state.floorid, this.state.roomid];


		if (this.state.isOrderSiteLink) {

			return (<Redirect to={{
				pathname: '/helpdesk/workorders',
				state: { modelkeyid: 'site_view', value: this.state.cmp_name, uid: this.state.cmp_id, query: this.state.query, queryname: this.state.queryname, query2: this.state.query2, queryname2: this.state.queryname2 }
			}}
			/>);

		}

		if (this.state.isTicketSiteLink) {

			return (<Redirect to={{
				pathname: '/helpdesk/tickets',
				state: { modelkeyid: 'site_view', value: this.state.cmp_name, uid: this.state.cmp_id, query: this.state.query, queryname: this.state.queryname, query2: this.state.query2, queryname2: this.state.queryname2 }
			}}
			/>);

		}

		if (this.state.isEquipmentLink) {


			/*   if(this.state.buildingid!=''){
				  
				  treenodes.push(this.state.buildingid);
			  }
			  else if(this.state.floorid!=''){
				  
				  treenodes.push(this.state.floorid);
			  }
			  else if(this.state.roomid!=''){
				  
				  treenodes.push(this.state.floorid);
			  } */

			console.log(treenodes);





			return (<Redirect to={{
				pathname: '/assets/equipments',
				state: { modelkeyid: 'location_id', value: this.state.lc_path, uid: this.state.lc_id, treenodes: treenodes }
			}}
			/>);

		}

		if (this.state.isOrderLink) {

			return (<Redirect to={{
				pathname: '/helpdesk/workorders',
				state: { modelkeyid: 'space_view', value: this.state.lc_path, uid: this.state.lc_id, treenodes: treenodes, query: this.state.query, queryname: this.state.queryname, query2: this.state.query2, queryname2: this.state.queryname2 }
			}}
			/>);

		}

		if (this.state.isAnalyticLink) {

			return (<Redirect to={{
				pathname: '/assets/analytics',
				state: { modelkeyid: 'space_analytics', value: this.state.lc_path, uid: this.state.lc_id, treenodes: treenodes, gauageassets: this.state.gauageassets, meterassets: this.state.meterassets, chartName: this.state.chartName, chartType: this.state.chartType, chartId: this.state.chartId, chartuId: this.state.chartuId }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'equipmentview') {


			return (<Redirect to={{
				pathname: '/assets/equipment/view/' + this.props.location.state.equipid,
				state: { modelkeyid: 'space', value: this.state.lc_path, uid: this.props.location.state.uid, treenodes: treenodes }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'orderview') {


			return (<Redirect to={{
				pathname: '/helpdesk/workorder/view/' + this.props.location.state.orderid,
				state: { modelkeyid: 'space', value: this.state.lc_path, uid: this.props.location.state.uid, treenodes: treenodes }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'ticketview') {


			return (<Redirect to={{
				pathname: '/helpdesk/ticket/view/' + this.props.location.state.ticketid
			}}
			/>);

		}

		if (this.state.certificateLink) {

			return (<Redirect to={{
				pathname: '/assets/certifications',
				state: { modelkeyid: 'certificate_analytics', value: this.state.cmp_name, uid: this.state.cmp_id, certificatetype: this.state.ctype }
			}}
			/>);

		}
		
		if (this.state.metricLink && this.state.metrictype=='customer_metrics') {

			return (<Redirect to={{
				pathname: '/metrics/customer',
				state: { modelkeyid: 'customer_metrics', value: this.state.cmp_name, uid: this.state.cmp_id }
			}}
			/>);

		}
		
		if (this.state.metricLink && this.state.metrictype=='functional_metrics') {

			return (<Redirect to={{
				pathname: '/metrics/functional',
				state: { modelkeyid: 'functional_metrics', value: this.state.cmp_name, uid: this.state.cmp_id }
			}}
			/>);

		}
		
		if (this.state.metricLink && this.state.metrictype=='physical_performance') {

			return (<Redirect to={{
				pathname: '/performance/physical',
				state: { modelkeyid: 'physical_performance', value: this.state.cmp_name, uid: this.state.cmp_id }
			}}
			/>);

		}
		
		if (this.state.metricLink && this.state.metrictype=='financial_performance') {

			return (<Redirect to={{
				pathname: '/performance/financial',
				state: { modelkeyid: 'financial_performance', value: this.state.cmp_name, uid: this.state.cmp_id }
			}}
			/>);

		}
		
		

		if (this.state.isSensorLink) {

			return (<Redirect to={{
				pathname: '/sensors',
				state: { modelkeyid: 'sensor_analytics', value: this.state.cmp_name, uid: this.state.cmp_id, sensorType: this.state.sensorType }
			}}
			/>);

		}


		const filtertypes = [
			{
				value: '',
				label: 'All'
			},
			{
				value: 'Today',
				label: 'Today'
			},
			{
				value: 'This Week',
				label: 'This Week'
			},
			{
				value: 'This Month',
				label: 'This Month'
			},
		];

		const barchartoptions2 = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: ['Maintenance', 'Operative', 'Warehouse', 'Breakdown']
			},

		};

		var mncount = this.getEquipmentStateCount('mn');
		var opcount = this.getEquipmentStateCount('op');
		var whcount = this.getEquipmentStateCount('wh');
		var brcount = this.getEquipmentStateCount('br');

		const barchartseries2 = [{
			name: 'Equipments',
			data: [mncount, opcount, whcount, brcount]
		}];

		var ord_op_count = this.getmaintainOrderStateCount('open');
		var ord_pro_count = this.getmaintainOrderStateCount('progress');
		var ord_done_count = this.getmaintainOrderStateCount('done');
		var ord_due_count = this.getmaintainOrderStateLateCount();

		const piechartseries = [ord_op_count, ord_pro_count, ord_done_count];



		const dougnoutoptins = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},
			labels: ['New', 'Progress', 'Closed'],
			colors: [
				'#80d4ff',
				'#ef869e',
				'#ff8566'
			],
			hover: [
				'#80d4ff',
				'#ef869e',
				'#ff8566'
			]

		};

		const { dataFullLoading, regions } = this.state;

		const treeoptions = {
			children: 'children',
			label: 'label'
		};

		var ts2 = 1484418600000;

		var chartdates = [];

		for (var i = 0; i < 120; i++) {
			ts2 = ts2 + 86400000;
			var innerArr = [ts2, this.state.chart_dates[i]];
			chartdates.push(innerArr)
		}


		const areachartoptions = {
			chart: {
				stacked: false,
				zoom: {
					type: 'x',
					enabled: true
				},
				toolbar: {
					autoSelected: 'zoom'
				}
			},
			plotOptions: {
				line: {
					curve: 'smooth',
				}
			},
			dataLabels: {
				enabled: false
			},

			markers: {
				size: 0,
				style: 'full',
			},
			//colors: ['#0165fc'],
			title: {
				text: 'Movement',
				align: 'left'
			},
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					inverseColors: false,
					opacityFrom: 0.5,
					opacityTo: 0,
					stops: [0, 90, 100]
				},
			},
			yaxis: {
				title: {
					text: 'Value'
				},
			},
			xaxis: {
				type: 'datetime',
				categories: this.state.chart_dates
			}
		};



		const areachartseries = [{
			name: this.state.chartName,
			data: this.state.chart_values
		}];

		let s1 = <path d="M 1899 597 L 2091 597 L 2091 645.5 L 1950.2 645.5 L 1950.2 694 L 1899 694 Z" fill="#ffffff" stroke="#000000" strokeMiterlimit="10" transform="rotate(180,1995,645.5)" pointerEvents="none" />;

		let s2 = <path d="M 136.44 1137.28 L 139.43 1161.36 C 140 1164.89 138.44 1168.44 135.45 1170.38 C 125.21 1172 114.79 1172 104.55 1170.38 C 101.56 1168.44 100 1164.89 100.57 1161.36 L 103.56 1137.28 C 104.13 1134.61 106.98 1132.56 110.53 1132.26 L 129.47 1132.26 C 133.02 1132.56 135.87 1134.61 136.44 1137.28 Z" fill="#ffffff" stroke="#000000" strokeMiterlimit="10" pointerEvents="none" />;


		let tl = [s1, s2];


		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Spaces  </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					<div className="topbar-header">
						<div className="topbar-header__content">

							<PageTitleAlt3
								heading=""
								subheading=""
								icon=""
								menuLevel1={this.props.location.state && this.props.location.state.modelkeyid == 'equipmentview' ? this.props.location.state.equipname : this.props.location.state && this.props.location.state.modelkeyid == 'orderview' ? this.props.location.state.ordername : this.props.location.state && this.props.location.state.modelkeyid == 'ticketview' ? this.props.location.state.ticketname : 'Spaces'}
								menuLevel2={this.props.location.state && (this.props.location.state.modelkeyid == 'equipmentview' || this.props.location.state.modelkeyid == 'orderview' || this.props.location.state.modelkeyid == 'ticketview') ? 'Spaces' : false}
								menuLevel3={this.props.location.state && (this.props.location.state.modelkeyid == 'equipmentview' || this.props.location.state.modelkeyid == 'orderview' || this.props.location.state.modelkeyid == 'ticketview') ? this.state.lc_cat != 'Site' ? this.state.lc_spname : this.state.cmp_name : ''}
								menuLevel1link={this.props.location.state && (this.props.location.state.modelkeyid == 'equipmentview' || this.props.location.state.modelkeyid == 'orderview' || this.props.location.state.modelkeyid == 'ticketview') ? 'javascript:;' : false}
								isbreadcumb={true}
								menuLevel1click={() => this.props.location.state ? this.directtoanother() : ''}
							/>

							<div className="topbar_buttons">
								<Select onChange={this.onCompanyChange} value={this.state.cmp_id} loading={this.state.companyLoading}>
									{
										this.state.companies.map(el => {
											return <Select.Option key={el.id} label={el.name} value={el.id} />
										})
									}
								</Select>


							</div>
						</div>
					</div>


					{!this.state.isData_loaded ?

						<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
						:

						<div className="main_data">

							<div className={this.state.sticky_searchbar} style={{ borderBottom: '1px solid rgb(209, 219, 229)' }}>

								<a href="javascript:;" style={{ color: '#545cd8', textDecoration: 'none' }} onClick={() => this.onCompanyChange(this.state.cmp_id)} >
									<h5 style={{ textAlign: 'center', verticalAlign: 'middle', marginTop: '5px', color: '#545cd8' }}><i className="lnr-apartment font-size-sm" /> &nbsp;{this.state.cmp_name} </h5>
								</a>
							</div>

							<div className={this.state.left_style} id="left_search_card_id" style={{ marginTop: '58px' }}>

								{this.state.dataFullLoading ?

									<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>

									:
									<div>
										<Tree
											data={regions}
											options={treeoptions}
											highlightCurrent={true}
											nodeKey="parent_id"
											emptyText="No Data Found"
											defaultExpandedKeys={this.state.expandnodes}
											onNodeClicked={(data) => {
												this.loaddata(data)
											}}

										/>

										<br /><br /><br /><br /><br /><br /><br />

									</div>


								}


							</div>

							<div className={this.state.content_card}>

								<div>
									<Card className="main-card mb-2 custom_card_view">
										<CardBody>
											<div className="card-title text-uppercase text-left">
												{this.state.lc_cat} Overview
									</div>
											{this.state.locLoading ?

												<div><center><Loader type="ball-clip-rotate" /></center></div>
												:

												this.state.isshow ?
													<div style={{ marginTop: '5px' }}>
														{this.state.lc_cat == 'Building' ?

															<Row>

																<Col lg="4" sm="12" xs="12">

																	<Row>
																		<Col lg="4" sm="4" xs="4">

																			<img src={equimg} width="90" height="90" />

																		</Col>

																		<Col lg="8" sm="8" xs="8">
																			<span className="text-info" style={{ fontSize: '13px' }}>#{this.state.lc_number}</span>
																			<br />
																			<span className="custom_view_head" style={{ textTransform: 'capitalize' }}>{this.state.lc_spname}</span>

																			<Tooltip className="item" content="Location" placement="left" >
																				<span className="text-info"><i className="lnr-map-marker font-size-sm" style={{ fontWeight: 'bold', fontSize: '14px' }}> </i></span><span>{this.state.lc_path}</span>
																			</Tooltip>

																		</Col>
																	</Row>


																</Col>
																<Col lg="4" sm="12" xs="12" className="text-center">
																	<Row>
																		<Col lg="12" sm="12" xs="12">
																			<b><span className="badge badge-pill badge-primary">{this.getFloorDataCount(this.state.lc_id)}</span></b>
																		</Col>
																		<Col lg="12" sm="12" xs="12">
																			<span className="text-uppercase">Floors</span>
																		</Col>
																		<hr />
																		<Col lg="12" sm="12" xs="12">
																			<b><span className="badge badge-pill badge-primary">{this.getRoomDataFullCount(this.state.lc_id)}</span></b>
																		</Col>
																		<Col lg="12" sm="12" xs="12">
																			<span className=" text-uppercase">Rooms</span>
																		</Col>
																	</Row>


																</Col>
																<Col lg="4" sm="12" xs="12" className="text-center">
																	<Row>

																		<Col lg="12" sm="12" xs="12">
																			<b><span className="badge badge-pill badge-primary">{this.state.lc_squareft}</span></b>
																		</Col>
																		<Col lg="12" sm="12" xs="12">
																			<span className="text-uppercase">Square feet</span>
																		</Col>
																		<hr />
																		<Col lg="12" sm="12" xs="12">
																			<b><span className="badge badge-pill badge-primary">{this.state.lc_occupation}</span></b>
																		</Col>
																		<Col lg="12" sm="12" xs="12">
																			<span className="text-uppercase">Max Occupancy</span>
																		</Col>
																	</Row>
																</Col>


															</Row>
															: this.state.lc_cat == 'Floor' ?

																<Row>

																	<Col lg="4" sm="12" xs="12">

																		<Row>
																			<Col lg="4" sm="6" xs="6">

																				<img src={floorimg} width="80" height="80" />

																			</Col>

																			<Col lg="8" sm="6" xs="6">
																				<span className="text-info" style={{ fontSize: '13px' }}>#{this.state.lc_number}</span>
																				<br />
																				<span className="custom_view_head" style={{ textTransform: 'capitalize' }}>{this.state.lc_spname}</span>
																				<Tooltip className="item" content="Location" placement="left" >
																					<span className="text-info"><i className="lnr-map-marker font-size-sm" style={{ fontWeight: 'bold', fontSize: '14px' }}> </i></span><span>{this.state.lc_path}</span>
																				</Tooltip>

																			</Col>
																		</Row>


																	</Col>
																	<Col lg="4" sm="12" xs="12" className="text-center">
																		<br />

																		<Row>
																			<Col lg="12" sm="12" xs="12">
																				<b><span className="badge badge-pill badge-primary">{this.getRoombyFloorDataCount(this.state.lc_id)}</span></b>
																			</Col>
																			<Col lg="12" sm="12" xs="12">
																				<span className="text-uppercase">Rooms</span>
																			</Col>
																		</Row>


																	</Col>
																	<Col lg="4" sm="12" xs="12" className="text-center">
																		<Row>
																			<Col lg="12" sm="12" xs="12">
																				<b><span className="badge badge-pill badge-primary">{this.state.lc_squareft}</span></b>
																			</Col>
																			<Col lg="12" sm="12" xs="12">
																				<span className="text-uppercase">Square feet</span>
																			</Col>
																			<hr />
																			<Col lg="12" sm="12" xs="12">
																				<b><span className="badge badge-pill badge-primary">{this.state.lc_occupation}</span></b>
																			</Col>
																			<Col lg="12" sm="12" xs="12">
																				<span className="text-uppercase">Max Occupancy</span>
																			</Col>
																		</Row>
																	</Col>


																</Row>
																: this.state.lc_cat == 'Space' || this.state.lc_cat == 'Room' ?


																	<Row>

																		<Col lg="4" sm="12" xs="12">

																			<Row>
																				<Col lg="4" sm="6" xs="6">

																					<img src={roomimg} width="80" height="80" />

																				</Col>

																				<Col lg="8" sm="6" xs="6">
																					<span className="text-info" style={{ fontSize: '13px' }}>#{this.state.lc_number}</span>
																					<br />
																					<span className="custom_view_head" style={{ textTransform: 'capitalize' }}>{this.state.lc_spname}</span>
																					<Tooltip className="item" content="Location" placement="left" >
																						<span className="text-info"><i className="lnr-map-marker font-size-sm" style={{ fontWeight: 'bold', fontSize: '14px' }}> </i></span><span>{this.state.lc_path}</span>
																					</Tooltip>

																				</Col>
																			</Row>


																		</Col>
																		<Col lg="4" sm="12" xs="12" className="text-center">
																			<br />
																			<Row>
																				<Col lg="12" sm="12" xs="12">
																					<b><span className="badge badge-pill badge-primary">{this.state.lc_squareft}</span></b>
																				</Col>
																				<Col lg="12" sm="12" xs="12">
																					<span className="text-uppercase">Square feet</span>
																				</Col>
																			</Row>

																		</Col>
																		<Col lg="4" sm="12" xs="12" className="text-center">
																			<br />
																			<Row>
																				<Col lg="12" sm="12" xs="12">
																					<b><span className="badge badge-pill badge-primary">{this.state.lc_occupation}</span></b>
																				</Col>
																				<Col lg="12" sm="12" xs="12">
																					<span className="text-uppercase">Max Occupancy</span>
																				</Col>
																			</Row>
																		</Col>


																	</Row>

																	:

																	<Row>

																		<Col lg="4" sm="12" xs="12">

																			<Row>
																				<Col lg="3" sm="6" xs="6">

																					<div className="avatar-icon rounded">
																						{this.state.cmp_image ? <img src={'data:image/png;base64,' + this.state.cmp_image} /> : <img src={cimg} />}
																					</div>


																				</Col>

																				<Col lg="8" sm="8" xs="8">
																					<span className="text-info" style={{ fontSize: '13px' }}>#{this.state.cmp_code}</span>
																					<br />
																					<span className="custom_view_head" style={{ textTransform: 'capitalize' }}>{this.state.cmp_name}</span>

																				</Col>
																			</Row>


																		</Col>
																		<Col lg="4" sm="12" xs="12" className="text-center">

																			<Row>
																				<Col lg="12" sm="12" xs="12">
																					<b><span className="badge badge-pill badge-primary">{this.state.buildings.length}</span></b>
																				</Col>
																				<Col lg="12" sm="12" xs="12">
																					<span className="text-uppercase">{locale.t('el.overview.buildings')}</span>
																				</Col>
																			</Row>


																		</Col>
																		<Col lg="4" sm="12" xs="12" className="text-center">

																			<Row>

																				<Col lg="12" sm="12" xs="12">
																					<b><span className="badge badge-pill badge-primary">{this.state.cmp_sq}</span></b>
																				</Col>
																				<Col lg="12" sm="12" xs="12">
																					<span className="text-uppercase">{locale.t('el.space.sqfeet')}</span>
																				</Col>

																			</Row>
																		</Col>


																	</Row>


														}
													</div>
													: ''
											}
										</CardBody>
									</Card>
								</div>

								{this.state.isshow && this.state.lc_cat != 'Site' ?
									<Card className="main-card mb-2 custom_card_view1">
										<CardBody className="custom_card_body1">

											<Nav>
												<NavLink className="tab_nav_link" active={this.state.activeTab == '1' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('1')} >Summary</NavLink>
												<NavLink className="tab_nav_link" active={this.state.activeTab == '2' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('2')} >Readings</NavLink>
												<NavLink className="tab_nav_link" active={this.state.activeTab == '3' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('3')} >Alarms</NavLink>
												<NavLink className="tab_nav_link" active={this.state.activeTab == '4' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('4')} >Occupancy</NavLink>
											</Nav>
										</CardBody>
									</Card>
									: this.state.isshow && this.state.lc_cat == 'Site' ?
										<Card className="main-card mb-2 custom_card_view1">
											<CardBody className="custom_card_body1">
												<Nav>
													<NavLink className="tab_nav_link" active={this.state.activeSiteTab == '11' ? true : false} href="javascript:void(0);" onClick={() => this.onSiteTabChange('11')} >{locale.t('el.space.sitePerformance')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeSiteTab == '12' ? true : false} href="javascript:void(0);" onClick={() => this.onSiteTabChange('12')} >{locale.t('el.space.strategicCerifications')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeSiteTab == '13' ? true : false} href="javascript:void(0);" onClick={() => this.onSiteTabChange('13')} >Metrics</NavLink>
												</Nav>
											</CardBody>
										</Card>

										:

										''}

								<div>
									{this.state.isshow && this.state.lc_cat != 'Site' && this.state.activeTab == '1' ?
										<Row>
											<Col md="6" lg="6" className="custom_col_left">
												<Card className="widget-chart widget-chart2 text-left mb-2 card-btm-border card-shadow-primary">
													<div className="widget-chat-wrapper-outer">
														<div className="widget-chart-content">

															<div className="">
																<span className="card-title  text-uppercase"> {locale.t('el.space.workOrders')}  </span>
																{this.getmaintainOrderStateLateCount() != 0 ?
																	<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Late')}>
																		<span style={{ textTransform: 'capitalize' }} className="pull-right text-danger">Overdue <b>{this.getmaintainOrderStateLateCount()}</b></span>
																	</a>
																	: ''}
															</div>
															<br />
															{this.state.dataLoading ?

																<div><center><Loader type="ball-clip-rotate" /></center></div>
																:
																<div className="text-center">
																	{this.state.spaceorders.length > 0 ?
																		<Row>
																			<Col lg="4" sm="6" xs="6">
																				<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Open')}>
																					<h4><b>{this.getmaintainOrderStateCount('open')}</b></h4>
																					<span style={{ fontSize: '13px' }}>New</span>
																				</a>
																			</Col>
																			<Col lg="4" sm="6" xs="6">
																				<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Progress')}>
																					<h4><b>{this.getmaintainOrderStateCount('progress')}</b></h4>
																					<span style={{ fontSize: '13px' }}>Progress</span>
																				</a>
																			</Col>
																			<Col lg="4" sm="6" xs="6">
																				<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Done')}>
																					<h4><b>{this.getmaintainOrderStateCount('done')}</b></h4>
																					<span style={{ fontSize: '13px' }}>Closed</span>
																				</a>
																			</Col>
																		</Row>
																		:
																		<div><img src={nodata} width="35" height="35" /> <br /><b>No Work Orders Found..</b></div>
																	}
																</div>
															}

														</div>
													</div>
												</Card>
											</Col>

											<Col md="6" lg="6" className="custom_col_right">
												<Card className="widget-chart widget-chart2 text-left mb-2 card-btm-border card-shadow-primary">
													<div className="widget-chat-wrapper-outer">
														<div className="widget-chart-content">

															<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.setState({ isEquipmentLink: true })}>

																<div className="">
																	<span className="card-title  text-uppercase">  {locale.t('el.equipment.title')}  </span>
																	{this.getEquipmentStateCount('br') != 0 ? <span style={{ textTransform: 'capitalize' }} className="pull-right text-danger">Breakdown <b>{this.getEquipmentStateCount('br')}</b></span> : ''}
																</div>
																<br />
																{this.state.dataLoading ?

																	<div><center><Loader type="ball-clip-rotate" /></center></div>
																	:
																	<div className="text-center">
																		{this.state.equipsgroups.length > 0 ?
																			<Row>
																				<Col lg="4" sm="6" xs="6">
																					<h4><b>{this.getEquipmentStateCount('mn')}</b></h4>
																					<span style={{ fontSize: '13px' }}>Maintenance</span>
																				</Col>
																				<Col lg="4" sm="6" xs="6">
																					<h4><b>{this.getEquipmentStateCount('op')}</b></h4>
																					<span style={{ fontSize: '13px' }}>Operative</span>
																				</Col>
																				<Col lg="4" sm="6" xs="6">
																					<h4><b>{this.getEquipmentStateCount('wh')}</b></h4>
																					<span style={{ fontSize: '13px' }}>Warehouse</span>
																				</Col>
																			</Row>
																			:
																			<div><img src={nodata} width="35" height="35" /> <br /><b>No Equipments Found..</b></div>
																		}
																	</div>
																}
															</a>
														</div>
													</div>
												</Card>
											</Col>

											<Col lg="6" sm="12" xs="12" className="custom_col_left">

												<Card className="widget-chart mb-2 card_equal_height">
													<div className="widget-chat-wrapper-outer">
														<div className="widget-chart-content">
															<div className="card-title text-uppercase text-left">
																Orders By State (%)
														</div>
															{this.state.dataLoading ?

																<div><center><Loader type="ball-clip-rotate" /></center></div>
																:
																<div className="text-left">
																	{this.state.spaceorders.length == '0' ?

																		<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

																		:
																		<div className="donut">
																			<Chart options={dougnoutoptins} series={piechartseries} type="donut" id="apexchart-example-3" width="100%" />

																		</div>
																	}
																</div>
															}
														</div>
													</div>
												</Card>
											</Col>

											<Col lg="6" sm="12" xs="12" className="custom_col_right">

												<Card className="widget-chart mb-2 card_equal_height">
													<div className="widget-chat-wrapper-outer">
														<div className="widget-chart-content">
															<div className=" card-title text-uppercase text-left">
																Equipments By State
														</div>
															{this.state.dataLoading ?

																<div><center><Loader type="ball-clip-rotate" /></center></div>
																:
																<div>
																	{this.state.equipsgroups.length == 0 ?

																		<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

																		:
																		<div className="bar">
																			<Chart options={barchartoptions2} series={barchartseries2} type="bar" width="100%" />
																		</div>
																	}
																</div>
															}
														</div>
													</div>
												</Card>
											</Col>


										</Row>
										: ''}
								</div>



								<div>
									{this.state.isshow && this.state.lc_cat == 'Site' && this.state.activeSiteTab == '11' ?
										<Row>

											<Col md="12" lg="8" className="custom_col_left">
												<Row>
													<Col md="6" lg="6" className="custom_col_left">
														<Card className="widget-chart widget-chart2 text-left mb-2 card-btm-border card-shadow-primary" style={{ height: '97%' }}>
															<div className="widget-chat-wrapper-outer">
																<div className="widget-chart-content">
																	<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.setSensorLink('Indoor Air Quality')} >
																		<div className="">
																			<span className="card-title  text-uppercase"> Indoor Air Quality</span>
																		</div>
																		<br />

																		<Row className="text-left">

																			<Col md="6" lg="3">
																				<div>

																					{this.getcolorvalue(this.state.sensorData.iaq)}

																				</div>
																			</Col>
																			<Col md="12" lg="9">
																				<Row>
																					<Col md="12" lg="9">
																						<span><b style={{ fontSize: '25px' }}>{this.getroundvalue(this.state.sensorData.iaq)}</b></span><br />
																						<span>Current AQI</span>


																					</Col>

																					<Col md="12" lg="12">
																						{this.getcolorslogan(this.state.sensorData.iaq)}
																					</Col>
																				</Row>
																			</Col>


																		</Row>
																	</a>
																	<br />
																	<div className="text-left">
																		<span className="text-primary">Impact</span><br />
																		<span className=""><b>{this.getcolorstext(this.state.sensorData.iaq)}</b></span><br />
																		<span className="text-primary">Recommended Action</span><br />
																		<span className=""><b>{this.getcolorssol(this.state.sensorData.iaq)}</b></span>
																	</div>


																</div>
															</div>
														</Card>
													</Col>

													<Col md="6" lg="6" className="custom_col_right">
														<Card className="widget-chart mb-2" style={{ height: '97%' }}>
															<div className="widget-chat-wrapper-outer">
																<div className="widget-chart-content">
																	<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.setSensorLink('Energy')} >
																		<div className="text-left">
																			<span className="card-title text-uppercase">{locale.t('el.space.energyConsumption')}  </span>

																		</div>
																		<br />
																		<Row className="text-left">

																			<Col md="6" lg="3">
																				<div>

																					<i className="pe-7s-gleam" style={{ fontSize: '70px' }}></i>

																				</div>
																			</Col>
																			<Col md="6" lg="9">
																				<Row>
																					<Col md="6" lg="12">
																						<span><b style={{ fontSize: '25px' }}>{this.getroundvalue(this.state.todayEnergy)}</b>&nbsp;Kwh</span><br />
																						<span>Today's</span><br />
																						<span>Energy Consumption</span>

																					</Col>


																				</Row>
																			</Col>

																		</Row>
																	</a>
																	<br/>
																	
																	<div className="text-left">
																			<span className="card-title text-uppercase">Water Consumption</span>

																		</div>
																		<br />
																		<Row className="text-left">

																			<Col md="6" lg="3">
																				<div>

																					<i className="pe-7s-paint-bucket" style={{ fontSize: '70px' }}></i>

																				</div>
																			</Col>
																			<Col md="6" lg="9">
																				<Row>
																					<Col md="6" lg="12">
																						<span><b style={{ fontSize: '25px' }}>120</b>&nbsp;gal</span><br />
																						<span>Today's</span><br />
																						<span>Water Consumption</span>

																					</Col>


																				</Row>
																			</Col>

																		</Row>

																</div>
															</div>
														</Card>
													</Col>

													<Col lg="6" sm="12" xs="12" className="custom_col_left">

														<Card className="widget-chart mb-2 " style={{ height: '164%'}}>
															<div className="widget-chat-wrapper-outer">
																<div className="widget-chart-content">
																	<div className="card-title text-uppercase text-left">
																		<span className="card-title  text-uppercase"> {locale.t('el.space.workOrders')} </span>
																		{this.getmaintainOrderStateLateCountCompany() != 0 ?
																			<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderSiteLink('Late')}>
																				<span style={{ textTransform: 'capitalize' }} className="pull-right text-danger">Overdue <b>{this.getmaintainOrderStateLateCountCompany()}</b></span>
																			</a>
																			: ''}
																	</div>
																	<br />

																	{this.state.ordersLoading ?

																		<div><center><Loader type="ball-clip-rotate" /></center></div>
																		:
																		<div className="text-center">
																			{this.state.companyorders.length > 0 ?
																				<div style={{ marginBottom: '20px' }}>
																					<Row>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderSiteLink('Open')}>
																								<h4><b>{this.getmaintainOrderCompanyStateCount('open')}</b></h4>
																								<span style={{ fontSize: '13px' }}>New</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderSiteLink('Progress')}>
																								<h4><b>{this.getmaintainOrderCompanyStateCount('progress')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Progress</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderSiteLink('Done')}>
																								<h4><b>{this.getmaintainOrderCompanyStateCount('done')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Closed</span>
																							</a>
																						</Col>
																					</Row>
																				</div>
																				:
																				<div><img src={nodata} width="55" height="55" /> <br /><b>No Work Orders Found..</b></div>
																			}
																		</div>
																	}
																</div>
															</div>
														</Card>
													</Col>

													<Col lg="6" sm="12" xs="12" className="custom_col_right">

														<Card className="widget-chart mb-2 " style={{ height: '164%'}}>
															<div className="widget-chat-wrapper-outer">
																<div className="widget-chart-content">
																	<div className=" card-title text-uppercase text-left">
																		Tickets
														</div>
																	<br />
																	{this.state.ticketsLoading ?

																		<div><center><Loader type="ball-clip-rotate" /></center></div>
																		:
																		<div className="text-center">
																			{this.state.companytickets.length > 0 ?
																				<div style={{ marginBottom: '20px' }}>
																					<Row>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onTicketSiteLink('Open')}>
																								<h4><b>{this.getTicketCompanyStateCount('open')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Open</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onTicketSiteLink('Progress')}>
																								<h4><b>{this.getTicketCompanyStateCount('progress')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Progress</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onTicketSiteLink('Done')}>
																								<h4><b>{this.getTicketCompanyStateCount('done')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Closed</span>
																							</a>
																						</Col>

																					</Row>

																				</div>
																				:
																				<div><img src={nodata} width="55" height="55" /> <br /><b>No Tickets Found..</b></div>
																			}
																		</div>
																	}

																</div>
															</div>
														</Card>
													</Col>
												</Row>
											</Col>

											<Col lg="4" sm="12" xs="12" className="custom_col_right">

												<Card className="widget-chart mb-2" style={{ height: '106%' }}>
													<div className="widget-chat-wrapper-outer">
														<div className="widget-chart-content">

															<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.setSensorLink('Temperature')} >
																<div className=" card-title text-uppercase text-left">
																	{locale.t('el.space.temperature')}
																</div>

																<Row className="text-left">

																	<Col md="6" lg="3">
																		<div>

																			<i className="pe-7s-battery" style={{ fontSize: '65px' }}></i>

																		</div>
																	</Col>
																	<Col md="6" lg="9">
																		<Row>
																			<Col md="6" lg="12">
																				<span><b style={{ fontSize: '25px' }}>{this.getroundvalue(this.state.sensorData.temperature)}</b>&nbsp;°C</span><br />
																				<span>{locale.t('el.space.current')}</span><br />
																				<span>{locale.t('el.space.temperature')}</span>
																			</Col>
																		</Row>
																	</Col>
																</Row>
															</a>
															<br />
															<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.setSensorLink('Humidity')} >
																<div className=" card-title text-uppercase text-left">
																	{locale.t('el.space.humidity')}
																</div>

																<Row className="text-left">

																	<Col md="6" lg="3">
																		<div>

																			<i className="pe-7s-drop" style={{ fontSize: '65px' }}></i>

																		</div>
																	</Col>
																	<Col md="6" lg="9">
																		<Row>
																			<Col md="6" lg="12">
																				<span><b style={{ fontSize: '25px' }}>{this.getroundvalue(this.state.sensorData.humidity)}</b>&nbsp;r.H.</span><br />
																				<span>{locale.t('el.space.current')}</span><br />
																				<span>{locale.t('el.space.humidity')}</span>
																			</Col>
																		</Row>
																	</Col>
																</Row>
															</a>
															<br />
															<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.setSensorLink('Pressure')} >
																<div className=" card-title text-uppercase text-left">
																	{locale.t('el.space.pressure')}
																</div>
																<Row className="text-left">

																	<Col md="6" lg="3">
																		<div>

																			<i className="lnr-rocket" style={{ fontSize: '65px' }}></i>

																		</div>
																	</Col>
																	<Col md="6" lg="9">
																		<Row>
																			<Col md="6" lg="12">
																				<span><b style={{ fontSize: '25px' }}>{this.getroundvalue(this.state.prdata)}</b>&nbsp;hPa</span><br />
																				<span>{locale.t('el.space.current')}</span><br />
																				<span>{locale.t('el.space.pressure')}</span>

																			</Col>


																		</Row>
																	</Col>


																</Row>
															</a>
															<br />
															<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.setSensorLink('Lux')} >
																<div className=" card-title text-uppercase text-left">
																	{locale.t('el.space.lux')}
																</div>
																<Row className="text-left">

																	<Col md="6" lg="3">
																		<div>
																			<i className="pe-7s-light" style={{ fontSize: '65px' }}></i>

																		</div>
																	</Col>
																	<Col md="6" lg="9">
																		<Row>
																			<Col md="6" lg="12">
																				<span><b style={{ fontSize: '25px' }}>{this.getroundvalue(this.state.sensorData.lux)}</b>&nbsp;lx</span><br />
																				<span>{locale.t('el.space.current')}</span><br />
																				<span>{locale.t('el.space.lux')}</span>
																			</Col>
																		</Row>
																	</Col>
																</Row>
															</a>
														</div>
													</div>
												</Card>
											</Col>
										</Row>
										: ''}
								</div>

								<div>
									{this.state.isshow && this.state.lc_cat == 'Site' && this.state.activeSiteTab == '12' ?

										this.state.certLoading ?

											<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
											:
											<React.Fragment>

												<Row>
													<Col lg="12" sm="6" xs="6">
														<Card className="main-card mb-2" >
															<CardBody>
																<div className="text-center">
																	<img src={isoimg} height="50" />
																</div>
																<div className="text-center">
																	<span style={{ fontWeight: 'bold', fontSize: "16px" }}>ISO 55001</span><br />
																	<span style={{ fontWeight: 'bold', fontSize: "16px" }}>{locale.t('el.space.assetManagement')}</span>
																</div>
															</CardBody>
														</Card>
													</Col>
												</Row>


												<Row>
													<Col lg="4" sm="6" xs="6" className="custom_col_left">
														<a href="javascript:;" onClick={() => this.onCertificateChange('LEED')} style={{ textDecoration: 'none', color: '#495057' }}>
															<Card className="main-card mb-2 card-shadow-primary border-leed card-btm-border" style={{height: '100%' }}>
																<CardBody>
																	<div className="text-center">
																		<img src={leedimg} width="90" height="90" />
																		<h3>LEED Certification</h3>
																		<span>{locale.t('el.space.complianceStatus')} : </span><span>{this.getSiteData('LEED', 'status')}</span><br />
																		<span>{locale.t('el.space.currentPoints')} : </span><span>{this.getSiteData('LEED', 'points')}</span>
																	</div>
																</CardBody>
															</Card>
														</a>
													</Col>
													<Col lg="4" sm="6" xs="6" className="custom_col_left custom_col_right">
														<a href="javascript:;" onClick={() => this.onCertificateChange('SITES')} style={{ textDecoration: 'none', color: '#495057' }}>
															<Card className="main-card mb-2 card-shadow-primary border-site card-btm-border" style={{height: '100%' }}>
																<CardBody style={{ textAlign: 'center' }}>
																	<div className="text-center">
																		<img src={siteimg} width="auto" height="90" />
																		<h3>SITES Certification</h3>
																		<span>{locale.t('el.space.complianceStatus')} : </span><span>{this.getSiteData('SITES', 'status')}</span><br />
																		<span>{locale.t('el.space.currentPoints')} : </span><span>{this.getSiteData('SITES', 'points')}</span>
																	</div>
																</CardBody>
															</Card>
														</a>
													</Col>
													<Col lg="4" sm="6" xs="6" className="custom_col_right">
														<a href="javascript:;" onClick={() => this.onCertificateChange('WELL')} style={{ textDecoration: 'none', color: '#495057' }}>
															<Card className="main-card mb-2 card-shadow-primary border-well card-btm-border" style={{height: '100%' }}>
																<CardBody style={{ textAlign: 'center' }}>
																	<div className="text-center">
																		<img src={wellimg} width="90" height="90" />
																		<h3>WELL Certification</h3>
																		<span>{locale.t('el.space.complianceStatus')} : </span><span>{this.getSiteData('WELL', 'status')}</span><br />
																		<span>{locale.t('el.space.currentPoints')} : </span><span>{this.getSiteData('WELL', 'points')}</span>
																	</div>
																</CardBody>
															</Card>
														</a>
													</Col>
												</Row>

											</React.Fragment>
										: ''}
								</div>
								
								<div>
									{this.state.isshow && this.state.lc_cat == 'Site' && this.state.activeSiteTab == '13' ?

										
											<React.Fragment>

												<Row>
													<Col lg="3" sm="6" xs="6" className="custom_col_left">
													<a href="javascript:;" onClick={() => this.onMetricChange('customer_metrics')} style={{ textDecoration: 'none', color: '#495057' }}>
														<Card className="widget-chart widget-chart2 mb-2 card-btm-border card-shadow-primary"  style={{height: '100%' }}>
															<div className="widget-chat-wrapper-outer">
															   <div className="widget-chart-content">
																	
																	  <div className="text-center">
																				<i className="lnr-user" style={{ fontSize: '65px' }}></i><br/>
																		         <span style={{fontWeight: 'bold',fontSize: '20px'}}>Customer Metrics</span>
																		</div>
																</div>
															</div>
														</Card>
													 </a>
													</Col>
													<Col lg="3" sm="6" xs="6" className="custom_col_left custom_col_right" >
													<a href="javascript:;" onClick={() => this.onMetricChange('functional_metrics')} style={{ textDecoration: 'none', color: '#495057' }}>
														<Card className="widget-chart widget-chart2 mb-2 card-btm-border card-shadow-primary" style={{height: '100%' }}>
															<div className="widget-chat-wrapper-outer">
															   <div className="widget-chart-content">
																	
																	  <div className="text-center">
																				<i className="lnr-pilcrow" style={{ fontSize: '65px' }}></i><br/>
																		         <span style={{fontWeight: 'bold',fontSize: '20px'}}>Functional Metrics</span>
																		</div>
																</div>
															</div>
														</Card>
													</a>
													</Col>
													<Col lg="3" sm="6" xs="6" className="custom_col_left custom_col_right" >
													<a href="javascript:;" onClick={() => this.onMetricChange('physical_performance')} style={{ textDecoration: 'none', color: '#495057' }}>
														<Card className="widget-chart widget-chart2 mb-2 card-btm-border card-shadow-primary" style={{height: '100%' }}>
															<div className="widget-chat-wrapper-outer">
															   <div className="widget-chart-content">
																	
																	  <div className="text-center">
																				<i className="lnr-leaf" style={{ fontSize: '65px' }}></i><br/>
																		         <span style={{fontWeight: 'bold',fontSize: '20px'}}>Physical Performance</span>
																		</div>
																</div>
															</div>
														</Card>
													</a>
													</Col>
													<Col lg="3" sm="6" xs="6" className="custom_col_right" >
													<a href="javascript:;" onClick={() => this.onMetricChange('financial_performance')} style={{ textDecoration: 'none', color: '#495057' }}>
														<Card className="widget-chart widget-chart2 mb-2 card-btm-border card-shadow-primary" style={{height: '100%' }}>
															<div className="widget-chat-wrapper-outer">
															   <div className="widget-chart-content">
																	
																	  <div className="text-center">
																				<i className="pe-7s-cash" style={{ fontSize: '65px' }}></i><br/>
																		         <span style={{fontWeight: 'bold',fontSize: '20px'}}>Financial Performance</span>
																		</div>
																</div>
															</div>
														</Card>
													 </a>
													</Col>
												</Row>

											</React.Fragment>
										: ''}
								</div>


								<div>
									{this.state.isshow && this.state.lc_cat != 'Site' && this.state.activeTab == '2' ?

										<Card className="main-card mb-2">

											<CardBody>

												{this.state.assetsLoading ?
													<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
													:
													this.state.meterassets.length > 0 || this.state.gauageassets.length > 0 ?
														<Col lg="12">
															<Table responsive className="mb-0">
																<thead>
																	<th>{locale.t('el.equipment.name')}</th>
																	<th>{locale.t('el.equipment.lastValue')}</th>
																	<th>{locale.t('el.equipment.lastUpdated')}</th>
																	<th>{locale.t('el.equipment.noOfAlarms')}</th>
																	<th className="text-center"><span>{locale.t('el.equipment.manage')}</span></th>
																</thead>
																<tbody>
																	{this.state.gauageassets.map((item, index) =>
																		<tr key={item}>
																			<td>{item.name}</td>
																			<td>{item.value}</td>
																			<td>{this.getdateoftimings(item.last_update_date)}</td>
																			<td>0</td>
																			<td align="center">
																				<Button type="primary" onClick={() => this.setAnalyticLink(item.id, item.gauge_id, item.name, 'gauge')}>
																					Analytics
																		        </Button>
																			</td>
																		</tr>
																	)}

																	{this.state.meterassets.map((item1, index) =>
																		<tr key={item1}>
																			<td>{item1.name}</td>
																			<td>{item1.value}</td>
																			<td>{this.getdateoftimings(item1.last_update_date)}</td>
																			<td>0</td>
																			<td align="center">
																				<Button type="primary" onClick={() => this.setAnalyticLink(item1.id, item1.meter_id, item1.name, 'meter')}>
																					Analytics
																		    </Button>
																			</td>
																		</tr>
																	)}
																	<tr><td colSpan="5"></td></tr>
																</tbody>
															</Table>
														</Col>
														:
														<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Logs Found..</b></div>

												}
											</CardBody>


											<Dialog
												title={this.state.chartName}
												size="large"
												visible={this.state.ChartTimeseries}
												onCancel={() => this.setState({ ChartTimeseries: false })}
												lockScroll={false}
											>
												<Dialog.Body>

													<div className="pull-left">
														<Col md="6" lg="4" className="">
															<Select onChange={this.setFilterType} value={this.state.filtertype}>
																{
																	filtertypes.map(el => {
																		return <Select.Option key={el.value} label={el.label} value={el.value} />
																	})
																}
															</Select>
														</Col>
													</div>
													<br />

													{this.state.chartLoading ?

														<div><center><Loader type="ball-clip-rotate" /></center></div>
														:
														<div className="">
															{this.state.chartData.length > 0 ?

																<div className="area">
																	<Chart options={areachartoptions} series={areachartseries} type="area" id="apexchart-example-3" width="100%" />

																</div>

																:

																<div className="text-center"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>


															}
														</div>
													}

												</Dialog.Body>

											</Dialog>

										</Card>

										: ''
									}
								</div>

								<div>
									{this.state.isshow && this.state.lc_cat != 'Site' && this.state.activeTab == '3' ?

										<Card className="main-card mb-2">

											<CardBody>


												<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Alarms Found..</b></div>


											</CardBody>

										</Card>

										: ''
									}
								</div>

								<div>
									{this.state.isshow && this.state.lc_cat == 'Floor' && this.state.lc_spname == 'Floor#1' && this.state.activeTab == '4' ?

										<Card className="main-card mb-2">

											<CardBody>


												<img src={bplan} height="100%" width="100%" />


											</CardBody>

										</Card>

										: ''
									}
									{this.state.isshow && this.state.lc_cat == 'Floor' && this.state.lc_spname == 'Ground Floor' && this.state.activeTab == '4' ?

										<Card className="main-card mb-2">

											<CardBody>


												<img src={floorplan} height="100%" width="100%" />


											</CardBody>

										</Card>

										: ''
									}
								</div>

							</div>
						</div>
					}
				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
