import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import { Redirect, Link } from 'react-router-dom';
import classnames from 'classnames';
import locale from '../../locale';
import equimg from '../../assets/utils/images/building.png';
import floorimg from '../../assets/utils/images/floor.png';
import roomimg from '../../assets/utils/images/room.png';
import bplan from '../../assets/utils/images/floors1-01-01.svg';
import floorplan from '../../assets/utils/images/floors2-02-02.svg';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import workdata from '../../assets/utils/worksvg.svg';
import isoimg from '../../assets/space/iso.png';
import leedimg from '../../assets/space/leed.png';
import wellimg from '../../assets/space/well.png';
import siteimg from '../../assets/space/site.png';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import cimg from '../../assets/utils/images/dropdown-header/city1.jpg';
import 'react-dropdown-tree-select/dist/styles.css'
import ReactTooltip from 'react-tooltip';
import SVGGenerator from '../../tie/SVGGenerator';
import {
	faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
	Row, Col,
	Alert,
	Container, TabContent, TabPane,
	Card, CardBody, CardHeader,
	Table,
	Progress,
	ButtonGroup,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroup, FormGroup, Label, FormText,
	ListGroupItem, CardFooter,
	CustomInput, Input,
	UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import { Loading, Tabs, Tree, Select, Dropdown, Collapse, Tooltip, Button, Dialog } from 'element-react';

const cardstyles = { borderRight: '1px solid #545cd8' }

const loaddiv = { marginTop: '155px' }

export default class Metrics extends Component {
	constructor(props) {
		super(props);

		this.state = {

			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			companies: [],
			metricLink : false,
			metrictype : '',
			cmp_id: Sanitize.getStoreageItem('company_id'),
			companyLoading: false,
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds()))
		};
		
		this.onCompanyChange = this.onCompanyChange.bind(this);
		

	}

	

	onCompanyChange(company) {

		this.setState({ cmp_id: company });
		this.state.cmp_id = company;
		this.loadcompanybyid(company);
		

	}
	
	loadcompanybyid(company) {
		this.setState({ certLoading: true });
		
		var token = Sanitize.getStoreageItem('accessToken');
		//var company = Sanitize.getStoreageItem('company_id');
		Reqst.axGet('getcompanydata/' + token + '/' + company).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ certLoading: false });
				
				if (res) {
					this.setState({
						cmp_name: res.name,
						cmp_id: res.id
					});
					
				}
			}
		});
	}

	
	

	componentDidMount() {

		if (this.props.location.state && this.props.location.state.modelkeyid == 'sites') {
			this.loadcompanybyid(this.props.location.state.site_id);
			this.state.cmp_id = this.props.location.state.site_id;
		}

		this.getCompanies();


	}
	
	getRefreshToken() {

		this.setState({ companyLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ companyLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				this.getCompanies();
				
			}
		}).catch(error => {
			this.setState({ companyLoading: false });
			//console.log(error.response.status);

		});

	}


	
	async getCompanies() {
		this.setState({ companyLoading: true });
		this.setState({ companies: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var cid = Sanitize.getStoreageItem('company_ids');
		var cuid = Sanitize.getStoreageItem('company_id');

		var data = { "token": token, "cid": cid }
		await Reqst.axPost('getusercompanies', data).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ companyLoading: false });

				/* var data = [];

				for (var i = 0; i < res.length; i++) {

					if (res[i].id != cuid) {

						data.push(res[i]);
					}

				} */
				this.setState({ companies: res });
			}
		});
	}

	
	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}

	
	onMetricChange(type) {

		this.setState({ metrictype: type, metricLink: true });

	}

	directtoanother() {

		this.setState({ isDirect: true });

	}

	
	render() {


		if (this.state.metricLink && this.state.metrictype=='customer_metrics') {

			return (<Redirect to={{
				pathname: '/metrics/customer',
				state: { modelkeyid: 'customer_metrics_single', value: 'Metrics', uid: this.state.cmp_id }
			}}
			/>);

		}
		
		if (this.state.metricLink && this.state.metrictype=='functional_metrics') {

			return (<Redirect to={{
				pathname: '/metrics/functional',
				state: { modelkeyid: 'functional_metrics_single', value: 'Metrics', uid: this.state.cmp_id }
			}}
			/>);

		}
		
		if (this.state.metricLink && this.state.metrictype=='physical_performance') {

			return (<Redirect to={{
				pathname: '/performance/physical',
				state: { modelkeyid: 'physical_performance_single', value: 'Metrics', uid: this.state.cmp_id }
			}}
			/>);

		}
		
		if (this.state.metricLink && this.state.metrictype=='financial_performance') {

			return (<Redirect to={{
				pathname: '/performance/financial',
				state: { modelkeyid: 'financial_performance_single', value: 'Metrics', uid: this.state.cmp_id }
			}}
			/>);

		}
		
		


		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Metrics  </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					<div className="topbar-header">
						<div className="topbar-header__content">

							 <PageTitleAlt3
								heading=""
								subheading=""
								icon=""
								menuLevel1="Metrics"
								isbreadcumb={true}
						  />

							<div className="topbar_buttons">
								<Select onChange={this.onCompanyChange} value={this.state.cmp_id} loading={this.state.companyLoading}>
									{
										this.state.companies.map(el => {
											return <Select.Option key={el.id} label={el.name} value={el.id} selected={el.id==this.state.cmp_id ? true : false} />
										})
									}
								</Select>


							 </div>
						</div>
					</div>


					
						

								
								<div className="card_topbar">
							    
										{this.state.companyLoading ?

											<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
											:
											<React.Fragment>

												<Row>
													<Col lg="3" sm="6" xs="6" className="custom_col_left">
													<a href="javascript:;" onClick={() => this.onMetricChange('customer_metrics')} style={{ textDecoration: 'none', color: '#495057' }}>
														<Card className="widget-chart widget-chart2 mb-2 card-btm-border card-shadow-primary"  style={{height: '100%' }}>
															<div className="widget-chat-wrapper-outer">
															   <div className="widget-chart-content">
																	
																	  <div className="text-center">
																				<i className="lnr-user" style={{ fontSize: '65px' }}></i><br/>
																		         <span style={{fontWeight: 'bold',fontSize: '20px'}}>Customer Metrics</span>
																		</div>
																</div>
															</div>
														</Card>
													 </a>
													</Col>
													<Col lg="3" sm="6" xs="6" className="custom_col_left custom_col_right" >
													<a href="javascript:;" onClick={() => this.onMetricChange('functional_metrics')} style={{ textDecoration: 'none', color: '#495057' }}>
														<Card className="widget-chart widget-chart2 mb-2 card-btm-border card-shadow-primary" style={{height: '100%' }}>
															<div className="widget-chat-wrapper-outer">
															   <div className="widget-chart-content">
																	
																	  <div className="text-center">
																				<i className="lnr-pilcrow" style={{ fontSize: '65px' }}></i><br/>
																		         <span style={{fontWeight: 'bold',fontSize: '20px'}}>Functional Metrics</span>
																		</div>
																</div>
															</div>
														</Card>
													</a>
													</Col>
													<Col lg="3" sm="6" xs="6" className="custom_col_left custom_col_right" >
													<a href="javascript:;" onClick={() => this.onMetricChange('physical_performance')} style={{ textDecoration: 'none', color: '#495057' }}>
														<Card className="widget-chart widget-chart2 mb-2 card-btm-border card-shadow-primary" style={{height: '100%' }}>
															<div className="widget-chat-wrapper-outer">
															   <div className="widget-chart-content">
																	
																	  <div className="text-center">
																				<i className="lnr-leaf" style={{ fontSize: '65px' }}></i><br/>
																		         <span style={{fontWeight: 'bold',fontSize: '20px'}}>Physical Performance</span>
																		</div>
																</div>
															</div>
														</Card>
													</a>
													</Col>
													<Col lg="3" sm="6" xs="6" className="custom_col_right" >
													<a href="javascript:;" onClick={() => this.onMetricChange('financial_performance')} style={{ textDecoration: 'none', color: '#495057' }}>
														<Card className="widget-chart widget-chart2 mb-2 card-btm-border card-shadow-primary" style={{height: '100%' }}>
															<div className="widget-chat-wrapper-outer">
															   <div className="widget-chart-content">
																	
																	  <div className="text-center">
																				<i className="pe-7s-cash" style={{ fontSize: '65px' }}></i><br/>
																		         <span style={{fontWeight: 'bold',fontSize: '20px'}}>Financial Performance</span>
																		</div>
																</div>
															</div>
														</Card>
													 </a>
													</Col>
												</Row>

											</React.Fragment>
										   }
										
								</div>
								
				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
