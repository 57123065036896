import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import {Helmet} from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import {Redirect,Link} from 'react-router-dom';
import classnames from 'classnames';
import preventive_img from '../../assets/utils/images/Preventive_black.png';
import oc_img from '../../assets/utils/images/On Condition_black.png';
import corrective_img from '../../assets/utils/images/Corrective_black.png';
import periodic_img from '../../assets/utils/images/Periodic_black.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import {Doughnut} from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import CountUp from 'react-countup';
import Select from 'react-select';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import ReactTooltip from 'react-tooltip';
import locale from '../../locale';

import {
    faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
    Row, Col,
	Alert,
    Container,TabContent,TabPane,
	Card,CardBody,CardHeader,
	Table,
    ButtonGroup,
	Modal,ModalHeader,ModalBody,ModalFooter,
    ListGroup,FormGroup,Label,FormText,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import {Loading,Tabs,Tree,Dropdown,Button,Progress,Tooltip,Tag,Dialog,DateRangePicker} from 'element-react';

const cardstyles = {borderRight: '1px solid #545cd8'}

const loaddiv = {marginTop: '155px' }

const options = [
	{ value: '1', label: 'Today' },
	{ value: '2', label: 'Last Week' },
	{ value: '3', label: 'Last 30 Days' },
	{ value: '4', label: 'Last 3 Months' },
	{ value: '5', label: 'Last Year' },
];

export default class FunctionalMetrics extends Component {
	constructor(props) {
        super(props);

        this.state = {
			accTok : Sanitize.getStoreageItem('accessToken'),
			reTok : Sanitize.getStoreageItem('refreshToken'),
			isDirect : false,
			isDataLoaded : false,
			orderLateLoading : false,
			orderLoading : false,
			ordersExecLoading : false,
			ordersExecdata : [],
			daterange : this.getTodays(),
			filtertype : 'Today',
			execMonths : [],
			execValues : [],
			ordersdata : [],
			ordersLatedata : [],
			pacount: 0,
			pmcount: 0,
			occount: 0,
			bmcount: 0,
			selectedOption: '',
			metricType : 'Work Management',
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			
        };
			
			this.onDateChange = this.onDateChange.bind(this);
			this.setFilterType = this.setFilterType.bind(this);
	
    }
	
	getTodays(){
		   
				var dates = [];
			   var d = new Date();
               var date = d.setDate(d.getDate() - 1);
			   
			   var yesterday = new Date(date);
			   
			  var today = new Date();
			  
			   dates.push(yesterday);
			   dates.push(today);
			   
		
			   return dates;
		   
		   
	   }
	   
	    daysInMonth (month, year) { 
          return new Date(year, month, 0).getDate(); 
     }        
    
	getdatesofmonth() { 
			 

                var date = new Date(); 
                var firstDay = new Date(date.getFullYear(), 
                                date.getMonth(), 1); 
                                  
                var lastDay = new Date(date.getFullYear(), 
                        date.getMonth(), this.daysInMonth(date.getMonth()+1, 
                        date.getFullYear())); 
						
				var daterange = [];
		
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
				
				
				 this.getmaintainorderdatadate(firstDay,lastDay);
				 this.getmaintainorderlatedata(firstDay,lastDay);
      } 
	  
	   getDateSeries(series) { 
			 
				
				var daycount = 7;
				
				if(series=='Last_Week'){
					
					daycount = 7;
				}
				else if(series=='Last_Month'){
					
					daycount = 30;
				}
				else if(series=='Last_3_Months'){
					
					daycount = 90;
				}
				
                var lastDay = new Date();
				var firstDay = new Date();
			    firstDay.setTime(firstDay.getTime() - 3600 * 1000 * 24 * daycount);	
				
				var daterange = [];
		
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
			
				this.getmaintainorderdatadate(firstDay,lastDay);
				this.getmaintainorderlatedata(firstDay,lastDay);
				
      } 
	  
	    getTodayData(){
		  
		
		     var date1 = new Date();
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			   var hours = date1.getHours();
			   var minutes = date1.getMinutes();
			   var seconds = date1.getSeconds();
			   month = month < 10 ? '0'+month : month;
			   day = day < 10 ? '0'+day : day;
			   hours = hours < 10 ? '0'+hours : hours;
			   minutes = minutes < 10 ? '0'+minutes : minutes;
			   seconds = seconds < 10 ? '0'+seconds : seconds;
			   
			   var d = new Date();
               var date = d.setDate(d.getDate() - 1);
			   
			   var yesterday = this.getdateoforginal(date);
			   
			  var today = year + '-' + month + '-' + day;
			  
			  var daterange = [];
				
				var firstDay = new Date(date);
				var lastDay = new Date();
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
				
				this.getmaintainorderdatadate(yesterday,today);
				this.getmaintainorderlatedata(yesterday,yesterday);
				
		  
	  }
	  
	  
	  getdatesofweek() { 
			 
			
               var curr = new Date; // get current date
				var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
				var last = first + 6; // last day is the first day + 6

				var firstDay = new Date(curr.setDate(first)).toUTCString();
				var lastDay = new Date(curr.setDate(last)).toUTCString();
				
				var daterange = [];
				
				var firstDay1 = new Date(curr.setDate(first));
				var lastDay1 = new Date(curr.setDate(last));
				daterange.push(firstDay1);
				daterange.push(lastDay1);
				
				this.setState({daterange:daterange});
				
		
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
				
			
				this.getmaintainorderdatadate(firstDay,lastDay);
				this.getmaintainorderlatedata(firstDay,lastDay);
				
      } 
	  
	  getdateoforginal(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			   var hours = date1.getHours();
			   var minutes = date1.getMinutes();
			   var seconds = date1.getSeconds();
			   month = month < 10 ? '0'+month : month;
			   day = day < 10 ? '0'+day : day;
			   hours = hours < 10 ? '0'+hours : hours;
			   minutes = minutes < 10 ? '0'+minutes : minutes;
			   seconds = seconds < 10 ? '0'+seconds : seconds;
			   
			  var strTime = year + '-' + month + '-' + day;
			  return strTime;
		 }
		 else{
			 
			 return '- - - -';
		 }
			
	  }
	
	onDateChange(date) { 
			 
			
			 
			 this.setState({daterange:date});
			 
			 var firstDay = date[0];
			 
			 var lastDay = date[1];
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
				
			
				
				 this.getmaintainorderdatadate(firstDay,lastDay);
				 this.getmaintainorderlatedata(firstDay,lastDay);
				  
				
      } 
	
	setFilterType(column){
		 
		 this.setState({filtertype:''});
	
		 this.setState({filtertype:column});
				
		 if(column=='This Month'){
			
			this.getdatesofmonth();
		 }
		 else if(column=='Today'){
			 
			 this.getTodayData();
			 
		 }
		 else if(column=='This Week'){
			 
			 this.getdatesofweek();
			 
		 } 
		 else if(column=='Last_Week'){
			 
			 this.getDateSeries(column);
			 
		 }
		 else if(column=='Last_Month'){
			 
			 this.getDateSeries(column);
			 
		 }
        else if(column=='Last_3_Months'){
			 
			 this.getDateSeries(column);
			 
		 }		 

		  
	  }
	
	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}
	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
	};
	
	
	componentDidMount(){
		
		if(this.props.location.state){
			
		   this.getdata();
		  
		}
	
	}
	
	getdata(){
		
			if(this.props.location.state.modelkeyid=='functional_metrics' || this.props.location.state.modelkeyid=='functional_metrics_single'){
				
							this.getTodayData();
		                  
							
			
		  }
	  
	}
	
	async getOrdersByExecuted() {
		this.setState({ ordersExecLoading: true });
		this.setState({ ordersExecdata: [], execMonths: [], execValues: [],execHours : [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.order';
		var groupby = '["date_execution"]';
		var fields = '["date_execution","worked_hours"]';
		var domain = '["date_execution", "!=", false]';
		//var domain = '';
		var data = { "token": token, "model": model, "group": groupby, "domain": domain,"fields":fields };
		await Reqst.axPost('getstatsdata', data).then((result) => {

			let res = result['data'];

			this.setState({ ordersExecLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						ordersExecdata: res
					});
					this.getExecCount();
				}
			}
		});
	}
	
	getExecCount() {

		var data = this.state.ordersExecdata;

		var count = []; var names = []; var hours = [];
		
		for (var i = 0; i < data.length; i++) {
			
			if(data[i].date_execution){
							
				count.push(data[i].date_execution_count);
				names.push(data[i].date_execution);
				hours.push(data[i].worked_hours);
				
			}
			
		}

		this.setState({ execMonths: names, execValues: count,execHours: hours });
	}
	
	async getmaintainorderdata() {
		this.setState({ orderLoading: true });
		this.setState({ ordersdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.order';
		var groupby = '["maintenance_type", "state"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ orderLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						ordersdata: res
					});
					this.getmaintainOrdersCount();
				}
			}
		});
	}
	
	async getmaintainorderdatadate(firstday,lastday) {
		this.setState({ orderLoading: true });
		this.setState({ ordersdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.order';
		var groupby = '["maintenance_type", "state"]';
		var domain = '"&",["create_date",">=","'+firstday+'"],["create_date","<=","'+lastday+'"]'
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getstatsdynam',data).then((result) => {

			let res = result['data'];

			this.setState({ orderLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						ordersdata: res
					});
					this.getmaintainOrdersCount();
				}
			}
		});
	}
	
	getmaintainOrderStateCount(status) {

		var data = this.state.ordersdata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'open') {
				if ((((data[i].state == 'released') || (data[i].state == 'ready'))))
					result += data[i].__count;
			}
			else if (status == 'progress') {
				if ((((data[i].state == 'assigned') || (data[i].state == 'in_progress') || (data[i].state == 'pause'))))
					result += data[i].__count;
			}
			else if (status == 'done') {
				if (((data[i].state == 'done')))
					result += data[i].__count;
			}
		}

		return result;
	}
	
	getmaintainOrdersCount() {

		var data = this.state.ordersdata;

		var pa = 0; var bm = 0; var pm = 0; var oc = 0;

		for (var i = 0; i < data.length; i++) {
			if (((data[i].maintenance_type == 'pr') || (data[i].maintenance_type == 'oc')))
				oc += data[i].__count;
			else if ((data[i].maintenance_type == 'bm'))
				bm += data[i].__count;
			else if ((data[i].maintenance_type == 'pm'))
				pm += data[i].__count;
			else if ((data[i].maintenance_type == 'pr'))
				pa += data[i].__count;

		}

		this.setState({ pacount: pa, bmcount: bm, pmcount: pm, occount: oc });
	}
	
	async getmaintainorderlatedata(firstday,lastday) {
		this.setState({ orderLateLoading: true });
		this.setState({ ordersLatedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.order';
		var groupby = '["maintenance_type", "state"]';
		var cdate = this.state.cdate;
		var domain = '"&",["create_date",">=","'+firstday+'"],["create_date","<=","'+lastday+'"],["date_scheduled","<=","' + lastday + '"],"&","&",["state","!=","draft"],["state","!=","done"],["state","!=","cancel"]';
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getdashbaordlatecharts', data).then((result) => {

			let res = result['data'];

			this.setState({ orderLateLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						ordersLatedata: res
					});
					        this.setState({left_style:'left_search_card'});
							this.setState({sticky_searchbar:'sticky_top_searchbar'});
							this.setState({topbar_buttons:'topbar_buttons'});
							this.setState({content_card:'content_top_card'});
							this.setState({isDataLoaded : true});
				}
			}
		});
	}
	
	getRefreshToken() {
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		console.log(reftoken);
		if (reftoken != null) {
			
			Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
				let res = result['data'];
				if (res) {
					
					Sanitize.setStorage('accessToken', res.access_token);
					Sanitize.setStorage('refreshToken', res.refresh_token);
					//this.setState({accTok:res.access_token,reTok:res.refresh_token});
					this.getTodayData();
				}
			}).catch(error => {
				//this.setState({ dataLoading: false });
				//console.log(error.response.status);

			});
		}

	}
	
	getmaintainOrderStateLateCountByState() {

		var data = this.state.ordersLatedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			result += data[i].__count;
		}

		return result;
	}
	
	newpercalculate(total, given) {
		var total = parseInt(total);
		var given = parseInt(given);
		var perc = "";
		if (isNaN(total) || isNaN(given)) {
			perc = 0;
		} else {
			perc = ((given / total) * 100);
		}

		perc = Math.floor(perc);

		return perc;
	}
	  
	  getSingleMetric(type){
		
		this.state.metricType = type;
		
		this.setState({metricType:type});
		
			if(type=='Workforce Effectiveness'){
				
				this.getOrdersByExecuted();
			}
		
	  }
	
	
	
	  directtoanother(){
		
		this.setState({isDirect:true});
		
	  }
	  
	  
	 
	render() {
		
		const { selectedOption } = this.state;
		
		var pacount = this.state.pacount;
		var bmcount = this.state.bmcount;
		var pmcount = this.state.pmcount;
		var occount = this.state.occount;

		var totpiecount = parseInt(pacount) + parseInt(bmcount) + parseInt(pmcount) + parseInt(occount);

		var paperc = this.newpercalculate(totpiecount, pacount);
		var bmperc = this.newpercalculate(totpiecount, bmcount);
		var pmperc = this.newpercalculate(totpiecount, pmcount);
		var ocperc = this.newpercalculate(totpiecount, occount);

		
	    var opencount = this.getmaintainOrderStateCount('open');
		var progresscount = this.getmaintainOrderStateCount('progress');
		var closedcount = this.getmaintainOrderStateCount('done');
		var lateordcount = this.getmaintainOrderStateLateCountByState();

		var totordcount = parseInt(opencount) + parseInt(progresscount) + parseInt(closedcount)

		var openperc = this.newpercalculate(totordcount, opencount);
		var progressperc = this.newpercalculate(totordcount, progresscount);
		var closedperc = this.newpercalculate(totordcount, closedcount);
		var lateperc = this.newpercalculate(totordcount, lateordcount);
		
		const dougnoutoptins1 = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},
			labels: ['Open', 'Inprogress', 'Completed'],
			colors: [
				'#ff6666',
				'#80d4ff',
				'#b4e34f'
			],
			hover: [
				'#ff6666',
				'#80d4ff',
				'#b4e34f'
			]

		};
		
       const piechartseries1 = [opencount, progresscount, closedcount];
		const piechartseries = [bmcount, pmcount, occount, pacount];

		const dougnoutoptins = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},

			labels: ['Reactive', 'Preventive', 'On-Condition', 'Predictive'],
			colors: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			],
			hover: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			]

		};
		
		const areachartoptions = {
            chart: {
              stacked: false,
              zoom: {
                type: 'x',
                enabled: true
              },
              toolbar: {
                autoSelected: 'zoom'
              }
            },
            plotOptions: {
              line: {
                curve: 'smooth',
              }
            },
            dataLabels: {
              enabled: false
            },

            markers: {
              size: 0,
              style: 'full',
            },
          
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              title: {
                text: 'Values'
              },
			  type: 'time',
			  categories : this.state.execHours
            },
            xaxis: {
              type: 'month',
			  categories : this.state.execMonths
            }
          };
		  
	
          const areachartseries =  [{
            name: "Workorders",
            data: this.state.execValues
          }];
		  
		  const barchartseries1 = [{
			name: 'Workorders',
			data: this.state.execValues
		}];
		
		const barchartoptions1 = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: this.state.execMonths
			},

		};
	  
	  if(this.state.isDirect && this.props.location.state.modelkeyid=='functional_metrics'){
		 
		
			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid:'sites',site_id: this.props.location.state.uid,site_name:this.props.location.state.value,type : '13'}
			}}
			/>);

	  }
	  
	  if(this.state.isDirect && this.props.location.state.modelkeyid=='functional_metrics_single'){
		 
		
			return (<Redirect to={{
				pathname: '/metricslist',
				state: { modelkeyid:'sites',site_id: this.props.location.state.uid,site_name:this.props.location.state.value}
			}}
			/>);

	  }
	  
	 
		  
        return (
            <Fragment>
			<Helmet>
					<title>{GC.APPTITLE} | Functional Matrics </title>
				</Helmet>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
					
					 <div className="topbar-header">
				       <div className="topbar-header__content">	
					   
					   <PageTitleAlt3
							heading=""
							subheading=""
							icon=""
							menuLevel1={this.props.location.state  ? this.props.location.state.value : 'Functional Matrics' }
							menuLevel2="Functional Matrics"
							menuLevel1click = { () => this.props.location.state ? this.directtoanother() : ''}
						    menuLevel1link = {this.props.location.state ? 'javascript:;' : false} 
							
							isbreadcumb={true}
						/>
                  
					                 <div className="topbar_buttons">
												<div>
												 {this.state.metricType == 'Work Management' ? 
											      <DateRangePicker
														  value={this.state.daterange}
														  placeholder="Pick a range"
														  format="yyyy-MM-dd"
														  align="right"
														  ref={e=>this.daterangepicker2 = e}
														  onChange={(date) => { this.onDateChange(date) }}
														  shortcuts={[{
															text: 'Last week',
															onClick: ()=> {
															  this.setFilterType('Last_Week')
															  this.daterangepicker2.togglePickerVisible()
															}
														  }, {
															text: 'Last month',
															onClick: ()=> {
															  this.setFilterType('Last_Month')
															  this.daterangepicker2.togglePickerVisible()
															}
														  }, {
															text: 'Last 3 months',
															onClick: ()=> {
															  this.setFilterType('Last_3_Months')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },{
														  text: 'This Week',
															onClick: ()=> {
															  this.setFilterType('This Week')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },
														  {
														  text: 'This Month',
															onClick: ()=> {
															  this.setFilterType('This Month')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },
														  {
														  text: 'Today',
															onClick: ()=> {
															  this.setFilterType('Today')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  }
														  ]}
														  />
												 : '' }
													</div>
							            </div>
						</div>
					</div>
					
					
					  {!this.state.isDataLoaded ?
					
							 <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
					    :
			 			
				          <div className="main_data">
					           <div className={this.state.sticky_searchbar} style={{borderBottom:'1px solid rgb(209, 219, 229)'}}> 
										   
										<h5 style={{textAlign: 'center',verticalAlign: 'middle',marginTop: '5px',color:'#545cd8'}}><i className="lnr-highlight font-size-sm"/> &nbsp;Functional Metrics </h5>	   
											
								</div>
										
										 
												
											
				                <div className={this.state.left_style} id="left_search_card_id" style={{marginTop: '58px'}}>
							      
										
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Work Management')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Work Management' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Work Management" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>Work Management</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Workforce Effectiveness')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Workforce Effectiveness' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Workforce Effectiveness" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>Workforce Effectiveness</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Labour Efficiency')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Labour Efficiency' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Labour Efficiency" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>Labour Efficiency</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('FM Learning')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'FM Learning' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="FM Learning" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>FM Learning</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
										
												<br/><br/><br/><br/><br/><br/><br/><br/>
												
							   </div>
						
						
				<div className={this.state.content_card}>
				
					 <div>
					 {this.state.metricType=='Work Management' ?
					 <div>
						<Card className="mb-2" style={{ marginBottom: '7px' }}>
							<CardHeader className="card-header-tab z-index-6">
								<div className="card-title text-uppercase">

									{locale.t('el.dashboard.workPerform')}
								</div>
								
							</CardHeader>
							{this.state.orderLoading ?
								<div> <center><Loader type="ball-clip-rotate" /></center> </div>
								:

								opencount != 0 || progresscount != 0 || closedcount != 0 ?
									<Row className="no-gutters">
										<Col sm="6" md="3" xl="3">
											
												<div className="card  shadow bg-transparent widget-chart text-left">
													<div className="icon-wrapper rounded-circle">
														<div className="icon-wrapper-bg opacity-9 bg-danger" />
														<i className="lnr-enter text-white" />
													</div>
													<div className="widget-chart-content">
														<div className="widget-title opacity-5 text-uppercase">
															Open
                                                         </div>
														<div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
															<div className="widget-chart-flex align-items-center">
																<div className="widget-numbers text-danger">
																	<CountUp start={0}
																		end={opencount}
																		separator=""
																		decimals={0}
																		decimal=","
																		prefix=""
																		useEasing={false}
																		suffix=""
																		duration="1" />
																</div>
																<div className="">
																	<Circle
																		animate={true} // Boolean: Animated/Static progress
																		animationDuration="10s" // String: Length of animation
																		responsive={false} // Boolean: Make SVG adapt to parent size
																		size="46" // String: Defines the size of the circle.
																		lineWidth="30" // String: Defines the thickness of the circle's stroke.
																		progress={openperc} // String: Update to change the progress and percentage.
																		progressColor="var(--danger)" // String: Color of "progress" portion of circle.
																		bgColor="#ecedf0" // String: Color of "empty" portion of circle.
																		textColor="#6b778c" // String: Color of percentage text color.
																		textStyle={{
																			fontSize: '6rem' // CSSProperties: Custom styling for percentage.
																		}}
																		percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
																		roundedStroke={true} // Boolean: Rounded/Flat line ends
																		showPercentage={true} // Boolean: Show/hide percentage.
																		showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											
											<div className="divider m-0 d-md-none d-sm-block" />

										</Col>
										<Col sm="6" md="3" xl="3">
											
												<div className="card shadow bg-transparent widget-chart text-left">
													<div className="icon-wrapper rounded-circle">
														<div className="icon-wrapper-bg opacity-9 bg-warning" />
														<i className="lnr-calendar-full text-white" />
													</div>
													<div className="widget-chart-content">
														<div className="widget-title opacity-5 text-uppercase">
															Late
                                                         </div>
														<div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
															<div className="widget-chart-flex align-items-center">
																<div className="widget-numbers text-warning">
																	<CountUp start={0}
																		end={lateordcount}
																		separator=""
																		decimals={0}
																		decimal=","
																		prefix=""
																		useEasing={false}
																		suffix=""
																		duration="1" />
																</div>
																<div className="">
																	<Circle
																		animate={true} // Boolean: Animated/Static progress
																		animationDuration="10s" // String: Length of animation
																		responsive={false} // Boolean: Make SVG adapt to parent size
																		size="46" // String: Defines the size of the circle.
																		lineWidth="30" // String: Defines the thickness of the circle's stroke.
																		progress={lateperc} // String: Update to change the progress and percentage.
																		progressColor="var(--warning)" // String: Color of "progress" portion of circle.
																		bgColor="#ecedf0" // String: Color of "empty" portion of circle.
																		textColor="#6b778c" // String: Color of percentage text color.
																		textStyle={{
																			fontSize: '6rem' // CSSProperties: Custom styling for percentage.
																		}}
																		percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
																		roundedStroke={true} // Boolean: Rounded/Flat line ends
																		showPercentage={true} // Boolean: Show/hide percentage.
																		showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											
											<div className="divider m-0 d-md-none d-sm-block" />
										</Col>
										<Col sm="6" md="3" xl="3">
											
												<div className="card  shadow bg-transparent widget-chart text-left">
													<div className="icon-wrapper rounded-circle">
														<div className="icon-wrapper-bg opacity-9 bg-info" />
														<i className="lnr-pencil text-white" />
													</div>
													<div className="widget-chart-content">
														<div className="widget-title opacity-5 text-uppercase">
															Inprogress
                                                         </div>
														<div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
															<div className="widget-chart-flex align-items-center">
																<div className="widget-numbers text-info">
																	<CountUp start={0}
																		end={progresscount}
																		separator=""
																		decimals={0}
																		decimal=","
																		prefix=""
																		useEasing={false}
																		suffix=""
																		duration="1" />
																</div>
																<div className="">
																	<Circle
																		animate={true} // Boolean: Animated/Static progress
																		animationDuration="10s" // String: Length of animation
																		responsive={false} // Boolean: Make SVG adapt to parent size
																		size="46" // String: Defines the size of the circle.
																		lineWidth="30" // String: Defines the thickness of the circle's stroke.
																		progress={progressperc} // String: Update to change the progress and percentage.
																		progressColor="var(--info)" // String: Color of "progress" portion of circle.
																		bgColor="#ecedf0" // String: Color of "empty" portion of circle.
																		textColor="#6b778c" // String: Color of percentage text color.
																		textStyle={{
																			fontSize: '6rem' // CSSProperties: Custom styling for percentage.
																		}}
																		percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
																		roundedStroke={true} // Boolean: Rounded/Flat line ends
																		showPercentage={true} // Boolean: Show/hide percentage.
																		showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											
											<div className="divider m-0 d-md-none d-sm-block" />
										</Col>
										<Col sm="6" md="3" xl="3">
											
												<div className="card shadow bg-transparent widget-chart text-left">
													<div className="icon-wrapper rounded-circle">
														<div className="icon-wrapper-bg opacity-9 bg-success" />
														<i className="lnr-checkmark-circle text-white" />
													</div>
													<div className="widget-chart-content">
														<div className="widget-title opacity-5 text-uppercase">
															Completed
                                                            </div>
														<div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
															<div className="widget-chart-flex align-items-center">
																<div className="widget-numbers text-success">
																	<CountUp start={0}
																		end={closedcount}
																		separator=""
																		decimals={0}
																		decimal=","
																		prefix=""
																		useEasing={false}
																		suffix=""
																		duration="1" />
																</div>
																<div className="">
																	<Circle
																		animate={true} // Boolean: Animated/Static progress
																		animationDuration="10s" // String: Length of animation
																		responsive={false} // Boolean: Make SVG adapt to parent size
																		size="46" // String: Defines the size of the circle.
																		lineWidth="30" // String: Defines the thickness of the circle's stroke.
																		progress={closedperc} // String: Update to change the progress and percentage.
																		progressColor="var(--success)" // String: Color of "progress" portion of circle.
																		bgColor="#ecedf0" // String: Color of "empty" portion of circle.
																		textColor="#6b778c" // String: Color of percentage text color.
																		textStyle={{
																			fontSize: '6rem' // CSSProperties: Custom styling for percentage.
																		}}
																		percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
																		roundedStroke={true} // Boolean: Rounded/Flat line ends
																		showPercentage={true} // Boolean: Show/hide percentage.
																		showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											
											<div className="divider m-0 d-md-none d-sm-block" />
										</Col>
									</Row>

									:

									<div className="text-center col-md-12"><img src={nodata} width="150" height="150" /> <br /><b>No Data Found..</b></div>


							}

						</Card>
						<Row>
							<Col lg="6" sm="12" xs="12" className="custom_col_left" >

								<Card className="widget-chart mb-2" style={{height: '100%' }}>
									<div className="widget-chat-wrapper-outer">
										<div className="widget-chart-content">

											<div className="text-left">
												<span className="card-title text-uppercase"> {locale.t('el.dashboard.workStatus')}  </span>
												{this.getmaintainOrderStateLateCountByState() != 0 ? <span style={{ textTransform: 'capitalize' }} className="pull-right text-warning">Late <b>{this.getmaintainOrderStateLateCountByState()}</b></span> : ''}
											</div>
											{this.state.orderLoading ?
												<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
												:

												<div className="text-left" >
													{opencount == 0 && progresscount == 0 && closedcount == 0 ?

														<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

														:
														<div className="pie" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
															<Chart options={dougnoutoptins1} series={piechartseries1} type="pie" id="apexchart-example-3" width="100%" />

														</div>
													}
												</div>
											}
										</div>
									</div>
								</Card>
							</Col>

							<Col lg="6" sm="12" xs="12" className="custom_col_right" >

								<Card className="widget-chart mb-2" style={{height: '100%' }} >
									<div className="widget-chat-wrapper-outer">
										<div className="widget-chart-content">
											<div className="card-title text-uppercase text-left">
												{locale.t('el.dashboard.workType')}
											</div>
											{this.state.orderLoading ?
												<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
												:

												<div className="text-left">
													{pmcount == 0 && bmcount == 0 && occount == 0 && pacount == 0 ?

														<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

														:
														<div className="pie" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
															<Chart options={dougnoutoptins} series={piechartseries} type="pie" id="apexchart-example-3" width="100%" />

														</div>
													}
												</div>
											}
										</div>
									</div>
								</Card>
							</Col>
						</Row>
					</div>
					  : this.state.metricType=='Workforce Effectiveness' ?
					  
					   <Card className="widget-chart mb-2" style={{height: '100%' }}>
										<div className="widget-chat-wrapper-outer">
											<div className="widget-chart-content">

												<div className="text-left">
													<span className="card-title text-uppercase"> Workorders Execution (Month Wise) </span>

												</div>
												{this.state.ordersExecLoading ?
													<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
													:

													<div className="text-left" >
														{this.state.ordersExecdata.length==0 ?

															<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

															:
															<div className="bar" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
																<Chart options={barchartoptions1} series={barchartseries1} type="bar" id="apexchart-example-3" width="100%" />

															</div>
														}
													</div>
												}
											</div>
										</div>
									</Card>
									
						:
					  
							  <Card className="main-card mb-2 custom_card_view">
							
								   <CardBody>
								        <div className="text-center"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>
								   </CardBody>
								</Card>
	                   }

					</div>
					  </div>
					 </div>
					}
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}
