import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PageTitleAlt3 from '../../../Layout/AppMain/PageTitleAlt3';
import {Helmet} from 'react-helmet';
import Tabs, {TabPane} from 'rc-tabs';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import CountUp from 'react-countup';
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst'
import * as GC from '../../../tie/GlobalConstants';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import Loader from 'react-loaders';
import Circle from 'react-circle';
import {
    AreaChart, Area, LineChart, Line,
    ResponsiveContainer,
} from 'recharts';

import {
    faAngleDown,
	faAngleUp,
    faCalendarAlt,
    faEllipsisH,
    faCheck,
    faTrashAlt

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';



import {
    Row, Col,
    Button,
    Container,
    CardHeader,
    Card,
    CardBody,
    Progress,
    ButtonGroup,
    ListGroup,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
    UncontrolledButtonDropdown
} from 'reactstrap';

// Examples
import Select from 'react-select';
import Ionicon from 'react-ionicons';

const bordstyle = {borderRight: '2px solid #a2b0ee'};
const loaddiv = {marginTop: '155px' }
const options = [
    {value: '1', label: 'Today'},
    {value: '2', label: 'Last Week'},
    {value: '3', label: 'Last 30 Days'},
    {value: '4', label: 'Last 3 Months'},
    {value: '5', label: 'Last Year'},
];

const data123 = [
    {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
    {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
    {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
    {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
    {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
    {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
    {name: 'Page C', uv: 2000, pv: 6800, amt: 2290},
    {name: 'Page D', uv: 4780, pv: 7908, amt: 2000},
    {name: 'Page E', uv: 2890, pv: 9800, amt: 2181},
    {name: 'Page F', uv: 1390, pv: 3800, amt: 1500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
];


const data2 = [
    {name: 'Page A', uv: 5400, pv: 5240, amt: 1240},
    {name: 'Page B', uv: 7300, pv: 4139, amt: 3221},
    {name: 'Page C', uv: 8200, pv: 7980, amt: 5229},
    {name: 'Page D', uv: 6278, pv: 4390, amt: 3200},
    {name: 'Page E', uv: 3189, pv: 7480, amt: 6218},
    {name: 'Page D', uv: 9478, pv: 6790, amt: 2200},
    {name: 'Page E', uv: 1289, pv: 1980, amt: 7218},
    {name: 'Page F', uv: 3139, pv: 2380, amt: 5150},
    {name: 'Page G', uv: 5349, pv: 3430, amt: 3210},
];


export default class AnalyticsDashboard extends Component {
	constructor(props) {
        super(props);

        this.state = {
			dashdata : [],
			accTok : Sanitize.getStoreageItem('accessToken'),
			reTok : Sanitize.getStoreageItem('refreshToken'),
			dataLoading : false,
			cdate : (new Date().getFullYear())+'-'+(this.addzero(new Date().getMonth()+1))+'-'+(this.addzero(new Date().getDate()))+' '+(this.addzero(new Date().getHours()))+':'+(this.addzero(new Date().getMinutes()))+':'+(this.addzero(new Date().getSeconds())),
			newcount : 0,
			late : 0,
			inprogress : 0,
			completed : 0,
			total : 0,
			newper : 0,
			_isMounted : false,
			proper : 0,
			comper : 0,
			lateper : 0
        };
	  this.newpercalculate = this.newpercalculate.bind(this);
	  this.latepercalculate = this.latepercalculate.bind(this);
	  this.progpercalculate = this.progpercalculate.bind(this);
	  this.comperpercalculate = this.comperpercalculate.bind(this);
	  this.getRefreshToken = this.getRefreshToken.bind(this);
    }
	
	 state = {
        selectedOption: null,
    };
	
	addzero(day){
				
				var temp = ''+day;
				if(temp.length == 1){

				temp = "0"+temp;
				return temp;
				}
				else{

				return temp;
				}

		}
		
   newpercalculate(total,given){
        var total = parseInt(total); 
        var given = parseInt(given);
        var perc="";
        if(isNaN(total) || isNaN(given)){
            perc=0;
           }else{
           perc = ((given/total) * 100);
           }
		   
		   perc = parseInt(perc);

        this.setState({newper:perc});
    }
	
	latepercalculate(total,given){
        var total = parseInt(total); 
        var given = parseInt(given);
        var perc="";
        if(isNaN(total) || isNaN(given)){
            perc=0;
           }else{
           perc = ((given/total) * 100);
           }
		   
		   perc = parseInt(perc);

        this.setState({lateper:perc});
    }
	
	progpercalculate(total,given){
        var total = parseInt(total); 
        var given = parseInt(given);
        var perc="";
        if(isNaN(total) || isNaN(given)){
            perc=0;
           }else{
           perc = ((given/total) * 100);
           }
		   
		   perc = parseInt(perc);

        this.setState({proper:perc});
    }
	
	comperpercalculate(total,given){
        var total = parseInt(total); 
        var given = parseInt(given);
        var perc="";
        if(isNaN(total) || isNaN(given)){
            perc=0;
           }else{
           perc = ((given/total) * 100);
           }
		   
		   perc = parseInt(perc);

        this.setState({comper:perc});
    }

		
	handleChange = (selectedOption) => {
        this.setState({selectedOption});
    };
	
	/* componentDidMount(){
		this.state._isMounted = true;
	 if (this.state._isMounted) {
		this.getdata();
	 }
		
	} */
	
   componentWillMount() {
       //this.state._isMounted = false;
	   this.getdata();
   }
	
	getdata(){
		this.setState({dataLoading:true});
		this.setState({newcount : 0,inprogress : 0,completed : 0,total:0,newper:0,comper:0,proper:0});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('readgroup?id='+token).then((result) => {
			
			let res = result['data'];
			
			if(res.code=='401'){
				
				this.getRefreshToken();
				
			}
			else{
			    this.setState({dataLoading:false});
				this.setState({newcount : res.New,inprogress : res.Inprogress,completed : res.Completed,total:res.Total});
				this.newpercalculate(res.Total,res.New);
				this.progpercalculate(res.Total,res.Inprogress);
				this.comperpercalculate(res.Total,res.Completed);
				this.getlatedata();
			}
		});
	}
	
	
	getlatedata(){
		this.setState({dataLoading:true});
		this.setState({late : 0,lateper:0});
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('readgrouplate/'+token+'/'+this.state.cdate).then((result) => {
			
			let res = result['data'];
			
			if(res.code=='401'){
				
				this.getRefreshToken();
				
			}
			else{
			    this.setState({dataLoading:false});
				this.setState({late : res.Late});
				this.latepercalculate(this.state.total,res.Late);
			}
		});
	}
	
	getRefreshToken(){
		
		this.setState({dataLoading:true});
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id='+reftoken).then((result) => {
			let res = result['data'];
			if(res){
				this.setState({dataLoading:false});
				Sanitize.setStorage('accessToken',res.access_token);
				Sanitize.setStorage('refreshToken',res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.getdata();
				//this.getlatedata();

			}
		}).catch(error => {
				this.setState({dataLoading:false});
				  //console.log(error.response.status);
				 
			  });
		
	}
	
	
    render() {
		const {selectedOption} = this.state;
        return (
            <Fragment>
			<Helmet>
					<title>{GC.APPTITLE} | Dashboard </title>
				</Helmet>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <PageTitleAlt3
                        heading="Dashboard"
                        subheading=""
                        icon="pe-7s-graph icon-gradient bg-mean-fruit"
						menuLevel1=""
						menuLevel2=""
						menuLevel3=""
                    />
				{this.state.dataLoading ?	
				  <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
				: 
                  
				     <Card className="mb-3">
                        <CardHeader className="card-header-tab z-index-6">
                            <div
                                className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                <i className="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                                Work Orders Performance
                            </div>
                            <div className="btn-actions-pane-right text-capitalize">
                                    <span className="d-inline-block ml-2" style={{width: 200}}>
                                        <Select
                                            value={selectedOption}
                                            onChange={this.handleChange}
                                            options={options}
                                        />
                                    </span>
                            </div>
                        </CardHeader>
						
                        <Row className="no-gutters">
                            <Col sm="6" md="3" xl="3">
                                <div className="card  shadow bg-transparent widget-chart text-left">
								 <div className="icon-wrapper rounded-circle">
                                        <div className="icon-wrapper-bg opacity-9 bg-success"/>
                                        <i className="lnr-enter text-white"/>
                                    </div>
                                   <div className="widget-chart-content">
                                        <div className="widget-title opacity-5 text-uppercase">
                                           New
                                        </div>
                                        <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                            <div className="widget-chart-flex align-items-center">
                                                <div className="widget-numbers text-success">
                                                    <CountUp start={0}
                                                     end={this.state.newcount}
                                                     separator=""
                                                     decimals={0}
                                                     decimal=","
                                                     prefix=""
                                                     useEasing={false}
                                                     suffix=""
                                                     duration="1"/>
                                                 </div>
                                                <div className="">
                                                    <Circle
                                                        animate={true} // Boolean: Animated/Static progress
                                                        animationDuration="10s" // String: Length of animation
                                                        responsive={false} // Boolean: Make SVG adapt to parent size
                                                        size="46" // String: Defines the size of the circle.
                                                        lineWidth="30" // String: Defines the thickness of the circle's stroke.
                                                        progress={this.state.newper} // String: Update to change the progress and percentage.
                                                        progressColor="var(--success)" // String: Color of "progress" portion of circle.
                                                        bgColor="#ecedf0" // String: Color of "empty" portion of circle.
                                                        textColor="#6b778c" // String: Color of percentage text color.
                                                        textStyle={{
                                                            fontSize: '6rem' // CSSProperties: Custom styling for percentage.
                                                        }}
                                                        percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
                                                        roundedStroke={true} // Boolean: Rounded/Flat line ends
                                                        showPercentage={true} // Boolean: Show/hide percentage.
                                                        showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider m-0 d-md-none d-sm-block"/>
                            </Col>
                            <Col sm="6" md="3" xl="3">
                                <div className="card shadow bg-transparent widget-chart text-left">
								 <div className="icon-wrapper rounded-circle">
                                        <div className="icon-wrapper-bg opacity-9 bg-danger"/>
                                        <i className="lnr-calendar-full text-white"/>
                                    </div>
                                   <div className="widget-chart-content">
                                        <div className="widget-title opacity-5 text-uppercase">
                                           Late
                                        </div>
                                        <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                            <div className="widget-chart-flex align-items-center">
                                                <div className="widget-numbers text-danger">
                                                    <CountUp start={0}
                                                     end={this.state.late}
                                                     separator=""
                                                     decimals={0}
                                                     decimal=","
                                                     prefix=""
                                                     useEasing={false}
                                                     suffix=""
                                                     duration="1"/>
                                                 </div>
                                                <div className="">
                                                    <Circle
                                                        animate={true} // Boolean: Animated/Static progress
                                                        animationDuration="10s" // String: Length of animation
                                                        responsive={false} // Boolean: Make SVG adapt to parent size
                                                        size="46" // String: Defines the size of the circle.
                                                        lineWidth="30" // String: Defines the thickness of the circle's stroke.
                                                        progress={this.state.lateper} // String: Update to change the progress and percentage.
                                                        progressColor="var(--danger)" // String: Color of "progress" portion of circle.
                                                        bgColor="#ecedf0" // String: Color of "empty" portion of circle.
                                                        textColor="#6b778c" // String: Color of percentage text color.
                                                        textStyle={{
                                                            fontSize: '6rem' // CSSProperties: Custom styling for percentage.
                                                        }}
                                                        percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
                                                        roundedStroke={true} // Boolean: Rounded/Flat line ends
                                                        showPercentage={true} // Boolean: Show/hide percentage.
                                                        showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider m-0 d-md-none d-sm-block"/>
                            </Col>
                            <Col sm="6" md="3" xl="3">
                                <div className="card  shadow bg-transparent widget-chart text-left">
								 <div className="icon-wrapper rounded-circle">
                                        <div className="icon-wrapper-bg opacity-9 bg-primary"/>
                                        <i className="lnr-pencil text-white"/>
                                    </div>
                                   <div className="widget-chart-content">
                                        <div className="widget-title opacity-5 text-uppercase">
                                           Inprogress
                                        </div>
                                        <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                            <div className="widget-chart-flex align-items-center">
                                                <div className="widget-numbers text-primary">
                                                    <CountUp start={0}
                                                     end={this.state.inprogress}
                                                     separator=""
                                                     decimals={0}
                                                     decimal=","
                                                     prefix=""
                                                     useEasing={false}
                                                     suffix=""
                                                     duration="1"/>
                                                 </div>
                                                <div className="">
                                                    <Circle
                                                        animate={true} // Boolean: Animated/Static progress
                                                        animationDuration="10s" // String: Length of animation
                                                        responsive={false} // Boolean: Make SVG adapt to parent size
                                                        size="46" // String: Defines the size of the circle.
                                                        lineWidth="30" // String: Defines the thickness of the circle's stroke.
                                                        progress={this.state.proper} // String: Update to change the progress and percentage.
                                                        progressColor="var(--primary)" // String: Color of "progress" portion of circle.
                                                        bgColor="#ecedf0" // String: Color of "empty" portion of circle.
                                                        textColor="#6b778c" // String: Color of percentage text color.
                                                        textStyle={{
                                                            fontSize: '6rem' // CSSProperties: Custom styling for percentage.
                                                        }}
                                                        percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
                                                        roundedStroke={true} // Boolean: Rounded/Flat line ends
                                                        showPercentage={true} // Boolean: Show/hide percentage.
                                                        showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider m-0 d-md-none d-sm-block"/>
                            </Col>
							<Col sm="6" md="3" xl="3">
                                <div className="card shadow bg-transparent widget-chart text-left">
								 <div className="icon-wrapper rounded-circle">
                                        <div className="icon-wrapper-bg opacity-9 bg-info"/>
                                        <i className="lnr-checkmark-circle text-white"/>
                                    </div>
                                   <div className="widget-chart-content">
                                        <div className="widget-title opacity-5 text-uppercase">
                                           Completed
                                        </div>
                                        <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                            <div className="widget-chart-flex align-items-center">
                                                <div className="widget-numbers text-info">
                                                    <CountUp start={0}
                                                     end={this.state.completed}
                                                     separator=""
                                                     decimals={0}
                                                     decimal=","
                                                     prefix=""
                                                     useEasing={false}
                                                     suffix=""
                                                     duration="1"/>
                                                 </div>
                                                <div className="">
                                                    <Circle
                                                        animate={true} // Boolean: Animated/Static progress
                                                        animationDuration="10s" // String: Length of animation
                                                        responsive={false} // Boolean: Make SVG adapt to parent size
                                                        size="46" // String: Defines the size of the circle.
                                                        lineWidth="30" // String: Defines the thickness of the circle's stroke.
                                                        progress={this.state.comper} // String: Update to change the progress and percentage.
                                                        progressColor="var(--info)" // String: Color of "progress" portion of circle.
                                                        bgColor="#ecedf0" // String: Color of "empty" portion of circle.
                                                        textColor="#6b778c" // String: Color of percentage text color.
                                                        textStyle={{
                                                            fontSize: '6rem' // CSSProperties: Custom styling for percentage.
                                                        }}
                                                        percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
                                                        roundedStroke={true} // Boolean: Rounded/Flat line ends
                                                        showPercentage={true} // Boolean: Show/hide percentage.
                                                        showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider m-0 d-md-none d-sm-block"/>
                            </Col>
                        </Row>
                       
                    </Card>
					 }
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}
