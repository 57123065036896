import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import {Helmet} from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import {Redirect,Link} from 'react-router-dom';
import classnames from 'classnames';
import preventive_img from '../../assets/utils/images/Preventive_black.png';
import oc_img from '../../assets/utils/images/On Condition_black.png';
import corrective_img from '../../assets/utils/images/Corrective_black.png';
import periodic_img from '../../assets/utils/images/Periodic_black.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import {Doughnut} from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import ReactTooltip from 'react-tooltip';

import {
    faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
    Row, Col,
	Alert,
    Container,TabContent,TabPane,
	Card,CardBody,CardHeader,
	Table,
    ButtonGroup,
	Modal,ModalHeader,ModalBody,ModalFooter,
    ListGroup,FormGroup,Label,FormText,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import {Loading,Tabs,Tree,Select,Dropdown,Button,Progress,Tooltip,Tag,Dialog,DateRangePicker} from 'element-react';

const cardstyles = {borderRight: '1px solid #545cd8'}

const loaddiv = {marginTop: '155px' }

export default class Analytics extends Component {
	constructor(props) {
        super(props);

        this.state = {
			accTok : Sanitize.getStoreageItem('accessToken'),
			reTok : Sanitize.getStoreageItem('refreshToken'),
			chartName : '',
			ChartTimeseries : false,
			chart_dates : [],
			chart_values : [],
			chartType : '',
			filtertype : 'Today',
			daterange : this.getTodays(),
			chartData : [],
			chartcategory : 'area',
			queryText : ',["create_date", ">", "'+this.getYesterday()+'"],["create_date", "<=", "'+this.getdateoforginal(new Date())+'"]',
			chartLoading : false,
			isDirect : false,
			gauageassets : [],
			datafrom : '',
			meterassets : [],
			lc_id : '',
			chartId : '',
			isDataLoaded : false,
			
			
        };
			this.getdatesofmonth = this.getdatesofmonth.bind(this);
			this.onDateChange = this.onDateChange.bind(this);
		    this.setFilterType = this.setFilterType.bind(this);
			this.setChartCategory = this.setChartCategory.bind(this);
	
    }
	
	
	componentDidMount(){
		
		if(this.props.location.state){
			
		   this.getdata();
		  
		}
	
	}
	
	getdata(){
		
		if(this.props.location.state.modelkeyid=='space_analytics'){
			
			var id = this.props.location.state.chartId;
			var name = this.props.location.state.chartName;
			var type = this.props.location.state.chartType;
	
			var gauageassets = this.props.location.state.gauageassets;
			
			var meterassets = this.props.location.state.meterassets;
			var spaceid = this.props.location.state.uid;
	
			this.setState({meterassets:meterassets,gauageassets:gauageassets,datafrom:this.props.location.state.modelkeyid});
			
			this.onGetLogsChart(id,name,type,spaceid);
			
		}
		else if(this.props.location.state.modelkeyid=='equip_analytics'){
			
			
			var id = this.props.location.state.chartId;
			var name = this.props.location.state.chartName;
			var type = this.props.location.state.chartType;
	
			var gauageassets = this.props.location.state.gauageassets;
			
			var meterassets = this.props.location.state.meterassets;
			var spaceid = this.props.location.state.uid;
	
			this.setState({meterassets:meterassets,gauageassets:gauageassets,datafrom:this.props.location.state.modelkeyid});
			
			this.onGetLogsChart(id,name,type,spaceid);
			
		}
		
	}
	
	
	 setFilterType(column){
		 
		 this.setState({queryText:''});
		 this.setState({filtertype:''});
		 this.state.queryText = '';
		  
		 this.setState({filtertype:column});
				
		 if(column=='This Month'){
			
			this.getdatesofmonth();
		 }
		 else if(column=='Today'){
			 
			 this.getTodayData();
			 
		 }
		 else if(column=='This Week'){
			 
			 this.getdatesofweek();
			 
		 } 
		 else if(column=='Last_Week'){
			 
			 this.getDateSeries(column);
			 
		 }
		 else if(column=='Last_Month'){
			 
			 this.getDateSeries(column);
			 
		 }
        else if(column=='Last_3_Months'){
			 
			 this.getDateSeries(column);
			 
		 }		 
		 else{
			 
			 this.onGetLogsChart(this.state.chartId,this.state.chartName,this.state.chartType,this.state.lc_id);
			 
		 }
		  
	  }
	  
	  
	  getDateSeries(series) { 
			 
				this.setState({queryText:''});
				
				var daycount = 7;
				
				if(series=='Last_Week'){
					
					daycount = 7;
				}
				else if(series=='Last_Month'){
					
					daycount = 30;
				}
				else if(series=='Last_3_Months'){
					
					daycount = 90;
				}
				
                var lastDay = new Date();
				var firstDay = new Date();
			    firstDay.setTime(firstDay.getTime() - 3600 * 1000 * 24 * daycount);	
				
				var daterange = [];
		
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
						
					var queryText = ',["create_date", ">=", "'+firstDay+'"],["create_date", "<", "'+lastDay+'"]'
                          
					this.setState({queryText:queryText});
					this.state.queryText = queryText;
					
					var type = this.state.chartType;
					var id = this.state.chartId;
					
					if(type=='gauge'){
						
						this.getgaugesdata(id,this.state.lc_id);
					}
					else{
	
						this.getmetersdata(id,this.state.lc_id);
						
					}
      } 
	  
	  
	  setChartCategory(cat){
		 
		 this.setState({chartcategory:cat});
		 this.state.chartcategory = cat;
		  this.onGetLogsChart(this.state.chartId,this.state.chartName,this.state.chartType,this.state.lc_id);
	
	  }
	  
	  daysInMonth (month, year) { 
          return new Date(year, month, 0).getDate(); 
     }        
    
	getdatesofmonth() { 
			 
				this.setState({queryText:''});
				
                var date = new Date(); 
                var firstDay = new Date(date.getFullYear(), 
                                date.getMonth(), 1); 
                                  
                var lastDay = new Date(date.getFullYear(), 
                        date.getMonth(), this.daysInMonth(date.getMonth()+1, 
                        date.getFullYear())); 
						
				var daterange = [];
		
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
						
					var queryText = ',["create_date", ">=", "'+firstDay+'"],["create_date", "<", "'+lastDay+'"]'
                          
					this.setState({queryText:queryText});
					this.state.queryText = queryText;
					
					var type = this.state.chartType;
					var id = this.state.chartId;
					
					if(type=='gauge'){
						
						this.getgaugesdata(id,this.state.lc_id);
					}
					else{
	
						this.getmetersdata(id,this.state.lc_id);
						
					}
      } 
	  
	  
	  onDateChange(date) { 
			 
			 this.setState({queryText:''});
			 
			 this.setState({daterange:date});
			 
			 var firstDay = date[0];
			 
			 var lastDay = date[1];
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
						
					var queryText = ',["create_date", ">=", "'+firstDay+'"],["create_date", "<", "'+lastDay+'"]'
                          
					this.setState({queryText:queryText});
					this.state.queryText = queryText;
					
					var type = this.state.chartType;
					var id = this.state.chartId;
					
					if(type=='gauge'){
						
						this.getgaugesdata(id,this.state.lc_id);
					}
					else{
	
						this.getmetersdata(id,this.state.lc_id);
						
					}
					
      } 
	  
	  
	  getdatesofweek() { 
			 
				this.setState({queryText:''});
				
               var curr = new Date; // get current date
				var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
				var last = first + 6; // last day is the first day + 6

				var firstDay = new Date(curr.setDate(first)).toUTCString();
				var lastDay = new Date(curr.setDate(last)).toUTCString();
				
				var daterange = [];
				
				var firstDay1 = new Date(curr.setDate(first));
				var lastDay1 = new Date(curr.setDate(last));
				daterange.push(firstDay1);
				daterange.push(lastDay1);
				
				this.setState({daterange:daterange});
				
		
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
						
					var queryText = ',["create_date", ">=", "'+firstDay+'"],["create_date", "<", "'+lastDay+'"]'
                          
					this.setState({queryText:queryText});
					this.state.queryText = queryText;
					
					var type = this.state.chartType;
					var id = this.state.chartId;
					
					if(type=='gauge'){
						
						this.getgaugesdata(id,this.state.lc_id);
					}
					else{
	
						this.getmetersdata(id,this.state.lc_id);
						
					}
      } 
	  
	  
	   convertLoalTime(date_future){
		  
		  var date_future = new Date(date_future);
		  
		     var year = date_future.getFullYear();
			  var month = (date_future.getMonth()+1);
			  var day   = date_future.getDate()
		
			  var addhours = date_future.getHours()+5;
			  var mints = date_future.getMinutes();
			  var secs = date_future.getMinutes();
			  
			  if(addhours=='13'){
		
				  addhours = '01';
			  }
			  else if(addhours=='14'){
				  
				   addhours = '02';
				  
			  }
			  else if(addhours=='15'){
				  
				   addhours = '03';
				  
			  }
			  else if(addhours=='16'){
				  
				   addhours = '04';
				  
			  }
			  else if(addhours=='17'){
				  
				   addhours = '05';
				  
			  }
			  
			  return year+'-'+month+'-'+day+' '+addhours+':'+mints+':'+secs
		  
	  }
	  
	  
	   getdateofdttime(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			  var strTime = day + '/' + month + '/' + year;
			  
			  var hours = date1.getHours();
			  var minutes = date1.getMinutes();
			  var ampm = hours >= 12 ? 'pm' : 'am';
			  hours = hours % 12;
			  hours = hours ? hours : 12; // the hour '0' should be '12'
			  minutes = minutes < 10 ? '0'+minutes : minutes;
			  var strTime1 = hours + ':' + minutes + ' ' + ampm;
  
			  return strTime+' '+strTime1;
		 }
		 else{
			 
			 return '- - - -';
		 }
			
	  }
	  
	  getdateoftimings(date){
			
			var cdate = this.convertLoalTime(date);
			
			
			
	      var date_future = new Date(cdate);
		  
		     
	   
	      var date_now = new Date();
		  

		  // get total seconds between the times
			var delta = Math.abs(date_future - date_now) / 1000;

			// calculate (and subtract) whole days
			var days = Math.floor(delta / 86400);
			delta -= days * 86400;

			// calculate (and subtract) whole hours
			var hours = Math.floor(delta / 3600) % 24;
			delta -= hours * 3600;

			// calculate (and subtract) whole minutes
			var minutes = Math.floor(delta / 60) % 60;
			delta -= minutes * 60;
			
		    if(days!=0 || days>2){
				
				return this.getdateofdttime(date);
			}
			else if(days==0 && hours!=0){
				
				return hours+' hours ago';
				
			}
			else{
				
				return minutes+' minutes ago';
				
			} 
			
	   }
	   
	   getYesterday(){
		   
			   var d = new Date();
               var date = d.setDate(d.getDate() - 1);
			   
			   var yesterday = this.getdateoforginal(date);
			   
			   return yesterday;
		   
		   
	   }
	   
	    getTodays(){
		   
				var dates = [];
			   var d = new Date();
               var date = d.setDate(d.getDate() - 1);
			   
			   var yesterday = new Date(date);
			   
			  var today = new Date();
			  
			   dates.push(yesterday);
			   dates.push(today);
			   
			   console.log(dates);
			   
			   return dates;
		   
		   
	   }

	  
	  getTodayData(){
		  
		  this.setState({queryText:''});
		  
		     var date1 = new Date();
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			   var hours = date1.getHours();
			   var minutes = date1.getMinutes();
			   var seconds = date1.getSeconds();
			   month = month < 10 ? '0'+month : month;
			   day = day < 10 ? '0'+day : day;
			   hours = hours < 10 ? '0'+hours : hours;
			   minutes = minutes < 10 ? '0'+minutes : minutes;
			   seconds = seconds < 10 ? '0'+seconds : seconds;
			   
			   var d = new Date();
               var date = d.setDate(d.getDate() - 1);
			   
			   var yesterday = this.getdateoforginal(date);
			   
			  var today = year + '-' + month + '-' + day+' '+hours+':'+minutes+':'+seconds;
			  
			  var daterange = [];
				
				var firstDay = new Date(date);
				var lastDay = new Date();
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
	
		    var queryText = ',["create_date", ">", "'+yesterday+'"],["create_date", "<=", "'+today+'"]';
                          
					this.setState({queryText:queryText});
					this.state.queryText = queryText;
					
					var type = this.state.chartType;
					var id = this.state.chartId;
					
					if(type=='gauge'){
						
						this.getgaugesdata(id,this.state.lc_id);
					}
					else{
	
						this.getmetersdata(id,this.state.lc_id);
						
					}
		  
		  
		  
	  }
	
             
	getChartData(){

			var data = this.state.chartData;

			var dates= []; var values= [];

			for(var i=0;i<data.length;i++){
				
				if(data[i].mroord_count!=0){
					
					dates.push(data[i].create_date);
					values.push(data[i].value);
				}

			}

			this.setState({chart_dates:dates,chart_values:values});
	  }
	
	getgaugesdata(guageid,id){
		
		this.setState({chartLoading:true});
		this.setState({chartData : [],chart_dates : [],chart_values : []});
		var token = Sanitize.getStoreageItem('accessToken');
		
		console.log(id);
		//var id = this.state.lc_id;
		
		var queryText = this.state.queryText;
		
		var typeid = '';
		
		var datafrom = this.state.datafrom;
		
		if(datafrom=='space_analytics'){
			
			typeid = 'space_id';
		}
		else if(datafrom=='equip_analytics'){
			
			typeid = 'equipment_id';
		}
		
		var query = '"&",["'+typeid+'", "in", ['+id+']],["gauge_id","=",'+guageid+']'+queryText;
		
		 var data = {"token":token,"query":query};
			Reqst.axPost('gaugeslogs',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else if(result['data'].code == '500'){
				
					this.setState({ chartLoading: false });
					alert(result['data'].data);
			    }
				else{
					this.setState({chartLoading:false});
				    this.setState({chartData : res});
					this.getChartData();
				}
			});
	}
	
	getRefreshToken(){
		
		this.setState({chartLoading:true});
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id='+reftoken).then((result) => {
			let res = result['data'];
			if(res){
				this.setState({chartLoading:false});
				Sanitize.setStorage('accessToken',res.access_token);
				Sanitize.setStorage('refreshToken',res.refresh_token);
				
				this.onGetLogsChart(this.state.chartId,this.state.chartName,this.state.chartType,this.state.lc_id);
		
			}
		}).catch(error => {
				this.setState({chartLoading:false});
				  //console.log(error.response.status);
				 
			  });
		
	}
	
	getmetersdata(meterid,id){
		
		this.setState({chartLoading:true});
		this.setState({chartData : [],chart_dates : [],chart_values : []});
		var token = Sanitize.getStoreageItem('accessToken');
		
	//	var id = this.state.lc_id;
	
	console.log(id);
		
		var queryText = this.state.queryText;
		
		var typeid = '';
		
		var datafrom = this.state.datafrom;
		
		if(datafrom=='space_analytics'){
			
			typeid = 'space_id';
		}
		else if(datafrom=='equip_analytics'){
			
			typeid = 'equipment_id';
		}
		
		var query = '"&",["'+typeid+'", "in", ['+id+']],["meter_id","=",'+meterid+']'+queryText;
		
		 var data = {"token":token,"query":query};
			Reqst.axPost('meterlogs',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else if(result['data'].code == '500'){
				
					this.setState({ chartLoading: false });
					alert(result['data'].data);
			    }
				else{
					this.setState({chartLoading:false});
				    this.setState({chartData : res});
					this.getChartData();
				}
			});
	}
	
	onGetLogsChart(id,name,type,spaceid){
			//this.setState({queryText:''});
			//this.setState({filtertype:''});
			//this.state.queryText = ',["create_date", ">", "'+this.getYesterday()+'"],["create_date", "<=", "'+this.getdateoforginal(new Date())+'"]';
			//this.setState({daterange:this.getTodays()});
			//this.setState({queryText:',["create_date", ">", "'+this.getYesterday()+'"],["create_date", "<=", "'+this.getdateoforginal(new Date())+'"]'});
			//this.setState({chartcategory:'area'})
		    this.setState({ChartTimeseries:true});
			this.setState({chartName:name});
			this.setState({chartType:type});
			this.setState({chartId:id});
			this.setState({lc_id:spaceid});
			this.setState({left_style:'left_search_card'});
		    this.setState({sticky_searchbar:'sticky_top_searchbar'});
			this.setState({topbar_buttons:'topbar_buttons'});
			this.setState({content_card:'content_top_card'});
			this.setState({isDataLoaded : true});
			        
			
		
		if(type=='gauge'){
			
			this.getgaugesdata(id,spaceid);
			
		}
		else{
			
			this.getmetersdata(id,spaceid);
		}
		
	}
   
   
     getdateofdt(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			  var strTime = day + '/' + month + '/' + year;
			  return strTime;
		 }
		 else{
			 
			 return '- - - -';
		 }
			
	  }
	  
	  directtoanother(){
		
		this.setState({isDirect:true});
		
	}
	  
	  
	  getdateoforginal(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			   var hours = date1.getHours();
			   var minutes = date1.getMinutes();
			   var seconds = date1.getSeconds();
			   month = month < 10 ? '0'+month : month;
			   day = day < 10 ? '0'+day : day;
			   hours = hours < 10 ? '0'+hours : hours;
			   minutes = minutes < 10 ? '0'+minutes : minutes;
			   seconds = seconds < 10 ? '0'+seconds : seconds;
			   
			  var strTime = year + '-' + month + '-' + day+' '+hours+':'+minutes+':'+seconds;
			  return strTime;
		 }
		 else{
			 
			 return '- - - -';
		 }
			
	  }
	
	render() {
		
		
	if(this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid=='space_analytics'){
		 
		
			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid:'space_analytics',value: this.props.location.state.value,uid:this.props.location.state.uid,treenodes:this.props.location.state.treenodes }
			}}
			/>);

	  }
	  
	  if(this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid=='equip_analytics'){
		 
		
			return (<Redirect to={{
				pathname: '/assets/equipment/view/'+this.props.location.state.uid
			}}
			/>);

	  }
	  
	  const barchartoptions = {
                chart: {
                    id: 'apexchart-example-3'
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        vertical: true
                    }
                },
                xaxis: {
                    categories: this.state.chart_dates
                },
				  
            };
			
			const  barchartseries = [{
				 name: this.state.chartName,
                 data: this.state.chart_values
            }]
	  
	   const charttypes = [
		 {
		  value: 'area',
		  label: 'Area'
		},
	    {
		  value: 'pie',
		  label: 'Pie'
		},
		{
		  value: 'bar',
		  label: 'Bar'
		}
	 ];
		
	  const areachartoptions = {
            chart: {
              stacked: false,
              zoom: {
                type: 'x',
                enabled: true
              },
              toolbar: {
                autoSelected: 'zoom'
              }
            },
            plotOptions: {
              line: {
                curve: 'smooth',
              }
            },
            dataLabels: {
              enabled: false
            },

            markers: {
              size: 0,
              style: 'full',
            },
            //colors: ['#0165fc'],
            title: {
              text: this.state.chartName,
              align: 'left'
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              title: {
                text: 'Value'
              },
            },
            xaxis: {
              type: 'datetime',
			  categories : this.state.chart_dates
            }
          };
		  
	
          const areachartseries =  [{
            name: this.state.chartName,
            data: this.state.chart_values
          }];
		  
		  const  piechartseries = this.state.chart_values;
		  
		  const piechartpotions = {
				chart: {
                    id: 'apexchart-example-4',
					toolbar: {
				  show: true,
				  tools: {
					download: true,
					selection: true,
					zoom: true,
					zoomin: true,
					zoomout: true,
					pan: true,
					customIcons: []
				  },
				  autoSelected: 'zoom' 
                 },
                },
				dataLabels: {
                    enabled: true
                },
				legend: {
                 position: 'left'
                },
			   
                    labels: this.state.chart_dates,
					colors: [
					'#80d4ff',
					'#ef869e',
					'#ff8566',
					'#8dace7'
				],
				hover: [
				    '#80d4ff',
					'#ef869e',
					'#ff8566',
					'#8dace7'
				]
     
            };
		  
        return (
            <Fragment>
			<Helmet>
					<title>{GC.APPTITLE} | Analytics </title>
				</Helmet>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
					
					 <div className="topbar-header">
				       <div className="topbar-header__content">	
					   
					   <PageTitleAlt3
							heading=""
							subheading=""
							icon=""
							menuLevel1="Analytics"
							menuLevel2={this.state.chartName}
							
							menuLevel1={this.props.location.state  ? this.props.location.state.value : 'Analytics' }
							menuLevel2={this.props.location.state ? 'Analytics' : this.state.chartName }
							menuLevel3={this.props.location.state ? this.state.chartName : '' }
							menuLevel1click = { () => this.props.location.state ? this.directtoanother() : ''}
						    menuLevel1link = {this.props.location.state ? 'javascript:;' : false} 
							
							isbreadcumb={true}
						/>
                  
					              <div className="topbar_buttons">
												<div>
											      <DateRangePicker
														  value={this.state.daterange}
														  placeholder="Pick a range"
														  format="yyyy-MM-dd HH:mm:ss"
														  align="right"
														  ref={e=>this.daterangepicker2 = e}
														  onChange={(date) => { this.onDateChange(date) }}
														  shortcuts={[{
															text: 'Last week',
															onClick: ()=> {
															  this.setFilterType('Last_Week')
															  this.daterangepicker2.togglePickerVisible()
															}
														  }, {
															text: 'Last month',
															onClick: ()=> {
															  this.setFilterType('Last_Month')
															  this.daterangepicker2.togglePickerVisible()
															}
														  }, {
															text: 'Last 3 months',
															onClick: ()=> {
															  this.setFilterType('Last_3_Months')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },{
														  text: 'This Week',
															onClick: ()=> {
															  this.setFilterType('This Week')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },
														  {
														  text: 'This Month',
															onClick: ()=> {
															  this.setFilterType('This Month')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },
														  {
														  text: 'Today',
															onClick: ()=> {
															  this.setFilterType('Today')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  }
														  ]}
														  />
													</div>
													&nbsp;&nbsp;&nbsp;
													<div>	  
														<Select onChange={this.setChartCategory} value={this.state.chartcategory}>
																  {
																	charttypes.map(el => {
																	  return <Select.Option key={el.value} label={el.label} value={el.value} />
																	})
																  }
													  </Select>
													 </div>
							            </div>
								</div>
							</div>
					
					
					  {!this.state.isDataLoaded ?
					
							 <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
					    :
			 			
				          <div className="main_data">
					           <div className={this.state.sticky_searchbar} style={{borderBottom:'1px solid rgb(209, 219, 229)'}}> 
										   
										<h5 style={{textAlign: 'center',verticalAlign: 'middle',marginTop: '5px',color:'#545cd8'}}><i className="lnr-highlight font-size-sm"/> &nbsp;Readings </h5>	   
											
								</div>
										
										 
												
											
				                <div className={this.state.left_style} id="left_search_card_id" style={{marginTop: '58px'}}>
							      
										
												{this.state.gauageassets.map((item,index) =>
														<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.onGetLogsChart(item.gauge_id,item.name,'gauge',this.state.lc_id)}>
														<div style={{padding: '1px 5px'}} className={this.state.chartId == item.gauge_id && this.state.chartType == 'gauge' ? 'active_left_data' : ''}>
															<Tooltip className="item" content={item.name} placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>{item.name}</b></span>
															</Tooltip>
															<div className="nowrap_content">
																<span className="text-capitalize" style={{fontSize: '11px'}}>value : {item.value}&nbsp;</span>
																<span className="text-capitalize" style={{fontSize: '11px',whiteSpace:'nowrap'}}>|&nbsp;{this.getdateoftimings(item.last_update_date)}</span>
																
															</div>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
														</a>
												)}
												
												
												{this.state.meterassets.map((item1,index1) =>
														<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.onGetLogsChart(item1.meter_id,item1.name,'meter',this.state.lc_id)}>
														<div style={{padding: '1px 5px'}} className={this.state.chartId == item1.meter_id && this.state.chartType == 'meter' ? 'active_left_data' : ''}>
															<Tooltip className="item" content={item1.name} placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>{item1.name}</b></span>
															</Tooltip>
														<div className="nowrap_content">
															<span className="text-capitalize" style={{fontSize: '11px'}}>value : {item1.value}&nbsp;</span>
															<span className="text-capitalize" style={{fontSize: '11px',whiteSpace:'nowrap'}}>|&nbsp;{this.getdateoftimings(item1.last_update_date)}</span>
															
														</div>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
														</a>
												)}
												
												<br/><br/><br/><br/><br/><br/><br/><br/>
												
							   </div>
						
						
				<div className={this.state.content_card}>
					 
					 
					  
					   <div>
			
						   <Card className="main-card mb-2 custom_card_view">
						
							   <CardBody>
							   
							           
									{this.state.chartLoading ?
				                       <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
				                      :			
									   <div className="">
										{this.state.chartData.length>0 ?
											<div>
											   {this.state.chartcategory=='area' ?
																   
												 <div className="area">
													 <Chart options={areachartoptions} series={areachartseries} type="area"   width="100%"/>
																	
												 </div>
												 
												 : this.state.chartcategory=='pie' ?
												 
												 <div className="pie" style={{width: '100%',textAlign: 'center',marginLeft: 'auto',marginRight: 'auto'}}>
													 <Chart options={piechartpotions} series={piechartseries} type="pie" id="apexchart-example-4"   width="80%"/>
																	
												 </div>
												 
											   : 
											   
											           <div className="bar">
														  <Chart options={barchartoptions} series={barchartseries} type="bar" width="100%"/>
													    </div>
												 
											   }
												 
												 
												 
												 
												  
											 </div>
																
											:
															   
										   <div className="text-center"><img src={nodata} width="230" height="230"/> <br/><b>No Data Found..</b></div>
															   
															  
										}
									</div>
									 }
							   </CardBody>
						    </Card>
						 </div>
					  </div>
					 </div>
					}
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}
