import React, {Fragment} from 'react';
import cx from 'classnames';

import {connect} from 'react-redux';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo';

import SearchBox from './Components/SearchBox';
import MegaMenu from './Components/MegaMenu';
import UserBox from './Components/UserBox';
import HeaderRightDrawer from "./Components/HeaderRightDrawer";
import {withRouter,Redirect,Link} from 'react-router-dom';
import Sanitize from '../../tie/Sanitize';

import {
	Nav, NavItem, NavLink,
} from 'reactstrap';

import HeaderDots from "./Components/HeaderDots";

class Header extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			
			activeSubMenu : 'overview',
			isLink : true,
			subLink : '',
			activeMainMenu : 'Home'

		};
		
		this.onSubMenuChange = this.onSubMenuChange.bind(this);
		

	}
	
	componentDidMount(){
		
		var path = window.location.hash;
		
		console.log(path);
		
		if(path=='#/strategiccertifications'){
			
			this.setState({ activeSubMenu: 'strategic_certifications' });
		}
	
		
	}
	
	onSubMenuChange(tab) {

		this.setState({ activeSubMenu: tab });
		
	}
	
	
    render() {
		
		var path = this.props.location.pathname;
		
		console.log(path);
		
		if( Sanitize.getStoreageItem('isuser_login')!='yes'  || ( Sanitize.getStoreageItem('accessToken')=='' && Sanitize.getStoreageItem('refreshToken')==''))
		{
			return(<Redirect to="/login" />);
		}
		
		if(path == '/'){
			
			this.state.activeSubMenu = 'overview';
		}
		else if(path == '/strategiccertifications'){
			
			this.state.activeSubMenu = 'strategic_certifications';
		}
		else if(path == '/metricslist'){
			
			this.state.activeSubMenu = 'metrics';
		}
		else if(path == '/assets/spaces'){
			
			this.state.activeSubMenu = 'space';
		}
		else if(path == '/assets/equipments'){
			
			this.state.activeSubMenu = 'equipments';
		}
		else if(path == '/assets/equipment/add'){
			
			this.state.activeSubMenu = 'equipments';
		}
		else if(path == '/dashboard/tickets'){
			
			this.state.activeSubMenu = 'tic_dashboard';
		}
		else if(path == '/helpdesk/newticket'){
			
			this.state.activeSubMenu = 'add_ticket';
		}
		else if(path == '/helpdesk/tickets'){
			
			this.state.activeSubMenu = 'tickets';
		}
		else if(path == '/dashboard/workorders'){
			
			this.state.activeSubMenu = 'maintain_dashboard';
		}
		else if(path == '/helpdesk/workorders'){
			
			this.state.activeSubMenu = 'work_orders';
		}
		else if(path == '/maintenance/ppmviewer'){
			
			this.state.activeSubMenu = 'ppm_viewer';
		}
		else if(path == '/dashboard/humanresources'){
			
			this.state.activeSubMenu = 'hr_dashboard';
		}
		else if(path == '/hr/employees'){
			
			this.state.activeSubMenu = 'hr_employees';
		}
		else if(path == '/hr/shift'){
			
			this.state.activeSubMenu = 'hr_shift';
		}
		else if(path == '/hr/attendance'){
			
			this.state.activeSubMenu = 'hr_attendance';
		}
		else if(path == '/hr/team'){
			
			this.state.activeSubMenu = 'hr_team';
		}
		else if(path == '/inventory/parts'){
			
			this.state.activeSubMenu = 'inv_parts';
		}
		else if(path == '/inventory/tools'){
			
			this.state.activeSubMenu = 'inv_tools';
		}
		else if(path == '/vendors'){
			
			this.state.activeSubMenu = 'cont_vendors';
		}
		else if(path == '/customers'){
			
			this.state.activeSubMenu = 'cont_customers';
		}
		
		
		
		/* if (this.state.isLink && this.state.subLink!='') {

			return (<Redirect to={this.state.subLink} />);

		} */
		 
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;
        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>

                    <HeaderLogo/>

                    <div className={cx(
                        "app-header__content",
                        {'header-mobile-open': enableMobileMenuSmall},
                    )}>
                        <div className="app-header-left">
                                                
												 {path == '/' || path == '/strategiccertifications' || path == '/metricslist' ?
												  <Nav>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'overview' ? true : false} href="#/" onClick={() => this.onSubMenuChange('overview')} >Overview</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'strategic_certifications' ? true : false} href="#/strategiccertifications" onClick={() => this.onSubMenuChange('strategic_certifications')} >Strategic Certifications</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'metrics' ? true : false} href="#/metricslist" onClick={() => this.onSubMenuChange('metrics')} >Metrics</NavLink>
												</Nav>
												 : '' }
												 
												 {path == '/assets/spaces' || path == '/assets/equipments' || path == '/assets/equipment/add' ?
												  <Nav>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'space' ? true : false} href="#/assets/spaces" onClick={() => this.onSubMenuChange('space')} >Spaces</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'equipments' ? true : false} href="#/assets/equipments" onClick={() => this.onSubMenuChange('equipments')} >Equipments</NavLink>
												</Nav>
												 : '' }
												 
												 {path == '/dashboard/tickets' || path == '/helpdesk/newticket' ||  path == '/helpdesk/tickets' ?
												  <Nav>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'tic_dashboard' ? true : false} href="#/dashboard/tickets" onClick={() => this.onSubMenuChange('tic_dashboard')} >Dashboard</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'add_ticket' ? true : false} href="#/helpdesk/newticket" onClick={() => this.onSubMenuChange('add_ticket')} >Raise an Ticket</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'tickets' ? true : false} href="#/helpdesk/tickets" onClick={() => this.onSubMenuChange('tickets')} >Tickets</NavLink>
												</Nav>
												 : '' }
												 
												  {path == '/dashboard/workorders' || path == '/helpdesk/workorders' ||  path == '/maintenance/ppmviewer' ?
												  <Nav>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'maintain_dashboard' ? true : false} href="#/dashboard/workorders" onClick={() => this.onSubMenuChange('maintain_dashboard')} >Dashboard</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'work_orders' ? true : false} href="#/helpdesk/workorders" onClick={() => this.onSubMenuChange('work_orders')} >Workorders</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'ppm_viewer' ? true : false} href="#/maintenance/ppmviewer" onClick={() => this.onSubMenuChange('ppm_viewer')} >PPM Viewer</NavLink>
												</Nav>
												 : '' }
												 
												 {path == '/dashboard/tankers' || path == '/tankers/addtransaction' ||  path == '/tankers/list' ?
												  <Nav>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'tanker_dashboard' ? true : false} href="#/dashboard/tankers" onClick={() => this.onSubMenuChange('tanker_dashboard')} >Dashboard</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'tanker_transaction' ? true : false} href="#/tankers/addtransaction" onClick={() => this.onSubMenuChange('tanker_transaction')} >Add an Transaction</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'tankers' ? true : false} href="#/tankers/list" onClick={() => this.onSubMenuChange('tankers')} >Tankers</NavLink>
												</Nav>
												 : '' }
												 
												 {path == '/dashboard/humanresources' || path == '/hr/employees' ||  path == '/hr/shift' ||  path == '/hr/attendance' ||  path == '/hr/team' ?
												  <Nav>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'hr_dashboard' ? true : false} href="#/dashboard/humanresources" onClick={() => this.onSubMenuChange('hr_dashboard')} >Dashboard</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'hr_employees' ? true : false} href="#/hr/employees" onClick={() => this.onSubMenuChange('hr_employees')} >Employees</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'hr_shift' ? true : false} href="#/hr/shift" onClick={() => this.onSubMenuChange('hr_shift')} >Shift</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'hr_attendance' ? true : false} href="#/hr/attendance" onClick={() => this.onSubMenuChange('hr_attendance')} >Attendance</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'hr_team' ? true : false} href="#/hr/team" onClick={() => this.onSubMenuChange('hr_team')} >Team</NavLink>
												</Nav>
												 : '' }
												 
												 
												 {path == '/inventory/parts' || path == '/inventory/tools' ?
												  <Nav>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'inv_parts' ? true : false} href="#/inventory/parts" onClick={() => this.onSubMenuChange('inv_parts')} >Parts</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'inv_tools' ? true : false} href="#/inventory/tools" onClick={() => this.onSubMenuChange('inv_tools')} >Tools</NavLink>
													
												</Nav>
												 : '' }
												 
												  {path == '/vendors' || path == '/customers' ?
												  <Nav>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'cont_vendors' ? true : false} href="#/vendors" onClick={() => this.onSubMenuChange('cont_vendors')} >Vendors</NavLink>
													<NavLink className="header_tab_nav_link" active={this.state.activeSubMenu == 'cont_customers' ? true : false} href="#/customers" onClick={() => this.onSubMenuChange('cont_customers')} >Customers</NavLink>
													
												</Nav>
												 : '' }
							
												
                        </div>
                        <div className="app-header-right">
                            <HeaderDots/>
                            <UserBox/>
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter (Header));