import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import {Helmet} from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import {Redirect,Link} from 'react-router-dom';
import classnames from 'classnames';
import preventive_img from '../../assets/utils/images/Preventive_black.png';
import oc_img from '../../assets/utils/images/On Condition_black.png';
import corrective_img from '../../assets/utils/images/Corrective_black.png';
import periodic_img from '../../assets/utils/images/Periodic_black.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import {Doughnut} from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import ReactTooltip from 'react-tooltip';

import {
    faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
    Row, Col,
	Alert,
    Container,TabContent,TabPane,
	Card,CardBody,CardHeader,
	Table,
    ButtonGroup,
	Modal,ModalHeader,ModalBody,ModalFooter,
    ListGroup,FormGroup,Label,FormText,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import {Loading,Tabs,Tree,Select,Dropdown,Button,Progress,Tooltip,Tag,Dialog,DateRangePicker} from 'element-react';

const cardstyles = {borderRight: '1px solid #545cd8'}

const loaddiv = {marginTop: '155px' }

export default class PhysicalPerformance extends Component {
	constructor(props) {
        super(props);

        this.state = {
			accTok : Sanitize.getStoreageItem('accessToken'),
			reTok : Sanitize.getStoreageItem('refreshToken'),
			isDirect : false,
			isDataLoaded : false,
			metricType : 'Electricity Consumption',
			
        };
			
	
    }
	
	
	componentDidMount(){
		
		if(this.props.location.state){
			
		   this.getdata();
		  
		}
	
	}
	
	getdata(){
		
			if(this.props.location.state.modelkeyid=='physical_performance' || this.props.location.state.modelkeyid=='physical_performance_single'){
				
			
							this.setState({left_style:'left_search_card'});
							this.setState({sticky_searchbar:'sticky_top_searchbar'});
							this.setState({topbar_buttons:'topbar_buttons'});
							this.setState({content_card:'content_top_card'});
							this.setState({isDataLoaded : true});
			
		  }
	  
	}
	  
	  getSingleMetric(type){
		
		this.setState({metricType:type});
		
	  }
	
	
	
	  directtoanother(){
		
		this.setState({isDirect:true});
		
	  }
	  
	  
	 
	render() {
		
		const areachartoptions = {
            chart: {
              stacked: false,
              zoom: {
                type: 'x',
                enabled: true
              },
              toolbar: {
                autoSelected: 'zoom'
              }
            },
            plotOptions: {
              line: {
                curve: 'smooth',
              }
            },
            dataLabels: {
              enabled: false
            },

            markers: {
              size: 0,
              style: 'full',
            },
          
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              title: {
                text: 'Value'
              },
            },
            xaxis: {
              type: 'date',
			  categories : ['2019-08-10','2019-09-11','2019-09-20','2019-09-23','2019-09-28','2019-10-03','2019-10-07']
            }
          };
		  
	
          const areachartseries1 =  [{
            name: "Electricity (W)",
            data: [227,556,444,662,332,486,789]
          }];
		  
		  const areachartseries2 =  [{
            name: "Gas (P)",
            data: [67,98,56,55,78,55,89]
          }];
		  
		  const areachartseries3 =  [{
            name: "Water (gl)",
            data: [125,90,86,85,38,75,120]
          }];
		
		
	
	  
	  if(this.state.isDirect && this.props.location.state.modelkeyid=='physical_performance'){
		 
		
			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid:'sites',site_id: this.props.location.state.uid,site_name:this.props.location.state.value,type : '13'}
			}}
			/>);

	  }
	  
	  if(this.state.isDirect && this.props.location.state.modelkeyid=='physical_performance_single'){
		 
		
			return (<Redirect to={{
				pathname: '/metricslist',
				state: { modelkeyid:'sites',site_id: this.props.location.state.uid,site_name:this.props.location.state.value}
			}}
			/>);

	  }
	  
	 
		  
        return (
            <Fragment>
			<Helmet>
					<title>{GC.APPTITLE} | Physical Performance </title>
				</Helmet>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
					
					 <div className="topbar-header">
				       <div className="topbar-header__content">	
					   
					   <PageTitleAlt3
							heading=""
							subheading=""
							icon=""
							menuLevel1={this.props.location.state  ? this.props.location.state.value : 'Physical Performance' }
							menuLevel2="Physical Performance"
							menuLevel1click = { () => this.props.location.state ? this.directtoanother() : ''}
						    menuLevel1link = {this.props.location.state ? 'javascript:;' : false} 
							
							isbreadcumb={true}
						/>
                  
					                 <div className="topbar_buttons">
												
							            </div>
						</div>
					</div>
					
					
					  {!this.state.isDataLoaded ?
					
							 <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
					    :
			 			
				          <div className="main_data">
					           <div className={this.state.sticky_searchbar} style={{borderBottom:'1px solid rgb(209, 219, 229)'}}> 
										   
										<h5 style={{textAlign: 'center',verticalAlign: 'middle',marginTop: '5px',color:'#545cd8'}}><i className="lnr-highlight font-size-sm"/> &nbsp;Physical Performance </h5>	   
											
								</div>
										
										 
												
											
				                <div className={this.state.left_style} id="left_search_card_id" style={{marginTop: '58px'}}>
							      
										
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Electricity Consumption')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Electricity Consumption' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Electricity Consumption" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>Electricity Consumption</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Gas Consumption')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Gas Consumption' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Gas Consumption" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>Gas Consumption</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Water Consumption')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Water Consumption' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Water Consumption" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>Water Consumption</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Building Consumption')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Building Consumption' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Building Consumption" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>Building Consumption</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('HVAC Consumption')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'HVAC Consumption' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="HVAC Consumption" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>HVAC Consumption</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
										
												<br/><br/><br/><br/><br/><br/><br/><br/>
												
							   </div>
						
						
				<div className={this.state.content_card}>
					 
					 
					  
					   <div>
			
						   <Card className="main-card mb-2 custom_card_view">
						
							   <CardBody>
							   {this.state.metricType=='Electricity Consumption' ?
							   
									<div className="area" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
																<Chart options={areachartoptions} series={areachartseries1} type="area" id="apexchart-example-3" width="100%" />

										</div>
							   : '' }
							   {this.state.metricType=='Gas Consumption' ?
							   
									<div className="area" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
																<Chart options={areachartoptions} series={areachartseries2} type="area" id="apexchart-example-3" width="100%" />

										</div>
							   : '' }
							   {this.state.metricType=='Water Consumption' ?
							   
									<div className="area" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
																<Chart options={areachartoptions} series={areachartseries3} type="area" id="apexchart-example-3" width="100%" />

										</div>
							   : '' }
							   
							    {this.state.metricType=='HVAC Consumption' ?
							   
									<div className="text-center"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>
							   : '' }
							   
							    {this.state.metricType=='Building Consumption' ?
							   
								   <div className="text-center"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>
							   : '' }
							   </CardBody>
						    </Card>
						 </div>
					  </div>
					 </div>
					}
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}
