import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';

import {
    faHome

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TitleComponent1 from './PageTitleAlt3Examples/Variation1'
import TitleComponent2 from './PageTitleAlt3Examples/Variation2'
import TitleComponent3 from './PageTitleAlt3Examples/Variation3'
import TitleComponent4 from './PageTitleAlt3Examples/Variation4'

class PageTitleAlt3 extends Component {
    randomize(myArray) {
        return myArray[Math.floor(Math.random() * myArray.length)];
    }

    render() {
        let {
            enablePageTitleIcon,
            enablePageTitleSubheading,
            heading,
            icon,
			menuLevel1,
			menuLevel2,
			menuLevel3,
			menuLevel1link,
			menuLevel2link,
			menuLevel3link,
			menuLevel1click,
			isbreadcumb
			
        } = this.props;

        var arr = [<TitleComponent1/>, <TitleComponent2/>, <TitleComponent4/>]

        return (
            <Fragment>
                <div className="app-page-title app-page-title-simple">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div>
                                <div className="page-title-head center-elem">
                                    <span className={cx("d-inline-block pr-2", {'d-none': !enablePageTitleIcon})}>
                                        <i className={icon}/>
                                    </span>
                                    <span className="d-inline-block">
                                        {heading}
                                    </span>
                                </div>
                                <div
                                    className={cx("page-title-subheading opacity-10", {'d-none': !enablePageTitleSubheading})}>
                                    
                                </div>
                            </div>
                        </div>
						{isbreadcumb ?
                        <div className="page-title-actions">
                             <Breadcrumb>
						<BreadcrumbItem><a href="#/">
							<FontAwesomeIcon icon={faHome}/></a>
						</BreadcrumbItem>
						<BreadcrumbItem>
						  {menuLevel1link && menuLevel1link!='' ? <a href={menuLevel1link} onClick={menuLevel1click}>{menuLevel1}</a> : menuLevel1 }
						</BreadcrumbItem>
						{menuLevel2 ? <BreadcrumbItem active><a href={menuLevel2link}>{menuLevel2}</a></BreadcrumbItem> : '' }
						{menuLevel3 ? <BreadcrumbItem active><a href={menuLevel3link}>{menuLevel3}</a></BreadcrumbItem> : '' }
					</Breadcrumb>
                        </div>
						: '' }
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitleAlt3);