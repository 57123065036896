
export const API_URL = "https://ifmpdemo.zentere.com/api/";
//export const APIURL = "http://rcapi.nellaitoday.com/";

export const APPTITLE = 'Evexia';

export const SITEURL = 'http://zentere.com/';


export const CLIENTID = 'JbP0xTRuOzyv4leXaucfOMYHVWWlzq';

export const CLIENTSECRET = 'ZASQXYU0QjWUaoMszBixh23avAzReO';


export const SITELOGO = 'http://dbswallet.in/images/logo.png';
