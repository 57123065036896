import React from 'react';
import ReactDOM from 'react-dom';
import { i18n } from 'element-react';
import english from './locale/lang/en';
import tamil from './locale/lang/ta';
import * as serviceWorker from './serviceWorker';
import { Route, HashRouter, Switch } from 'react-router-dom';
import './assets/base.scss';
import Main from './DemoPages/Main';
import Login from './DemoPages/UserPages/Login/';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
if (localStorage.getItem("curlang") == 'ta') {
    i18n.use(tamil);
}
else if (localStorage.getItem("curlang") == 'en') {
    i18n.use(english);
} else {
    i18n.use(english);
}
const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Switch>
                    <Route path={"/login"} component={Login} />
                    <Route path={"/"} component={Main} />
                </Switch>
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./DemoPages/Main', () => {
        const NextApp = require('./DemoPages/Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();

