import React, { Fragment, Component } from "react";
import Sanitize from '../../../tie/Sanitize';
import * as Reqst from '../../../tie/Reqst';
import nodata from '../../../assets/utils/images/02b0c5209c.svg';
import {
    Card, CardBody, CustomInput, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Table
} from 'reactstrap';
import BigCalendar from 'react-big-calendar';
import datesUtility from 'react-big-calendar/lib/utils/dates';
import moment from 'moment';
import Loader from 'react-loaders';
import $ from 'jquery';
import events from './Events';
import './style.css';
import { Dialog, Button, Tooltip } from 'element-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'element-theme-default';
import locale from '../../../locale';

//const loaddiv = { marginTop: '150px', marginBottom: '150px', verticalAlign: 'middle' }
const localizer = BigCalendar.momentLocalizer(moment)

export default class CalendarBasic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            calendardata: [],
            dataLoading: false,
            eventdata: [],
            filterArray: [],
            dialogVisible: false,
            equipdata: [],
            equipsLoading: false,
            singleDataLoading: false,
            selectedDate: '',
            isdata: false
            //start: moment(datesUtility.firstVisibleDay(new Date(), localizer)).format('YYYY-MM-DD hh:mm:ss'),
            //end: moment(datesUtility.lastVisibleDay(new Date(), localizer)).format('YYYY-MM-DD hh:mm:ss')
        }
        this.eventSelected = this.eventSelected.bind(this);
        this.onView = this.onView.bind(this);
        this.onRangeChange = this.onRangeChange.bind(this);
    }


    componentWillMount() {
        var s = moment.utc(datesUtility.firstVisibleDay(new Date(), localizer)).format();
        var e = moment.utc(datesUtility.lastVisibleDay(new Date(), localizer)).format();
        this.getData(s, e);
    }

    componentDidMount() {
        this.setState({ dataLoading: true });
    }



    onView(e) {
        //console.log(e);
    }

    onRangeChange(e) {
        // var startVisible = moment(e.start).format('YYYY-MM-DD hh:mm:ss');
        //var endVisible = moment(e.end).format('YYYY-MM-DD hh:mm:ss');
        console.log(e);
        var startVisible = moment.utc(e.start).format();
        var endVisible = moment.utc(e.end).format();
        this.getData(startVisible, endVisible);
    }

    getRefreshToken(start, end) {
        this.setState({ dataLoading: true });
        var reftoken = Sanitize.getStoreageItem('refreshToken');
        Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
            let res = result['data'];
            if (res) {
                this.setState({ dataLoading: false });
                Sanitize.setStorage('accessToken', res.access_token);
                Sanitize.setStorage('refreshToken', res.refresh_token);
                this.getData(start, end);
            }
        }).catch(error => {
            this.setState({ dataLoading: false });
        });
    }

    getData(start, end) {

        this.setState({ dataLoading: true });
        this.setState({ calendardata: [], eventdata: [] });
        var token = Sanitize.getStoreageItem('accessToken');
        var limit = '';
        var offset = 0;

        var domain = '"&",["start", "<=", "' + end + '"], ["stop", ">=", "' + start + '"]';
        var data = { "token": token, "limit": limit, 'offset': offset, 'domain': domain }
        Reqst.axPost('getppmcalendar', data).then((result) => {

            let res = result['data'];
            if (res.code == '401') {
                this.getRefreshToken(start, end);
            }
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
            else {
                this.setState({ dataLoading: false });
                this.setState({ calendardata: res });
                var s = this.groupByMultiple(res, function (obj) {
                    return [obj.maintenance_team_id[1]];
                });
                this.setState({ filterArray: s });
                this.setEventData();
            }
        });
    }

    groupByMultiple(array, f) {
        var groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });

    }

    getcolor(index) {
        const colors = ["#a95f7c", "#5fa960", "#f7d993", "#3a87ad", "#a95f7c", "#a99b5", "#6cb7ef"];
        var cr = '';
        for (var i = 0; i < colors.length; i++) {
            if (index == i) {
                cr = colors[i]
            }
        }
        return cr;
    }

    getpriorityname(pr) {

        var result = '';

        if (pr == '0') {

            result = 'Low';

        }
        else if (pr == '1') {

            result = 'Normal';

        }
        else if (pr == '2') {

            result = 'High';

        }
        else if (pr == '3') {

            result = 'Breakdown';

        }

        return result


    }

    getcatname(cat) {

        var result = '';

        if (cat == 'e') {

            result = 'Equipment';

        }
        else if (cat == 'ah') {

            result = 'Space';

        }

        return result


    }

    getnovalues(str) {

        if (str != '' && str != null) {
            return str;
        }
        else {

            return 'Not Assigned';
        }

    }

    setEventData() {
        var data = this.state.calendardata;
        if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                for (var j = 0; j < this.state.filterArray.length; j++) {
                    var fdata = this.state.filterArray[j];
                    var bcolor = this.getcolor(j);
                    if (fdata[0].maintenance_team_id[1] == data[i].maintenance_team_id[1]) {
                        data[i].backgroundColor = bcolor;
                        data[i].borderColor = bcolor;
                    }
                }
                data[i].title = data[i]['name'];
                data[i].start = new Date(data[i]['start']);
                data[i].end = new Date(data[i]['stop']);
                delete data[i].name;
                delete data[i].stop;
            }
        }
        this.setState({ eventdata: data });
    }



    eventStyleGetter(event, start, end, isSelected) {
        var backgroundColor = event.backgroundColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    }

    eventSelected(eventInfo) {
        this.setState({
            dialogVisible: true,
        });
        this.getdatabyid(eventInfo.id);
    }

    getdateofdt(date) {

        if (date != '' && date != null) {
            var date1 = new Date(date);
            var year = date1.getFullYear();
            var month = (date1.getMonth() + 1);
            var day = date1.getDate()
            var strTime = day + '/' + month + '/' + year;
            return strTime;
        }
        else {

            return '';
        }

    }

    getstatename(staten) {

        var orgstate = '';
        if (staten == 'mn') {

            orgstate = 'Maintenance';

        }
        else if (staten == 'op') {

            orgstate = 'Operative';

        }
        else if (staten == 'wh') {

            orgstate = 'Warehouse';

        }
        else if (staten == 'br') {

            orgstate = 'Breakdown';

        }
        else if (staten == 'sc') {

            orgstate = 'Scrapped';

        }

        return orgstate;

    }

    getdatabyid(id) {
        this.setState({ singleDataLoading: true });
        var token = Sanitize.getStoreageItem('accessToken');
        this.setState({
            display_name: '',
            ppm_category: '',
            equipment_count: '',
            helpdesk_count: '',
            category_type: '',
            space_count: '',
            ppm_equip_cat: '',
            ppm_isall: '',
            ppm_priority: '',
            ppm_mt: '',
            ppm_mo: '',
            asset_category: '',
            isdata: false
        });
        Reqst.axGet('getppmsingle/' + token + '/' + id).then((result) => {

            let res = result['data'][0];
            if (result['data'].code == '401') {
                this.getRefreshToken();
            }
            else {
                if (res) {
                    this.setState({ singleDataLoading: false });
                    this.setState({
                        dialogVisible: true,
                        display_name: res.display_name,
                        ppm_category: res.category_type,
                        equipment_count: res.equipment_count,
                        helpdesk_count: res.helpdesk_count,
                        category_type: res.category_type,
                        space_count: res.space_count,
                        ppm_equip_cat: res.category_id[1],
                        ppm_isall: res.is_all_records,
                        ppm_priority: res.priority,
                        ppm_mt: res.maintenance_team_id[1],
                        ppm_mo: res.task_id[1],
                        asset_category: res.asset_category_id[1],
                        isdata: true,
                    });
                } else {
                    this.setState({ singleDataLoading: false });
                }
                //this.getequpdata(res.equipment_ids, res.category_type);
            }
        });
    }

    getequpdata(id, type) {
        if (id.length > 0 && type == 'e') {
            this.setState({ equipsLoading: true });
            this.setState({ equipdata: [] });
            var token = Sanitize.getStoreageItem('accessToken');
            var data = { "token": token, "equips": id };
            Reqst.axPost('getequipmentsbyppm', data).then((result) => {

                let res = result['data'];
                if (res.code == '401') {
                    this.getRefreshToken();
                }
                else {

                    this.setState({ equipsLoading: false });
                    this.setState({ equipdata: res });
                    console.log(res);
                }
            });
        }
    }



    render() {
        var tit = "Open";

        var codeBlock =
            <React.Fragment>
                <span style={{ display: '-webkit-inline-box' }}>
                    <span style={{ fontSize: '16px', fontWeight: '700' }}>{tit}: {this.state.display_name}</span>
                    <Tooltip className="item" content='Helpdesks' placement="top-start" style={{ position: 'absolute', right: '105px', top: '18px' }}>
                        < button className="mb-2 mr-2 btn-icon btn-icon-only btn btn-link btn-sm" >
                            <i className="pe-7s-ticket btn-icon-wrapper font-size-xlg"> </i>
                            <span className="badge badge-pill badge-primary">{this.state.helpdesk_count}</span>
                        </button >
                    </Tooltip>
                    {this.state.category_type == 'e' ?
                        <Tooltip className="item" content='Equipments' placement="top-start" style={{ position: 'absolute', right: '52px', top: '18px' }} >
                            <button className="mb-2 mr-2 btn-icon btn-icon-only btn btn-link btn-sm">
                                <i className="pe-7s-tools btn-icon-wrapper font-size-xlg"> </i>
                                <span className="badge badge-pill badge-success">{this.state.equipment_count}</span>
                            </button>
                        </Tooltip> : ''}
                    {this.state.category_type == 'ah' ?
                        <Tooltip className="item" content='Spaces' placement="top-start" style={{ position: 'absolute', right: '52px', top: '18px' }}>
                            <button className="mb-2 mr-2 btn-icon btn-icon-only btn btn-link btn-sm">
                                <i className="pe-7s-map-marker btn-icon-wrapper font-size-xlg"> </i>
                                <span className="badge badge-pill badge-warning">{this.state.space_count}</span>
                            </button>
                        </Tooltip> : ''}
                </span>
            </React.Fragment >;
        //};


        return (
            <Fragment>
                <div>
                    {this.state.dataLoading ? <Col md={12} > <Loader type="ball-clip-rotate" /> </Col> : null}
                </div>

                <Card className="mb-3">
                    <CardBody style={{ paddingTop: '10px' }}>
                        <BigCalendar
                            localizer={localizer}
                            events={this.state.eventdata}
                            //events={events}
                            selectable={true}
                            startAccessor="start"
                            endAccessor="end"
                            messages={{ today: locale.t('el.datepicker.today'), next: ">", previous: "<" }}
                            onRangeChange={this.onRangeChange}
                            defaultView='month'
                            popup
                            //eventPropGetter={(this.eventStyleGetter)}
                            onSelectEvent={this.eventSelected}
                        />
                        <Dialog
                            title={this.state.isdata ? codeBlock : ''}
                            size="large"
                            visible={this.state.dialogVisible}
                            onCancel={() => this.setState({ dialogVisible: false })}
                            lockScroll={false}
                        ><hr style={{ margin: '0px' }} />
                            <Dialog.Body style={{ paddingTop: "0px", paddingRight: "0px" }}>
                                <div>
                                    <Card className="main-card mb-2 custom_card_view" style={{ paddingRight: '0px' }}>
                                        <CardBody style={{ paddingRight: '0px' }}>
                                            {this.state.singleDataLoading ?
                                                <div className="text-center"><Loader type="ball-clip-rotate" /></div>
                                                :
                                                this.state.isdata ?
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col md={4}>
                                                                <span className="text-capitalize">PPM For</span><br />
                                                                <span><b>{this.getcatname(this.state.ppm_category)}</b></span>  <br /><br />
                                                                <span className="text-capitalize">Priority</span><br />
                                                                <span><b>{this.getpriorityname(this.state.ppm_priority)}</b></span>
                                                            </Col>
                                                            <Col md={4}>
                                                                <span className="text-capitalize">Asset Category</span><br />
                                                                <span><b>{this.getnovalues(this.state.asset_category)}</b></span><br /><br />
                                                                <span className="text-capitalize">Maintenance Team</span><br />
                                                                <span><b>{this.getnovalues(this.state.ppm_mt)}</b></span>
                                                            </Col>
                                                            <Col md={4}>
                                                                {/* <span className="text-capitalize">Is All?</span><br />
                                                                <span><b>{this.state.ppm_isall ? 'Yes' : 'No'}</b></span><br /><br /> */}
                                                                <span className="text-capitalize">Maintenance Operations</span><br />
                                                                <span><b>{this.getnovalues(this.state.ppm_mo)}</b></span>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment> : <div className="text-center"><img src={nodata} width="150" /> <br /><b>No Data Found..</b></div>}
                                        </CardBody>

                                    </Card>
                                </div>

                                {/*<Card className="main-card mb-2 custom_card_view">

                                        <CardBody>
                                            {this.state.ppm_category == 'e' ?

                                                this.state.equipsLoading || this.state.singleDataLoading ?
                                                    <div className="text-center col-md-12" style={loaddiv}><Loader type="ball-clip-rotate" /></div>
                                                    :
                                                    this.state.equipdata.length > 0 ?
                                                        <Col lg="12">
                                                            <Table responsive className="mb-0">
                                                                <thead>
                                                                    <th>Equipment Name</th>
                                                                    <th>Equipment Number</th>
                                                                    <th>Category</th>
                                                                    <th>Space</th>
                                                                    <th>Status</th>
                                                                    <th>Warrenty End</th>
                                                                    <th>Description</th>
                                                                    <th>Company</th>
                                                                </thead>
                                                                <tbody>


                                                                    {this.state.equipdata.map((item, index) =>
                                                                        <tr key={item}>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.equipment_seq}</td>
                                                                            <td>{item.category_id[1]}</td>
                                                                            <td>{item.location_id[1]}</td>
                                                                            <td>{this.getstatename(item.state)}</td>
                                                                            <td>{this.getdateofdt(item.warrenty_end_date)}</td>
                                                                            <td>{item.equipment_number}</td>
                                                                            <td>{item.company_id[1]}</td>

                                                                        </tr>
                                                                    )}


                                                                    <tr><td colSpan="5"></td></tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                        :
                                                        <div className="text-center"><img src={nodata} width="150" /> <br /><b>No Equipments Found..</b></div>


                                                : ''

                                            }
                                        </CardBody>

                                    </Card>*/}
                            </Dialog.Body>
                            {/*<Dialog.Footer className="dialog-footer">
                                    <Button onClick={() => this.setState({ dialogVisible: false })} type="danger">Cancel</Button>
                                </Dialog.Footer>*/}
                        </Dialog>
                    </CardBody>
                </Card>
            </Fragment >

        )
    }
}
