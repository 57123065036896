import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import {Helmet} from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import {Redirect,Link} from 'react-router-dom';
import classnames from 'classnames';
import preventive_img from '../../assets/utils/images/Preventive_black.png';
import oc_img from '../../assets/utils/images/On Condition_black.png';
import corrective_img from '../../assets/utils/images/Corrective_black.png';
import periodic_img from '../../assets/utils/images/Periodic_black.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import {Doughnut} from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import ReactTooltip from 'react-tooltip';

import {
    faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
    Row, Col,
	Alert,
    Container,TabContent,TabPane,
	Card,CardBody,CardHeader,
	Table,
    ButtonGroup,
	Modal,ModalHeader,ModalBody,ModalFooter,
    ListGroup,FormGroup,Label,FormText,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import {Loading,Tabs,Tree,Select,Dropdown,Button,Progress,Tooltip,Tag,Dialog,DateRangePicker} from 'element-react';

const cardstyles = {borderRight: '1px solid #545cd8'}

const loaddiv = {marginTop: '155px' }

export default class CustomerMetrics extends Component {
	constructor(props) {
        super(props);

        this.state = {
			accTok : Sanitize.getStoreageItem('accessToken'),
			reTok : Sanitize.getStoreageItem('refreshToken'),
			isDirect : false,
			isDataLoaded : false,
			metricType : 'workplace_comfort',
			chart_dates:[],
			mainData : [],
			statedata : [],
			channel_names : [],
			channel_data : [],
			channelData : [],
			channelLoading : false,
			employeeLoading : false,
			stateLoading : false,
			monthLoading : false,
			monthData : [],
			monthNames : [],
			monthCount : [],
			empData : [],
			empNames : [],
			empCount : [],
			chart_values:[],
			chart_values1:[],
			filtertype : 'Today',
			daterange : this.getTodays(),
			chart_values2:[],
			chart_values3:[],
			chart_values4:[],
			chartLoading : false
			
        };
			this.onDateChange = this.onDateChange.bind(this);
			this.setFilterType = this.setFilterType.bind(this);
	
    }
	
	setFilterType(column){
		 
		 this.setState({filtertype:''});
	
		 this.setState({filtertype:column});
				
		 if(column=='This Month'){
			
			this.getdatesofmonth();
		 }
		 else if(column=='Today'){
			 
			 this.getTodayData();
			 
		 }
		 else if(column=='This Week'){
			 
			 this.getdatesofweek();
			 
		 } 
		 else if(column=='Last_Week'){
			 
			 this.getDateSeries(column);
			 
		 }
		 else if(column=='Last_Month'){
			 
			 this.getDateSeries(column);
			 
		 }
        else if(column=='Last_3_Months'){
			 
			 this.getDateSeries(column);
			 
		 }		 
		 else{
			 
			this.getSingleSensor();
			 
		 }
		  
	  }
	  
	   daysInMonth (month, year) { 
          return new Date(year, month, 0).getDate(); 
     }        
    
	getdatesofmonth() { 
			 

                var date = new Date(); 
                var firstDay = new Date(date.getFullYear(), 
                                date.getMonth(), 1); 
                                  
                var lastDay = new Date(date.getFullYear(), 
                        date.getMonth(), this.daysInMonth(date.getMonth()+1, 
                        date.getFullYear())); 
						
				var daterange = [];
		
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
				
				
				 var tab = this.state.metricType;
				
				if(tab=='workplace_comfort'){

				   this.getSensorDataFilter(firstDay,lastDay);
				 
				}
				else{
				
				   this.getTicketByStateDate(firstDay,lastDay);
				   this.getTicketByChannelDate(firstDay,lastDay);
				   this.getTicketByEmployeeDate(firstDay,lastDay);
				   
				}
				
      } 
	  
	   getDateSeries(series) { 
			 
				
				var daycount = 7;
				
				if(series=='Last_Week'){
					
					daycount = 7;
				}
				else if(series=='Last_Month'){
					
					daycount = 30;
				}
				else if(series=='Last_3_Months'){
					
					daycount = 90;
				}
				
                var lastDay = new Date();
				var firstDay = new Date();
			    firstDay.setTime(firstDay.getTime() - 3600 * 1000 * 24 * daycount);	
				
				var daterange = [];
		
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
			
				 var tab = this.state.metricType;
				
				if(tab=='workplace_comfort'){

				   this.getSensorDataFilter(firstDay,lastDay);
				 
				}
				else{
				
				   this.getTicketByStateDate(firstDay,lastDay);
				   this.getTicketByChannelDate(firstDay,lastDay);
				   this.getTicketByEmployeeDate(firstDay,lastDay);
				   
				}
				
      } 
	  
	    getTodayData(){
		  
		
		     var date1 = new Date();
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			   var hours = date1.getHours();
			   var minutes = date1.getMinutes();
			   var seconds = date1.getSeconds();
			   month = month < 10 ? '0'+month : month;
			   day = day < 10 ? '0'+day : day;
			   hours = hours < 10 ? '0'+hours : hours;
			   minutes = minutes < 10 ? '0'+minutes : minutes;
			   seconds = seconds < 10 ? '0'+seconds : seconds;
			   
			   var d = new Date();
               var date = d.setDate(d.getDate() - 1);
			   
			   var yesterday = this.getdateoforginal(date);
			   
			  var today = year + '-' + month + '-' + day;
			  
			  var daterange = [];
				
				var firstDay = new Date(date);
				var lastDay = new Date();
				daterange.push(firstDay);
				daterange.push(lastDay);
				
				this.setState({daterange:daterange});
				
				var tab = this.state.metricType;
				
				if(tab=='workplace_comfort'){

				   this.getSensorDataFilter(yesterday,today);
				 
				}
				else{
				
				   this.getTicketByStateDate(yesterday,today);
				   this.getTicketByChannelDate(yesterday,today);
				   this.getTicketByEmployeeDate(yesterday,today);
				   
				}
				
		  
	  }
	  
	  
	  getdatesofweek() { 
			 
			
               var curr = new Date; // get current date
				var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
				var last = first + 6; // last day is the first day + 6

				var firstDay = new Date(curr.setDate(first)).toUTCString();
				var lastDay = new Date(curr.setDate(last)).toUTCString();
				
				var daterange = [];
				
				var firstDay1 = new Date(curr.setDate(first));
				var lastDay1 = new Date(curr.setDate(last));
				daterange.push(firstDay1);
				daterange.push(lastDay1);
				
				this.setState({daterange:daterange});
				
		
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
				
			
				 var tab = this.state.metricType;
				
				if(tab=='workplace_comfort'){

				   this.getSensorDataFilter(firstDay,lastDay);
				 
				}
				else{
				
				   this.getTicketByStateDate(firstDay,lastDay);
				   this.getTicketByChannelDate(firstDay,lastDay);
				   this.getTicketByEmployeeDate(firstDay,lastDay);
				   
				}
				
      } 
	
	
	getTodays(){
		   
				var dates = [];
			   var d = new Date();
               var date = d.setDate(d.getDate() - 1);
			   
			   var yesterday = new Date(date);
			   
			  var today = new Date();
			  
			   dates.push(yesterday);
			   dates.push(today);
			   
		
			   return dates;
		   
		   
	   }
	
	componentDidMount(){
		
		if(this.props.location.state){
			
		   this.getdata();
		  
		}
	
	}
	
	getdata(){
		
			if(this.props.location.state.modelkeyid=='customer_metrics' || this.props.location.state.modelkeyid=='customer_metrics_single'){
				
			           
						this.getSensorData();
			
		  }
	  
	}
	
	async getSensorData(){
				this.setState({chartLoading:true});
				this.setState({mainData : []});
				
				await Reqst.axGet('getsensordatadashboard').then((result) => {
					
					let res = result['data'];
		
					if(res){
						
						this.setState({chartLoading:false});
						this.setState({mainData : res.hits.hits});
						this.setState({
							filtertype : 'Today',
							daterange : this.getTodays()
						});
						this.setState({left_style:'left_search_card'});
						this.setState({sticky_searchbar:'sticky_top_searchbar'});
						this.setState({topbar_buttons:'topbar_buttons'});
						this.setState({content_card:'content_top_card'});
						this.setState({isDataLoaded : true});
						this.getSingleSensor();
						
					}
					else{
						this.setState({chartLoading:false});
						
					}
				});
	}
	
	 getdateoforginal(date){
		      
			  if(date!='' && date!=null){		
			  var date1 = new Date(date);
		      var year = date1.getFullYear();
			  var month = (date1.getMonth()+1);
			  var day   = date1.getDate()
			   var hours = date1.getHours();
			   var minutes = date1.getMinutes();
			   var seconds = date1.getSeconds();
			   month = month < 10 ? '0'+month : month;
			   day = day < 10 ? '0'+day : day;
			   hours = hours < 10 ? '0'+hours : hours;
			   minutes = minutes < 10 ? '0'+minutes : minutes;
			   seconds = seconds < 10 ? '0'+seconds : seconds;
			   
			  var strTime = year + '-' + month + '-' + day;
			  return strTime;
		 }
		 else{
			 
			 return '- - - -';
		 }
			
	  }
	
	onDateChange(date) { 
			 
			
			 
			 this.setState({daterange:date});
			 
			 var firstDay = date[0];
			 
			 var lastDay = date[1];
						
				firstDay = this.getdateoforginal(firstDay);
				lastDay = this.getdateoforginal(lastDay);
				
			
				 var tab = this.state.metricType;
				
				if(tab=='workplace_comfort'){

				   this.getSensorDataFilter(firstDay,lastDay);
				 
				}
				else{
				
				   this.getTicketByStateDate(firstDay,lastDay);
				   this.getTicketByChannelDate(firstDay,lastDay);
				   this.getTicketByEmployeeDate(firstDay,lastDay);
				   
				}
				  
				
      } 
	  
	  getDiffDays(firstday,lastday){
		  
		   // To set two dates to two variables 
			var date1 = new Date(firstday); 
			var date2 = new Date(lastday); 
			  
			// To calculate the time difference of two dates 
			var Difference_In_Time = date2.getTime() - date1.getTime(); 
			  
			// To calculate the no. of days between two dates 
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
			
			return Difference_In_Days;
		  
	  }
	  
	  async getSensorDataFilter(firstday,lastday){
		  
		   var len = this.getDiffDays(firstday,lastday);
		   
		   console.log(len);
		   
		   for(var i=0;i<len;i++){
		   
		         var d = new Date(firstday);
                 var preday = d.setDate(d.getDate()+i);
				 
				  preday = this.getdateoforginal(preday);
		   
				this.setState({chartLoading:true});
				//this.setState({mainData : []});
				
				await Reqst.axGet('getsensordatafilterloop/'+preday+'/'+lastday).then((result) => {
					
					let res = result['data'];
		
					if(res){
						
						//this.setState({chartLoading:false});
						var data = this.state.mainData.concat(res.hits.hits);
						this.setState({mainData : data});
						//this.getSingleSensor();
						
					}
					else{
						this.setState({chartLoading:false});
						
					}
				});
				
		   }
		    console.log(i);
		   if(len==i){
		     this.getSingleSensor();
			 this.setState({chartLoading:false});
		   }
	}
	  
	
	getSingleSensor(){
		
		this.setState({chartLoading:true});
		this.setState({chartData : [],chart_dates:[],chart_values:[],chart_values1:[],chart_values2:[],chart_values3:[],chart_values4:[]});
		
		var data = this.state.mainData;
		
		var dates = [];
		var values = [];
		var values1 = [];
		var values2 = [];
		var values3 = [];
		var values4 = [];
		

		for(var i=0;i<data.length;i++){
			
			dates.push(data[i]._source.timestamp);
			
			  values.push(parseInt(data[i]._source.reading.humidity));
			
			  values1.push(parseInt(data[i]._source.reading.pressure/1000));
			
			  values2.push(parseInt(data[i]._source.reading.temperature));
			
			  values3.push(parseInt(data[i]._source.reading.lux));
			  
			  var iaq = data[i]._source.reading.iaq;
			  
			
			  if(iaq > 200){
				  
				  iaq = 200;
			  }
			
			  values4.push(parseInt(iaq));
			
			
		}
		
		this.setState({chart_dates:dates,chart_values:values,chart_values1:values1,chart_values2:values2,chart_values3:values3,chart_values4:values4});
		this.setState({chartLoading:false});
		
	}
	
	async getTicketByState() {
		this.setState({ stateLoading: true });
		this.setState({ statedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["state_id"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ stateLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						statedata: res
					});
					//this.getmaintainOrdersCount();
				}
			}
		});
	}
	
	async getTicketByStateDate(firstday,lastday) {
		this.setState({ stateLoading: true });
		this.setState({ statedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["state_id"]';
		var domain = '"&",["create_date",">=","'+firstday+'"],["create_date","<=","'+lastday+'"]'
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getstatsdynam',data).then((result) => {

			let res = result['data'];

			this.setState({ stateLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						statedata: res
					});
					//this.getmaintainOrdersCount();
				}
			}
		});
	}
	
	getEmployeesCount() {

		var data = this.state.empData;

		var count = []; var names = [];

		for (var i = 0; i < data.length; i++) {
			
			if(data[i].employee_id){
				
				count.push(data[i].employee_id_count);
				names.push(data[i].employee_id[1]);
				
			}
		
		}

		this.setState({ empNames: names, empCount: count });
	}
	
	getMonthsCount() {

		var data = this.state.monthData;

		var count = []; var names = [];
		
		var k = 'close_date:month';

		for (var i = 0; i < data.length; i++) {
			
			if(data[i].close_date){
							
				count.push(data[i].close_date_count);
				names.push(data[i].close_date);
				
			}
			
		}

		this.setState({ monthNames: names, monthCount: count });
	}
	
	getChannelsCount() {

		var data = this.state.channelData;

		var count = []; var names = [];

		for (var i = 0; i < data.length; i++) {
			
			
			if(data[i].__domain[1][2]){

				count.push(data[i].__count);
				names.push(data[i].__domain[1][2]);
			}
		
		}

		this.setState({ channel_names: names, channel_data: count });
	}
	
	async getTicketByChannel() {
		this.setState({ channelLoading: true });
		this.setState({ channelData: [],channel_names:[],channel_data : [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["channel"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ channelLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						channelData: res
					});
					this.getChannelsCount();
				}
			}
		});
	}
	
	async getTicketByChannelDate(firstday,lastday) {
		this.setState({ channelLoading: true });
		this.setState({ channelData: [],channel_names:[],channel_data : [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["channel"]';
		var domain = '"&",["create_date",">=","'+firstday+'"],["create_date","<=","'+lastday+'"]'
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getstatsdynam',data).then((result) => {

			let res = result['data'];

			this.setState({ channelLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						channelData: res
					});
					this.getChannelsCount();
				}
			}
		});
	}
	
	async getTicketByEmployeeDate(firstday,lastday) {
		this.setState({ employeeLoading: true });
		this.setState({ empData: [], empNames: [], empCount: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["employee_id"]';
		var domain = '["state_category_id", "=", "cld"],"&",["create_date",">=","'+firstday+'"],["create_date","<=","'+lastday+'"]';
		//var domain = '';
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getclosedticketsbyemployee', data).then((result) => {

			let res = result['data'];

			this.setState({ employeeLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						empData: res
					});
					this.getEmployeesCount();
				}
			}
		});
	}
	
	async getTicketByMonth() {
		this.setState({ monthLoading: true });
		this.setState({ monthData: [], monthNames: [], monthCount: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["close_date"]';
		var domain = '["state_category_id", "=", "cld"]';
		//var domain = '';
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getclosedticketsbyemployee', data).then((result) => {

			let res = result['data'];

			this.setState({ monthLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						monthData: res
					});
					this.getMonthsCount();
				}
			}
		});
	}
	
	getRefreshToken() {
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		console.log(reftoken);
		if (reftoken != null) {
			this.setState({ dataLoading: true });
			Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
				let res = result['data'];
				if (res) {
					this.setState({ dataLoading: false });
					Sanitize.setStorage('accessToken', res.access_token);
					Sanitize.setStorage('refreshToken', res.refresh_token);
					//this.setState({accTok:res.access_token,reTok:res.refresh_token});
					this.getTodayData();

				}
			}).catch(error => {
				this.setState({ dataLoading: false });
				//console.log(error.response.status);

			});
		}

	}
	
	  
	  getSingleMetric(type){
		
		this.state.metricType = type;
		this.setState({metricType:type});
		
		if(type=='Customer Satisfication'){
			
			this.getTodayData();
			//this.getTicketByChannelDate(fi);
			//this.getTicketByEmployee();
			this.getTicketByMonth();
		}
		else if(type=='workplace_comfort'){
			
			this.getTodayData();
			
		}
		
	  }
	  
	  getTicketCompanyStateCount(status) {

		var data = this.state.statedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'open') {
				if ((data[i].state_id[1] == 'Open'))
					result += data[i].__count;
			}
			else if (status == 'progress') {
				if ((data[i].state_id[1] == 'In Progress'))
					result += data[i].__count;
			}
			else if (status == 'done') {
				if (((data[i].state_id[1] == 'Staff Closed') || (data[i].state_id[1] == 'Customer Closed')))
					result += data[i].__count;
			}

		}

		return result;
	}
	
	
	
	  directtoanother(){
		
		this.setState({isDirect:true});
		
	  }
	  
	  
	 
	render() {
		
		
	
	  
	  if(this.state.isDirect && this.props.location.state.modelkeyid=='customer_metrics'){
		 
		
			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid:'sites',site_id: this.props.location.state.uid,site_name:this.props.location.state.value,type : '13'}
			}}
			/>);

	  }
	  
	  if(this.state.isDirect && this.props.location.state.modelkeyid=='customer_metrics_single'){
		 
		
			return (<Redirect to={{
				pathname: '/metricslist',
				state: { modelkeyid:'sites',site_id: this.props.location.state.uid,site_name:this.props.location.state.value}
			}}
			/>);

	  }
	  
	   const series = [{
        name: 'Humidity',
        type: 'line',
        data: this.state.chart_values
      }, {
        name: 'Pressure',
        type: 'line',
        data: this.state.chart_values1
      },{
        name: 'Temperature',
        type: 'line',
        data: this.state.chart_values2
      },
	  /* {
        name: 'Lux',
        type: 'line',
        data: this.state.chart_values3
      }, */
	  {
        name: 'Indoor Air Quality',
        type: 'line',
        data: this.state.chart_values4
      }
	  
	  ];
	  
	  const dougnoutoptins = {
				 stroke: {
					width: [4,4,4,4],
					curve: 'smooth'
				  },
			  plotOptions: {
				bar: {
				  columnWidth: '50%'
				}
			  },
            colors: ['#3A5794', '#A5C351', '#E14A84','#66d9ff'],
			 labels: this.state.chart_dates,
			  markers: {
				size: 0
			  },
			  xaxis: {
				type:'datetime'
			  },
			  yaxis: {
				min: 0,
				max: 200
			  },
			  tooltip: {
				shared: true,
				intersect: false,
				y: {
				  formatter: function (y) {
					if(typeof y !== "undefined") {
					  return  y.toFixed(0) + " Value";
					}
					return y;
					
				  }
				}
			  },
			  legend: {
				labels: {
				  useSeriesColors: true
			  }}

		};
		
		var opencount = this.getTicketCompanyStateCount('open');
		var progresscount = this.getTicketCompanyStateCount('progress');
		var closedcount = this.getTicketCompanyStateCount('done');
		
		const piechartseries1 = [opencount, progresscount, closedcount];

		const dougnoutoptins1 = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},
			labels: ['Open', 'Inprogress', 'Closed'],
			colors: [
				'#ff6666',
				'#80d4ff',
				'#b4e34f'
			],
			hover: [
				'#ff6666',
				'#80d4ff',
				'#b4e34f'
			]

		};
		
		const dougnoutoptins2 = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},
			labels: this.state.empNames
			
		};
		
		const piechartseries2 = this.state.empCount;
		
		
		
		const barchartoptions = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: this.state.channel_names
			},

		};
		
		
		
		const barchartseries = [{
			name: 'Tickets',
			data: this.state.channel_data
		}];
		
		const barchartseries1 = [{
			name: 'Tickets',
			data: this.state.monthCount
		}];
		
		const barchartoptions1 = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: this.state.monthNames
			},

		};
		
		const areachartoptions = {
            chart: {
              stacked: false,
              zoom: {
                type: 'x',
                enabled: true
              },
              toolbar: {
                autoSelected: 'zoom'
              }
            },
            plotOptions: {
              line: {
                curve: 'smooth',
              }
            },
            dataLabels: {
              enabled: false
            },

            markers: {
              size: 0,
              style: 'full',
            },
          
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              },
            },
            yaxis: {
              title: {
                text: 'Value'
              },
            },
            xaxis: {
              type: 'month',
			  categories : this.state.monthNames
            }
          };
		  
	
          const areachartseries =  [{
            name: "Tickets",
            data: this.state.monthCount
          }];
		
		 
	 
		  
        return (
            <Fragment>
			<Helmet>
					<title>{GC.APPTITLE} | Customer Matrics </title>
				</Helmet>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
					
					 <div className="topbar-header">
				       <div className="topbar-header__content">	
					   
					   <PageTitleAlt3
							heading=""
							subheading=""
							icon=""
							menuLevel1={this.props.location.state  ? this.props.location.state.value : 'Customer Matrics' }
							menuLevel2="Customer Matrics"
							menuLevel1click = { () => this.props.location.state ? this.directtoanother() : ''}
						    menuLevel1link = {this.props.location.state ? 'javascript:;' : false} 
							
							isbreadcumb={true}
						/>
                  
					                 <div className="topbar_buttons">
												<div>
												 {this.state.metricType == 'workplace_comfort'  || this.state.metricType == 'Customer Satisfication' ? 
											      <DateRangePicker
														  value={this.state.daterange}
														  placeholder="Pick a range"
														  format="yyyy-MM-dd"
														  align="right"
														  ref={e=>this.daterangepicker2 = e}
														  onChange={(date) => { this.onDateChange(date) }}
														  shortcuts={[{
															text: 'Last week',
															onClick: ()=> {
															  this.setFilterType('Last_Week')
															  this.daterangepicker2.togglePickerVisible()
															}
														  }, {
															text: 'Last month',
															onClick: ()=> {
															  this.setFilterType('Last_Month')
															  this.daterangepicker2.togglePickerVisible()
															}
														  }, {
															text: 'Last 3 months',
															onClick: ()=> {
															  this.setFilterType('Last_3_Months')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },{
														  text: 'This Week',
															onClick: ()=> {
															  this.setFilterType('This Week')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },
														  {
														  text: 'This Month',
															onClick: ()=> {
															  this.setFilterType('This Month')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  },
														  {
														  text: 'Today',
															onClick: ()=> {
															  this.setFilterType('Today')
															  this.daterangepicker2.togglePickerVisible()
															},
															
														  }
														  ]}
														  />
												 : '' }
													</div>
							            </div>
						</div>
					</div>
					
					
					  {!this.state.isDataLoaded ?
					
							 <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
					    :
			 			
				          <div className="main_data">
					           <div className={this.state.sticky_searchbar} style={{borderBottom:'1px solid rgb(209, 219, 229)'}}> 
										   
										<h5 style={{textAlign: 'center',verticalAlign: 'middle',marginTop: '5px',color:'#545cd8'}}><i className="lnr-highlight font-size-sm"/> &nbsp;Customer Metrics </h5>	   
											
								</div>
										
										 
												
											
				                <div className={this.state.left_style} id="left_search_card_id" style={{marginTop: '58px'}}>
							      
										
										    <a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('workplace_comfort')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'workplace_comfort' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Workplace Comfort & Productivity" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>Workplace Comfort & Productivity</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Customer Satisfication')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Customer Satisfication' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Customer Satisfication" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>Customer Satisfication</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Work Downtime')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Work Downtime' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Work Downtime" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>Work Downtime</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
										
												<br/><br/><br/><br/><br/><br/><br/><br/>
												
							   </div>
						
						
				   <div className={this.state.content_card}>
					 
					
						   <div>
				              {this.state.metricType == 'workplace_comfort' ? 
							   <Card className="main-card mb-2 custom_card_view">
							
								   <CardBody>
								    {this.state.chartLoading ?
				                       <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
				                      :	
								                    <div className="pie" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
															<Chart options={dougnoutoptins} series={series}  width="100%" />

														</div>
									}
								   </CardBody>
								</Card>
								: '' }
								{this.state.metricType == 'Customer Satisfication' ? 
							<Row>
							   <Col lg="6" sm="12" xs="12" className="custom_col_left" >
								  <Card className="widget-chart mb-2" style={{height: '97.5%' }}>
										<div className="widget-chat-wrapper-outer">
											<div className="widget-chart-content">

												<div className="text-left">
													<span className="card-title text-uppercase"> Tickets By Status  </span>

												</div>
												{this.state.stateLoading ?
													<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
													:

													<div className="text-left" >
														{opencount == 0 && progresscount == 0 && closedcount == 0 ?

															<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

															:
															<div className="pie" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
																<Chart options={dougnoutoptins1} series={piechartseries1} type="pie" id="apexchart-example-3" width="100%" />

															</div>
														}
													</div>
												}
											</div>
										</div>
									</Card>
								</Col>
								<Col lg="6" sm="12" xs="12" className="custom_col_right" >
								  <Card className="widget-chart mb-2" style={{height: '97.5%' }}>
										<div className="widget-chat-wrapper-outer">
											<div className="widget-chart-content">

												<div className="text-left">
													<span className="card-title text-uppercase"> Tickets By Channel  </span>

												</div>
												{this.state.channelLoading ?
													<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
													:

													<div className="text-left" >
														{this.state.channelData.length==0 ?

															<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

															:
															<div className="bar" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
																<Chart options={barchartoptions} series={barchartseries} type="bar" id="apexchart-example-3" width="100%" />

															</div>
														}
													</div>
												}
											</div>
										</div>
									</Card>
								</Col>
								<Col lg="6" sm="12" xs="12" className="custom_col_left" >
								  <Card className="widget-chart mb-2" style={{height: '100%' }}>
										<div className="widget-chat-wrapper-outer">
											<div className="widget-chart-content">

												<div className="text-left">
													<span className="card-title text-uppercase"> Tickets Closed By Employees  </span>

												</div>
												{this.state.employeeLoading ?
													<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
													:

													<div className="text-left" >
														{this.state.empData.length == 0 ?

															<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

															:
															<div className="pie" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
																<Chart options={dougnoutoptins2} series={piechartseries2} type="pie" id="apexchart-example-3" width="100%" />

															</div>
														}
													</div>
												}
											</div>
										</div>
									</Card>
								</Col>
								<Col lg="6" sm="12" xs="12" className="custom_col_right" >
								  <Card className="widget-chart mb-2" style={{height: '100%' }}>
										<div className="widget-chat-wrapper-outer">
											<div className="widget-chart-content">

												<div className="text-left">
													<span className="card-title text-uppercase"> Tickets Closed On Month Wise </span>

												</div>
												{this.state.monthLoading ?
													<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
													:

													<div className="text-left" >
														{this.state.monthData.length==0 ?

															<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

															:
															<div className="bar" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
																<Chart options={barchartoptions1} series={barchartseries1} type="bar" id="apexchart-example-3" width="100%" />

															</div>
														}
													</div>
												}
											</div>
										</div>
									</Card>
								</Col>
							</Row>
							: '' }
							{this.state.metricType=='Work Downtime' ?
							  
							  <Card className="main-card mb-2 custom_card_view">
							
								   <CardBody>
								        <div className="text-center"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>
								   </CardBody>
								</Card>
							  : '' }
							 </div>
					  </div>
					 </div>
					}
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}
