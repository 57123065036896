import React, {Component, PropTypes} from 'react';

import {greatPlaceStyle} from './my_great_place_styles.js';

export default class MyGreatPlace extends Component {
  
  static defaultProps = {};
  
  render() {
    return (
       <div style={greatPlaceStyle}>
          <i className="pe-7s-map-marker" style={{fontSize:'30px'}}></i>&nbsp;{this.props.text}
       </div>
    );
  }
 }
