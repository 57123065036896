import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import {Helmet} from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import Circle from 'react-circle';
import {Redirect,Link} from 'react-router-dom';
import classnames from 'classnames';
import preventive_img from '../../assets/utils/images/Preventive_black.png';
import oc_img from '../../assets/utils/images/On Condition_black.png';
import corrective_img from '../../assets/utils/images/Corrective_black.png';
import periodic_img from '../../assets/utils/images/Periodic_black.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import {Doughnut} from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import ReactTooltip from 'react-tooltip';

import {
    faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
    Row, Col,
	Alert,
    Container,TabContent,TabPane,
	Card,CardBody,CardHeader,
	Table,
    ButtonGroup,
	Modal,ModalHeader,ModalBody,ModalFooter,
    ListGroup,FormGroup,Label,FormText,
    ListGroupItem, CardFooter,
    CustomInput, Input,
    UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import {Loading,Tabs,Tree,Select,Dropdown,Button,Progress,Tooltip,Tag,Dialog,DateRangePicker} from 'element-react';

const cardstyles = {borderRight: '1px solid #545cd8'}

const loaddiv = {marginTop: '155px' }

export default class FinancialPerformance extends Component {
	constructor(props) {
        super(props);

        this.state = {
			accTok : Sanitize.getStoreageItem('accessToken'),
			reTok : Sanitize.getStoreageItem('refreshToken'),
			isDirect : false,
			isDataLoaded : false,
			metricType : 'Space Cost',
			
        };
			
	
    }
	
	
	componentDidMount(){
		
		if(this.props.location.state){
			
		   this.getdata();
		  
		}
	
	}
	
	getdata(){
		
			if(this.props.location.state.modelkeyid=='financial_performance' || this.props.location.state.modelkeyid=='financial_performance_single'){
				
			
							this.setState({left_style:'left_search_card'});
							this.setState({sticky_searchbar:'sticky_top_searchbar'});
							this.setState({topbar_buttons:'topbar_buttons'});
							this.setState({content_card:'content_top_card'});
							this.setState({isDataLoaded : true});
			
		  }
	  
	}
	  
	  getSingleMetric(type){
		
		this.setState({metricType:type});
		
	  }
	
	
	
	  directtoanother(){
		
		this.setState({isDirect:true});
		
	  }
	  
	  
	 
	render() {
		
		
	
	  
	  if(this.state.isDirect && this.props.location.state.modelkeyid=='financial_performance'){
		 
		
			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid:'sites',site_id: this.props.location.state.uid,site_name:this.props.location.state.value,type : '13'}
			}}
			/>);

	  }
	  
	  if(this.state.isDirect && this.props.location.state.modelkeyid=='financial_performance_single'){
		 
		
			return (<Redirect to={{
				pathname: '/metricslist',
				state: { modelkeyid:'sites',site_id: this.props.location.state.uid,site_name:this.props.location.state.value}
			}}
			/>);

	  }
	  
	  
	  
	 
		  
        return (
            <Fragment>
			<Helmet>
					<title>{GC.APPTITLE} | Financial Performance </title>
				</Helmet>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
					
					 <div className="topbar-header">
				       <div className="topbar-header__content">	
					   
					   <PageTitleAlt3
							heading=""
							subheading=""
							icon=""
							menuLevel1={this.props.location.state  ? this.props.location.state.value : 'Financial Performance' }
							menuLevel2="Financial Performance"
							menuLevel1click = { () => this.props.location.state ? this.directtoanother() : ''}
						    menuLevel1link = {this.props.location.state ? 'javascript:;' : false} 
							
							isbreadcumb={true}
						/>
                  
					                 <div className="topbar_buttons">
												
							            </div>
						</div>
					</div>
					
					
					  {!this.state.isDataLoaded ?
					
							 <div style={loaddiv}><center><Loader type="ball-clip-rotate"/></center></div>
					    :
			 			
				          <div className="main_data">
					           <div className={this.state.sticky_searchbar} style={{borderBottom:'1px solid rgb(209, 219, 229)'}}> 
										   
										<h5 style={{textAlign: 'center',verticalAlign: 'middle',marginTop: '5px',color:'#545cd8'}}><i className="lnr-highlight font-size-sm"/>Financial Performance </h5>	   
											
								</div>
										
										 
												
											
				                <div className={this.state.left_style} id="left_search_card_id" style={{marginTop: '58px'}}>
							      
										
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Space Cost')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Space Cost' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Space Cost" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'nowrap'}}>Space Cost</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Operational Cost')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Operational Cost' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Operational Cost" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>Operational Cost</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('Capital Budget')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'Capital Budget' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Capital Budget" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>Capital Budget</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												<a href="javascript:;" style={{textDecoration: 'none',color:'#111517'}} onClick={() => this.getSingleMetric('TCO')}>
														<div style={{padding: '10px 5px'}} className={this.state.metricType == 'TCO' ? 'active_left_data' : ''}>
															<Tooltip className="item" content="Total Cost of Ownership" placement="top-center" >
															    <span><b style={{fontSize: '15px',whiteSpace:'pre-wrap'}}>Total Cost of Ownership</b></span>
															</Tooltip>
															
														</div>
														<hr style={{marginBottom:'2px',marginTop:'0px',borderTop:'1px solid #d1dbe5',marginLeft: '-9px',width:'240px'}}/>
												</a>
												
												
												<br/><br/><br/><br/><br/><br/><br/><br/>
												
							   </div>
						
						
				<div className={this.state.content_card}>
					 
					 
					  
					   <div>
			
						   <Card className="main-card mb-2 custom_card_view">
						
							   <CardBody>
							      <div className="text-center"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>
							   
							   </CardBody>
						    </Card>
						 </div>
					  </div>
					 </div>
					}
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}
