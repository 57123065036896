import React, {Fragment, Component} from "react";

import Slider from "react-slick";
import SimpleReactValidator from 'simple-react-validator';
import * as Reqst from '../../../tie/Reqst';
import * as GC from '../../../tie/GlobalConstants';
import {Helmet} from 'react-helmet';
import Sanitize from '../../../tie/Sanitize';
import {Redirect,Link} from 'react-router-dom';
import $ from 'jquery';
import swal from 'sweetalert';
import {CLIENTID,CLIENTSECRET} from '../../../tie/GlobalConstants';

import bg1 from '../../../assets/utils/images/originals/city.jpg';
import bg2 from '../../../assets/utils/images/originals/citydark.jpg';
import bg3 from '../../../assets/utils/images/originals/citynights.jpg';
import Logo from '../../../assets/utils/images/logo.png';

import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import LaddaButton, {
    EXPAND_LEFT
} from 'react-ladda';

export default class Login extends Component {
	 constructor(props) {
        super(props);

        this.state = {
            isBtnLoading: false,
			showAlert : false,
			btnText : 'Login',
			isRedirect : false,
			cdate : (new Date().getFullYear())+'-'+(this.addzero(new Date().getMonth()+1))+'-'+(this.addzero(new Date().getDate()))+' '+(this.addzero(new Date().getHours()))+':'+(this.addzero(new Date().getMinutes()))+':'+(this.addzero(new Date().getSeconds())),
			progress : 0.5
        };
	  this.validator = new SimpleReactValidator();
	  this.handleFormSubmit = this.handleFormSubmit.bind(this);
	  this.handleLoginChange = this.handleLoginChange.bind(this);
	  this.handleLoginBlur = this.handleLoginBlur.bind(this);
     
    }
	
	handleLoginChange(e){
		this.setState({[e.target.name]: e.target.value});
	}
	
	handleLoginBlur(e){
		if (!this.validator.allValid()) {
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	addzero(day){
				
				var temp = ''+day;
				if(temp.length == 1){

				temp = "0"+temp;
				return temp;
				}
				else{

				return temp;
				}

		}
		
		
	
	
	handleFormSubmit(e){
		e.preventDefault();
		if (this.validator.allValid()) {
			this.setState({isBtnLoading:true,btnText:'Authenticating.....'});
			//var data = Sanitize.objectifyForm($("#frmLogin").serializeArray());
			//data = Reqst.Token(data);
			Reqst.axPost('loginviaoauth',$("#frmLogin").serialize()).then(res=>{
				
				 if(res){
					
					Sanitize.setStorage('accessToken',res.data.access_token);
				    Sanitize.setStorage('refreshToken',res.data.refresh_token);
					Sanitize.setStorage('isuser_login','yes');
					this.userdata();
				}
				else{
					
					this.setState({isBtnLoading: false,btnText:'Login',hasOtpVerify: false});	
					swal("Error","Invalid Username Or Password","error");
					$("#i__passWord").val('');
					
				} 
			}).catch(error => {

				  //console.log(error.response.status);
				  this.setState({isBtnLoading: false,btnText:'Login',hasOtpVerify: false});	
				   swal("Error","Invalid Username Or Password","error");
				    $("#i__passWord").val('');
				 
			  });
		}
		else{
			this.validator.showMessages();			
			this.forceUpdate();
		}
	}
	
	
	 async userdata(){
			var token = Sanitize.getStoreageItem('accessToken');
			await Reqst.axGet('getuserdata/'+token).then((result) => {
				
				let res = result['data'];
		
					if(result['data'].code=='401'){
				
						this.setState({isBtnLoading:false,btnText:'Login',showLoader: false,isRedirect: false});
						swal("Error","Invalid Username Or Password","error");
				        $("#i__passWord").val('');
						
					}
					else{
						if(res){
							Sanitize.setStorage('employee_id',res.employee_id);
				            Sanitize.setStorage('employee_name',res.employee_name);
							Sanitize.setStorage('company_name',res.company_name);
							//Sanitize.setStorage('employee_uid',res.sub);
							Sanitize.setStorage('company_id',res.company_id);
							Sanitize.setStorage('company_mail',res.email);
							Sanitize.setStorage('company_mobile',res.phone_number);
							Sanitize.setStorage('company_ids',res.company_ids);
							Sanitize.setStorage('child_company_ids',res.child_company_ids);
							
							
							this.setState({showLoader: false,isRedirect: true});//this.getCompanyData();
						}
					}
			});
		}
		
		 getCompanyData(){
			var token = Sanitize.getStoreageItem('accessToken');
			var user = Sanitize.getStoreageItem('employee_uid');
			 Reqst.axGet('getcompanydatabyuser/'+token+'/'+user).then((result) => {
				
				let res = result['data'][0];
		
					if(result['data'].code=='401'){
				
						this.setState({isBtnLoading:false,showLoader: false,isRedirect: false});
						swal("Error","Invalid Username Or Password","error");
				        $("#i__passWord").val('');
						
					}
					else{
						if(res){
							Sanitize.setStorage('company_id',res.company_id[0]);
							Sanitize.setStorage('company_mail',res.email);
							Sanitize.setStorage('company_mobile',res.mobile);
							this.setState({showLoader: false,isRedirect: true});
						}
					}
			});
		}
	
    render() {
		
		 if(this.state.isRedirect && Sanitize.getStoreageItem('accessToken')!='' && Sanitize.getStoreageItem('refreshToken')!='')
		{
			return(<Redirect to="/" />);
		}
		
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            initialSlide: 0,
            autoplay: true,
            adaptiveHeight: true

        };
        return (

            <Fragment>
			<Helmet>
					<title>{GC.APPTITLE} | Login </title>
				</Helmet>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="4" className="d-none d-lg-block">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg1 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Revolutionize Asset Management</h3>
                                            <p>
                                                Evexia Platform harnesses the power of Digital transformation via cutting-edge methodology geared for the digital economy industry. 
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg3 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Standard Based Approach</h3>
                                            <p>
                                                Enablement of LEED/SITES/WELL certification using ISO 55000 standard as a framework.

                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                        <div className="slide-img-bg opacity-6"
                                             style={{
                                                 backgroundImage: 'url(' + bg2 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Predict Your Asset Performance</h3>
                                            <p>
                                                Data driven Asset lifecycle management with insights to resolve the problems using Artificial Intelligence (AI).
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                        <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="app-logo">
								<img src={Logo} style={{height: '65px', width: '65px'}}/>
								</div>
                                <h4 className="mb-0">
                                    <div>Welcome back,</div>
                                    <span>Please sign in to your account.</span>
                                </h4>
                                <h6 className="mt-3">
                                    No account?{' '}
                                    <a href="javascript:void(0);" className="text-primary">Sign up now</a>
                                </h6>
                                <Row className="divider"/>
                                <div>
                                  <Form id="frmLogin" className="form" method="post" action="" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >
					                    
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="exampleEmail">Username</Label>
                                                    <Input type="text" name="username"  id="i__userName"
                                                           placeholder="Username" required onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
														    {this.validator.message('Username', this.state.username, 'required')}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="examplePassword">Password</Label>
                                                    <Input type="password" name="password" required id="i__passWord"
                                                           placeholder="**************" onChange={this.handleLoginChange} onBlur={this.handleLoginBlur} />
														   {this.validator.message('Password', this.state.password, 'required')}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                       
                                        <Row className="divider"/>
                                        <div className="d-flex align-items-center">
                                            <div className="ml-auto">
                                                <a href="javascript:void(0);" className="btn-lg btn btn-link">Recover
                                                    Password</a>{' '}{' '}
                                               
												<LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                         loading={this.state.isBtnLoading}
                                              
                                                         data-style={EXPAND_LEFT}
                                                 >
												 {this.state.btnText}
                                            </LaddaButton>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}
