import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import locale from '../../locale';
import { Redirect, Link } from 'react-router-dom';
import equimg from '../../assets/utils/images/sample_equip.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';



import {
	Row, Col,
	Alert,
	Container, TabContent, TabPane,
	Card, CardBody, CardHeader,
	Table,
	Progress,
	ButtonGroup,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroup, FormGroup, Label, FormText,
	ListGroupItem, CardFooter,
	CustomInput, Input,
	UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import { Loading, Tabs, Tree, Select, Dropdown, Button, Tooltip, Tag, Dialog } from 'element-react';

const cardstyles = { borderRight: '1px solid #545cd8' }

const loaddiv = { marginTop: '155px' }

export default class ViewEquipment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pdfnumPages: null,
			pdfpageNumber: 1,
			openLeft: false,
			equiplistLoading: false,
			search_query: '',
			openRight: false,
			formmode: false,
			equipoffset: 30,
			equipFilterScrollLoad: false,
			filters: [],
			savedtags: [],
			isEquipmentsIsLoaded: false,
			is_search_enable: false,
			documentSingleImgViewLoad: false,
			imgviewdialog: false,
			eqname: '',
			gauags: [],
			eq_vendor_id: '',
			query: '',
			queryname: '',
			query1: '',
			queryname1: '',
			query2: '',
			queryname2: '',
			isOrderLink: false,
			isSpaceLink: false,
			meters: [],
			cats: [],
			audits: [],
			equiplist: [],
			documents: [],
			relequipdata: [],
			selsearchtext: '',
			equipsearchname: '',
			assets: [],
			viewfilename: '',
			relequiptreedata: [],
			timelinelimit: 0,
			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			dataLoading: false,
			dataend: false,
			filterend: false,
			updateLoading: false,
			meterLoading: false,
			equiplistFilterLoading: false,
			toggled: false,
			gauageLoading: false,
			auditLoading: false,
			orderLoading: false,
			isequipment_loaded: false,
			assetsLoading: false,
			documentsLoading: false,
			metersLogsLoading: false,
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			gaugesLogsLoading: false,
			gaugeslogsdata: [],
			meterlogsdata: [],
			meterassets: [],
			eq_messages: [],
			locs: [],
			gauageassets: [],
			documentdata: '',
			documentSingleLoad: false,
			documentId: '',
			teams: [],
			vendors: [],
			vendorsLoading: false,
			isNameFilter: false,
			EquipFilterData: [],
			partners: [],
			isDirect: false,
			searchDialog: false,
			documentSingleViewLoad: false,
			pacount: 0,
			pmcount: 0,
			occount: 0,
			bmcount: 0,
			doccardcount: 0,
			ordersdata: [],
			left_style: '',
			sendMessage: false,
			search_query: '',
			content_card: '',
			sticky_searchbar: '',
			topbar_buttons: '',
			ordersLatedata: [],
			activeTab: '1',
			isAuditLogsLoaded: false,
			documentviewdata: '',
			id: this.props.match.params.id,
			chartName: '',
			ChartTimeseries: false,
			chart_dates: [],
			chart_values: [],
			chartType: '',
			isAnalyticLink: false,
			chartuId: '',
			chartId: '',
			chartData: []

		};

		this.toggle = this.toggle.bind(this);
		this.onTabChange = this.onTabChange.bind(this);
		this.onEquipChange = this.onEquipChange.bind(this);
		this.onFilterApply = this.onFilterApply.bind(this);
		this.onEquipNameChange = this.onEquipNameChange.bind(this);
		this.equipfilters = this.equipfilters.bind(this);
		this.onSendMessage = this.onSendMessage.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.updateEquipment = this.updateEquipment.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onFormModeChange = this.onFormModeChange.bind(this);
		this.handleCatChange = this.handleCatChange.bind(this);
		this.handleLocChange = this.handleLocChange.bind(this);
		this.getEquipListonScroll = this.getEquipListonScroll.bind(this);
		this.handleTeamChange = this.handleTeamChange.bind(this);
		this.handlePartnerChange = this.handlePartnerChange.bind(this);
		this.handleVendorChange = this.handleVendorChange.bind(this);
		this.getEquipmentList = this.getEquipmentList.bind(this);
		this.onScroll = this.onScroll.bind(this);
		this.onEquipScroll = this.onEquipScroll.bind(this);
		this.getAuditLogs = this.getAuditLogs.bind(this);
		this.onVendorClear = this.onVendorClear.bind(this);
		this.onSelSearch = this.onSelSearch.bind(this);
		this.getdatesofmonth = this.getdatesofmonth.bind(this);
		this.setFilterType = this.setFilterType.bind(this);
	}


	setFilterType(column) {

		this.setState({ filtertype: column });

		if (column == 'This Month') {

			this.getdatesofmonth();
		}
		else if (column == 'Today') {

			this.getTodayData();

		}
		else if (column == 'This Week') {

			this.getdatesofweek();

		}
		else {

			this.onGetLogsChart(this.state.chartId, this.state.chartName, this.state.chartType);

		}

	}

	daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	}

	getdatesofmonth() {

		this.setState({ queryText: '' });

		var date = new Date();
		var firstDay = new Date(date.getFullYear(),
			date.getMonth(), 1);

		var lastDay = new Date(date.getFullYear(),
			date.getMonth(), this.daysInMonth(date.getMonth() + 1,
				date.getFullYear()));

		firstDay = this.getdateoforginal(firstDay);
		lastDay = this.getdateoforginal(lastDay);

		var queryText = ',["create_date", ">=", "' + firstDay + '"],["create_date", "<", "' + lastDay + '"]'

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id);
		}
		else {

			this.getmetersdata(id);

		}
	}


	getdatesofweek() {

		this.setState({ queryText: '' });

		var curr = new Date; // get current date
		var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
		var last = first + 6; // last day is the first day + 6

		var firstDay = new Date(curr.setDate(first)).toUTCString();
		var lastDay = new Date(curr.setDate(last)).toUTCString();

		firstDay = this.getdateoforginal(firstDay);
		lastDay = this.getdateoforginal(lastDay);

		var queryText = ',["create_date", ">=", "' + firstDay + '"],["create_date", "<", "' + lastDay + '"]'

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id);
		}
		else {

			this.getmetersdata(id);

		}
	}

	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			hours = hours < 10 ? '0' + hours : hours;
			minutes = minutes < 10 ? '0' + minutes : minutes;
			seconds = seconds < 10 ? '0' + seconds : seconds;

			var strTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}

	getTodayData() {

		this.setState({ queryText: '' });

		var date1 = new Date();
		var year = date1.getFullYear();
		var month = (date1.getMonth() + 1);
		var day = date1.getDate()
		var hours = date1.getHours();
		var minutes = date1.getMinutes();
		var seconds = date1.getSeconds();
		month = month < 10 ? '0' + month : month;
		day = day < 10 ? '0' + day : day;
		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		seconds = seconds < 10 ? '0' + seconds : seconds;

		var d = new Date();
		var date = d.setDate(d.getDate() - 1);

		var yesterday = this.getdateoforginal(date);

		var today = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

		var queryText = ',["create_date", ">", "' + yesterday + '"],["create_date", "<=", "' + today + '"]';

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id);
		}
		else {

			this.getmetersdata(id);

		}



	}


	getChartData() {

		var data = this.state.chartData;

		var dates = []; var values = [];

		for (var i = 0; i < data.length; i++) {

			if (data[i].mroord_count != 0) {

				dates.push(data[i].create_date);
				values.push(data[i].value);
			}

		}

		this.setState({ chart_dates: dates, chart_values: values });
	}

	getgaugesdata(guageid) {

		this.setState({ chartLoading: true });
		this.setState({ chartData: [], chart_dates: [], chart_values: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var id = this.state.id;

		var queryText = this.state.queryText;

		var query = '"&",["equipment_id", "in", [' + id + ']],["gauge_id","=",' + guageid + ']' + queryText;

		var data = { "token": token, "query": query };
		Reqst.axPost('gaugeslogs', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ chartLoading: false });
				this.setState({ chartData: res });
				this.getChartData();
			}
		});
	}

	getmetersdata(meterid) {

		this.setState({ chartLoading: true });
		this.setState({ chartData: [], chart_dates: [], chart_values: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var id = this.state.id;

		var queryText = this.state.queryText;

		var query = '"&",["equipment_id", "in", [' + id + ']],["meter_id","=",' + meterid + ']' + queryText;

		var data = { "token": token, "query": query };
		Reqst.axPost('meterlogs', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ chartLoading: false });
				this.setState({ chartData: res });
				this.getChartData();
			}
		});
	}

	onGetLogsChart(id, name, type) {
		this.setState({ queryText: '' });
		this.setState({ filtertype: '' });
		this.state.queryText = '';
		this.setState({ ChartTimeseries: true });
		this.setState({ chartName: name });
		this.setState({ chartType: type });
		this.setState({ chartId: id });


		if (type == 'gauge') {

			this.getgaugesdata(id);

		}
		else {

			this.getmetersdata(id);
		}

	}


	onFilterApply(id, query, name) {

		query = query.substr(1).slice(0, -1);
		this.setState({ searchDialog: false });
		this.equipsavedfilters(query);

		var orgdata = [{ "key": id, "name": name, "query": query }];

		this.setState({ tags: [] });

		this.setState({ savedtags: orgdata });


		console.log(this.state.savedtags);

	}

	toggleMenu() {
		this.setState({ toggled: !this.state.toggled });
	}

	handleVendorChange(val) {
		this.setState({
			eq_vendor_update_id: val
		});

		console.log(this.state.eq_vendor_update_id);
	}

	handlePartnerChange(val) {
		this.setState({
			eq_manufact_update_id: val
		});
	}

	handleTeamChange(val) {
		this.setState({
			eq_mainteam_id: val
		});
	}

	handleChange(val) {
		this.setState({
			eq_crit: val
		});
	}

	handleLocChange(val) {
		this.setState({
			eqloc_id: val
		});
	}

	handleCatChange(val) {
		this.setState({
			eq_catid: val
		});
	}

	onInputChange(e) {

		this.setState({ [e.target.name]: e.target.value });
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	onDocumentLoadSuccess = ({ pdfnumPages }) => {
		this.setState({ pdfnumPages });
	}


	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}

	/* componentDidMount(){
		this.state._isMounted = true;
	 if (this.state._isMounted) {
		this.getdata();
	 }
		
	} */

	/* componentWillMount() {
		//this.state._isMounted = false;
		this.getdata();
	} */



	componentDidMount() {

		this.getdata();
		this.getEquipmentList();
		this.getFilters();
	}



	componentDidUpdate() {

		window.addEventListener('scroll', this.onEquipScroll());
		if (this.equipsearchname) {
			this.equipsearchname.focus();
			$("#search_text").focus();
		}

	}


	getFilters() {

		this.setState({ equiplistLoading: true });
		this.setState({ filters: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var data = { "token": token, "model": "mro.equipment" };
		Reqst.axPost('getfilters', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ equiplistLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ equiplistLoading: false });

				this.setState({ filters: res });


			}
		});

	}

	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;
			return strTime;
		}
		else {

			return 'Not Assigned';
		}

	}

	getdateofdttime(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime + ' ' + strTime1;
		}
		else {

			return 'Not Assigned';
		}

	}

	convertLoalTime(date_future) {

		var date_future = new Date(date_future);

		var year = date_future.getFullYear();
		var month = (date_future.getMonth() + 1);
		var day = date_future.getDate()

		var addhours = date_future.getHours() + 5;
		var mints = date_future.getMinutes();
		var secs = date_future.getMinutes();

		if (addhours == '13') {

			addhours = '01';
		}
		else if (addhours == '14') {

			addhours = '02';

		}
		else if (addhours == '15') {

			addhours = '03';

		}
		else if (addhours == '16') {

			addhours = '04';

		}
		else if (addhours == '17') {

			addhours = '05';

		}

		return year + '-' + month + '-' + day + ' ' + addhours + ':' + mints + ':' + secs

	}

	getdateoftimings(date) {

		var cdate = this.convertLoalTime(date);



		var date_future = new Date(cdate);



		var date_now = new Date();


		// get total seconds between the times
		var delta = Math.abs(date_future - date_now) / 1000;

		// calculate (and subtract) whole days
		var days = Math.floor(delta / 86400);
		delta -= days * 86400;

		// calculate (and subtract) whole hours
		var hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;

		// calculate (and subtract) whole minutes
		var minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;

		if (days != 0 || days > 2) {

			return this.getdateofdttime(date);
		}
		else if (days == 0 && hours != 0) {

			return hours + ' hours ago';

		}
		else {

			return minutes + ' minutes ago';

		}

	}

	validitycheck(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);

			var date3 = new Date();
			var year = date3.getFullYear();
			var month = (date3.getMonth() + 1);
			var day = date3.getDate()
			var strTime = year + '-' + month + '-' + day;

			var date2 = new Date(strTime);
			var diffTime = Math.abs(date2.getTime() - date1.getTime());
			var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

			if (parseInt(diffDays) > 0) {
				if (parseInt(diffDays) < 10) {
					return <div className="text-center text-danger"><i className="lnr-hourglass font-size-lg"></i>&nbsp;&nbsp;<span className="">Warranty Going to Expire  in {diffDays} Days</span></div>;
				}
				else {
					return <div className="text-center text-info"><i className="pe-7s-info font-size-lg"></i>&nbsp;&nbsp;<span className="">Warranty Going to Expire  in {diffDays} Days</span></div>;
				}
			}
			else {
				return <div className="text-center text-danger"><i className="lnr-calendar-full font-size-lg"></i>&nbsp;&nbsp;<span className="">Warranty Expired</span></div>;
			}

		}
		else {

			return <div className="text-center text-warning"><i className="pe-7s-attention font-size-lg"></i>&nbsp;&nbsp;<span className="">No Warranty Information Available</span></div>;
		}

	}

	getnovalues(str) {

		if (str != '' && str != null) {
			return str;
		}
		else {

			return 'Not Assigned';
		}

	}

	onTabChange(tab) {

		this.setState({ activeTab: tab });

		/* if(tab=='6'){
			
			this.getgauagedata(this.state.eq_gauges);
			this.setState({isGaugeLoaded:true});
			
		}
		else if(tab=='7'){
			
			this.getmeterdata(this.state.eq_meters);
			this.setState({isMeterLoaded:true});
			
		} */
		if (tab == '2') {

			this.getmaintainorderdata();
			this.setState({ isOrderCountLoaded: true });
			this.getmaintainorderlatedata();
			this.setState({ isOrderLateCountLoaded: true });
		}
		else if (tab == '3') {

			/* this.getGaugesLogsdata();
			this.setState({isGaugesLogsLoaded:true});
			this.getMetersLogsdata();
			this.setState({isMeterLogsLoaded:true}); */
			this.getLastAssetValues();
			this.setState({ isLastAssetsLoaded: true });
		}
		else if (tab == '8') {
			this.state.timelinelimit = 0;
			this.state.audits = [];
			this.setState({ timelinelimit: 0, audits: [] });

			this.getAuditLogs();
			window.addEventListener('scroll', this.onScroll());

		}
		else if (tab == '9') {

			this.getDocuments();
			this.setState({ isDocumentsLoaded: true });
		}


	}


	onEquipChange(id) {

		this.setState({ id: id });
		this.setState({ openLeft: !this.state.openLeft, openRight: false });
		this.setState({ eq_messages: [] });
		this.state.eq_messages = [];
		this.setState({ isOrderCountLoaded: false, isOrderLateCountLoaded: false, isLastAssetsLoaded: false, isAuditLogsLoaded: false, isDocumentsLoaded: false });
		this.getdatabyid(id);
		this.setState({ activeTab: '1' });
		this.setState({ queryText: '', filtertype: '' });


	}

	onSubTabChange(tab) {

		if (tab == '6') {

			this.getgauagedata(this.state.eq_gauges);
			this.setState({ isGaugeLoaded: true });

		}
		else if (tab == '7') {

			this.getmeterdata(this.state.eq_meters);
			this.setState({ isMeterLoaded: true });

		}


	}

	getmaintainOrderStateCount(mt, status) {

		var data = this.state.ordersdata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (mt != 'oc' && status == 'open') {
				if (((data[i].maintenance_type == mt) && ((data[i].state == 'released') || (data[i].state == 'ready'))))
					result += data[i].__count;
			}
			else if (mt != 'oc' && status == 'progress') {
				if (((data[i].maintenance_type == mt) && ((data[i].state == 'assigned') || (data[i].state == 'in_progress') || (data[i].state == 'pause'))))
					result += data[i].__count;
			}
			else if (mt != 'oc' && status == 'done') {
				if (((data[i].maintenance_type == mt) && (data[i].state == 'done')))
					result += data[i].__count;
			}
			else if (mt == 'oc' && status == 'open') {
				if ((((data[i].maintenance_type == mt) || (data[i].maintenance_type == 'pr')) && ((data[i].state == 'released') || (data[i].state == 'ready'))))
					result += data[i].__count;
			}
			else if (mt == 'oc' && status == 'progress') {
				if ((((data[i].maintenance_type == mt) || (data[i].maintenance_type == 'pr')) && ((data[i].state == 'assigned') || (data[i].state == 'in_progress') || (data[i].state == 'pause'))))
					result += data[i].__count;
			}
			else if (mt == 'oc' && status == 'done') {
				if ((((data[i].maintenance_type == mt) || (data[i].maintenance_type == 'pr')) && (data[i].state == 'done')))
					result += data[i].__count;
			}

		}

		return result;
	}


	getmaintainOrderStateLateCount(mt) {

		var data = this.state.ordersLatedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (mt != 'oc') {
				if (((data[i].maintenance_type == mt)))
					result += data[i].__count;
			}
			else if (mt == 'oc') {
				if ((((data[i].maintenance_type == mt) || (data[i].maintenance_type == 'pr'))))
					result += data[i].__count;
			}
		}

		return result;
	}

	getmaintainOrdersCount() {

		var data = this.state.ordersdata;

		var pa = 0; var bm = 0; var pm = 0; var oc = 0;

		for (var i = 0; i < data.length; i++) {
			if (((data[i].maintenance_type == 'pr') || (data[i].maintenance_type == 'oc')))
				oc += data[i].__count;
			else if ((data[i].maintenance_type == 'bm'))
				bm += data[i].__count;
			else if ((data[i].maintenance_type == 'pm'))
				pm += data[i].__count;
			else if ((data[i].maintenance_type == 'pr'))
				pa += data[i].__count;

		}

		this.setState({ pacount: pa, bmcount: bm, pmcount: pm, occount: oc });
	}


	getdata() {
		this.setState({ dataLoading: true });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getequipmentdata/' + token + '/' + this.state.id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				if (res) {
					this.state.eq_messages = res.message_ids;
					this.setState({
						dataLoading: false,
						eqname: res.name,
						eqloc: res.location_id[1],
						eqloc_id: res.location_id[0],
						parname: res.parent_id[1],
						eq_desc: res.equipment_number,
						eq_num: res.equipment_seq,
						eq_mcsn: res.serial,
						eq_model: res.model,
						eq_cat: res.category_id[1],
						eq_catid: res.category_id[0],
						eq_comp: res.company_id[1],
						eq_doc_count: res.doc_count,
						eq_ord_count: res.mroord_count,
						eq_gauge_count: res.gauge_count,
						eq_meter_count: res.meter_count,
						eq_state: res.state,
						eq_crit: res.criticality,
						eq_isparent: res.is_parent,
						eq_indate: res.start_date,
						eq_maindate: res.maintenance_date,
						eq_assetid: res.asset_id,
						eq_purvalue: res.purchase_value,
						eq_warsdate: res.warranty_start_date,
						eq_waredate: res.warranty_end_date,
						eq_manufact: res.manufacturer_id[1],
						eq_manufact_id: res.manufacturer_id[0],
						eq_mainteam: res.maintenance_team_id[1],
						eq_mainteam_id: res.maintenance_team_id[0],
						eq_vendor: res.vendor_id[1],
						eq_vendor_id: res.vendor_id[0],
						eq_purdate: res.purchase_date,
						eq_notes: res.note,
						eq_lat: res.latitude,
						eq_long: res.longitude,
						eq_relequips: res.mro_equ_tmpl_lines,
						eq_xpos: res.xpos,
						eq_ypos: res.ypos,
						eq_gauges: res.gauge_lines_ids,
						eq_meters: res.meter_lines_ids,
						eq_messages: res.message_ids,
					});

					this.setState({ left_style: 'left_search_card', sticky_searchbar: 'sticky_searchbar', topbar_buttons: 'topbar_buttons', content_card: 'content_card', isequipment_loaded: true });

					this.getrelequipdata(this.state.eq_relequips);

				}
			}
		});
	}

	getdatabyid(id) {
		this.setState({ dataLoading: true });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getequipmentdata/' + token + '/' + id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.state.eq_messages = res.message_ids;
					this.setState({
						eqname: res.name,
						eqloc: res.location_id[1],
						parname: res.parent_id[1],
						eqloc_id: res.location_id[0],
						eq_desc: res.equipment_number,
						eq_num: res.equipment_seq,
						eq_mcsn: res.serial,
						eq_model: res.model,
						eq_cat: res.category_id[1],
						eq_comp: res.company_id[1],
						eq_doc_count: res.doc_count,
						eq_ord_count: res.mroord_count,
						eq_gauge_count: res.gauge_count,
						eq_meter_count: res.meter_count,
						eq_state: res.state,
						eq_crit: res.criticality,
						eq_isparent: res.is_parent,
						eq_indate: res.start_date,
						eq_maindate: res.maintenance_date,
						eq_assetid: res.asset_id,
						eq_purvalue: res.purchase_value,
						eq_warsdate: res.warranty_start_date,
						eq_waredate: res.warranty_end_date,
						eq_manufact: res.manufacturer_id[1],
						eq_manufact_id: res.manufacturer_id[0],
						eq_catid: res.category_id[0],
						eq_mainteam: res.maintenance_team_id[1],
						eq_vendor: res.vendor_id[1],
						eq_purdate: res.purchase_date,
						eq_vendor_id: res.vendor_id[0],
						eq_mainteam_id: res.maintenance_team_id[0],
						eq_notes: res.note,
						eq_lat: res.latitude,
						eq_long: res.longitude,
						eq_relequips: res.mro_equ_tmpl_lines,
						eq_xpos: res.xpos,
						eq_ypos: res.ypos,
						eq_gauges: res.gauge_lines_ids,
						eq_meters: res.meter_lines_ids,
						eq_messages: res.message_ids,
					});

					this.getrelequipdata(this.state.eq_relequips);

				}
			}
		});
	}

	async onStateChange(state) {
		this.setState({ dataLoading: true });
		this.setState({ toggled: false });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('setequipmentstate/' + token + '/' + state + '/' + this.state.id).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({ dataLoading: false });
					this.getdata();
					swal("Success", "State Changed Successfully..", "success");
				}
				else {

					swal("Error", "Unable to change the State", "error");
				}
			}
		});
	}

	async getEquipmentList() {

		try {

			//if(!this.state.isequipment_loaded){
			this.setState({ equiplistLoading: true });
			//this.setState({orderlist : []});
			var token = Sanitize.getStoreageItem('accessToken');
			var limit = '30';
			var offset = '0';

			await Reqst.axGet('getequipments/' + token + '/' + limit + '/' + offset).then((result) => {

				let res = result['data'];
				console.log(res);
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else if(result['data'].code == '500'){
				
					this.setState({ equiplistLoading: false });
					alert(result['data'].data);
			    }
				else {

					console.log(res);

					this.setState({ equiplist: res });

					this.setState({ equiplistLoading: false });
				}
			});
		}
		//}
		catch (e) {

			console.log(e);
		}
	}



	getrelequipdata(rids) {

		if (rids != '') {

			this.setState({ dataLoading: true });
			this.setState({ relequipdata: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			Reqst.axGet('getreleatedequips/' + token + '/' + rids).then((result) => {

				let res = result['data'];
				if (result['data'].code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ dataLoading: false });
					if (res) {
						this.setState({
							relequipdata: res
						});
						this.getTreeEquipments();
					}
				}
			});
		}
	}

	getTreeEquipments() {

		var rels = [];

		var data = this.state.relequipdata;

		for (var i = 0; i < data.length; i++) {


			rels.push({ "label": data[i].name + ' (' + this.getstatenamelabel(data[i].state) + ')' });

		}

		var treedata = [
			{
				label: this.state.eqname,
				children: rels
			}
		];



		this.setState({ relequiptreedata: treedata });



	}


	async getmaintainorderdata() {
		if (!this.state.isOrderCountLoaded) {
			this.setState({ orderLoading: true });
			this.setState({ ordersdata: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			await Reqst.axGet('getequipmentmaintain/' + token + '/' + this.state.id).then((result) => {

				let res = result['data'];

				this.setState({ orderLoading: false });
				if (result['data'].code == '401') {

					this.getRefreshToken();

				}
				else {
					if (res) {
						this.setState({
							ordersdata: res
						});
						this.getmaintainOrdersCount();
					}
				}
			});
		}
	}

	async getmaintainorderlatedata() {
		if (!this.state.isOrderLateCountLoaded) {
			this.setState({ orderLoading: true });
			this.setState({ ordersLatedata: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			await Reqst.axGet('getequipmentmaintainoverdue/' + token + '/' + this.state.id + '/' + this.state.cdate).then((result) => {

				let res = result['data'];

				this.setState({ orderLoading: false });
				if (result['data'].code == '401') {

					this.getRefreshToken();

				}
				else {
					if (res) {
						this.setState({
							ordersLatedata: res
						});
					}
				}
			});
		}
	}

	async getGaugesLogsdata() {
		if (!this.state.isGaugesLogsLoaded) {
			this.setState({ gaugesLogsLoading: true });
			this.setState({ gaugeslogsdata: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			await Reqst.axGet('getequipmentgauagegroups/' + token + '/' + this.state.id).then((result) => {

				let res = result['data'];

				this.setState({ gaugesLogsLoading: false });
				if (result['data'].code == '401') {

					this.getRefreshToken();

				}
				else {
					if (res) {
						this.setState({
							gaugeslogsdata: res
						});
					}
				}
			});
		}
	}

	async getMetersLogsdata() {
		if (!this.state.isMeterLogsLoaded) {
			this.setState({ metersLogsLoading: true });
			this.setState({ meterlogsdata: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			await Reqst.axGet('getequipmentmetersgroups/' + token + '/' + this.state.id).then((result) => {

				let res = result['data'];

				this.setState({ metersLogsLoading: false });
				if (result['data'].code == '401') {

					this.getRefreshToken();

				}
				else {
					if (res) {
						this.setState({
							meterlogsdata: res
						});
					}
				}
			});
		}
	}



	handleCloseSavedTags(tag) {

		this.setState({ isNameFilter: false });
		this.setState({ equipsearchname: '', savedtags: [] });
		this.setState({ search_query: '' });
		this.setState({ is_search_enable: false });
		$("#search_text").val('');

	}

	async getmeterdata(mids) {
		if (mids != '') {
			if (!this.state.isMeterLoaded) {
				this.setState({ meterLoading: true });
				this.setState({ meters: [] });
				var token = Sanitize.getStoreageItem('accessToken');
				await Reqst.axGet('getequipmentmeterdata/' + token + '/' + mids).then((result) => {

					let res = result['data'];

					this.setState({ meterLoading: false });
					if (res) {
						this.setState({
							meters: res
						});
					}
				});
			}
		}
	}

	async getgauagedata(gids) {
		if (gids != '') {
			if (!this.state.isGaugeLoaded) {
				this.setState({ gauageLoading: true });
				this.setState({ gauags: [] });
				var token = Sanitize.getStoreageItem('accessToken');
				await Reqst.axGet('getequipmentgauagedata/' + token + '/' + gids).then((result) => {

					let res = result['data'];

					this.setState({ gauageLoading: false });
					if (res) {
						this.setState({
							gauags: res
						});
					}
				});
			}
		}
	}


	async getAuditLogs() {
		var mids = this.state.eq_messages;
		if (mids != '') {

			var midslimit = [];

			var size = this.state.timelinelimit + 10;

			this.setState({
				timelinelimit: this.state.timelinelimit + 10
			})

			var length = size + 10;

			var isloaded = false;

			if (length == '20') {

				length = '10';
			}

			if (size == '10') {

				size = 0;
				isloaded = true;

			}

			for (var i = size; i < length; i++) {

				if (i < mids.length) {
					midslimit.push(mids[i]);
				}
			}

			if (midslimit.length > 0) {
				//if(!this.state.isAuditLogsLoaded){
				this.setState({ auditLoading: true });
				this.setState({ isAuditLogsLoaded: isloaded });
				//this.setState({audits : []});
				var token = Sanitize.getStoreageItem('accessToken');
				var data = { "token": token, "mids": midslimit };
				await Reqst.axPost('getcompanyactions', data).then((result) => {

					let res = result['data'];

					this.setState({ auditLoading: false });
					this.setState({ isAuditLogsLoaded: false });
					if (res) {

						this.sortaudits(res);
					}
				});
			}
		}
		//}
	}

	onScroll() {

		$(window).scroll(() => {
			if ($(window).scrollTop() + $(window).height() == $(document).height()) {
				this.getAuditLogs(this.state.eq_messages);
			}
		});
	}

	onEquipScroll() {

		const that = this;
		/*  $(window).scroll(() => { 
		 var container = $(".left_tree_card");
			 if(container.scrollTop+container.clientHeight == container.scrollHeight) {
			 	
				 this.getWorkOrdersListonScroll();
			 	
			 }
		  }); */


		$('#left_search_card_id').scroll(function () {

			if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
				if (!that.state.equiplistLoading) {
					that.getEquipListonScroll();
				}
			}
		});
	}

	async getEquipListonScroll() {

		//if(!this.state.isEquipmentIsLoaded){
		//this.setState({isNameFilter:false});
		//this.setState({equiplist : []});
		var token = Sanitize.getStoreageItem('accessToken');
		var limit = '10';
		var offset = this.state.equipoffset + 10;
		this.setState({ equipoffset: this.state.equipoffset + 10 });
		/* if(offset=='80'){
			offset = '0';
		} */

		var isnamefilter = this.state.isNameFilter;

		if (!isnamefilter) {

			if (this.state.dataend == false) {

				this.setState({ equiplistLoading: true });
				await Reqst.axGet('getequipments/' + token + '/' + limit + '/' + offset).then((result) => {

					let res = result['data'];
					if (res.code == '401') {

						this.getRefreshToken();

					}
					else if(result['data'].code == '500'){
				
						this.setState({ equiplistLoading: false });
						alert(result['data'].data);
			        }
					else {
						this.setState({ equiplistLoading: false });
						var orderdata = this.state.equiplist.concat(res);
						if (res.length == '0') {
							this.setState({ dataend: true });
							this.state.dataend = true;
						}
						this.setState({ equiplist: orderdata });
						//console.log(orderdata);
					}
				});
			}
		}
		else {
			if (this.state.filterend == false) {
				this.setState({ equipFilterScrollLoad: true });
				var query = this.state.search_query;
				var data = { "token": token, "limit": limit, "offset": offset, "query": query };
				await Reqst.axPost('getequipmentfiterdata', data).then((result) => {

					let res = result['data'];
					if (res.code == '401') {

						this.getRefreshToken();

					}
					else {
						this.setState({ equiplistFilterLoading: false });
						var eqdata = this.state.EquipFilterData.concat(res);

						if (res.length == '0') {
							this.setState({ filterend: true });
							this.state.filterend = true;
						}
						this.setState({ EquipFilterData: eqdata });
					}
				});

			}

		}
		//}
	}



	async getLastAssetValues() {
		if (!this.state.isLastAssetsLoaded) {
			this.setState({ assetsLoading: true });
			this.setState({ assets: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			var data = { "token": token, "eid": this.state.id };
			await Reqst.axPost('getequipmentlastassetvalue', data).then((result) => {

				let res = result['data'][0];

				this.setState({ assetsLoading: false });
				if (res) {

					this.setState({
						assets: res, meterassets: res.meter_lines_ids, gauageassets: res.gauge_lines_ids
					});
				}
			});
		}
	}

	async updateEquipment() {
		this.setState({ updateLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		var formdata = { "model": this.state.eq_model, "serial": this.state.eq_mcsn, "purchase_value": this.state.eq_purvalue, "purchase_date": this.state.eq_purdate, "start_date": this.state.eq_indate, "equipment_number": this.state.eq_desc, "name": this.state.eqname, "category_id": this.state.eq_catid, "location_id": this.state.eqloc_id, "maintenance_team_id": this.state.eq_mainteam_id, "manufacturer_id": this.state.eq_manufact_update_id, "vendor_id": this.state.eq_vendor_update_id, "criticality": this.state.eq_crit };
		var data = { "token": token, "eid": this.state.id, "values": formdata };
		await Reqst.axPost('updateequipment', data).then((result) => {

			let res = result['data'];


			if (res) {

				this.setState({ updateLoading: false });
				this.setState({ formmode: false });
				swal("Success", "Data Updated Successfully..", "success");
				this.getdata();
			}
			else {

				this.setState({ updateLoading: false });
				this.setState({ formmode: false });
				swal("Error", "Unable to update..", "error");

			}
		});
	}

	async onSendMessage() {
		this.setState({ sendMessage: true });
		var token = Sanitize.getStoreageItem('accessToken');
		var msg = $("#msg_body").val();
		var formdata = { "model": "mro.equipment", "message_type": "comment", "res_id": parseInt(this.state.id), "body": msg };
		var data = { "token": token, "values": formdata };
		await Reqst.axPost('sendmessage', data).then((result) => {

			let res = result['data'];


			if (res) {

				this.setState({ sendMessage: false });
				$("#msg_body").val('');
				swal("Success", "Message Send Successfully..", "success");
				this.getdata();
			}
			else {

				this.setState({ sendMessage: false });
				swal("Error", "Unable to Send..", "error");

			}
		});
	}


	async getDocuments() {
		if (!this.state.isDocumentsLoaded) {
			this.setState({ documentsLoading: true });
			this.setState({ documents: [] });
			var token = Sanitize.getStoreageItem('accessToken');
			await Reqst.axGet('getequipmentdocuments/' + token + '/' + this.state.id).then((result) => {

				let res = result['data'];

				this.setState({ documentsLoading: false });
				if (result['data'].code == '401') {

					this.getRefreshToken();

				}
				else {
					if (res) {
						this.setState({
							documents: res
						});
					}
				}
			});
		}
	}


	async getCategories() {
		this.setState({ catsLoading: true });
		this.setState({ cats: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getequipmentcats/' + token).then((result) => {

			let res = result['data'];

			this.setState({ catsLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						cats: res
					});
				}
			}
		});
	}

	async getLocations() {
		this.setState({ locsLoading: true });
		this.setState({ locs: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getequipmentlocs/' + token).then((result) => {

			let res = result['data'];

			this.setState({ catsLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						locs: res
					});
				}
			}
		});
	}

	async getTeams() {
		this.setState({ teamsLoading: true });
		this.setState({ teams: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		await Reqst.axGet('getequipmentteams/' + token).then((result) => {

			let res = result['data'];

			this.setState({ teamsLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						teams: res
					});
				}
			}
		});
	}

	async getPartners() {
		this.setState({ partnersLoading: true });
		this.setState({ partners: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}

		var seldata = [];
		var manfname = this.state.eq_manufact;
		var manfid = this.state.eq_manufact_id;
		seldata.push({ "id": manfid, "name": manfname });

		await Reqst.axGet('getequipmentpartners/' + token + '/' + query).then((result) => {

			let res = result['data'];

			this.setState({ partnersLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						partners: seldata.concat(res)
					});
				}
			}
		});
	}

	async getVendors() {
		this.setState({ vendorsLoading: true });
		this.setState({ vendors: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}

		var seldata = [];
		var name = this.state.eq_vendor;
		var id = this.state.eq_vendor_id;
		seldata.push({ "id": id, "name": name });
		console.log(this.state.eq_vendor_id);

		await Reqst.axGet('getequipmentpartners/' + token + '/' + query).then((result) => {

			let res = result['data'];

			this.setState({ vendorsLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						vendors: seldata.concat(res)
					});
				}
			}
		});
	}



	sortaudits(data) {
		if (data.length > 0) {
			var orgdata = data.sort(function (a, b) {
				var dateA = new Date(a.date), dateB = new Date(b.date);
				return dateB - dateA;
			});
			var joined = this.state.audits;
			var newdata = joined.concat(orgdata);
			this.setState({
				audits: newdata
			});
		}
		else {
			var joined = this.state.audits;
			var newdata = joined.concat(data);
			this.setState({
				audits: newdata
			});
		}
	}

	getstatename(staten) {


		if (staten == 'mn') {

			return <span className="badge badge-primary custom_view_head_badge">Maintenance</span>;

		}
		else if (staten == 'op') {

			return <span className="badge badge-info custom_view_head_badge">Operative</span>;

		}
		else if (staten == 'wh') {

			return <span className="badge badge-success custom_view_head_badge">Warehouse</span>;

		}
		else if (staten == 'br') {

			return <span className="badge badge-danger custom_view_head_badge">Breakdown</span>;

		}
		else if (staten == 'sc') {

			return <span className="badge badge-warning custom_view_head_badge">Scrapped</span>;

		}



	}

	getstatenamelabel(staten) {


		if (staten == 'mn') {

			return 'Maintenance';

		}
		else if (staten == 'op') {

			return 'Operative';

		}
		else if (staten == 'wh') {

			return 'Warehouse';

		}
		else if (staten == 'br') {

			return 'Breakdown';

		}
		else if (staten == 'sc') {

			return 'Scrapped';

		}



	}

	getCritical(cr) {

		var orgcr = '';
		if (cr == '0') {

			return <span className="text-primary">General</span>;

		}
		else if (cr = '1') {

			return <span className="text-info">Important</span>;

		}
		else if (cr = '2') {

			return <span className="text-warning">Very Important</span>;

		}
		else if (cr = '3') {

			return <span className="text-danger">Critical</span>;

		}


	}


	onFormModeChange() {

		this.setState({ formmode: true })
		this.setState({ selsearchtext: '' })
		this.getCategories();
		this.getLocations();
		this.getTeams();
		this.getPartners();
		this.getVendors();

	}


	getRefreshToken() {

		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.setState({ isOrderCountLoaded: false, isOrderLateCountLoaded: false, isLastAssetsLoaded: false, isAuditLogsLoaded: false, isDocumentsLoaded: false });
				this.getdata();
				this.getEquipmentList();
				this.getFilters();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}

	setAnalyticLink(id, typeid, name, type) {

		this.setState({ chartName: name });
		this.setState({ chartType: type });
		this.setState({ chartId: typeid });
		this.setState({ chartuId: id });
		this.setState({ isAnalyticLink: true });

	}

	newpercalculate(total, given) {
		var total = parseInt(total);
		var given = parseInt(given);
		var perc = "";
		if (isNaN(total) || isNaN(given)) {
			perc = 0;
		} else {
			perc = ((given / total) * 100);
		}

		perc = Math.floor(perc);

		return perc;
	}

	upperCase(str) {
		if (str == '' || str == null || str == ' ')
			return '';
		else
			return str.toString().toUpperCase();
	}

	onEquipNameChange(e) {

		this.setState({ equipsearchname: e.target.value });

		if (e.key === 'Enter') {
			this.equipfilters(e.target.value);
		}

		///if (e.target.value.length>2) {
		this.equipfilters(e.target.value);
		//}

	}

	onSelSearch(type, query) {
		this.setState({ selsearchtext: '' });

		if (type == 'vendor') {
			this.setState({ eq_vendor_id: '' });
			if (this.state.eq_vendor_id == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getVendors();
			}
		}
		else if (type == 'partner') {

			this.setState({ eq_manufact_id: '' });
			if (this.state.eq_manufact_id == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getPartners();
			}


		}

	}

	onVendorClear() {

		this.setState({ eq_vendor_id: '' });

	}

	equipsavedfilters(query) {

		try {

			this.setState({ isNameFilter: true });
			this.setState({ equiplistFilterLoading: true });
			this.setState({ EquipFilterData: [] });
			this.setState({ search_query: '' });
			var token = Sanitize.getStoreageItem('accessToken');
			//var searchval = this.state.order_search_name;
			//console.log(searchval);
			//var query = '["name", "ilike","'+searchval+'" ]';
			this.setState({ search_query: query });
			var rpp = '30';
			var offset = '0';
			var datasearch = [];

			/* datasearch = this.filterdata();
			
			if(datasearch.length>0){
				
				this.setState({order_filter_data : datasearch});
				this.setState({orderlistFilterLoading:false});
					
			}
			else{ */
			var data = { "token": token, "limit": rpp, "offset": offset, "query": query };
			Reqst.axPost('getequipmentfiterdata', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ equiplistFilterLoading: false });
					this.setState({ EquipFilterData: res });
				}
			});
			//}
		}
		catch (error) {
			console.log(error);
		}

	}


	equipfilters(searchvalent) {

		try {
			var searchvallen = $("#search_text").val().length;
			console.log(searchvallen);
			if (searchvallen > 2) {
				this.setState({ isNameFilter: true });
				this.setState({ filterend: false });
				this.setState({ equiplistFilterLoading: true });
				this.setState({ EquipFilterData: [] });
				var token = Sanitize.getStoreageItem('accessToken');
				var searchval = $("#search_text").val();//this.state.equipsearchname;
				var query = '["name", "ilike","' + searchval + '" ]';
				this.setState({ search_query: '["name", "ilike","' + searchval + '" ]' });
				var rpp = '30';
				var offset = '0';
				var data = { "token": token, "limit": rpp, "offset": offset, "query": query };
				Reqst.axPost('getequipmentfiterdata', data).then((result) => {

					let res = result['data'];
					if (res.code == '401') {

						this.getRefreshToken();

					}
					else {
						this.setState({ equiplistFilterLoading: false });
						this.setState({ EquipFilterData: res });
					}
				});
			}
		}
		catch (error) {
			console.log(error);
		}

	}

	resetFilter() {

		this.setState({ isNameFilter: false });
		this.setState({ equipsearchname: '' });
		this.setState({ search_query: '' });
		this.setState({ is_search_enable: false, dataend: false, filterend: false });
		$("#search_text").val('');

	}



	filedownlod(type, filename, docid) {

		var text = '';

		this.setState({ documentSingleLoad: true });
		this.setState({ documentId: docid });
		this.setState({ documentdata: '' });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getequipmentdocumentload/' + token + '/' + this.state.id + '/' + docid).then((result) => {

			let res = result['data'][0].datas;
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ documentSingleLoad: false });
				this.setState({ documentId: '' });
				this.setState({ documentdata: res });
				text = res;
				var element = document.createElement('a');
				element.setAttribute('href', 'data:' + type + ';base64,' + encodeURIComponent(text));
				element.setAttribute('download', filename);

				element.style.display = 'none';
				document.body.appendChild(element);

				element.click();

				document.body.removeChild(element);

			}
		});

	}

	pdffileview(type, filename, docid, name) {

		var text = '';

		this.setState({ documentSingleViewLoad: true });
		this.setState({ documentId: docid });
		this.setState({ viewfilename: name });
		this.setState({ documentviewdata: '' });
		this.setState({ pdfviewdialog: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getequipmentdocumentload/' + token + '/' + this.state.id + '/' + docid).then((result) => {

			let res = result['data'][0].datas;
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ documentSingleViewLoad: false });
				this.setState({ documentId: '' });
				var pdfdata = res;
				var pdf_src = 'data:application/pdf;base64,' + pdfdata + '';
				this.setState({ documentviewdata: pdf_src });

			}
		});

	}

	imagefileview(type, filename, docid, name) {

		var text = '';

		this.setState({ documentSingleImgViewLoad: true });
		this.setState({ documentId: docid });
		this.setState({ imgviewdialog: true });
		this.setState({ viewfilename: name });
		this.setState({ documentviewdata: '' });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getequipmentdocumentload/' + token + '/' + this.state.id + '/' + docid).then((result) => {

			let res = result['data'][0].datas;
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ documentSingleImgViewLoad: false });
				this.setState({ documentId: '' });
				var imgdata = res;
				var img_src = 'data:' + type + ';base64,' + imgdata + '';
				this.setState({ documentviewdata: img_src });
			}
		});

	}



	pdfview(base64PDF) {

		var objbuilder = '';
		objbuilder += ('<object width="100%" height="100%"      data="data:application/pdf;base64,');
		objbuilder += (base64PDF);
		objbuilder += ('" type="application/pdf" class="internal">');
		objbuilder += ('<embed src="data:application/pdf;base64,');
		objbuilder += (base64PDF);
		objbuilder += ('" type="application/pdf" />');
		objbuilder += ('</object>');

		return objbuilder;

	}

	directtoanother() {

		this.setState({ isDirect: true });

	}

	defclassgen(index) {

		var defclass = 'custom_col_left';

		var cval = 1;

		cval = cval + index;


		this.state.doccardcount = cval;

		var val = index % 2;

		if (index == 0) {

			defclass = 'custom_col_left';


		}
		else if (val == 1) {

			var val2 = cval % 4;

			defclass = 'custom_col_left custom_col_right';

			if (val2 == 0) {

				defclass = 'custom_col_right';
			}

		}
		else if (val == 0) {

			defclass = 'custom_col_left custom_col_right';

			var val1 = index % 4;

			if (val1 == 0) {

				defclass = 'custom_col_left';
			}

		}

		return defclass;


	}

	onOrderLink(cat, type) {

		var query1 = '';
		var query2 = '';

		var id = this.state.id;
		var name = this.state.eqname;

		var cdate = this.state.cdate;

		var query = '["equipment_id","=",' + parseInt(id) + ']';

		if (cat == 'Corrective') {

			query1 = '["maintenance_type", "=","bm" ]';

		}
		else if (cat == 'Preventive') {

			query1 = '["maintenance_type", "=","pm" ]';

		}
		else if (cat == 'On-Condition') {

			query1 = '["maintenance_type", "=","oc" ]';

		}
		else if (cat == 'Predictive') {

			query1 = '["maintenance_type", "=","pr" ]';

		}

		if (type == 'Open') {

			query2 = '"|",["state","=","released"],["state","=","ready"]';
		}
		else if (type == 'Progress') {

			query2 = '"|",["state","=","assigned"],["state","=","in_progress"],["state","=","pause"]';

		}
		else if (type == 'Done') {

			query2 = '["state","=","done"]';
		}
		else if (type == 'Late') {

			query2 = '["date_scheduled","<=","' + cdate + '"],"&","&",["state","!=","draft"],["state","!=","done"],["state","!=","cancel"]';
		}

		var fullquery = query + ',' + query1 + ',' + query2;

		var queryname = name + ' ' + cat + ' ' + type;

		this.setState({ query1: query1 });
		this.setState({ query2: query2 });
		this.setState({ query: query });
		this.setState({ queryname: name });
		this.setState({ queryname1: cat });
		this.setState({ queryname2: type });
		this.setState({ isOrderLink: true });

	}


	render() {

		if (this.state.isOrderLink) {


			return (<Redirect to={{
				pathname: '/helpdesk/workorders',
				state: { modelkeyid: 'equipmentview', value: this.state.eqname, uid: this.state.id, query: this.state.query, queryname: this.state.queryname, query1: this.state.query1, queryname1: this.state.queryname1, query2: this.state.query2, queryname2: this.state.queryname2 }
			}}
			/>);

		}

		if (this.state.isSpaceLink) {

			var treenodes = [this.state.eqloc_id];



			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid: 'equipmentview', value: this.state.eqloc, uid: this.state.eqloc_id, treenodes: treenodes, equipname: this.state.eqname, equipid: this.state.id }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'space') {


			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid: 'equipmentview', value: this.props.location.state.value, uid: this.props.location.state.uid, treenodes: this.props.location.state.treenodes, equipname: this.state.eqname, equipid: this.state.id }
			}}
			/>);

		}

		if (this.state.isAnalyticLink) {

			return (<Redirect to={{
				pathname: '/assets/analytics',
				state: { modelkeyid: 'equip_analytics', value: this.state.eqname, uid: this.state.id, gauageassets: this.state.gauageassets, meterassets: this.state.meterassets, chartName: this.state.chartName, chartType: this.state.chartType, chartId: this.state.chartId, chartuId: this.state.chartuId }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'orderview') {


			return (<Redirect to={{
				pathname: '/helpdesk/workorder/view/' + this.props.location.state.uid,
				state: { modelkeyid: 'equipmentview', value: this.state.eqname, uid: this.state.id }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'ticketview') {


			return (<Redirect to={{
				pathname: '/helpdesk/ticket/view/' + this.props.location.state.uid,
				state: { modelkeyid: 'equipmentview', value: this.state.eqname, uid: this.state.id }
			}}
			/>);

		}

		var pacount = this.state.pacount;
		var bmcount = this.state.bmcount;
		var pmcount = this.state.pmcount;
		var occount = this.state.occount;

		var totpiecount = parseInt(pacount) + parseInt(bmcount) + parseInt(pmcount) + parseInt(occount);

		var paperc = this.newpercalculate(totpiecount, pacount);
		var bmperc = this.newpercalculate(totpiecount, bmcount);
		var pmperc = this.newpercalculate(totpiecount, pmcount);
		var ocperc = this.newpercalculate(totpiecount, occount);


		const piedata = {
			labels: [
				'Reactive' + ' (' + bmcount + ')',
				'Preventive' + ' (' + pmcount + ')',
				'On-Condition' + ' (' + occount + ')',
				'Predictive' + ' (' + pacount + ')'
			],
			datasets: [{
				data: [bmcount, pmcount, occount, pacount],
				backgroundColor: [
					'#80d4ff',
					'#ef869e',
					'#ff8566',
					'#8dace7'
				],
				hoverBackgroundColor: [
					'#80d4ff',
					'#ef869e',
					'#ff8566',
					'#8dace7'
				],
				hoverLabels: [
					'Reactive',
					'Preventive',
					'On-Condition',
					'Predictive'
				]
			}]
		};

		const pieoptions = {

			responsive: true,
			legend: {
				position: 'top',
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						var dataset = data.datasets[tooltipItem.datasetIndex];

						var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
							return previousValue + currentValue;
						});
						var currentValue = dataset.data[tooltipItem.index];
						var currentValueName = dataset.hoverLabels[tooltipItem.index];
						var percentage = Math.floor(((currentValue / total) * 100) + 0.5);
						return currentValueName + ' ' + percentage + "%";
					}
				}
			}

		};


		const areachartoptions = {
			chart: {
				stacked: false,
				zoom: {
					type: 'x',
					enabled: true
				},
				toolbar: {
					autoSelected: 'zoom'
				}
			},
			plotOptions: {
				line: {
					curve: 'smooth',
				}
			},
			dataLabels: {
				enabled: false
			},

			markers: {
				size: 0,
				style: 'full',
			},
			//colors: ['#0165fc'],
			title: {
				text: 'Movement',
				align: 'left'
			},
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					inverseColors: false,
					opacityFrom: 0.5,
					opacityTo: 0,
					stops: [0, 90, 100]
				},
			},
			yaxis: {
				title: {
					text: 'Value'
				},
			},
			xaxis: {
				type: 'datetime',
				categories: this.state.chart_dates
			}
		};

		console.log(this.state.chart_dates);

		const areachartseries = [{
			name: this.state.chartName,
			data: this.state.chart_values
		}];


		var pabarcount = this.getmaintainOrderStateLateCount('pr');
		var bmbarcount = this.getmaintainOrderStateLateCount('bm');
		var pmbarcount = this.getmaintainOrderStateLateCount('pm');
		var ocbarcount = this.getmaintainOrderStateLateCount('oc');

		const barchartoptions2 = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: ['Reactive', 'Preventive', 'On-Condition', 'Predictive']
			},

		};


		const criticals = [
			{
				value: '0',
				label: 'General'
			},
			{
				value: '1',
				label: 'Important'
			},
			{
				value: '2',
				label: 'Very Important'
			},
			{
				value: '3',
				label: 'Critical'
			}
		];

		const filtertypes = [
			{
				value: '',
				label: 'All'
			},
			{
				value: 'Today',
				label: 'Today'
			},
			{
				value: 'This Week',
				label: 'This Week'
			},
			{
				value: 'This Month',
				label: 'This Month'
			},
		];


		const barchartseries2 = [{
			name: 'Overdue',
			data: [bmbarcount, pmbarcount, ocbarcount, pabarcount]
		}];

		const piechartseries = [bmcount, pmcount, occount, pacount];

		const dougnoutoptins = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},
			labels: ['Reactive', 'Preventive', 'On-Condition', 'Predictive'],
			colors: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			],
			hover: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			]

		};


		const treeoptions = {
			children: 'children',
			label: 'label'
		};

		const {
			openLeft
		} = this.state;



		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Equipments | {this.state.eqname} </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					{!this.state.isequipment_loaded ?

						<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
						:

						<div className="main_data">
							<div className={this.state.sticky_searchbar}>

								<FormGroup className={this.state.topbar_buttons}>


									{this.state.is_search_enable ?
										<Input id="search_text" className="mat_form" name="equipsearchname" placeholder="Search Name" ref={(input) => { this.equipsearchname = input; }} onKeyDown={this.onEquipNameChange.bind(this)} />
										:
										<Button type="text" size="large" onClick={() => this.setState({ is_search_enable: true })}>
											<i className="pe-7s-search" style={{ fontSize: '20px' }} />
										</Button>
									}
									{!this.state.is_search_enable && !this.state.isNameFilter ?
										<span style={{ marginLeft: 'auto' }}>
											<Button type="text" onClick={() => this.setState({ searchDialog: true })}>
												<i className="lnr-funnel font-size-lg" />
											</Button>
										</span>
										: ''}


									{this.state.savedtags.length == 1 && !this.state.is_search_enable && this.state.isNameFilter ?
										<div style={{ marginLeft: 'auto', marginTop: '0px', marginRight: 'auto', textAlign: 'center' }}>
											{

												this.state.savedtags.map(tag => {
													return (
														<Tag
															key={tag.key}
															closable={true}
															type="primary"
															closeTransition={false}
															onClose={this.handleCloseSavedTags.bind(this, tag)}>{tag.name}</Tag>
													)
												})
											}
										</div>
										: ''}

									{this.state.is_search_enable ?
										<span style={{ marginLeft: 'auto' }}>
											<Tooltip className="item" content='Reset' placement="top-start" >
												<Button type="text" size="large" onClick={this.resetFilter}>
													<i className="pe-7s-close" style={{ fontSize: '20px' }} />
												</Button>
											</Tooltip>
										</span>
										: ''}
								</FormGroup>

							</div>

							<Modal isOpen={this.state.searchDialog} slide="right" size="sm" className="right1" backdrop={true}>
								<ModalHeader>
									Filters
												  <span className="modal_pull_right1"><button className="close" onClick={() => this.setState({ searchDialog: false, searchcolumn: '', searchfor: '', searchvalue: '' })}>&times;</button></span>
								</ModalHeader>

								<ModalBody className="right1_body">

									<div>


										{this.state.filtersLoading ?

											<div><center><Loader type="ball-clip-rotate" /></center></div>

											:
											this.state.filters.map((item, index) =>

												<div>
													<div style={{ padding: '4px 1px', display: 'flex', alignItems: 'center' }}>
														<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onFilterApply(item.id, item.domain, item.display_name)}>
															<div className="list_center_equal">
																<Tooltip className="item" content={item.display_name} placement="top-center" >
																	<span className="text-info"><i className="pe-7s-filter font-size-sm" style={{ fontWeight: 'bold', fontSize: '15px' }}> </i></span>&nbsp;<span style={{ fontSize: '15px' }} >{item.display_name}</span>
																</Tooltip>
															</div>
														</a>

													</div>

													<hr style={{ marginBottom: '2px', marginTop: '2px', borderTop: '1px solid #d1dbe5', width: '268px', marginLeft: '-15px' }} />
												</div>

											)
										}
									</div>

								</ModalBody>

							</Modal>

							<div className={this.state.left_style} id="left_search_card_id">

								{this.state.equiplistFilterLoading ?

									<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>

									:
									<span>
										{this.state.isNameFilter ?
											<div>
												<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
												{this.state.EquipFilterData.length > 0 ?
													<span>
														{this.state.EquipFilterData.map((item, index) =>
															<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onEquipChange(item.id)}>
																<div style={{ padding: '1px 5px' }} >
																	<Tooltip className="item" content={item.name} placement="top-center" >
																		<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>{item.name}</b></span>
																	</Tooltip>
																	<span className="text-capitalize" style={{ fontSize: '12px' }}>{item.location_id[1]}</span>
																</div>
																<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
															</a>
														)}
														{this.state.equipFilterScrollLoad ?

															<div><center><Loader type="ball-clip-rotate" /></center></div>
															: ''}
													</span>

													:

													<div className="text-center"><img src={nodata} width="165" /> <br /><b>No Equipments Found..</b></div>

												}
											</div>


											:
											<span>
												<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
												{this.state.equiplist.map((item, index) =>
													<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onEquipChange(item.id)} >
														<div style={{ padding: '1px 5px' }} >
															<Tooltip className="item" content={item.name} placement="top-center" >
																<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>{item.name}</b></span>
															</Tooltip>
															<span className="text-capitalize" style={{ fontSize: '12px' }}>{item.location_id[1]}</span>
														</div>
														<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
													</a>
												)}
												{this.state.equiplistLoading && this.state.left_style != '' ?

													<div><center><Loader type="ball-clip-rotate" /></center></div>
													: ''}
											</span>


										}
									</span>

								}


							</div>

							<div className={this.state.content_card}>

								<div className="drawer_left_menu pull-left">


									<PageTitleAlt3
										heading=""
										subheading=""
										icon=""
										menuLevel1={this.props.location.state ? this.props.location.state.value : 'Equipments'}
										menuLevel2={this.props.location.state ? 'Equipments' : this.state.eqname}
										menuLevel3={this.props.location.state ? this.state.eqname : ''}
										menuLevel2link={this.props.location.state ? '#/assets/equipments' : false}
										menuLevel1click={() => this.props.location.state ? this.directtoanother() : ''}
										menuLevel1link={this.props.location.state ? 'javascript:;' : '#/assets/equipments'}
										isbreadcumb={true}

									/>
								</div>

								{this.state.dataLoading ?
									<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
									:

									<div>
										<Card className="main-card mb-2 custom_card_view">

											<CardBody>
												<Row>
													<Col lg="2" sm="12" xs="12" className="mobile_img">
														<img src={equimg} height="110" width="140" />
													</Col>
													<Col lg="7" sm="12" xs="12">
														<div className="pull-right_form">

															<span className="text-center_form">{this.validitycheck(this.state.eq_waredate)}</span>
														</div>
														<span className="text-info">#{this.state.eq_num}</span><br />

														{this.state.formmode ? <Input type="text" className="mat_form_input" value={this.state.eqname} name="eqname" onChange={this.onInputChange.bind(this)} bsSize="lg" /> : <span className="custom_view_head">{this.state.eqname}</span>}

														{!this.state.formmode ? <span>&nbsp;<span>{this.getstatename(this.state.eq_state)}</span> <br /> </span> : ''}


														{this.state.formmode ?
															<span>
																<Select onChange={this.handleCatChange} value={this.state.eq_catid} filterable={true} clearable={true}>
																	{
																		this.state.cats.map(el => {
																			return <Select.Option key={el.id} label={el.name} value={el.id} />
																		})
																	}
																</Select>
																<br />
															</span>
															:
															<Tooltip className="item" content="Category" placement="left" >
																<span><span className="text-info"><i className="lnr-layers font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>{this.state.eq_cat}</span>
															</Tooltip>
														}

														{this.state.formmode ?
															<span>
																<Select onChange={this.handleLocChange} value={this.state.eqloc_id} filterable={true} clearable={true}>
																	{
																		this.state.locs.map(el => {
																			return <Select.Option key={el.id} label={el.path_name} value={el.id} />
																		})
																	}
																</Select>
																<br />
															</span>
															:
															<Tooltip className="item" content="Space" placement="left" >
																<span><span className="text-info"><i className="lnr-map-marker font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span><a href="javascript:;" onClick={() => this.setState({ isSpaceLink: true })} style={{ textDecoration: 'none', color: '#495057' }}>{this.state.eqloc}</a></span>
															</Tooltip>
														}

														{this.state.formmode ?
															<Select onChange={this.handleTeamChange} value={this.state.eq_mainteam_id} filterable={true} clearable={true}>
																{
																	this.state.teams.map(el => {
																		return <Select.Option key={el.id} label={el.name} value={el.id} />
																	})
																}
															</Select>
															:
															<Tooltip className="item" content="Maintenance Team" placement="left" >
																<span><span className="text-info"><i className="pe-7s-users font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;&nbsp;{this.state.eq_mainteam}</span>
															</Tooltip>
														}
													</Col>
													<Col lg="3" sm="12" xs="12" className="mobile_form_buttons">



														<span className="pull-right">
															{this.state.formmode ?
																<span>
																	<Button type="primary" disabled={this.state.updateLoading} onClick={this.updateEquipment}>
																		{this.state.updateLoading ? 'Updating...' : 'Update'}
																	</Button>
																	&nbsp;
																		<Button type="danger" onClick={() => this.setState({ formmode: false })} >
																		Cancel
																		</Button>
																</span>
																:
																''
															}
														</span>

														{!this.state.formmode ?
															<div className="btn-actions-pane-right text-capitalize actions-icon-btn pull-right">

																<Dropdown trigger="click" menu={(
																	<Dropdown.Menu>
																		<span className="el-select-group__title text-center">Manage</span>
																		<Dropdown.Item command="a" >
																			<div style={{ width: '90px' }} onClick={this.onFormModeChange}>
																				<Button type="text">
																					Edit
																   </Button>

																			</div>

																		</Dropdown.Item>

																		<span className="el-select-group__title text-center">Action</span>
																		{this.state.eq_state != 'br' ?
																			<Dropdown.Item command="b" >
																				<div style={{ width: '90px' }} onClick={() => this.onStateChange('action_in_breakdown')}>

																					<Button type="text">
																						Breakdown
																   </Button>
																				</div>
																			</Dropdown.Item>
																			: ''}
																		{this.state.eq_state != 'wh' && this.state.eq_state != 'br' ?
																			<Dropdown.Item command="c" >
																				<div style={{ width: '90px' }} onClick={() => this.onStateChange('action_put_warehouse')}>
																					<Button type="text">
																						Store in WH
																   </Button>
																				</div>
																			</Dropdown.Item>
																			: ''}
																		{this.state.eq_state != 'mn' && this.state.eq_state != 'br' && this.state.eq_state != 'sc' ?
																			<Dropdown.Item command="d" >
																				<div style={{ width: '90px' }} onClick={() => this.onStateChange('action_in_maintenance')}>
																					<Button type="text">
																						Maintenance
																   </Button>
																				</div>
																			</Dropdown.Item>
																			: ''}

																		{this.state.eq_state != 'sc' ?
																			<Dropdown.Item command="e" >
																				<div style={{ width: '90px' }} onClick={() => this.onStateChange('action_scrap')}>
																					<Button type="text">
																						Scrap
																   </Button>
																				</div>
																			</Dropdown.Item>
																			: ''}

																	</Dropdown.Menu>
																)}>
																	<span className="el-dropdown-link">
																		<i className="el-icon-more" />
																	</span>
																</Dropdown>

															</div>
															: ''}



													</Col>
												</Row>


											</CardBody>
										</Card>

										<Card className="main-card mb-2 custom_card_view1">
											<CardBody className="custom_card_body1">

												<Nav>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '1' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('1')} >{locale.t('el.equipment.summary')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '2' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('2')} >{locale.t('el.equipment.maintenance')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '3' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('3')}> {locale.t('el.equipment.readings')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '4' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('4')} >{locale.t('el.equipment.analytics')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '8' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('8')} >{locale.t('el.equipment.auditlog')}</NavLink>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '9' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('9')} >{locale.t('el.equipment.documents')}</NavLink>
												</Nav>


											</CardBody>
										</Card>
										<div>
											{this.state.activeTab == '1' ?
												<Row>
													<Col md="12" lg="7" className="custom_col_left">
														<Card className="main-card mb-2">
															{this.state.formmode ?
																<CardBody>
																	<Row>
																		<Col lg="6" sm="12" xs="12">
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.modelCode')}</Label>
																				<Input type="text" className="mat_form" value={this.state.eq_model} name="eq_model" onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			</FormGroup>
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.manufacturer')}</Label><br />
																				<Select onChange={this.handlePartnerChange} value={this.state.eq_manufact_id != '' ? this.state.eq_manufact_id : false} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this, 'partner')} loading={this.state.partnersLoading}>
																					{
																						this.state.partners.map(el => {
																							return <Select.Option key={el.id} label={el.name} value={el.id} />
																						})
																					}
																				</Select>
																			</FormGroup>
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.purchaseDate')}</Label>
																				<Input type="date" className="mat_form" value={this.state.eq_purdate} name="eq_purdate" onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			</FormGroup>
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.purchasevalue')}</Label>
																				<Input type="number" className="mat_form" value={this.state.eq_purvalue} name="eq_purvalue" onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			</FormGroup>

																		</Col>

																		<Col lg="6" sm="12" xs="12">
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.serialNo')}</Label>
																				<Input type="text" className="mat_form" value={this.state.eq_mcsn} name="eq_mcsn" onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			</FormGroup>

																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.vendor')}</Label><br />
																				<Select onChange={this.handleVendorChange} value={this.state.eq_vendor_id != '' ? this.state.eq_vendor_id : false} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this, 'vendor')} id="eq_vendor_id" loading={this.state.vendorsLoading}>
																					{
																						this.state.vendors.map(el => {
																							return <Select.Option key={el.id} label={el.name} value={el.id} />
																						})
																					}
																				</Select>

																			</FormGroup>
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.installmentdate')}</Label>
																				<Input type="date" className="mat_form" value={this.state.eq_indate} name="eq_indate" onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			</FormGroup>

																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.criticality')}</Label><br />
																				<Select onChange={this.handleChange} value={this.state.eq_crit}>
																					{
																						criticals.map(el => {
																							return <Select.Option key={el.value} label={el.label} value={el.value} />
																						})
																					}
																				</Select>

																			</FormGroup>
																		</Col>

																		<Col lg="6" sm="12" xs="12">
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.description')}</Label>
																				<Input type="text" className="mat_form" value={this.state.eq_desc} name="eq_desc" onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			</FormGroup>
																		</Col>

																	</Row>

																</CardBody>
																:

																<CardBody>
																	<Row>
																		<Col lg="6" sm="12" xs="12">
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.modelCode')}</Label>
																				<p><b>{this.getnovalues(this.state.eq_model)}</b></p>
																			</FormGroup>
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.manufacturer')}</Label>
																				<p><b>{this.getnovalues(this.state.eq_manufact)}</b></p>
																			</FormGroup>
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.purchaseDate')}</Label>
																				<p><b>{this.getdateofdt(this.state.eq_purdate)}</b></p>
																			</FormGroup>
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.purchasevalue')}</Label>
																				<p><b>{this.getnovalues(this.state.eq_purvalue)}</b></p>
																			</FormGroup>

																		</Col>

																		<Col lg="6" sm="12" xs="12">
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.serialNo')}</Label>
																				<p><b>{this.getnovalues(this.state.eq_mcsn)}</b></p>
																			</FormGroup>

																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.vendor')}</Label>
																				<p><b>{this.getnovalues(this.state.eq_vendor)}</b></p>
																			</FormGroup>
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.installmentdate')}</Label>
																				<p><b>{this.getdateofdt(this.state.eq_indate)}</b></p>
																			</FormGroup>

																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.criticality')}</Label>
																				<p><b>{this.getCritical(this.state.eq_crit)}</b></p>
																			</FormGroup>
																		</Col>

																		<Col lg="6" sm="12" xs="12">
																			<FormGroup>
																				<Label className="text-capitalize">{locale.t('el.equipment.description')}</Label>
																				<p><b>{this.getnovalues(this.state.eq_desc)}</b></p>
																			</FormGroup>
																		</Col>

																	</Row>

																</CardBody>
															}
														</Card>
													</Col>
													<Col md="12" lg="5" className="custom_col_right">
														<Card className="main-card mb-2 " style={{ minHeight: '98%' }}>
															<CardBody>
																<div className="card-title text-uppercase">
																	{locale.t('el.equipment.relatedEquipments')}
																</div>
																<br />
																{this.state.relequipdata.length > 0 ?

																	<Tree
																		data={this.state.relequiptreedata}
																		options={treeoptions}
																		defaultExpandAll={true}
																		highlightCurrent={true}
																	/>
																	:


																	<div className="text-center col-md-12"><img src={nodata} width="165" /> <br /><b>No Related Equipments Found..</b></div>
																}

															</CardBody>

														</Card>
													</Col>

													<Col md="12" lg="12" >
														<Card className="main-card mb-2">
															<CardBody>
																<div className="card-title text-uppercase">
																	{locale.t('el.equipment.sendMessage')}
																</div>
																<br />

																<FormGroup>
																	<Label className="text-capitalize"></Label>
																	<Input type="text" className="mat_form" name="msg_body" id="msg_body" placeholder={locale.t('el.equipment.writeSomething')} bsSize="sm" />
																</FormGroup>
																<span className="pull-right">
																	<Button type="primary" disabled={this.state.sendMessage} onClick={this.onSendMessage}>
																		<i className="pe-7s-paper-plane"> </i>&nbsp;{this.state.sendMessage ? 'Sending...' : 'Send'}
																	</Button>
																</span>

															</CardBody>

														</Card>
													</Col>
												</Row>
												: ''}
										</div>





										<div>
											{this.state.activeTab == '2' ?

												<div>
													{this.state.orderLoading ?
														<center><Loader type="ball-clip-rotate" /></center>
														:

														<Row>
															<Col md="6" lg="3" className="custom_col_left">
																<Card className="widget-chart widget-chart2 text-left mb-2 card-btm-border card-shadow-primary border-reactive ">
																	<div className="widget-chat-wrapper-outer">
																		<div className="widget-chart-content">
																			<div className="">
																				<span className="card-title text-uppercase"> {locale.t('el.equipment.reactive')}  </span>
																				{this.getmaintainOrderStateLateCount('bm') != 0 ?
																					<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Corrective', 'Late')}>
																						<span style={{ textTransform: 'capitalize' }} className="pull-right text-danger">Overdue <b>{this.getmaintainOrderStateLateCount('bm')}</b></span>
																					</a>
																					: ''}
																			</div>
																			<br />
																			<div className="text-center">
																				{bmcount != 0 ?
																					<Row>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Corrective', 'Open')}>
																								<h4 ><b>{this.getmaintainOrderStateCount('bm', 'open')}</b></h4>
																								<span style={{ fontSize: '13px' }}>New</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Corrective', 'Progress')}>
																								<h4><b>{this.getmaintainOrderStateCount('bm', 'progress')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Progress</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Corrective', 'Done')}>
																								<h4><b>{this.getmaintainOrderStateCount('bm', 'done')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Closed</span>
																							</a>
																						</Col>
																					</Row>
																					:
																					<div><img src={nodata} width="35" height="35" /> <br /><b>No Reactive Found..</b></div>
																				}
																			</div>
																		</div>
																	</div>
																</Card>
															</Col>
															<Col md="6" lg="3" className="custom_col_right custom_col_left">
																<Card className="widget-chart widget-chart2 text-left mb-2 card-btm-border card-shadow-primary border-preventive custom_card_view">
																	<div className="widget-chat-wrapper-outer">
																		<div className="widget-chart-content">
																			<div className="">
																				<span className="card-title text-uppercase"> {locale.t('el.equipment.preventive')} </span>
																				{this.getmaintainOrderStateLateCount('pm') != 0 ?
																					<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Preventive', 'Late')}>
																						<span style={{ textTransform: 'capitalize' }} className="pull-right text-danger">Overdue <b>{this.getmaintainOrderStateLateCount('pm')}</b></span>
																					</a>
																					: ''}
																			</div>
																			<br />
																			<div className="text-center">
																				{pmcount != 0 ?
																					<Row>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Preventive', 'Open')}>
																								<h4><b>{this.getmaintainOrderStateCount('pm', 'open')}</b></h4>
																								<span style={{ fontSize: '13px' }}>New</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Preventive', 'Progress')}>
																								<h4><b>{this.getmaintainOrderStateCount('pm', 'progress')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Progress</span>
																							</a>
																						</Col>

																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Preventive', 'Done')}>
																								<h4><b>{this.getmaintainOrderStateCount('pm', 'done')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Closed</span>
																							</a>
																						</Col>
																					</Row>
																					:
																					<div><img src={nodata} width="35" height="35" /> <br /><b>No Preventive Found..</b></div>
																				}

																			</div>
																		</div>
																	</div>
																</Card>
															</Col>
															<Col md="6" lg="3" className="custom_col_right custom_col_left">
																<Card className="widget-chart widget-chart2 text-left mb-2 card-btm-border card-shadow-primary border-oncondition">
																	<div className="widget-chat-wrapper-outer">
																		<div className="widget-chart-content">
																			<div className="">
																				<span className="card-title text-uppercase"> {locale.t('el.equipment.onCondition')}</span>
																				{this.getmaintainOrderStateLateCount('oc') != 0 ?
																					<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('On-Condition', 'Late')}>
																						<span style={{ textTransform: 'capitalize' }} className="pull-right text-danger">Overdue <b>{this.getmaintainOrderStateLateCount('oc')}</b></span>
																					</a>
																					: ''}
																			</div>
																			<br />
																			<div className="text-center">
																				{occount != 0 ?
																					<Row>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('On-Condition', 'Open')}>
																								<h4><b>{this.getmaintainOrderStateCount('oc', 'open')}</b></h4>
																								<span style={{ fontSize: '13px' }}>New</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('On-Condition', 'Progress')}>
																								<h4><b>{this.getmaintainOrderStateCount('oc', 'progress')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Progress</span>
																							</a>
																						</Col>

																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('On-Condition', 'Done')}>
																								<h4><b>{this.getmaintainOrderStateCount('oc', 'done')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Closed</span>
																							</a>
																						</Col>
																					</Row>
																					:
																					<div><img src={nodata} width="35" height="35" /> <br /><b>No On-Condition Found..</b></div>
																				}

																			</div>
																		</div>
																	</div>
																</Card>
															</Col>
															<Col md="6" lg="3" className="custom_col_right">
																<Card className="widget-chart widget-chart2 text-left mb-2 card-btm-border card-shadow-primary border-predictive">
																	<div className="widget-chat-wrapper-outer">
																		<div className="widget-chart-content">
																			<div className="">
																				<span className="card-title text-uppercase">{locale.t('el.equipment.predictive')}</span>
																				{this.getmaintainOrderStateLateCount('pr') != 0 ?
																					<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Predictive', 'Late')}>
																						<span style={{ textTransform: 'capitalize' }} className="pull-right text-danger">Overdue <b>{this.getmaintainOrderStateLateCount('pr')}</b></span>
																					</a>
																					: ''}
																			</div>
																			<br />
																			<div className="text-center">
																				{pacount != 0 ?
																					<Row>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Predictive', 'Open')}>
																								<h4><b>{this.getmaintainOrderStateCount('pr', 'open')}</b></h4>
																								<span style={{ fontSize: '13px' }}>New</span>
																							</a>
																						</Col>
																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Predictive', 'Progress')}>
																								<h4><b>{this.getmaintainOrderStateCount('pr', 'progress')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Progress</span>
																							</a>
																						</Col>

																						<Col lg="4" sm="6" xs="6">
																							<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('Predictive', 'Done')}>
																								<h4><b>{this.getmaintainOrderStateCount('pr', 'done')}</b></h4>
																								<span style={{ fontSize: '13px' }}>Closed</span>
																							</a>
																						</Col>
																					</Row>
																					:
																					<div><img src={nodata} width="35" height="35" /> <br /><b>No Predictive Found..</b></div>
																				}

																			</div>
																		</div>
																	</div>
																</Card>
															</Col>
															<Col lg="6" sm="12" xs="12" className="custom_col_left">

																<Card className="widget-chart mb-2 card_equal_height">
																	<div className="widget-chat-wrapper-outer">
																		<div className="widget-chart-content">
																			<div className="card-title text-uppercase text-left">
																				{locale.t('el.equipment.orderType')}
																			</div>
																			<div className="text-left">
																				{pmcount == 0 && bmcount == 0 && occount == 0 && pacount == 0 ?

																					<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

																					:
																					<div className="donut">
																						<Chart options={dougnoutoptins} series={piechartseries} type="donut" id="apexchart-example-3" width="100%" />

																					</div>
																				}
																			</div>
																		</div>
																	</div>
																</Card>
															</Col>

															<Col lg="6" sm="12" xs="12" className="custom_col_right">

																<Card className="widget-chart mb-2 card_equal_height">
																	<div className="widget-chat-wrapper-outer">
																		<div className="widget-chart-content">
																			<div className="card-title text-uppercase text-left">
																				{locale.t('el.equipment.orderOverdue')}
																			</div>
																			<div>
																				{pmcount == 0 && bmcount == 0 && occount == 0 && pacount == 0 ?

																					<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

																					:
																					<div className="bar">
																						<Chart options={barchartoptions2} series={barchartseries2} type="bar" width="100%" />
																					</div>
																				}
																			</div>
																		</div>
																	</div>
																</Card>
															</Col>


														</Row>

													}
												</div>
												: ''
											}
										</div>

										<div>
											{this.state.activeTab == '3' ?

												<Card className="main-card mb-2 custom_card_view">

													<CardBody>

														{this.state.assetsLoading ?
															<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
															:
															this.state.meterassets.length > 0 && this.state.gauageassets.length > 0 ?
																<Col lg="12">
																	<Table responsive className="mb-0">
																		<thead>
																			<th>{locale.t('el.equipment.name')}</th>
																			<th>{locale.t('el.equipment.lastValue')}</th>
																			<th>{locale.t('el.equipment.lastUpdated')}</th>
																			<th>{locale.t('el.equipment.noOfAlarms')}</th>
																			<th className="text-center"><span>{locale.t('el.equipment.manage')}</span></th>
																		</thead>
																		<tbody>


																			{this.state.gauageassets.map((item, index) =>
																				<tr key={item}>
																					<td>{item.name}</td>
																					<td>{item.value}</td>
																					<td>{this.getdateoftimings(item.last_update_date)}</td>
																					<td>0</td>
																					<td align="center">
																						<Button type="primary" onClick={() => this.setAnalyticLink(item.id, item.gauge_id, item.name, 'gauge')}>
																							Analytics
																		        </Button>
																					</td>
																				</tr>
																			)}

																			{this.state.meterassets.map((item1, index) =>
																				<tr key={item1}>
																					<td>{item1.name}</td>
																					<td>{item1.value}</td>
																					<td>{this.getdateoftimings(item1.last_update_date)}</td>
																					<td>0</td>
																					<td align="center">
																						<Button type="primary" onClick={() => this.setAnalyticLink(item1.id, item1.meter_id, item1.name, 'meter')}>
																							Analytics
																		    </Button>
																					</td>
																				</tr>
																			)}
																			<tr><td colSpan="5"></td></tr>
																		</tbody>
																	</Table>
																</Col>
																:
																<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Logs Found..</b></div>

														}
													</CardBody>


													<Dialog
														title={this.state.chartName}
														size="large"
														visible={this.state.ChartTimeseries}
														onCancel={() => this.setState({ ChartTimeseries: false })}
														lockScroll={false}
													>
														<Dialog.Body>

															<div className="pull-left">
																<Col md="6" lg="4" className="">
																	<Select onChange={this.setFilterType} value={this.state.filtertype}>
																		{
																			filtertypes.map(el => {
																				return <Select.Option key={el.value} label={el.label} value={el.value} />
																			})
																		}
																	</Select>
																</Col>
															</div>
															<br />

															{this.state.chartLoading ?

																<div><center><Loader type="ball-clip-rotate" /></center></div>
																:
																<div className="">
																	{this.state.chartData.length > 0 ?

																		<div className="area">
																			<Chart options={areachartoptions} series={areachartseries} type="area" id="apexchart-example-3" width="100%" />

																		</div>

																		:

																		<div className="text-center"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>


																	}
																</div>
															}

														</Dialog.Body>

													</Dialog>

												</Card>

												: ''
											}
										</div>


										<div>
											{this.state.activeTab == '8' ?
												<Row>
													<Col md="12" lg="12">
														<Card className="main-card mb-2 custom_card_view">

															<CardBody>

																{this.state.isAuditLogsLoaded ?
																	<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
																	:
																	<Col lg="12">
																		{this.state.audits.length > 0 ?

																			<VerticalTimeline animate={false} className="vertical-time-icons" layout="1-column">
																				{this.state.audits.map((item, index) =>
																					<VerticalTimelineElement
																						className="vertical-timeline-item"
																						icon={<div className="timeline-icon border-primary"><i
																							className="pe-7s-culture icon-gradient bg-night-fade" /></div>}
																					>

																						<h4 className="timeline-title">{item.author_id[1]} &nbsp; <span style={{ fontSize: '13px', fontWeight: '450', textTransform: 'lowercase' }}>{this.getdateoftimings(item.date)}</span></h4>
																						<p dangerouslySetInnerHTML={{ __html: item.body }} />
																						<ul>
																							{item.tracking_value_ids.map((item1, index1) =>
																								<li>{item1.old_value}&nbsp;<i className="lnr-arrow-right" />&nbsp;{item1.new_value}</li>
																							)}
																						</ul>
																					</VerticalTimelineElement>
																				)}
																				{this.state.auditLoading ?
																					<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
																					: ''}
																			</VerticalTimeline>
																			:
																			<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Audit Logs Found..</b></div>
																		}
																	</Col>

																}
															</CardBody>

														</Card>
													</Col>

												</Row>
												: ''
											}
										</div>

										<div>
											{this.state.activeTab == '9' ?

												<Card className="main-card mb-2 custom_card_view">
													<CardBody>

														{this.state.documentsLoading ?
															<div className="text-center col-md-12"><Loader type="ball-clip-rotate" /></div>
															:
															this.state.documents.length > 0 ?
																<Row>

																	{this.state.documents.map((item, index) =>
																		<Col md="6" lg="3" className={this.defclassgen(index)}>
																			<Card className="widget-chart widget-chart2 text-center mb-2  card-shadow-primary">
																				<div className="widget-chat-wrapper-outer">
																					<div className="widget-chart-content">
																						<div className="widget-title opacity-5 text-uppercase">
																							{item.name}
																						</div>
																						<br />
																						{item.mimetype == 'application/pdf' ?
																							<div>
																								<div className="text-danger">
																									<FontAwesomeIcon icon={faFilePdf} size="5x" />
																								</div>
																								<br />
																								<span className="text-left"><b style={{ textTransform: 'capitalize' }}>{item.ir_attachment_categ ? item.ir_attachment_categ[1] : ''}</b></span>
																								<div className="pull-right">

																									<span onClick={() => this.pdffileview(item.mimetype, item.datas_fname, item.id, item.name)}>{this.state.documentSingleViewLoad && this.state.documentId == item.id ? 'Downloading..' : <i className="lnr-eye font-size-lg"> </i>}</span>&nbsp;
																				  <span onClick={() => this.filedownlod(item.mimetype, item.datas_fname, item.id)}>{this.state.documentSingleLoad && this.state.documentId == item.id ? 'Downloading..' : <i className="lnr-download font-size-lg"> </i>}</span>

																								</div>

																							</div>
																							:
																							<div>
																								<div className="text-info">
																									<FontAwesomeIcon icon={faFileImage} size="5x" />
																								</div>
																								<br />
																								<span className="text-left"><b style={{ textTransform: 'capitalize' }}>{item.ir_attachment_categ ? item.ir_attachment_categ[1] : ''}</b></span>

																								<div className="pull-right">

																									<span onClick={() => this.imagefileview(item.mimetype, item.datas_fname, item.id, item.name)}>{this.state.documentSingleImgViewLoad && this.state.documentId == item.id ? 'Downloading..' : <i className="lnr-eye font-size-lg"> </i>}</span>&nbsp;
																				  <span onClick={() => this.filedownlod(item.mimetype, item.datas_fname, item.id)}>{this.state.documentSingleLoad && this.state.documentId == item.id ? 'Downloading..' : <i className="lnr-download font-size-lg"> </i>}</span>

																								</div>

																							</div>

																						}
																					</div>
																				</div>
																			</Card>
																		</Col>
																	)}

																</Row>
																:
																<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Documents Found..</b></div>
														}



														<Dialog
															title={this.state.viewfilename}
															size="small"
															visible={this.state.pdfviewdialog}
															onCancel={() => this.setState({ pdfviewdialog: false })}
															lockScroll={false}
														>
															<Dialog.Body>

																{this.state.documentSingleViewLoad ?

																	<div className="text-center"><Loader type="ball-clip-rotate" /></div>

																	:
																	<iframe width="640" height="400" frameborder="1" src={this.state.documentviewdata}></iframe>
																}
															</Dialog.Body>

														</Dialog>

														<Dialog
															title={this.state.viewfilename}
															size="tiny"
															visible={this.state.imgviewdialog}
															onCancel={() => this.setState({ imgviewdialog: false })}
															lockScroll={false}
														>
															<Dialog.Body>
																{this.state.documentSingleImgViewLoad ?

																	<div className="text-center"><Loader type="ball-clip-rotate" /></div>

																	:
																	<div className="text-center">
																		<img src={this.state.documentviewdata} width="300" height="300" />
																	</div>

																}
															</Dialog.Body>

														</Dialog>

													</CardBody>

												</Card>

												: ''
											}
										</div>

										<div>
											{this.state.activeTab == '4' ?
												<Row>
													<Col md="12" lg="12">
														<Card className="main-card mb-2 custom_card_view">
															<CardBody>
																<Col lg="12">
																	<div className="text-center"><img src={nodata} width="170" /> <br /><b>No Analytics Configured Yet..</b></div>
																</Col>
															</CardBody>

														</Card>
													</Col>

												</Row>
												: ''
											}
										</div>

									</div>
								}
							</div>
						</div>
					}
				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
