import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import locale from '../../locale';
import swal from 'sweetalert';
import Circle from 'react-circle';
import { Redirect, Link } from 'react-router-dom';
import classnames from 'classnames';
import leedimg from '../../assets/space/leed.png';
import wellimg from '../../assets/space/well.png';
import siteimg from '../../assets/space/site.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import { Pagination } from 'element-react';


import {
	Row, Col,
	Alert,
	Container, TabContent, TabPane,
	Card, CardBody, CardHeader,
	ButtonGroup,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroup, FormGroup, Label, FormText,
	ListGroupItem, CardFooter,
	CustomInput, Input,
	UncontrolledButtonDropdown, Collapse,
	CardTitle, Nav, NavItem, NavLink, Table
} from 'reactstrap';

import { Loading, Tabs, Tree, Select, Dropdown, Button, Progress, Tooltip, Tag, Dialog, DateRangePicker } from 'element-react';
//import { threadId } from 'worker_threads';

const loaddiv = { marginTop: '155px' }

export default class Certifications extends Component {
	constructor(props) {
		super(props);

		this.state = {
			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			chartName: '',
			ChartTimeseries: false,
			chart_dates: [],
			ctype: '',
			company: '',
			chart_values: [],
			filter_certificate: [],
			head_certificate: [],
			cmp_id: Sanitize.getStoreageItem('company_id'),
			siteData: [],
			chartType: '',
			filtertype: 'Today',
			daterange: this.getTodays(),
			chartData: [],
			chartcategory: 'area',
			queryText: ',["create_date", ">", "' + this.getYesterday() + '"],["create_date", "<=", "' + this.getdateoforginal(new Date()) + '"]',
			chartLoading: false,
			isDirect: false,
			gauageassets: [],
			certData: [],
			dataLoading: false,
			datafrom: '',
			meterassets: [],
			createdate: [],
			lc_id: '',
			chartId: '',
			totalcount: 0,
			isDataLoaded: false,
			todosPerPage: 50,
			currentPage: 1,
			columns: [],
			accordion: [],
			collapse: false,
			custom: [false],
			status: 'Closed',
			fadeIn: true,
			timeout: 300,
		};
		this.getdatesofmonth = this.getdatesofmonth.bind(this);
		this.onDateChange = this.onDateChange.bind(this);
		this.setFilterType = this.setFilterType.bind(this);
		this.setChartCategory = this.setChartCategory.bind(this);
		this.onShowChange = this.onShowChange.bind(this);
		this.onCurrentChange = this.onCurrentChange.bind(this);
		this.offsetcountfilter = this.offsetcountfilter.bind(this);
		this.offsetcount = this.offsetcount.bind(this);
		this.toggleAccordion = this.toggleAccordion.bind(this);
	}

	toggleAccordion(tab) {
		const prevState = this.state.accordion;
		const state = prevState.map((x, index) => tab === index ? !x : false);

		for (var i = 0; i < state.length; i++) {
			var idd = "#heading" + i;
			if (state[i] == false) {
				$(idd).find('i').removeClass("pe-7s-angle-down").addClass("pe-7s-angle-right");
			} else {

				$(idd).find('i').removeClass("pe-7s-angle-right").addClass("pe-7s-angle-down");
			}
		}

		this.setState({
			accordion: state,
		});
	}

	offsetcount(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		var total = this.state.totalcount;
		this.setState({ activePage: page });

		this.setState({ offset: offset });

		this.getdatadyn(offset);

	}

	offsetcountfilter(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		var total = this.state.totalcount;
		this.setState({ activePage: page });

		this.setState({ offset: offset });

		this.getfilterdatapagination(offset);

	}

	onShowChange(e) {
		this.state.todosPerPage = e;
		this.getCerificationData();
	}

	onCurrentChange(e) {
		this.state.currentPage = Number(e);
		this.getCerificationData();
	}

	componentDidMount() {

		if (this.props.location.state && (this.props.location.state.modelkeyid == 'certificate_analytics' || this.props.location.state.modelkeyid == 'certificate_analytics_single')) {
			this.getdata();
		}

	}

	getdata() {

		if (this.props.location.state.modelkeyid == 'certificate_analytics' || this.props.location.state.modelkeyid == 'certificate_analytics_single') {


			var type = this.props.location.state.certificatetype;

			var companyid = this.props.location.state.uid;


			this.setState({ ctype: type });
			this.state.company = companyid;
			this.setState({ company: companyid });

			this.getCerificationData();

		}


	}

	getCertificateList(tid) {
		var list = [];

		for (var i = 0; i < this.state.filter_certificate.length; i++) {
			var item = this.state.filter_certificate[i];
			if (item.title_id == tid) {
				list.push(<tr>
					<td>{item.category_id}</td>
					<td>{item.case_option}</td>
					<td>{item.type}</td>
					{item.type == 'Prerequisite' ?
						<React.Fragment>
							<td>{item.max_points}</td>
							<td colSpan="3" style={{ backgroundColor: "#b3b3b3a1" }}></td>
						</React.Fragment> :
						<React.Fragment>
							<td>{item.max_points}</td>
							<td>{item.yes_value}</td>
							<td>{item.maybe_value}</td>
							<td>{item.no_value}</td>
						</React.Fragment>}
				</tr>)
			}
		}
		return list;
	}

	getSiteData(type) {
		this.setState({ ctype: type });
		$("Button").find('i').removeClass("pe-7s-angle-down").addClass("pe-7s-angle-right");
		this.setState({ siteData: [], filter_certificate: [], head_certificate: [] });
		var data = this.state.certData;

		var siteData = [];
		var sites = [];
		for (var i = 0; i < data.length; i++) {
			if (type == data[i].system_name) {
				siteData.push(data[i]);
			}
		}
		this.setState({ siteData: siteData });
		if (siteData.length > 0) {
			//this.setState({ createdate: this.state.siteData[0].create_date })
			// Displaying Pages
			var sites = siteData[0].check_list_ids;
			/*const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
			const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
			const currentData = sites.slice(indexOfFirstTodo, indexOfLastTodo);*/

			var s = this.groupByMultiple(sites, function (obj) {
				return [obj.title_id];
			});

			this.setState({ head_certificate: s });

			var length = s.length; // user defined length
			var clen = [];
			for (var i = 0; i < length; i++) {
				clen.push(false);
			}
			console.log(clen);
			//var ltext = s[0][0].title_id;

			this.setState({ filter_certificate: sites, accordion: clen });
		}

	}


	groupByMultiple(array, f) {

		var groups = {};
		array.forEach(function (o) {
			var group = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		});

	}


	setFilterType(column) {

		this.setState({ queryText: '' });
		this.setState({ filtertype: '' });
		this.state.queryText = '';

		this.setState({ filtertype: column });

		if (column == 'This Month') {

			this.getdatesofmonth();
		}
		else if (column == 'Today') {

			this.getTodayData();

		}
		else if (column == 'This Week') {

			this.getdatesofweek();

		}
		else if (column == 'Last_Week') {

			this.getDateSeries(column);

		}
		else if (column == 'Last_Month') {

			this.getDateSeries(column);

		}
		else if (column == 'Last_3_Months') {

			this.getDateSeries(column);

		}
		else {

			this.onGetLogsChart(this.state.chartId, this.state.chartName, this.state.chartType, this.state.lc_id);

		}

	}


	getDateSeries(series) {

		this.setState({ queryText: '' });

		var daycount = 7;

		if (series == 'Last_Week') {

			daycount = 7;
		}
		else if (series == 'Last_Month') {

			daycount = 30;
		}
		else if (series == 'Last_3_Months') {

			daycount = 90;
		}

		var lastDay = new Date();
		var firstDay = new Date();
		firstDay.setTime(firstDay.getTime() - 3600 * 1000 * 24 * daycount);

		var daterange = [];

		daterange.push(firstDay);
		daterange.push(lastDay);

		this.setState({ daterange: daterange });

		firstDay = this.getdateoforginal(firstDay);
		lastDay = this.getdateoforginal(lastDay);

		var queryText = ',["create_date", ">=", "' + firstDay + '"],["create_date", "<", "' + lastDay + '"]'

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id, this.state.lc_id);
		}
		else {

			this.getmetersdata(id, this.state.lc_id);

		}
	}


	setChartCategory(cat) {

		this.setState({ chartcategory: cat });
		this.state.chartcategory = cat;
		this.onGetLogsChart(this.state.chartId, this.state.chartName, this.state.chartType, this.state.lc_id);

	}

	daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	}

	getdatesofmonth() {

		this.setState({ queryText: '' });

		var date = new Date();
		var firstDay = new Date(date.getFullYear(),
			date.getMonth(), 1);

		var lastDay = new Date(date.getFullYear(),
			date.getMonth(), this.daysInMonth(date.getMonth() + 1,
				date.getFullYear()));

		var daterange = [];

		daterange.push(firstDay);
		daterange.push(lastDay);

		this.setState({ daterange: daterange });

		firstDay = this.getdateoforginal(firstDay);
		lastDay = this.getdateoforginal(lastDay);

		var queryText = ',["create_date", ">=", "' + firstDay + '"],["create_date", "<", "' + lastDay + '"]'

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id, this.state.lc_id);
		}
		else {

			this.getmetersdata(id, this.state.lc_id);

		}
	}


	onDateChange(date) {

		this.setState({ queryText: '' });

		this.setState({ daterange: date });

		var firstDay = date[0];

		var lastDay = date[1];

		firstDay = this.getdateoforginal(firstDay);
		lastDay = this.getdateoforginal(lastDay);

		var queryText = ',["create_date", ">=", "' + firstDay + '"],["create_date", "<", "' + lastDay + '"]'

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id, this.state.lc_id);
		}
		else {

			this.getmetersdata(id, this.state.lc_id);

		}

	}


	getdatesofweek() {

		this.setState({ queryText: '' });

		var curr = new Date; // get current date
		var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
		var last = first + 6; // last day is the first day + 6

		var firstDay = new Date(curr.setDate(first)).toUTCString();
		var lastDay = new Date(curr.setDate(last)).toUTCString();

		var daterange = [];

		var firstDay1 = new Date(curr.setDate(first));
		var lastDay1 = new Date(curr.setDate(last));
		daterange.push(firstDay1);
		daterange.push(lastDay1);

		this.setState({ daterange: daterange });


		firstDay = this.getdateoforginal(firstDay);
		lastDay = this.getdateoforginal(lastDay);

		var queryText = ',["create_date", ">=", "' + firstDay + '"],["create_date", "<", "' + lastDay + '"]'

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id, this.state.lc_id);
		}
		else {

			this.getmetersdata(id, this.state.lc_id);

		}
	}


	convertLoalTime(date_future) {

		var date_future = new Date(date_future);

		var year = date_future.getFullYear();
		var month = (date_future.getMonth() + 1);
		var day = date_future.getDate()

		var addhours = date_future.getHours() + 5;
		var mints = date_future.getMinutes();
		var secs = date_future.getMinutes();

		if (addhours == '13') {

			addhours = '01';
		}
		else if (addhours == '14') {

			addhours = '02';

		}
		else if (addhours == '15') {

			addhours = '03';

		}
		else if (addhours == '16') {

			addhours = '04';

		}
		else if (addhours == '17') {

			addhours = '05';

		}

		return year + '-' + month + '-' + day + ' ' + addhours + ':' + mints + ':' + secs

	}


	getdateofdttime(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime + ' ' + strTime1;
		}
		else {

			return '- - - -';
		}

	}

	getdateoftimings(date) {

		var cdate = this.convertLoalTime(date);



		var date_future = new Date(cdate);



		var date_now = new Date();


		// get total seconds between the times
		var delta = Math.abs(date_future - date_now) / 1000;

		// calculate (and subtract) whole days
		var days = Math.floor(delta / 86400);
		delta -= days * 86400;

		// calculate (and subtract) whole hours
		var hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;

		// calculate (and subtract) whole minutes
		var minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;

		if (days != 0 || days > 2) {

			return this.getdateofdttime(date);
		}
		else if (days == 0 && hours != 0) {

			return hours + ' hours ago';

		}
		else {

			return minutes + ' minutes ago';

		}

	}

	getYesterday() {

		var d = new Date();
		var date = d.setDate(d.getDate() - 1);

		var yesterday = this.getdateoforginal(date);

		return yesterday;


	}

	getTodays() {

		var dates = [];
		var d = new Date();
		var date = d.setDate(d.getDate() - 1);

		var yesterday = new Date(date);

		var today = new Date();

		dates.push(yesterday);
		dates.push(today);



		return dates;


	}


	getTodayData() {

		this.setState({ queryText: '' });

		var date1 = new Date();
		var year = date1.getFullYear();
		var month = (date1.getMonth() + 1);
		var day = date1.getDate()
		var hours = date1.getHours();
		var minutes = date1.getMinutes();
		var seconds = date1.getSeconds();
		month = month < 10 ? '0' + month : month;
		day = day < 10 ? '0' + day : day;
		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		seconds = seconds < 10 ? '0' + seconds : seconds;

		var d = new Date();
		var date = d.setDate(d.getDate() - 1);

		var yesterday = this.getdateoforginal(date);

		var today = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;

		var daterange = [];

		var firstDay = new Date(date);
		var lastDay = new Date();
		daterange.push(firstDay);
		daterange.push(lastDay);

		this.setState({ daterange: daterange });

		var queryText = ',["create_date", ">", "' + yesterday + '"],["create_date", "<=", "' + today + '"]';

		this.setState({ queryText: queryText });
		this.state.queryText = queryText;

		var type = this.state.chartType;
		var id = this.state.chartId;

		if (type == 'gauge') {

			this.getgaugesdata(id, this.state.lc_id);
		}
		else {

			this.getmetersdata(id, this.state.lc_id);

		}



	}


	getChartData() {

		var data = this.state.chartData;

		var dates = []; var values = [];

		for (var i = 0; i < data.length; i++) {

			if (data[i].mroord_count != 0) {

				dates.push(data[i].create_date);
				values.push(data[i].value);
			}

		}

		this.setState({ chart_dates: dates, chart_values: values });
	}

	getgaugesdata(guageid, id) {

		this.setState({ chartLoading: true });
		this.setState({ chartData: [], chart_dates: [], chart_values: [] });
		var token = Sanitize.getStoreageItem('accessToken');



		var queryText = this.state.queryText;

		var typeid = '';

		var datafrom = this.state.datafrom;

		if (datafrom == 'space_analytics') {

			typeid = 'space_id';
		}
		else if (datafrom == 'equip_analytics') {

			typeid = 'equipment_id';
		}

		var query = '"&",["' + typeid + '", "in", [' + id + ']],["gauge_id","=",' + guageid + ']' + queryText;

		var data = { "token": token, "query": query };
		Reqst.axPost('gaugeslogs', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ chartLoading: false });
				this.setState({ chartData: res });
				this.getChartData();
			}
		});
	}

	getRefreshToken() {

		this.setState({ dataLoding: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoding: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);

				this.getCerificationData();

			}
		}).catch(error => {
			this.setState({ dataLoding: false });
			//console.log(error.response.status);

		});

	}

	getmetersdata(meterid, id) {

		this.setState({ chartLoading: true });
		this.setState({ chartData: [], chart_dates: [], chart_values: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		//	var id = this.state.lc_id;

		//console.log(id);

		var queryText = this.state.queryText;

		var typeid = '';

		var datafrom = this.state.datafrom;

		if (datafrom == 'space_analytics') {

			typeid = 'space_id';
		}
		else if (datafrom == 'equip_analytics') {

			typeid = 'equipment_id';
		}

		var query = '"&",["' + typeid + '", "in", [' + id + ']],["meter_id","=",' + meterid + ']' + queryText;

		var data = { "token": token, "query": query };
		Reqst.axPost('meterlogs', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ chartLoading: false });
				this.setState({ chartData: res });
				this.getChartData();
			}
		});
	}

	getCerificationData() {
		this.setState({ dataLoading: true });
		this.setState({ certData: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		//var cid = Sanitize.getStoreageItem('company_id');
		var data = { "token": token, "company": this.state.company }

		Reqst.axPost('getauditschecklists', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ certData: res });
				this.setState({ left_style: 'left_search_card' });
				this.setState({ sticky_searchbar: 'sticky_top_searchbar' });
				this.setState({ topbar_buttons: 'topbar_buttons' });
				this.setState({ content_card: 'content_top_card' });
				this.setState({ isDataLoaded: true });
				this.getSiteData(this.state.ctype);
			}
		});

	}

	onGetLogsChart(id, name, type, spaceid) {
		//this.setState({queryText:''});
		//this.setState({filtertype:''});
		//this.state.queryText = ',["create_date", ">", "'+this.getYesterday()+'"],["create_date", "<=", "'+this.getdateoforginal(new Date())+'"]';
		//this.setState({daterange:this.getTodays()});
		//this.setState({queryText:',["create_date", ">", "'+this.getYesterday()+'"],["create_date", "<=", "'+this.getdateoforginal(new Date())+'"]'});
		//this.setState({chartcategory:'area'})
		this.setState({ ChartTimeseries: true });
		this.setState({ chartName: name });
		this.setState({ chartType: type });
		this.setState({ chartId: id });
		this.setState({ lc_id: spaceid });
		this.setState({ left_style: 'left_search_card' });
		this.setState({ sticky_searchbar: 'sticky_top_searchbar' });
		this.setState({ topbar_buttons: 'topbar_buttons' });
		this.setState({ content_card: 'content_top_card' });
		this.setState({ isDataLoaded: true });



		if (type == 'gauge') {

			this.getgaugesdata(id, spaceid);

		}
		else {

			this.getmetersdata(id, spaceid);
		}

	}


	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}

	directtoanother() {

		this.setState({ isDirect: true });

	}


	getdateoforginal(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var seconds = date1.getSeconds();
			month = month < 10 ? '0' + month : month;
			day = day < 10 ? '0' + day : day;
			hours = hours < 10 ? '0' + hours : hours;
			minutes = minutes < 10 ? '0' + minutes : minutes;
			seconds = seconds < 10 ? '0' + seconds : seconds;

			var strTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
			return strTime;
		}
		else {

			return '- - - -';
		}

	}

	getCellDetails(row, column, cell, event) {
		console.log(row);
		console.log(column);
		console.log(cell);


	}

	getExpandvalue(e, i) {
		console.log(e);
		console.log(i);
		$(this).closest('td').toggleClass('el-table__expand-column');

		if (i === true) {
			//$(".").attr('aria-expanded', false);
			$(this).closest('td').toggleClass('el-table__expand-column');
		} else {
			$(".el-table__expand-column").attr('aria-expanded', true);
		}

		$("td.el-table__expanded-cell").css({ "padding": "0px" });
		$("td.el-table__expanded-cell").css({ "padding": "0px" });
		//$(".el-table").not($(this)).removeAttribute('defaultExpandAll');
		//$("table").attr("defaultExpandAll", true);
		//	$(".el-table th>.cell").css({ "padding": "3px" });
		$("span.Ptype").closest("td").css({ "background-color": "#b3b5b5", "position": "relative", "left": "16px" });
		$(".el-table .cell").css({ "word-break": "break-word" });
	}

	getCurrentvalue(current, old) {
		//console.log(current);

	}

	getYesTotal(tid) {
		var points = 0;
		for (var i = 0; i < this.state.filter_certificate.length; i++) {
			var item = this.state.filter_certificate[i];
			if (item.title_id == tid) {
				points += parseFloat(item.yes_value);
			}
		}
		return points;
	}

	getMayTotal(tid) {
		var points = 0;
		for (var i = 0; i < this.state.filter_certificate.length; i++) {
			var item = this.state.filter_certificate[i];
			if (item.title_id == tid) {
				points += parseFloat(item.maybe_value);
			}
		}
		return points;
	}

	getNoTotal(tid) {
		var points = 0;
		for (var i = 0; i < this.state.filter_certificate.length; i++) {
			var item = this.state.filter_certificate[i];
			if (item.title_id == tid) {
				points += parseFloat(item.no_value);
			}
		}
		return points;
	}


	render() {

		//$(".el-table__header:first").css({ "display": "none" });
		$(".el-table__header:first").find("thead").eq(0).remove();
		var columnsinside = [
			{
				label: "Category",
				prop: "category_id",
				width: 250,
				render: (row, column, index) => {
					return <span style={{ whiteSpace: 'pre-wrap' }}>{row.category_id}</span>;
				}
			},
			{
				label: "Case/Threshold",
				prop: "case_option",
				width: 250,
				render: (row, column, index) => {
					return <span style={{ whiteSpace: 'pre-wrap' }}>{row.case_option}</span>;
				}
			},
			{
				label: "Type",
				prop: "type",
				width: 150,

			},
			{
				label: "Max Points",
				prop: "max_points",
				width: 150,
			},
			{
				label: "Yes",
				render: (row, column, index) => {
					if (row.type == 'Prerequisite') {
						return <span className="Ptype"></span >;
					} else {
						return <span>{row.yes_value}</span>;
					}
				}
			},
			{
				label: "May Be",
				render: (row, column, index) => {
					if (row.type == 'Prerequisite') {
						return <span className="Ptype"></span>;
					} else {
						return <span>{row.maybe_value}</span>;
					}
				}
			},
			{
				label: "No",
				render: (row, column, index) => {
					if (row.type == 'Prerequisite') {
						return <span className="Ptype"></span>;
					} else {
						return <span>{row.no_value}</span>;
					}
				}

			},

		];



		var columns = [
			{
				type: 'expand',
				expandPannel: function (data) {
					return (
						<Table
							style={{ width: '100%' }}
							columns={columnsinside}
							data={data}
							stripe={false}
						/>
					)
				}
			},
			{
				prop: "title_id",
				resizable: true,
				width: 400,
				render: (row, column, index) => {
					return <span>{row[0].title_id}</span>;
				}
			},
			{
				prop: "case",
				width: 145,
			},
			{
				prop: "type",
			},
			{
				prop: "maxpoints",
			},
			{
				prop: "yes",
				render: (row, column, index) => {
					return <span>{this.getYesTotal(row[0].title_id)}</span>;
				}
			},
			{
				prop: "maybe",
				render: (row, column, index) => {
					return <span>{this.getMayTotal(row[0].title_id)}</span>;
				}
			},
			{
				prop: "no",
				render: (row, column, index) => {
					return <span>{this.getNoTotal(row[0].title_id)}</span>;
				}
			},];


		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'certificate_analytics') {

			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid: 'sites', site_id: this.props.location.state.uid, site_name: this.props.location.state.value,type : '12' }
			}}
			/>);

		}
		
		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'certificate_analytics_single') {

			return (<Redirect to={{
				pathname: '/strategiccertifications',
				state: { modelkeyid: 'sites', site_id: this.props.location.state.uid, site_name: this.props.location.state.value }
			}}
			/>);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'equip_analytics') {


			return (<Redirect to={{
				pathname: '/assets/equipment/view/' + this.props.location.state.uid
			}}
			/>);

		}

		const barchartoptions = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: this.state.chart_dates
			},

		};

		const barchartseries = [{
			name: this.state.chartName,
			data: this.state.chart_values
		}]

		const charttypes = [
			{
				value: 'area',
				label: 'Area'
			},
			{
				value: 'pie',
				label: 'Pie'
			},
			{
				value: 'bar',
				label: 'Bar'
			}
		];

		const areachartoptions = {
			chart: {
				stacked: false,
				zoom: {
					type: 'x',
					enabled: true
				},
				toolbar: {
					autoSelected: 'zoom'
				}
			},
			plotOptions: {
				line: {
					curve: 'smooth',
				}
			},
			dataLabels: {
				enabled: false
			},

			markers: {
				size: 0,
				style: 'full',
			},
			//colors: ['#0165fc'],
			title: {
				text: this.state.chartName,
				align: 'left'
			},
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					inverseColors: false,
					opacityFrom: 0.5,
					opacityTo: 0,
					stops: [0, 90, 100]
				},
			},
			yaxis: {
				title: {
					text: 'Value'
				},
			},
			xaxis: {
				type: 'datetime',
				categories: this.state.chart_dates
			}
		};


		const areachartseries = [{
			name: this.state.chartName,
			data: this.state.chart_values
		}];

		const piechartseries = this.state.chart_values;

		const piechartpotions = {
			chart: {
				id: 'apexchart-example-4',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},

			labels: this.state.chart_dates,
			colors: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			],
			hover: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			]

		};

		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Analytics </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					<div className="topbar-header">
						<div className="topbar-header__content">

							<PageTitleAlt3
								heading=""
								subheading=""
								icon=""
								menuLevel1={this.props.location.state ? this.props.location.state.value : 'Strategic Certifications'}
								menuLevel2={this.props.location.state ? 'Strategic Certifications' : this.state.ctype}
								menuLevel3={this.props.location.state ? this.state.ctype : ''}
								menuLevel1click={() => this.props.location.state ? this.directtoanother() : ''}
								menuLevel1link={this.props.location.state ? 'javascript:;' : false}

								isbreadcumb={true}
							/>

							<div className="topbar_buttons">
								{/*<Pagination
									layout="sizes,prev,next,total"
									total={this.state.filter_certificate.length}
									pageSizes={[50, 100, 200, 300]}
									pageSize={this.state.todosPerPage}
									currentPage={this.state.currentPage}
									onSizeChange={this.onShowChange}
									onCurrentChange={this.onCurrentChange}
								/>
								<Pagination
									layout="sizes,total"
									total={this.state.head_certificate.length}
									pageSizes={[2, 4, 6, 10]}
									pageSize={this.state.todosPerPage}
									currentPage={this.state.currentPage}
									onSizeChange={this.state.certData.length > 0 ? this.offsetcountfilter : this.offsetcount}
									onCurrentChange={this.state.certData.length > 0 ? this.setfilterrpp : this.setrpp}
								/>*/}
							</div>
						</div>
					</div>


					{!this.state.isDataLoaded ?

						<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
						:

						<div className="main_data">
							<div className={this.state.sticky_searchbar} style={{ borderBottom: '1px solid rgb(209, 219, 229)' }}>

								<h5 style={{ textAlign: 'center', verticalAlign: 'middle', marginTop: '5px', color: '#545cd8' }}><i className="lnr-highlight font-size-sm" /> &nbsp;{
									locale.t('el.space.checklists')} </h5>

							</div>


							<div className={this.state.left_style} id="left_search_card_id" style={{ marginTop: '58px' }}>
								<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.getSiteData('LEED')}>
									<div style={{ padding: '1px 5px' }} className={this.state.ctype == 'LEED' ? 'active_left_data' : ''}>
										<Tooltip className="item" content='LEED' placement="top-center" style={{ height: '43px', paddingTop: '10px' }}>
											<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>LEED</b></span>
										</Tooltip>
									</div>
									<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
								</a>
								<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.getSiteData('SITES')}>
									<div style={{ padding: '1px 5px' }} className={this.state.ctype == 'SITES' ? 'active_left_data' : ''}>
										<Tooltip className="item" content='SITES' placement="top-center" style={{ height: '43px', paddingTop: '10px' }} >
											<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>SITES</b></span>
										</Tooltip>
									</div>
									<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
								</a>
								<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.getSiteData('WELL')}>
									<div style={{ padding: '1px 5px' }} className={this.state.ctype == 'WELL' ? 'active_left_data' : ''}>
										<Tooltip className="item" content="WELL" placement="top-center" style={{ height: '43px', paddingTop: '10px' }} >
											<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>WELL</b></span>
										</Tooltip>
									</div>
									<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
								</a>

							</div>


							<div className={this.state.content_card}>
								<div>
									<Card className="main-card mb-2">

										<CardBody>
											{this.state.dataLoading ?
												<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
												:
												<React.Fragment>
													<div className="block">
														<div className="row">
															<div className={this.state.ctype == 'SITES' ? "col-lg-6" : "content-heading col-lg-5"}>
																<div className="span4" style={{ float: 'left' }}>
																	{this.state.ctype == 'LEED' ?
																		<img src={leedimg} style={{ float: 'left' }} width="100" height="100" />
																		:
																		this.state.ctype == 'SITES' ?
																			<img src={siteimg} style={{ float: 'left', marginTop: '10px', marginBottom: '10px' }} width="auto" height="80" /> :

																			this.state.ctype == 'WELL' ?
																				<img src={wellimg} style={{ float: 'left' }} width="100" height="100" /> : ''
																	}
																	<div className="content-heading" style={{ float: 'left', marginTop: '25px', marginLeft: '10px' }}>
																		{this.state.ctype == 'LEED' ?
																			<React.Fragment>
																				<span className="card-title uppercase" style={{ fontSize: '27px' }}>LEED</span> <br />
																				<span className="card-title uppercase">Certification</span></React.Fragment>
																			:
																			this.state.ctype == 'SITES' ?
																				<React.Fragment>
																					<span className="card-title uppercase" style={{ fontSize: '27px' }}>SITES</span> <br />
																					<span className="card-title uppercase">Certification</span></React.Fragment>
																				:
																				this.state.ctype == 'WELL' ?
																					<React.Fragment>
																						<span className="card-title uppercase" style={{ fontSize: '27px' }}>WELL</span> <br />
																						<span className="card-title uppercase">Certification</span></React.Fragment>
																					: ''}
																	</div>
																</div>
															</div>
															<div className={this.state.ctype == 'SITES' ? "content-heading col-lg-6" : "content-heading col-lg-6"} style={{ marginTop: '13px' }}>
																<span style={{ fontSize: '17px' }}>{
																	locale.t('el.space.certificateStatus')}&nbsp;:&nbsp;
																	<b>
																		{this.state.siteData.length > 0 ? this.state.siteData[0].status_name : ''}</b></span><br />
																<span style={{ fontSize: '17px' }}>{
																	locale.t('el.space.currentPoints')}&nbsp;:&nbsp;	<b> {this.state.siteData.length > 0 ? this.state.siteData[0].yes_total : ''}</b>
																	{this.state.ctype == 'LEED' && this.state.siteData.length > 0 ?
																		< React.Fragment >
																			< br />
																			<span style={{ fontSize: '17px' }}>{
																				locale.t('el.space.auditedOn')} : <b>{this.state.siteData[0].create_date}</b></span>
																		</React.Fragment>
																		:
																		this.state.ctype == 'SITES' && this.state.siteData.length > 0 ?
																			< React.Fragment >
																				< br />
																				<span style={{ fontSize: '17px' }}>{
																					locale.t('el.space.auditedOn')} :  <b>{this.state.siteData[0].create_date}</b></span>
																			</React.Fragment>
																			:
																			this.state.ctype == 'WELL' && this.state.siteData.length > 0 ?
																				< React.Fragment >
																					< br />
																					<span style={{ fontSize: '17px' }}>{
																						locale.t('el.space.auditedOn')} : <b>{this.state.siteData[0].create_date}</b></span>
																				</React.Fragment> :
																				< React.Fragment >
																					< br />
																					<span style={{ fontSize: '17px' }}>{
																						locale.t('el.space.auditedOn')} :  </span>
																				</React.Fragment>
																	}</span>
															</div>
														</div>
													</div>
												</React.Fragment>
											}
										</CardBody>
									</Card>

									<Card className="main-card mb-2" style={{ paddingTop: '15px' }}>

										<CardBody>

											{this.state.dataLoading ?
												<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
												:

												<div id="accordion" className="accordion-wrapper mb-3">
													<Card>
														{this.state.head_certificate.length > 0 ?
															this.state.head_certificate.map((item, i) =>
																<React.Fragment>
																	<CardHeader id={"heading" + i} style={{ padding: '0px' }}>
																		<Button block color="link" id={"heading" + i} style={{ border: 'none' }} className="text-left m-0 p-0"
																			onClick={() => this.toggleAccordion(i)}
																			aria-expanded={this.state.accordion[i]}
																			aria-controls={"collapse" + i}>
																			<i class="metismenu-icon pe-7s-angle-right" style={{ fontSize: '2.5em', color: '#545cd8' }}></i>&emsp;
																			<span className="card-title uppercase" style={{ fontSize: '15px', position: 'relative', bottom: '8px' }}>{item[0].title_id}</span>
																		</Button>
																	</CardHeader>
																	<Collapse isOpen={this.state.accordion[i]} data-parent="#accordion"
																		id={"collapse" + i} aria-labelledby={"heading" + i}>
																		<Table responsive className="mb-0" width="100%" >
																			<thead>
																				<tr key={i} style={{ color: '#545cd8' }}>
																					<th colSpan="4" style={{ border: 'none' }}></th>
																					<th style={{ border: 'none' }}>{this.getYesTotal(item[0].title_id)}</th>
																					<th style={{ border: 'none' }}>{this.getMayTotal(item[0].title_id)}</th>
																					<th style={{ border: 'none' }}>{this.getNoTotal(item[0].title_id)}</th>
																				</tr>
																				<tr>
																					<th style={{ width: "20%" }}>{
																						locale.t('el.space.category')}</th>
																					<th style={{ width: "30%" }}>{
																						locale.t('el.space.casethres')}</th>
																					<th style={{ width: "10%" }}>{
																						locale.t('el.space.type')}</th>
																					<th style={{ width: "10%" }}>{
																						locale.t('el.space.maxPoints')}</th>
																					<th style={{ width: "10%", padding: '6px' }}>{
																						locale.t('el.space.yes')}</th>
																					<th style={{ width: "10%" }}>{
																						locale.t('el.space.mayBe')}</th>
																					<th style={{ width: "10%" }}>{
																						locale.t('el.space.no')}</th>
																				</tr>
																			</thead>
																			<tbody>
																				{this.getCertificateList(item[0].title_id)}
																			</tbody>
																		</Table><br />
																	</Collapse>
																</React.Fragment>
															)

															: ''}

													</Card>
												</div>
											}
										</CardBody>
									</Card>
								</div>
							</div>
						</div>
					}
				</ReactCSSTransitionGroup>
			</Fragment >
		)
	}
}
