import React, { Fragment } from 'react';

import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Loader from 'react-loaders';
// Examples
import CalendarBasic from './Examples/Basic';

export default class CalendarExample extends React.Component {

    render() {

        return (
            <Fragment>
                { /* <PageTitleAlt3
                    heading="Calendar"
                    subheading="Calendars are used in a lot of apps. We thought to include one for React."
                    icon="pe-7s-car icon-gradient bg-warm-flame"
                />*/}
                <div className="drawer_left_menu pull-left">
                    <PageTitleAlt3
                        heading=""
                        subheading=""
                        icon=""
                        menuLevel1="PPM Calendar"
                        isbreadcumb={true}
                    />
                </div>
                <CalendarBasic />
            </Fragment>
        );
    }
}