import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import locale from '../../locale';
import Circle from 'react-circle';
import { Redirect, Link } from 'react-router-dom';
import classnames from 'classnames';
import userimg from '../../assets/utils/images/avatars/avatar_1.png';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileViewer from 'react-file-viewer';
import ReactTooltip from 'react-tooltip';
import ReactFileReader from 'react-file-reader';

import {
	faFilePdf,
	faFileImage

} from '@fortawesome/free-solid-svg-icons';

import Hamburger from 'react-hamburgers';

import PerfectScrollbar from 'react-perfect-scrollbar';

import Drawer from 'react-motion-drawer';

import DrawerBigExample from '.../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';

import DatePicker from 'react-datepicker';

import FloatingMenuItem from '../../tie/FloatingMenuItem';

import {
	Row, Col,
	Alert,
	Container, TabContent, TabPane,
	Card, CardBody, CardHeader,
	Table,
	ButtonGroup,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroup, FormGroup, Label, FormText,
	ListGroupItem, CardFooter,
	CustomInput, Input,
	UncontrolledButtonDropdown,
	CardTitle, Nav, NavItem, NavLink,
} from 'reactstrap';

import { Loading, Tabs, Tree, Select, Dropdown, Button, Progress, Tooltip, Tag, Dialog } from 'element-react';

const cardstyles = { borderRight: '1px solid #545cd8' }

const loaddiv = { marginTop: '155px' }

export default class ViewEmployee extends Component {
	constructor(props) {
		super(props);

		this.state = {
			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			employee_filter_data: [],
			filters: [],
			searchcolumn: '',
			searchfor: '',
			updateLoading : false,
			searchvalue: '',
			dataLoading: false,
			employeelistloading: false,
			employeefilterscrollLoad: false,
			employeelist: [],
			employee_search_name: '',
			topbar_buttons: '',
			emp_name: '',
			orderoffset: 30,
			left_style: '',
			formmode: false,
			search_query: '',
			content_card: '',
			is_search_enable: false,
			sticky_searchbar: '',
			savedtags: [],
			employeeistFilterLoading: false,
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			activeTab: '1',
			selsearchtext: '',
			id: this.props.match.params.id

		};


		this.onTabChange = this.onTabChange.bind(this);
		this.onFormModeChange = this.onFormModeChange.bind(this);
		this.setsearchvalue = this.setsearchvalue.bind(this);
		this.onEmployeeChange = this.onEmployeeChange.bind(this);
		this.employeefiltersclick = this.employeefiltersclick.bind(this);
		this.employeefilters = this.employeefilters.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onFilterApply = this.onFilterApply.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
		this.setsearchfor = this.setsearchfor.bind(this);
		this.onEmployeeDataChange = this.onEmployeeDataChange.bind(this);
		this.getEmployeeListonScroll = this.getEmployeeListonScroll.bind(this);
		this.setgender = this.setgender.bind(this);
		this.setMarital = this.setMarital.bind(this);

	}

	setgender(column) {
		this.setState({ emp_gender: column });
	}
	
	setMarital(column) {
		this.setState({ emp_marital: column });
	}
	
	onFormModeChange() {

		this.setState({ formmode: true });

	}

	onFilterApply(id, query, name) {

		query = query.substr(1).slice(0, -1);
		this.setState({ searchDialog: false });
		this.employeesavedfilters(query);

		var orgdata = [{ "key": id, "name": name, "query": query }];

		this.setState({ tags: [] });

		this.setState({ savedtags: orgdata });


		console.log(this.state.savedtags);

	}


	setsearchvalue(e) {

		this.setState({ searchvalue: e.target.value });
	}

	
	setselsearchvalue(value) {

		this.setState({ searchvalue: value });
	}

	onInputChange(e) {

		this.setState({ [e.target.name]: e.target.value });
	}

	

	

	setsearchfor(e) {

		if (e.target.checked) {

			this.setState({ searchfor: e.target.value });
		}
		else {

			this.setState({ searchfor: '' });
		}
	}


	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}



	componentDidMount() {

		this.getdata();
		this.getEmployeeList();
		this.getFilters();
		// setInterval(this.getSLAOnRun, 1000);

	}

	componentDidUpdate() {

		window.addEventListener('scroll', this.onTicketScroll());
		if (this.employee_search_name) {
			this.employee_search_name.focus();
			$("#search_text").focus();
		}

	}


	getFilters() {

		this.setState({ employeelistloading: true });
		this.setState({ filters: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var data = { "token": token, "model": "hr.employee" };
		Reqst.axPost('getfilters', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ employeelistloading: false });

				this.setState({ filters: res });


			}
		});

	}



	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;
			return strTime;
		}
		else {

			return 'Not Assigned';
		}

	}

	getdateofdttime(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime + ' ' + strTime1;
		}
		else {

			return 'Not Assigned';
		}

	}

	
	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate();
			var mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			var mname = mlist[date1.getMonth()];
			var strTime = day + ' ' + mname + ' ' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime;
		}
		else {

			return '- - - -';
		}

	}

	getdateoftimingsind(date) {

		var cdate = this.convertLoalTime(date);



		var date_future = new Date(cdate);



		var date_now = new Date();


		// get total seconds between the times
		var delta = Math.abs(date_future - date_now) / 1000;

		// calculate (and subtract) whole days
		var days = Math.floor(delta / 86400);
		delta -= days * 86400;

		// calculate (and subtract) whole hours
		var hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;

		// calculate (and subtract) whole minutes
		var minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;

		if (days != 0 || days > 2) {

			return this.getdateofdttime(date);
		}
		else if (days == 0 && hours != 0) {

			return hours + ' hours ago';

		}
		else {

			return minutes + ' minutes ago';

		}

	}


	getdateoftime(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime1;
		}
		else {

			return '- - - -';
		}

	}

	convertLoalTime(date_future) {

		var date_future = new Date(date_future);

		var year = date_future.getFullYear();
		var month = (date_future.getMonth() + 1);
		var day = date_future.getDate()

		var addhours = date_future.getHours() + 5;
		var mints = date_future.getMinutes();
		var secs = date_future.getSeconds();

		if (addhours == '13') {

			addhours = '01';
		}
		else if (addhours == '14') {

			addhours = '02';

		}
		else if (addhours == '15') {

			addhours = '03';

		}
		else if (addhours == '16') {

			addhours = '04';

		}
		else if (addhours == '17') {

			addhours = '05';

		}

		return year + '-' + month + '-' + day + ' ' + addhours + ':' + mints + ':' + secs

	}

	

	getTimeFromFloatTimer() {

		var decimalTimeString = this.state.tic_sla_timer;
		var decimalTime = parseFloat(decimalTimeString);
		decimalTime = decimalTime * 60 * 60;
		var hours = Math.floor((decimalTime / (60 * 60)));
		decimalTime = decimalTime - (hours * 60 * 60);
		var minutes = Math.floor((decimalTime / 60));
		decimalTime = decimalTime - (minutes * 60);
		var seconds = Math.round(decimalTime);
		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		//var result = "" + hours + ":" + minutes;


		var tothours = hours * 3600;

		var totmints = minutes * 60;

		var count = tothours + totmints;
		//var counter = setInterval(1000); //1000 will  run it every 1 second

		this.setState({ tic_sla_timer_count: count });

		this.state.tic_sla_timer_count = count;


	}


	getSLAOnRun() {

		var ccount = this.state.tic_sla_timer_count - 1;

		this.state.tic_sla_timer_count = ccount;
		this.setState({ tic_sla_timer_count: ccount });

		var tseconds = ccount % 60;
		var tminutes = Math.floor(ccount / 60);
		var thours = Math.floor(tminutes / 60);
		tminutes %= 60;
		thours %= 60;

		if (thours < 10) {
			thours = "0" + thours;
		}
		if (tminutes < 10) {
			tminutes = "0" + tminutes;
		}
		if (tseconds < 10) {
			tseconds = "0" + tseconds;
		}

		if (ccount != -1) {

			var result = "" + thours + ":" + tminutes + ":" + tseconds;
			this.state.tic_sla_timer_run = result;
			this.setState({ tic_sla_timer_run: result });

		}

	}

	getSLATimer(ghours, gminutes) {

		var tothours = ghours * 3600;

		var totmints = gminutes * 60;

		var count = tothours + totmints;
		console.log(count);
		var counter = setInterval(1000); //1000 will  run it every 1 second

		count = count - 1;
		if (count == -1) {
			clearInterval();
			return;
		}

		var seconds = count % 60;
		var minutes = Math.floor(count / 60);
		var hours = Math.floor(minutes / 60);
		minutes %= 60;
		hours %= 60;

		//document.getElementById("timer").innerHTML = hours + "hours " + minutes + "minutes and" + seconds + " seconds left on this Sale!"; // watch for 
		//spelling



		var result = "" + hours + ":" + minutes + ":" + seconds;

		console.log(result);

		return result;

	}

	timer(count) {
		count = count - 1;
		if (count == -1) {
			clearInterval();
			return;
		}

		var seconds = count % 60;
		var minutes = Math.floor(count / 60);
		var hours = Math.floor(minutes / 60);
		minutes %= 60;
		hours %= 60;

		//document.getElementById("timer").innerHTML = hours + "hours " + minutes + "minutes and" + seconds + " seconds left on this Sale!"; // watch for 
		//spelling

		var result = "" + hours + ":" + minutes + ":" + seconds;

		return result;
	}



	getDateTimingsText(date, date1) {


		if (date != '') {

			if (date >= date1) {

				return <span className="text-success" style={{ verticalAlign: 'middle' }}>ON-TIME</span>;
			}
			else {
				return <span className="text-danger" style={{ verticalAlign: 'middle' }}>LATE BY</span>;

			}

		}
		else {

			return <span className="text-warning" style={{ verticalAlign: 'middle' }}>ELAPSED BY</span>;

		}


	}

	getDateTimingsStatus(date, date1) {

		var status = '';
		if (date != '') {

			if (date >= date1) {

				status = 'ontime';
			}
			else {
				status = 'late';

			}

		}
		else {

			status = 'elapse';

		}

		return status;
	}

	getDateTimingsColor(date, date1) {

		var color = '';

		if (date != '') {

			if (date >= date1) {

				color = 'text-success';
			}
			else {
				color = 'text-danger';

			}

		}
		else {

			color = 'text-warning';

		}

		return color;


	}

	getdateoftimings(date, date1, type) {


		var cdate = this.convertLoalTime(date);



		var date_future = new Date(cdate);



		var date_now = new Date();

		if (date1 && date != '') {

			date_now = this.convertLoalTime(date1);

		}

		var delta = '';

		if (date_future >= date_now) {
			// get total seconds between the times
			delta = Math.abs(date_future - date_now) / 1000;
		}
		else {

			delta = Math.abs(date_now - date_future) / 1000;
		}

		// calculate (and subtract) whole days
		var days = Math.floor(delta / 86400);
		delta -= days * 86400;

		// calculate (and subtract) whole hours
		var hours = Math.floor(delta / 3600) % 24;
		delta -= hours * 3600;

		// calculate (and subtract) whole minutes
		var minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;

		/* if(days!=0 || days>2){
			
			var isplural = 'Days';
			
			if(days==1){
				
				isplural = 'Day';
			}
			
			
			return days+'  '+hours+'  '+minutes;
		}
		else if(days==0 && hours!=0){
			
			return hours+'   '+minutes;
			
		}
		else{
			
			return minutes;
			
		}  */

		if (type == 'day') {

			return days;
		}
		else if (type == 'hour') {

			return hours;

		}
		else if (type == 'minute') {

			return minutes;

		}

	}



	getnovalues(str) {

		if (str != '' && str != null) {
			return str;
		}
		else {

			return 'Not Assigned';
		}

	}

	onTabChange(tab) {

		this.setState({ activeTab: tab });


	}

	

	


	onEmployeeDataChange(id) {

		this.setState({ id: id });
		this.getdatabyid(id);
		this.setState({ activeTab: '1' });
		this.setState({ audits: [], isAuditLogsLoaded: false, documents: [], isDocumentsLoaded: false })


	}

	getdatabyid(id) {
		this.setState({ dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getemployeesingledata/' + token + '/' + id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.setState({
						emp_name: res.name,
						emp_image: res.image,
						emp_gender : res.gender,
						emp_dob : res.birthdate,
						emp_marital : res.marital,
						emp_id : res.identification_id,
						emp_mail : res.work_email,
						emp_mobile : res.mobile_phone,
						emp_company : res.company_id[1],
						emp_job : res.job_title,
						emp_nation : res.country_id[1],
						emp_dep : res.department_id[1],
						emp_passno : res.passport_id,
						emp_accno : res.bank_account_id[1],
						emp_work_addt : res.address_id[1],
						emp_emernum : res.emergency_contact,
						emp_jobpos : res.job_id[1],
						emp_mgr : res.parent_id[1],

					});
					//this.getTimeFromFloatTimer();
				}
			}
		});
	}

	getdata() {
		this.setState({ dataLoading: true });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getemployeesingledata/' + token + '/' + this.state.id).then((result) => {

			let res = result['data'][0];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.setState({
						emp_name: res.name,
						emp_image: res.image,
						emp_gender : res.gender,
						emp_dob : res.birthdate,
						emp_marital : res.marital,
						emp_id : res.identification_id,
						emp_mail : res.work_email,
						emp_mobile : res.mobile_phone,
						emp_company : res.company_id[1],
						emp_job : res.job_title,
						emp_nation : res.country_id[1],
						emp_dep : res.department_id[1],
						emp_passno : res.passport_id,
						emp_accno : res.bank_account_id[1],
						emp_work_addt : res.address_id[1],
						emp_emernum : res.emergency_contact,
						emp_jobpos : res.job_id[1],
						emp_mgr : res.parent_id[1],
						
					});

				}
			}
		});
	}

	async getEmployeeList() {

		if (!this.state.isEmployeesIsLoaded) {
			this.setState({ employeelistloading: true });
			this.setState({ left_style: '' });
			this.setState({ sticky_searchbar: '' });
			this.setState({ topbar_buttons: '' });
			this.setState({ content_card: '' });
			this.setState({ isNameFilter: false });
			//this.setState({employeelist : []});
			var token = Sanitize.getStoreageItem('accessToken');
			var limit = '30';
			var offset = '0';
			
			  var cids = Sanitize.getStoreageItem('company_ids');
			
			 var domain = '["company_id","in",['+cids+']]'
		
		     var data = { "token": token, "limit": limit, 'offset': offset, 'domain': domain };

			await Reqst.axPost('getemployees',data).then((result) => {

				let res = result['data'];
				if (result['data'].code == '401') {

					this.getRefreshToken();

				}
				else if(result['data'].code == '500'){
				
				 this.setState({ employeelistloading: false });
				 alert(result['data'].data);
			    }
				else {
					this.setState({ employeelistloading: false });
					this.setState({ employeelist: res });
					this.setState({ left_style: 'left_search_card' });
					this.setState({ sticky_searchbar: 'sticky_searchbar' });
					this.setState({ topbar_buttons: 'topbar_buttons' });
					this.setState({ content_card: 'content_card' });
					this.setState({ isEmployeesIsLoaded: true });
				}
			});
		}
	}

	
	onTicketScroll() {

		const that = this;
		/*  $(window).scroll(() => { 
		 var container = $(".left_tree_card");
			 if(container.scrollTop+container.clientHeight == container.scrollHeight) {
			 	
				 this.getEmployeeListonScroll();
			 	
			 }
		  }); */


		$('#left_search_card_id').scroll(function () {

			if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
				if (!that.state.employeelistloading) {
					that.getEmployeeListonScroll();
				}

			}
		});
	}

	getUnique() {
		var uniqueArray = [];
		var orders = this.state.employeelist;
		// Loop through array values
		for (var i = 0; i < orders.length; i++) {
			if (uniqueArray.indexOf(orders[i]) === -1) {
				uniqueArray.push(orders[i]);
			}
		}
		return uniqueArray;
	}

	async getEmployeeListonScroll() {

		//if(!this.state.isEquipmentIsLoaded){
		//this.setState({isNameFilter:false});
		//this.setState({equiplist : []});
		var token = Sanitize.getStoreageItem('accessToken');
		var limit = '10';
		var offset = this.state.orderoffset + 10;
		this.setState({ orderoffset: this.state.orderoffset + 10 });
		/* if(offset=='80'){
			offset = '0';
		} */

		var isnamefilter = this.state.isNameFilter;

		console.log(isnamefilter);

		if (!isnamefilter) {
			
			  var cids = Sanitize.getStoreageItem('company_ids');
			
			 var domain = '["company_id","in",['+cids+']]';
		
		    var data = { "token": token, "limit": limit, 'offset': offset, 'domain': domain };

			this.setState({ employeelistloading: true });
			await Reqst.axPost('getemployees',data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ employeelistloading: false });
					var orderdata = this.state.employeelist.concat(res);
					this.setState({ employeelist: orderdata });
					//console.log(orderdata);
				}
			});
		}
		else {

			var query = this.state.search_query;
			
			var cids = Sanitize.getStoreageItem('company_ids');
			
			 var domain = '["company_id","in",['+cids+']],';
			 
			 query = domain+query;
			 
			this.setState({ employeefilterscrollLoad: true });
			var data = { "token": token, "limit": limit, "offset": offset, "query": query };
			await Reqst.axPost('getemployeefiterdata', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ employeefilterscrollLoad: false });
					var orderdata = this.state.employee_filter_data.concat(res);
					this.setState({ employee_filter_data: orderdata });
				}
			});

		}
		//}
	}


	resetFilter() {

		this.setState({ isNameFilter: false });
		this.setState({ employee_search_name: '' });
		this.setState({ search_query: '' });
		this.setState({ is_search_enable: false });
		$("#search_text").val('');

	}

	handleCloseSavedTags(tag) {

		this.setState({ isNameFilter: false });
		this.setState({ employee_search_name: '', savedtags: [] });
		this.setState({ search_query: '' });
		this.setState({ is_search_enable: false });
		$("#search_text").val('');

	}

	getTimeFromFloat(decimalTimeString) {

		var isimagine = '';

		if (decimalTimeString < 0) {

			decimalTimeString = Math.abs(decimalTimeString);
			isimagine = '-';
		}
		var decimalTime = parseFloat(decimalTimeString);
		decimalTime = decimalTime * 60 * 60;
		var hours = Math.floor((decimalTime / (60 * 60)));
		decimalTime = decimalTime - (hours * 60 * 60);
		var minutes = Math.floor((decimalTime / 60));
		decimalTime = decimalTime - (minutes * 60);
		var seconds = Math.round(decimalTime);
		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		var result = isimagine + "" + hours + ":" + minutes;

		return result;


	}


	getstatelabel(staten) {


		if (staten == 'Open') {

			return <span className="badge badge-danger custom_view_head_badge">Open</span>;

		}
		else if (staten == 'In Progress') {

			return <span className="badge badge-info custom_view_head_badge">In Progress</span>;

		}
		else if (staten == 'Staff Closed') {

			return <span className="badge badge-success custom_view_head_badge">Staff Closed</span>;

		}

		else {

			return '';

		}

	}

	getstatename(staten) {

		var orgstate = '';
		if (staten == 'draft') {

			orgstate = 'DRAFT';

		}
		else if (staten == 'released') {

			orgstate = 'WAITING PARTS';

		}
		else if (staten == 'ready') {

			orgstate = 'READY TO MAINTENANCE';

		}
		else if (staten == 'assigned') {

			orgstate = 'ASSIGNED';

		}
		else if (staten == 'in_progress') {

			orgstate = 'IN PROGRESS';

		}
		else if (staten == 'pause') {

			orgstate = 'PAUSE';

		}
		else if (staten == 'done') {

			orgstate = 'DONE';

		}
		else if (staten == 'cancel') {

			orgstate = 'CANCELED';

		}

		return orgstate;

	}


	getstatenamelabel(staten) {


		if (staten == 'mn') {

			return 'Maintenance';

		}
		else if (staten == 'op') {

			return 'Operative';

		}
		else if (staten == 'wh') {

			return 'Warehouse';

		}
		else if (staten == 'br') {

			return 'Breakdown';

		}
		else if (staten == 'sc') {

			return 'Scrapped';

		}

	}

	defclassgen(index) {

		var defclass = 'custom_col_left';

		var cval = 1;

		cval = cval + index;


		this.state.doccardcount = cval;

		var val = index % 2;

		if (index == 0) {

			defclass = 'custom_col_left';


		}
		else if (val == 1) {

			var val2 = cval % 4;

			defclass = 'custom_col_left custom_col_right';

			if (val2 == 0) {

				defclass = 'custom_col_right';
			}

		}
		else if (val == 0) {

			defclass = 'custom_col_left custom_col_right';

			var val1 = index % 4;

			if (val1 == 0) {

				defclass = 'custom_col_left';
			}

		}

		return defclass;


	}

	getproducttype(pr) {


		if (pr == 'consu') {

			return 'Consumable';

		}
		else if (pr == 'service') {

			return 'Service';

		}
		else if (pr == 'product') {

			return 'Storable Product';

		}
		else {

			return '';

		}

	}

	gettypename(tp) {


		if (tp == 'asset') {

			return 'Space';

		}
		else if (tp == 'general') {

			return 'General';

		}
		else if (tp == 'equipment') {

			return 'Equipment';

		}
		else {

			return '';

		}

	}

	getCritical(cr) {

		var orgcr = '';
		if (cr == '0') {

			return <span className="text-primary">General</span>;

		}
		else if (cr = '1') {

			return <span className="text-info">Important</span>;

		}
		else if (cr = '2') {

			return <span className="text-warning">Very Important</span>;

		}
		else if (cr = '3') {

			return <span className="text-danger">Critical</span>;

		}


	}

	getprioritynamebyname(pr) {

		if (pr == 'Low') {

			return <div><span className="badge badge-dot badge-dot-sm badge-info">&nbsp;</span><span>&nbsp;Low</span></div>;

		}
		else if (pr == 'Normal') {

			return <div><span className="badge badge-dot badge-dot-sm badge-warning">&nbsp;</span><span>&nbsp;Normal</span></div>;

		}
		else if (pr == 'High') {

			return <div><span className="badge badge-dot badge-dot-sm badge-orange">&nbsp;</span><span>&nbsp;High</span></div>;

		}
		else if (pr == 'Breakdown') {

			return <div><span className="badge badge-dot badge-dot-sm badge-danger">&nbsp;</span><span>&nbsp;Breakdown</span></div>;

		}



	}




	getRefreshToken() {
	
		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		console.log(reftoken);
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.getdata();
				this.getEmployeeList();
				this.getFilters();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}

	getmtname(mt) {

		var orgstate = '';
		if (mt == 'bm') {

			orgstate = 'Corrective';

		}
		else if (mt == 'pm') {

			orgstate = 'Preventive';

		}
		else if (mt == 'oc') {

			orgstate = 'On Condition';

		}
		else if (mt == 'pr') {

			orgstate = 'Periodic';

		}

		return orgstate;

	}

	directtoanother() {

		this.setState({ isDirect: true });
		console.log(this.props.location.state);

	}


	filterdata() {

		var dat = [];
		for (var i = 0; i < this.state.employeelist.length; i++) {


			if (this.state.employee_search_name != '') {
				if (this.state.employeelist[i].name.toLowerCase().search(this.state.employee_search_name.toLowerCase()) != -1) {

					dat.push(this.state.employeelist[i]);
				}
			}


		}

		return dat;

	}

	employeefiltersclick() {

		this.setState({ is_search_enable: true });

	}

	employeesavedfilters(query) {

		try {

			this.setState({ isNameFilter: true });
			this.setState({ employeeistFilterLoading: true });
			this.setState({ employee_filter_data: [] });
			this.setState({ search_query: '' });
			var token = Sanitize.getStoreageItem('accessToken');
			//var searchval = this.state.employee_search_name;
			//console.log(searchval);
			//var query = '["name", "ilike","'+searchval+'" ]';
			this.setState({ search_query: query });
			var rpp = '30';
			var offset = '0';
			var datasearch = [];

			/* datasearch = this.filterdata();
			
			if(datasearch.length>0){
				
				this.setState({employee_filter_data : datasearch});
				this.setState({employeeistFilterLoading:false});
					
			}
			else{ */
			var data = { "token": token, "limit": rpp, "offset": offset, "query": query };
			Reqst.axPost('getemployeefiterdata', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ employeeistFilterLoading: false });
					this.setState({ employee_filter_data: res });
				}
			});
			//}
		}
		catch (error) {
			console.log(error);
		}

	}

	employeefilters(searchval) {

		try {

			this.setState({ isNameFilter: true });
			this.setState({ employeeistFilterLoading: true });
			this.setState({ employee_filter_data: [] });
			this.setState({ search_query: '' });
			var token = Sanitize.getStoreageItem('accessToken');
			//var searchval = this.state.employee_search_name;
			console.log(searchval);
			var query = '["name", "ilike","' + searchval + '" ]';
			this.setState({ search_query: '["name", "ilike","' + searchval + '" ]' });
			var rpp = '30';
			var offset = '0';
			var datasearch = [];

			/* datasearch = this.filterdata();
			
			if(datasearch.length>0){
				
				this.setState({employee_filter_data : datasearch});
				this.setState({employeeistFilterLoading:false});
					
			}
			else{ */
			var data = { "token": token, "limit": rpp, "offset": offset, "query": query };
			Reqst.axPost('getemployeefiterdata', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ employeeistFilterLoading: false });
					this.setState({ employee_filter_data: res });
				}
			});
			//}
		}
		catch (error) {
			console.log(error);
		}

	}

	async onSendMessage() {
		this.setState({ sendMessage: true });
		var token = Sanitize.getStoreageItem('accessToken');
		var msg = $("#msg_body").val();
		var formdata = { "model": "website.support.ticket", "message_type": "comment", "res_id": parseInt(this.state.id), "body": msg };
		var data = { "token": token, "values": formdata };
		await Reqst.axPost('sendmessage', data).then((result) => {

			let res = result['data'];


			if (res) {

				this.setState({ sendMessage: false });
				$("#msg_body").val('');
				swal("Success", "Message Send Successfully..", "success");
				this.getdata();
			}
			else {

				this.setState({ sendMessage: false });
				swal("Error", "Unable to Send..", "error");

			}
		});
	}

	onEmployeeChange(e) {

		this.setState({ employee_search_name: e.target.value });

		if (e.key === 'Enter' && e.target.value.length > 2) {
			this.employeefilters(e.target.value);
		}

		if (e.target.value.length > 2) {
			this.employeefilters(e.target.value);
		}

	}

	newpercalculate(total, given) {
		var total = parseInt(total);
		var given = parseInt(given);

		var perc = "";
		if (isNaN(total) || isNaN(given)) {
			perc = 0;
		} else {

			if (total == 0) {
				perc = 0;
			}
			else {
				perc = ((given / total) * 100);
			}
		}

		perc = Math.floor(perc);



		return perc;
	}

	upperCase(str) {
		if (str == '' || str == null || str == ' ')
			return '';
		else
			return str.toString().toUpperCase();
	}





	render() {


		const genders = [
			{
				value: 'male',
				label: 'Male'
			},
			{
				value: 'female',
				label: 'Female'
			},
			{
				value: 'other',
				label: 'Other'
			}
		];
		
		const maritals = [
			{
				value: 'single',
				label: 'Single'
			},
			{
				value: 'married',
				label: 'Married'
			},
			{
				value: 'cohabitant',
				label: 'Legal Cohabitant'
			},
			{
				value: 'widower',
				label: 'Widower'
			},
			{
				value: 'divorced',
				label: 'Divorced'
			}
		];



		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Employee | {this.state.emp_name} </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>


					{!this.state.isEmployeesIsLoaded ?

						<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
						:

						<div className="main_data">
							<div className={this.state.sticky_searchbar}>

								<FormGroup className={this.state.topbar_buttons}>


									{this.state.is_search_enable ?
										<Input id="search_text" className="mat_form" name="employee_search_name" placeholder="Employee Name" autofocus="true" ref={(input) => { this.employee_search_name = input; }} onKeyDown={this.onEmployeeChange.bind(this)} />
										:
										<Button type="text" size="large" onClick={this.employeefiltersclick}>
											<i className="pe-7s-search" style={{ fontSize: '20px' }} />
										</Button>

									}
									{!this.state.is_search_enable && !this.state.isNameFilter ?
										<span style={{ marginLeft: 'auto' }}>
											<Button type="text" onClick={() => this.setState({ searchDialog: true })}>
												<i className="lnr-funnel font-size-lg" />
											</Button>
										</span>
										: ''}

									{this.state.savedtags.length == 1 && !this.state.is_search_enable && this.state.isNameFilter ?
										<div style={{ marginLeft: 'auto', marginTop: '0px', marginRight: 'auto', textAlign: 'center' }}>
											{

												this.state.savedtags.map(tag => {
													return (
														<Tag
															key={tag.key}
															closable={true}
															type="primary"
															closeTransition={false}
															onClose={this.handleCloseSavedTags.bind(this, tag)}>{tag.name}</Tag>
													)
												})
											}
										</div>
										: ''}

									{this.state.is_search_enable ?
										<span style={{ marginLeft: 'auto' }}>
											<Tooltip className="item" content='Reset' placement="top-start" >
												<Button type="text" size="large" onClick={this.resetFilter}>
													<i className="pe-7s-close" style={{ fontSize: '20px' }} />
												</Button>
											</Tooltip>
										</span>
										: ''}
								</FormGroup>

							</div>


							<Modal isOpen={this.state.searchDialog} slide="right" size="sm" className="right1" backdrop={true}>
								<ModalHeader>
									Filters
												  <span className="modal_pull_right1"><button className="close" onClick={() => this.setState({ searchDialog: false, searchcolumn: '', searchfor: '', searchvalue: '' })}>&times;</button></span>
								</ModalHeader>

								<ModalBody className="right1_body">

									<div>


										{this.state.filtersLoading ?

											<div><center><Loader type="ball-clip-rotate" /></center></div>

											:
											this.state.filters.map((item, index) =>

												<div>
													<div style={{ padding: '4px 1px', display: 'flex', alignItems: 'center' }}>
														<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onFilterApply(item.id, item.domain, item.display_name)}>
															<div className="list_center_equal">
																<Tooltip className="item" content={item.display_name} placement="top-center" >
																	<span className="text-info"><i className="pe-7s-filter font-size-sm" style={{ fontWeight: 'bold', fontSize: '15px' }}> </i></span>&nbsp;<span style={{ fontSize: '15px' }} >{item.display_name}</span>
																</Tooltip>
															</div>
														</a>

													</div>

													<hr style={{ marginBottom: '2px', marginTop: '2px', borderTop: '1px solid #d1dbe5', width: '268px', marginLeft: '-15px' }} />
												</div>

											)
										}
									</div>

								</ModalBody>

							</Modal>

							<div className={this.state.left_style} id="left_search_card_id">

								{this.state.employeeistFilterLoading ?

									<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>

									:
									<span>
										{this.state.isNameFilter ?
											<div>
												<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
												{this.state.employee_filter_data.length > 0 ?
													<span>
														{this.state.employee_filter_data.map((item, index) =>
															<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onEmployeeDataChange(item.id)}>
																<div style={{ padding: '1px 5px' }} >
																	<Tooltip className="item" content={item.name} placement="top-center" >
																		<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>{item.name}</b></span>
																	</Tooltip>
																	<div className="nowrap_content">
																		<span className="text-capitalize" style={{ fontSize: '11px' }}>{item.company_id[1]}&nbsp;</span>
																		<span className="text-capitalize" style={{ fontSize: '11px', whiteSpace: 'nowrap' }}>|&nbsp;{this.getnovalues(item.work_email)}</span>
																	</div>

																</div>
																<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
															</a>
														)}
														{this.state.employeefilterscrollLoad ?

															<div><center><Loader type="ball-clip-rotate" /></center></div>
															: ''}
													</span>

													:

													<div className="text-center"><img src={nodata} width="165" /> <br /><b>No Tickets Found..</b></div>

												}
											</div>


											:
											<span>
												<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
												{this.state.employeelist.map((item, index) =>
													<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onEmployeeDataChange(item.id)} >
														<div style={{ padding: '1px 5px' }}>
															      <Tooltip className="item" content={item.name} placement="top-center" >
																		<span><b style={{ fontSize: '15px', whiteSpace: 'nowrap' }}>{item.name}</b></span>
																	</Tooltip>
																	<div className="nowrap_content">
																		<span className="text-capitalize" style={{ fontSize: '11px' }}>{item.company_id[1]}&nbsp;</span>
																		<span className="text-capitalize" style={{ fontSize: '11px', whiteSpace: 'nowrap' }}>|&nbsp;{this.getnovalues(item.work_email)}</span>
																	</div>

														</div>
														<hr style={{ marginBottom: '2px', marginTop: '0px', borderTop: '1px solid #d1dbe5', marginLeft: '-9px', width: '240px' }} />
													</a>
												)}
												{this.state.employeelistloading && this.state.left_style != '' ?

													<div><center><Loader type="ball-clip-rotate" /></center></div>
													: ''}
											</span>


										}
									</span>

								}


							</div>


							<div className={this.state.content_card}>

								<div className="drawer_left_menu pull-left">


									<PageTitleAlt3
										heading=""
										subheading=""
										icon=""
										menuLevel1={this.props.location.state ? this.props.location.state.value : 'Employees'}
										menuLevel2={this.props.location.state ? 'Employees' : this.state.emp_name}
										menuLevel3={this.props.location.state ? this.state.emp_name : ''}
										menuLevel2link={this.props.location.state ? '#/hr/employees' : false}
										menuLevel1click={() => this.props.location.state ? this.directtoanother() : ''}
										menuLevel1link={this.props.location.state ? 'javascript:;' : '#/hr/employees'}
										isbreadcumb={true}
									/>
								</div>


								{this.state.dataLoading ?
									<div style={loaddiv}><center><Loader type="ball-clip-rotate" /></center></div>
									:
									<div>

										<Card className="main-card mb-2 custom_card_view">

											<CardBody>
												{!this.state.formmode ?
													<Row>
														<Col lg="2" sm="12" xs="12">

															<div className="text-center">
																{this.state.emp_image!='' ? <img src={'data:image/png;base64,' + this.state.emp_image} width="80" /> : <img src={userimg} width="80"  />}
																<br />
																<span className="custom_view_head">{this.state.emp_name}</span>
															</div>

														</Col>
														<Col lg="3" sm="12" xs="12">
														
															<Tooltip className="item" content="Gender" placement="left" >
																<span style={{textTransform:'capitalize'}}><span className="text-info"><i className={this.state.emp_gender == 'male' ? 'pe-7s-male font-size-sm' : 'pe-7s-female font-size-sm'} style={{ fontWeight: 'bold', fontSize: '18px' }}> </i></span>&nbsp;&nbsp;{this.state.emp_gender}</span>
															</Tooltip>
															
															<Tooltip className="item" content="DOB" placement="left" >
																<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="pe-7s-date font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.state.emp_dob)}</span>
															</Tooltip>
															
															<Tooltip className="item" content="Mobile" placement="left" >
																<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="pe-7s-phone font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.state.emp_mobile)}</span>
															</Tooltip>
															
															<Tooltip className="item" content="Company" placement="left" >
																<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="pe-7s-culture font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.state.emp_company}</span>
															</Tooltip>
														</Col>

														<Col lg="3" sm="12" xs="12" className="">

															<Tooltip className="item" content="Marital Status" placement="left" >
																<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="pe-7s-leaf font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.state.emp_marital}</span>
															</Tooltip>
															
															<Tooltip className="item" content="Nationality" placement="left" >
																<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="pe-7s-global font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.state.emp_nation)}</span>
															</Tooltip>
															
															<Tooltip className="item" content="Email" placement="left" >
																<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="pe-7s-mail font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.state.emp_mail)}</span>
															</Tooltip>
															
															<Tooltip className="item" content="Job Title" placement="left" >
																<span style={{textTransform:'capitalize'}}><span className="text-info"><i className="pe-7s-portfolio font-size-sm" style={{ fontWeight: 'bold', fontSize: '18px'}}> </i></span>&nbsp;&nbsp;{this.getnovalues(this.state.emp_job)}</span>
															</Tooltip>

														</Col>
														
														<Col lg="4" sm="12" xs="12" className="mobile_form_buttons">
															
															<div className="btn-actions-pane-right text-capitalize actions-icon-btn pull-right">

																<Dropdown trigger="click" menu={(
																	<Dropdown.Menu>
																		<span className="el-select-group__title text-center">Manage</span>
																		<Dropdown.Item command="a" >
																			<div style={{ width: '90px' }} onClick={this.onFormModeChange}>
																				<Button type="text">
																					Edit
																   </Button>

																			</div>

																		</Dropdown.Item>

																	</Dropdown.Menu>
																)}>
																	<span className="el-dropdown-link">
																		<i className="el-icon-more" />
																	</span>
																</Dropdown>

															</div>
															
														</Col>
													</Row>
													:
													<Row>
														<Col lg="2" sm="12" xs="12">

														
														</Col>
														<Col lg="5" sm="12" xs="12">
															          <FormGroup>
																			<Label className="text-capitalize">Name</Label>
																			
																			  <Input type="text" className="mat_form" name="emp_name" value={this.state.emp_name} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			
																		</FormGroup>
																		
																		<FormGroup>
																			<Label className="text-capitalize">DOB</Label>
																			
																			  <Input type="date" className="mat_form" name="emp_dob" value={this.state.emp_dob} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			
																		</FormGroup>
																		
																	   <FormGroup>
																		    <Label className="text-capitalize">Mobile</Label>
																			
																			  <Input type="number" className="mat_form" name="emp_mobile" value={this.state.emp_mobile} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			
																		</FormGroup>
																		
																		 <FormGroup>
																		    <Label className="text-capitalize">Nationality</Label>
																			
																			  <Input type="number" className="mat_form" name="emp_mobile" value={this.state.emp_mobile} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			
																		</FormGroup>
														
														</Col>
														<Col lg="5" sm="12" xs="12">
														
															          <FormGroup>
																			<Label className="text-capitalize">Gender</Label>
																			
																			 <Select onChange={this.setgender} value={this.state.emp_gender}>
																				{
																					genders.map(el => {
																						return <Select.Option key={el.value} label={el.label} value={el.value} />
																					})
																				}
																			</Select>
																			
																		</FormGroup>
																		
																		 <FormGroup>
																			<Label className="text-capitalize">Marital Status</Label>
																			
																			  <Select onChange={this.setMarital} value={this.state.emp_marital}>
																				{
																					maritals.map(el => {
																						return <Select.Option key={el.value} label={el.label} value={el.value} />
																					})
																				}
																			</Select>
																			
																		</FormGroup>
																		
																		<FormGroup>
																		    <Label className="text-capitalize">Email</Label>
																			
																			  <Input type="email" className="mat_form" name="emp_mail" value={this.state.emp_mail} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			
																		</FormGroup>
																		
																		<FormGroup>
																		    <Label className="text-capitalize">Job Title</Label>
																			
																			  <Input type="text" className="mat_form" name="emp_job" value={this.state.emp_job} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			
																		</FormGroup>
														
														</Col>


														<Col lg="12" sm="12" xs="12" className="">
															
															<span className="pull-right">

																
																	<Button type="primary" disabled={this.state.updateLoading} onClick={this.updateData}>
																		{this.state.updateLoading ? 'Updating...' : 'Update'}
																	</Button>
																	&nbsp;
																			<Button type="danger" onClick={() => this.setState({ formmode: false })} >
																		    Cancel
																			</Button>
																
															</span>
															
														</Col>



													</Row>

												}


											</CardBody>
										</Card>

										<Card className="main-card mb-2 custom_card_view1">
											<CardBody className="custom_card_body1">

												<Nav>
													<NavLink className="tab_nav_link" active={this.state.activeTab == '1' ? true : false} href="javascript:void(0);" onClick={() => this.onTabChange('1')} >Summary</NavLink>
												
												</Nav>


											</CardBody>
										</Card>
										
											<div>
												
											{this.state.activeTab == '1' ?
												<Row>
													<Col md="12" lg="6" className="custom_col_left" >
														<Card className="main-card mb-2" style={{ height: '97.5%' }}>
															<CardBody>
															<div className="card-title text-uppercase">
																	Work Information
									 							</div>
																<Row>
																	<Col lg="6" sm="12" xs="12">
																	
																	   <FormGroup>
																			<Label className="text-capitalize">Department</Label>
																			{!this.state.formmode ?
																			
																				<p><b>{this.getnovalues(this.state.emp_dep)}</b></p>
																				
																				:
																				
																				<Input type="text" className="mat_form" name="emp_name" value={this.state.emp_name} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																				
																		</FormGroup>
																		
																		<FormGroup>
																			<Label className="text-capitalize">Job Position</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.emp_jobpos)}</b></p>
																				
																				:
																				
																				<Input type="text" className="mat_form" name="emp_name" value={this.state.emp_name} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																				
																		</FormGroup>
																		
																		
																	   
																	</Col>

																	<Col lg="6" sm="12" xs="12">
																	
																	  <FormGroup>
																			<Label className="text-capitalize">Manager</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.emp_mgr)}</b></p>
																				
																				:
																				
																				<Input type="text" className="mat_form" name="emp_name" value={this.state.emp_name} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																				
																		</FormGroup>
																		
																		<FormGroup>
																			<Label className="text-capitalize">Address</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.emp_work_addt)}</b></p>
																				
																				:
																				
																				<Input type="text" className="mat_form" name="emp_work_addt" value={this.state.emp_work_addt} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																				
																		</FormGroup>
																		
																		

																		
																	</Col>

																	

																</Row>

															</CardBody>
														</Card>
													</Col>
													<Col md="12" lg="6" className="custom_col_right">
														<Card className="main-card mb-2 " style={{ height: '97.5%' }}>
															<CardBody>
																<div className="card-title text-uppercase">
																	Private Information
									 							</div>
																<Row>
																	<Col lg="6" sm="12" xs="12">
																		<FormGroup>
																			<Label className="text-capitalize">Passport No</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.emp_passno)}</b></p>
																				
																				:
																				
																				<Input type="text" className="mat_form" name="emp_passno" value={this.state.emp_passno} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																				
																		</FormGroup>
																		
																		 <FormGroup>
																			<Label className="text-capitalize">Bank Account No</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.emp_accno)}</b></p>
																				
																				:
																				
																				<Input type="text" className="mat_form" name="emp_accno" value={this.state.emp_accno} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																				
																		</FormGroup>
																		
																	

																		
																	</Col>

																	<Col lg="6" sm="12" xs="12">
																	
																	  <FormGroup>
																			<Label className="text-capitalize">Identification No</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.emp_id)}</b></p>
																				
																				:
																				
																				<Input type="text" className="mat_form" name="emp_id" value={this.state.emp_id} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																				
																		</FormGroup>
																		
																		 <FormGroup>
																			<Label className="text-capitalize">Emergency Contact</Label>
																			{!this.state.formmode ?
																				<p><b>{this.getnovalues(this.state.emp_emernum)}</b></p>
																				
																				:
																				
																				<Input type="number" className="mat_form" name="emp_emernum" value={this.state.emp_emernum} onChange={this.onInputChange.bind(this)} bsSize="sm" />
																			}
																				
																		</FormGroup>
																		
																		

																	</Col>



																</Row>

															</CardBody>

														</Card>
													</Col>
												</Row>

												: ''}
										</div>

										
										
									</div>
								}
							</div>
						</div>
					}
				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
