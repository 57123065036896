import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import locale from '../../locale';
import * as Reqst from '../../tie/Reqst';
import * as GC from '../../tie/GlobalConstants';
import Loader from 'react-loaders';
import $ from 'jquery';
import swal from 'sweetalert';
import SweetAlert from 'sweetalert-react';
import QRCode from 'qrcode.react';
import { Redirect, Link } from 'react-router-dom';
import {
	Row, Col,
	Container,
	CardHeader,
	Card,
	CardBody,
	Progress,
	ButtonGroup,
	ListGroup, Label,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroupItem, CardFooter,
	CustomInput, InputGroupButtonDropdown, InputGroup, Input, InputGroupAddon, InputGroupText,
	Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
	UncontrolledButtonDropdown, FormGroup, UncontrolledDropdown
} from 'reactstrap';
import { Loading, Table, Tag, Select, Dialog, Button, Pagination, Tooltip } from 'element-react';

import 'element-theme-default';
import cx from 'classnames';

export default class Equipments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			equipdata: [],
			filterlist: [],
			activeSearch: false,
			totalcount: 0,
			idd: '',
			loadingTxt: 'No Data Found',
			todosPerPage: 50,
			dropdownOpen: false,
			searchArea: false,
			addLink : false,
			filtersDialog: false,
			columnsDialog: false,
			searchDialog: false,
			filter_modal: false,
			isFilterRemove: false,
			activePage: 1,
			offset: 0,
			searchvalue: '',
			searchcolumn: '',
			tab_state: true,
			tab_wrdate: true,
			tab_cat: true,
			selecteddata: [],
			filtersLoading: '',
			filters: [],
			columns: [],
			searchfor: '',
			filtername: '',
			tags: [],
			isupdate: false,
			is_filter_default: false,
			savedtags: [],
			isDirect: false,
			filterSave: false,
			currentPage: 1,
			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			dataLoading: false,
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			_isMounted: false,
		};

		this.offsetcount = this.offsetcount.bind(this);
		this.onTabChange = this.onTabChange.bind(this);
		this.offsetcountfilter = this.offsetcountfilter.bind(this);
		this.setrpp = this.setrpp.bind(this);
		this.onFilterCreate = this.onFilterCreate.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.setfiltername = this.setfiltername.bind(this);
		this.offsetcountfiltersaved = this.offsetcountfiltersaved.bind(this);
		this.setfilterrppfiltersaved = this.setfilterrppfiltersaved.bind(this);
		this.printDocument = this.printDocument.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.setisdeffilter = this.setisdeffilter.bind(this);
		this.setcolumn = this.setcolumn.bind(this);
		this.setsearchvalue = this.setsearchvalue.bind(this);
		this.setsearchfor = this.setsearchfor.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.toggle = this.toggle.bind(this);
		this.showSearchArea = this.showSearchArea.bind(this);
		this.setfilterrpp = this.setfilterrpp.bind(this);
		this.onAddFields = this.onAddFields.bind(this);
		this.handleClearFilters = this.handleClearFilters.bind(this);
		this.onRemoveFilter = this.onRemoveFilter.bind(this);
		this.onSelectData = this.onSelectData.bind(this);
	}

	onSelectData(data) {
		this.setState({
			selecteddata: data
		});
	}

	printDocument() {

		//$(".print-report").hide();
		var content = document.getElementById("print_report");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	setfiltername(e) {

		this.setState({ filtername: e.target.value });
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}

	showSearchArea() {

		this.setState({
			searchArea: !this.state.searchArea
		});

	}

	onRemoveFilter() {
		this.setState({ filtersLoading: true, isFilterRemove: false });
		var token = Sanitize.getStoreageItem('accessToken');
		var id = this.state.filter_id;
		Reqst.axGet('removefilter/' + token + '/' + id).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ filtersLoading: false });
				this.getFilters();
				swal("Success", "Filter Removed Successfully..", "success");

			}
		});
	}

	setisdeffilter(e) {

		if (e.target.checked) {

			this.setState({ is_filter_default: true });
		}
		else {

			this.setState({ is_filter_default: false });
		}
	}


	handlePageChange(pageNumber) {

		this.setState({ activePage: pageNumber });
		this.state.currentPage = Number(pageNumber);
	}

	checkColumnExists(column) {

		var bstate = false;
		var needle = column;
		for (var i = 0; i < this.state.tags.length; i++) {
			// look for the entry with a matching `code` value
			if (this.state.tags[i].column == needle) {
				// we found it
				// obj[i].name is the matched result
				bstate = true;
				break;
			}
			else {
				bstate = false;
			}
		}
		return bstate;
	}

	onInitialFilter() {

		var orgdata = [];

		var tags = this.state.tags;

		var column = this.props.location.state.modelkeyid;
		var value = this.props.location.state.uid;

		var cond = '=';

		if (column != '' && cond != '') {


			var custval = value;

			var custfname = this.custfiltname(cond);

			if (column == 'state') {

				cond = '=';

				custfname = 'equal to';
			}

			if (cond == '!=false') {

				cond = '!=';
				value = 'false';
				custval = 'false';
				custfname = 'is set';
			}
			else if (cond == '=false') {

				cond = '=';
				value = 'false';
				custval = 'false';
				custfname = 'is not set';

			}

			var len = tags.length + 1;

			var cuscolname = this.custcolumn(column);

			var query = '["' + column + '", "' + cond + '",' + custval + ' ]';

			this.state.tags.push({ "key": len, "name": cuscolname + ' ' + custfname + ' ' + '"' + value + '"', "column": column, "value": value, "query": query });

			orgdata = this.state.tags;

			console.log(orgdata);

			this.setState({ tags: orgdata });

			this.setState({ searchcolumn: '', searchfor: '', searchvalue: '' });

			this.getfilterdata();
			this.getdatafiltercount();

		}



	}
	onTabChange(tab, query, name) {

		this.setState({ activeTab: tab });
		query = query.substr(1).slice(0, -1);
		this.getfilterdatabyquery(query);
		this.getdatafiltercountbyquery(query);

		var orgdata = [{ "key": tab, "name": name }];

		this.setState({ tags: [] });


		this.setState({ savedtags: orgdata });

		console.log(this.state.savedtags);

	}

	offsetcountfiltersaved(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		var total = this.state.totalcount;
		this.setState({ activePage: page });

		this.setState({ offset: offset });

		this.getfilterdatabyquery_offset(offset);

	}

	getfilterdatabyquery_offset(offset) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var querydata = this.state.savedtags;

		var query = querydata[0].query;

		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;

		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		var data = { "token": token, "limit": this.state.todosPerPage, "offset": offset, "query": query };
		Reqst.axPost('getequipmentfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}

	getfilterdatabyquery(query) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');


		var data = { "token": token, "limit": this.state.todosPerPage, "offset": this.state.offset, "query": query };
		Reqst.axPost('getequipmentfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}

	getdatafiltercountbyquery(query) {
		this.setState({ dataLoading: true });
		this.setState({ totalcount: 0 });
		var token = Sanitize.getStoreageItem('accessToken');

		var data = { "token": token, "query": query };
		Reqst.axPost('getequipmentfiltercount', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ totalcount: res.total[0].__count });
			}
		});
	}

	onFilterCreate() {
		this.setState({ filterSave: true });
		var token = Sanitize.getStoreageItem('accessToken');

		var query = [];

		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = '[' + iscombine + qdata + ']';
		console.log(qdata);
		if (qdata == '') {
			qdata = 'no'
		}
		var data = { "token": token, "name": this.state.filtername, "modelid": "mro.equipment", "isdefault": this.state.is_filter_default, "qdata": qdata };
		Reqst.axPost('filtercreate', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ filterSave: false });
				this.setState({ filtername: '', is_filter_default: false, filter_modal: false });
				this.setState({ filtersLoaded: false });
				this.getFilters();
			}
		});

	}

	onSearchChange() {

		var orgdata = [];

		var tags = this.state.tags;

		var column = this.state.searchcolumn;
		var value = this.state.searchvalue;

		var cond = this.state.searchfor;

		if (column != '' && (cond != '' || column == 'state')) {

			/* if(cond!='!=false' && cond!='=false' && value==''){
				
				swal("Value Missing","Type any Value","error");
				
			}
			else{ */

			var custval = '"' + value + '"';

			var custfname = this.custfiltname(cond);

			if (column == 'state') {

				cond = '=';

				custfname = 'equal to';
			}

			if (cond == '!=false') {

				cond = '!=';
				value = 'false';
				custval = 'false';
				custfname = 'is set';
			}
			else if (cond == '=false') {

				cond = '=';
				value = 'false';
				custval = 'false';
				custfname = 'is not set';

			}

			var len = tags.length + 1;

			var cuscolname = this.custcolumn(column);

			var query = '["' + column + '", "' + cond + '",' + custval + ' ]';

			this.state.tags.push({ "key": len, "name": cuscolname + ' ' + custfname + ' ' + '"' + value + '"', "column": column, "value": value, "query": query });

			orgdata = this.state.tags;

			console.log(orgdata);

			this.setState({ tags: orgdata });

			this.setState({ searchcolumn: '', searchfor: '', searchvalue: '' });

			this.getfilterdata();
			this.getdatafiltercount();

		}

		/* }
		else{
			
			swal("Column Missing","Select any Column or Type","error");
			
		} */


	}


	custcolumn(col) {

		var orgcol = '';


		if (col == 'equipment_seq') {

			orgcol = 'Number';
		}
		else if (col == 'name') {

			orgcol = 'Name';
		}
		else if (col == 'category_id') {

			orgcol = 'Category';
		}
		else if (col == 'location_id') {

			orgcol = 'Space';
		}
		else if (col == 'maintenance_team_id') {

			orgcol = 'Maintenance Team';
		}
		else if (col == 'vendor_id') {

			orgcol = 'Vendor';
		}
		else if (col == 'company_id') {

			orgcol = 'Company';
		}
		else if (col == 'state') {

			orgcol = 'Status';
		}

		return orgcol;

	}

	custfiltname(fname) {

		var orgfname = '';

		if (fname == 'ilike') {

			orgfname = 'contains';
		}
		else if (fname == 'not ilike') {

			orgfname = 'not contains';
		}
		else if (fname == '=') {

			orgfname = 'equal to';
		}
		else if (fname == '!=') {

			orgfname = 'not equal to';
		}

		return orgfname;

	}





	setcolumn(column) {
		this.setState({ searchfor: '' });
		this.setState({ searchvalue: '' });
		this.setState({ searchcolumn: column });
	}

	setsearchvalue(e) {

		this.setState({ searchvalue: e.target.value });
	}

	setsearchfor(e) {

		if (e.target.checked) {

			this.setState({ searchfor: e.target.value });
		}
		else {

			this.setState({ searchfor: '' });
		}
	}

	onAddFields(e) {

		if (e.target.checked) {

			this.setState({ [e.target.name]: true });
		}
		else {

			this.setState({ [e.target.name]: false });
		}
	}

	setselsearchvalue(value) {

		this.setState({ searchvalue: value });
	}



	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}



	/* componentDidMount(){
		this.state._isMounted = true;
	 if (this.state._isMounted) {
		this.getdata();
	 }
		
	} */

	/* componentWillMount() {
		//this.state._isMounted = false;
		this.getdata();
	} */

	componentDidMount() {
		//this.state._isMounted = false;

		if (this.props.location.state) {

			this.onInitialFilter();


		}
		else {
			this.getFilters();
		    /* this.offsetcount(this.state.currentPage);
	        this.getdatacount(); */
		}

		$("#print_report").hide();
	}



	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;
			return strTime;
		}
		else {

			return '';
		}

	}

	handleClose(tag) {
		const { tags } = this.state;

		tags.splice(tags.map(el => el.key).indexOf(tag.key), 1);

		this.setState({ tag });
		this.setState({ searchcolumn: '', searchfor: '', searchvalue: '' });
		this.getfilterdata();
		this.getdatafiltercount();
		$("#search_text").val('');
	}





	handleClearFilters() {
		this.setState({ tags: [] });
		this.setState({ searchcolumn: '', searchfor: '', searchvalue: '' });
		this.offsetcount(this.state.currentPage);
		this.getdatacount();
		$("#search_text").val('');
	}

	setrpp(rpp) {


		this.setState({ todosPerPage: rpp });
		this.offsetcount1(this.state.currentPage, rpp);

	}

	setfilterrpp(rpp) {


		this.setState({ todosPerPage: rpp });
		this.offsetcount2(this.state.currentPage, rpp);

	}

	setfilterrppfiltersaved(rpp) {


		this.setState({ todosPerPage: rpp });
		this.offsetcount3(this.state.currentPage, rpp);

	}

	offsetcount3(page, rpp) {

		var pc = parseInt(page) + 1;

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		this.setState({ offset: offset });

		this.getfilterdatabyquery_offsetrpp(offset, rpp);

	}

	getfilterdatabyquery_offsetrpp(offset, rpp) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var querydata = this.state.savedtags;

		var query = querydata[0].query;

		var total = this.state.totalcount;

		var limit = rpp;

		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		var data = { "token": token, "limit": rpp, "offset": offset, "query": query };
		Reqst.axPost('getequipmentfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}

	onUpdate(id) {

		this.state.idd = id;
		this.setState({ isUpdate: true });
	}

	offsetcount1(page, rpp) {

		var pc = parseInt(page) + 1;

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		this.setState({ offset: offset });

		this.getdatadyn1(offset, rpp);

	}

	offsetcount2(page, rpp) {

		var pc = parseInt(page) + 1;

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		this.setState({ offset: offset });

		this.getfilterdatapagination1(offset, rpp);

	}

	getdatadyn1(offset, rpp) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var total = this.state.totalcount;

		var limit = rpp;

		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}
		Reqst.axGet('getequipments/' + token + '/' + rpp + '/' + offset).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {

				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
				this.setState({ dataLoading: false });
			}
		});
	}

	offsetcount(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		var total = this.state.totalcount;
		this.setState({ activePage: page });

		this.setState({ offset: offset });

		this.getdatadyn(offset);

	}

	offsetcountfilter(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		var total = this.state.totalcount;
		this.setState({ activePage: page });

		this.setState({ offset: offset });

		this.getfilterdatapagination(offset);

	}


	getfilterdatapagination1(offset, rpp) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var query = [];

		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = iscombine + qdata;
		console.log(qdata);
		if (qdata == '') {
			qdata = 'no'
		}

		var total = this.state.totalcount;

		var limit = rpp;

		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		var data = { "token": token, "limit": rpp, "offset": offset, "query": qdata };
		Reqst.axPost('getequipmentfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}

	getfilterdatapagination(offset) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var query = [];

		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = iscombine + qdata;
		console.log(qdata);
		if (qdata == '') {
			qdata = 'no'
		}

		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;

		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		var data = { "token": token, "limit": this.state.todosPerPage, "offset": offset, "query": qdata };
		Reqst.axPost('getequipmentfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}



	getdatadyn(offset) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;

		var xoffset = '';


		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		

		Reqst.axGet('getequipments/' + token + '/' + limit + '/' + offset).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});
	}

	getdata() {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getequipments/' + token + '/' + this.state.todosPerPage + '/' + this.state.offset).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false, loadingTxt: '' });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});
	}

	checkIfArrayIsUnique() {
		var tags = this.state.tags;

		for (var i = 0; i < tags.length; i++) {
			for (var j = 0; j < tags.length; j++) {
				if (i != j) {
					if (tags[i]['column'] == tags[j]['column']) {
						return true;
						break;// means there are duplicate values
					}
				}
			}
		}
		return false; // means there are no duplicate values.
	}

	getfilterdata() {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var query = [];

		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = iscombine + qdata;
		console.log(qdata);
		if (qdata == '') {
			qdata = 'no'
		}
		var data = { "token": token, "limit": this.state.todosPerPage, "offset": this.state.offset, "query": qdata };
		Reqst.axPost('getequipmentfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}

	getdatafiltercount() {
		this.setState({ dataLoading: true });
		this.setState({ totalcount: 0 });
		var token = Sanitize.getStoreageItem('accessToken');
		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = iscombine + qdata;
		if (qdata == '') {
			qdata = 'no'
		}
		var data = { "token": token, "query": qdata };
		Reqst.axPost('getequipmentfiltercount', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ totalcount: res.total[0].__count });
			}
		});
	}

	getdatacount() {
		this.setState({ dataLoading: true });
		this.setState({ totalcount: 0 });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getequipmentscount?id=' + token).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ totalcount: res.total[0].__count });
			}
		});
	}

	/* getdydata(state,instance){
		this.setState({dataLoading:true,loadingTxt:'Loading...'});
		this.setState({equipdata : []});
		Reqst.axPost('getequipmentsdynamic',{
		  page: state.page,
		  pageSize: state.pageSize,
		  sorted: state.sorted,
		  filtered: state.filtered,
		  acctok : Sanitize.getStoreageItem('accessToken')
		  
		}).then((result) => {
			
			let res = result['data'];
			console.log(res);
			if(res.code=='401'){
				
				this.getRefreshToken();
				
			}
			else{
			    this.setState({dataLoading:false,loadingTxt:''});
				this.setState({equipdata : res.rows,pages: res.pages});
			}
		});
	} */

	getToggleColumns() {

		if (this.state.tab_vendor) {

			$(".tab_vendor").show();
		}
		else {

			$(".tab_vendor").hide();
		}
		if (this.state.tab_mainteam) {

			$(".tab_mainteam").show();
		}
		else {

			$(".tab_mainteam").hide();
		}
		if (this.state.tab_company) {

			$(".tab_company").show();
		}
		else {

			$(".tab_company").hide();
		}



	}

	handleCloseSavedTags(tag) {

		this.setState({ savedtags: [], activeTab: '' });
		this.offsetcount(this.state.currentPage);
		this.getdatacount();
	}

	getstatename(staten) {

		var orgstate = '';
		if (staten == 'mn') {

			orgstate = 'Maintenance';

		}
		else if (staten == 'op') {

			orgstate = 'Operative';

		}
		else if (staten == 'wh') {

			orgstate = 'Warehouse';

		}
		else if (staten == 'br') {

			orgstate = 'Breakdown';

		}
		else if (staten == 'sc') {

			orgstate = 'Scrapped';

		}

		return orgstate;

	}



	getRefreshToken() {

		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.offsetcount(this.state.currentPage);
				this.getdatacount();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}

	getFilters() {
		//this.setState({columnsDialog:false,searchDialog:true});
		//if(!this.state.filtersLoaded){
		this.setState({ filtersLoading: true });
		this.setState({ dataLoading: true, loadingTxt: 'Loading.....' });
		this.setState({ filters: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var data = { "token": token, "model": "mro.equipment" };
		Reqst.axPost('getfilters', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ filtersLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ filtersLoading: false });
				this.setState({ filtersLoaded: true });

				if (res.length > 0) {

					var data = [];

					for (var i = 0; i < res.length; i++) {

						if (res[i].is_default) {
							data.push(res[i]);
						}
					}

					console.log(data);

					if (data.length > 0) {

						this.onTabChange(data[0].id, data[0].domain, data[0].display_name);
					}
					else {

						this.offsetcount(this.state.currentPage);
						this.getdatacount();
						//this.setState({dataLoading:false,loadingTxt:''});

					}


				}
				else {

					this.offsetcount(this.state.currentPage);
					this.getdatacount();
					//this.setState({dataLoading:false,loadingTxt:''});

				}
				/* console.log(res.length);
				if(res.length>4){
					
					var data = [];
					for(var i=4;i<res.length;i++){
						
						data.push(res[i]);
					}
					
					this.setState({filters1:data});
					
					var firstdata = [];
					for(var i=0;i<4;i++){
						
						firstdata.push(res[i]);
					}
					
					this.setState({filters:firstdata});
				} */
				//else{
				this.setState({ filters: res });

				//}
			}
		});
		//}

	}

	directtoanother() {

		this.setState({ isDirect: true });

	}


	render() {

		if (this.state.isUpdate) {

			return (<Redirect to={'/assets/equipment/view/' + this.state.idd} />);

		}
		
		if (this.state.addLink) {

			return (<Redirect to={'/assets/equipment/add'} />);

		}
		
		

		if (this.state.isDirect && this.props.location.state) {


			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { uid: this.props.location.state.uid, treenodes: this.props.location.state.treenodes }
			}}
			/>);

		}





		const seloptions = [
			{
				value: '25',
				label: '25'
			},
			{
				value: '50',
				label: '50'
			},
			{
				value: '75',
				label: '75'
			},
			{
				value: '100',
				label: '100'
			}
		];

		const selstates = [
			{
				value: 'mn',
				label: 'Maintenance'
			},
			{
				value: 'op',
				label: 'Operative'
			},
			{
				value: 'wh',
				label: 'Warehouse'
			},
			{
				value: 'br',
				label: 'Breakdown'
			},
			{
				value: 'sc',
				label: 'Scrapped'
			}
		];


		const selcolumns = [
			{
				value: '',
				label: 'Select'
			},
			{
				value: 'equipment_seq',
				label: 'Number'
			},
			{
				value: 'name',
				label: 'Name'
			},
			{
				value: 'category_id',
				label: 'Category'
			},
			{
				value: 'location_id',
				label: 'Space'
			},
			{
				value: 'maintenance_team_id',
				label: 'Maintenance Team'
			},
			{
				value: 'vendor_id',
				label: 'Vendor'
			},
			{
				value: 'company_id',
				label: 'Company'
			},
			{
				value: 'state',
				label: 'State'
			}
		];



		var columns = [
			{
				type: 'selection',
				width: 20,
			},
			{
				label: locale.t('el.equipment.number'),
				prop: "equipment_seq",
				resizable: true,
				fixed: 'left',
				sortable: true,
				align: 'center',
				width: 120,
				render: (row, column, index) => {
					return <span><a href={"#/assets/equipment/view/" + row.id}>{row.equipment_seq}</a></span>;
				}
			},
			{
				label: locale.t('el.equipment.name'),
				prop: "name",
				fixed: 'left',
				sortable: true,
				resizable: true,
				minWidth: 170,
				render: (row, column, index) => {
					return <Tooltip className="item" content={row.name} placement="bottom" >
						<span data-tip={row.name} style={{ fontSize: '14px', fontWeight: 'bold', color: '#1f2d3d', textDecoration: 'none' }}><a style={{ color: '#1f2d3d', textDecoration: 'none' }} href={"#/assets/equipment/view/" + row.id}>{row.name}</a></span>
					</Tooltip>;
				}

			},



		];

		if (this.state.tab_cat) {
			columns.push({
				label: locale.t('el.equipment.category'),
				prop: "category_id.1",
				resizable: true,
				sortable: true,
				minWidth: 170,

			},
			);
		}

		if (this.state.tab_state) {
			columns.push({
				label: locale.t('el.equipment.state'),
				prop: "state",
				resizable: true,
				sortable: true,
				minWidth: 170,
				render: (row, column, index) => {
					return <span>{this.getstatename(row.state)}</span>;
				}
			},
			);
		}

		if (this.state.tab_wrdate) {
			columns.push({
				label: locale.t('el.equipment.warrantyDate'),
				prop: "warranty_end_date",
				sortable: true,
				resizable: true,
				className: 'tab_wardate',
				minWidth: 170,
				render: (row, column, index) => {
					return <span>{this.getdateofdt(row.warranty_end_date)}</span>;
				}
			},
			);
		}

		if (this.state.tab_space) {
			columns.push({
				label: "Space",
				prop: "location_id.1",
				sortable: true,
				resizable: true,
				minWidth: 170,

			},
			);
		}

		if (this.state.tab_company) {
			columns.push({
				label: "Company",
				prop: "company_id.1",
				sortable: true,
				minWidth: 170,
				className: 'tab_company',
				resizable: true,

			},
			);
		}

		if (this.state.tab_mainteam) {
			columns.push({
				label: "Maintenance Team",
				prop: "maintenance_team_id.1",
				minWidth: 170,
				sortable: true,
				className: 'tab_mainteam',
				resizable: true,

			},
			);
		}

		if (this.state.tab_vendor) {
			columns.push({
				label: "Vendor",
				prop: "vendor_id.1",
				className: 'tab_vendor',
				sortable: true,
				minWidth: 170,
				resizable: true,

			},
			);
		}




		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Equipments </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					<SweetAlert
						title="Are you sure you want to Remove this Filter?"
						confirmButtonColor=""
						show={this.state.isFilterRemove}
						text=""
						showCancelButton
						onConfirm={() => this.onRemoveFilter()}
						onCancel={() => this.setState({ isFilterRemove: false })} />

					<div className="topbar-header">
						<div className="topbar-header__content">
							<PageTitleAlt3
								heading=""
								subheading=""
								icon=""
								menuLevel1={this.props.location.state ? this.props.location.state.value : locale.t('el.equipment.title')}
								menuLevel2={!this.props.location.state ? '' : locale.t('el.equipment.title')}
								menuLevel3=""
								menuLevel1link={this.props.location.state ? 'javascript:;' : ''}
								isbreadcumb={true}
								menuLevel1click={() => this.props.location.state ? this.directtoanother() : ''}
							/>


							<div className="topbar_buttons">

								<div>

									<Pagination
										layout="prev,sizes,total,pager, next"
										total={this.state.totalcount}
										pageCount={3}
										currentPage={this.state.activePage}
										small={true}
										pageSizes={[25, 50, 75, 100]}
										pageSize={this.state.todosPerPage}
										onCurrentChange={this.state.tags.length > 0 ? this.offsetcountfilter : this.state.savedtags.length == 1 ? this.offsetcountfiltersaved : this.offsetcount}
										onSizeChange={this.state.tags.length > 0 ? this.setfilterrpp : this.state.savedtags.length == 1 ? this.setfilterrppfiltersaved : this.setrpp} />


								</div>
								&nbsp;
									{this.state.selecteddata.length > 0 ?

									<Button type="text" onClick={() => this.printDocument()}>
										<i className="lnr-printer  font-size-lg" />
									</Button>

									: ''}
								&nbsp;
								<Tooltip className="item" content="Add an Equipment" placement="bottom" >
								   <Button type="text" onClick={() => this.setState({ addLink: true })}>
										<i className="lnr-plus-circle  font-size-lg" />
									</Button>
								</Tooltip>
								&nbsp;&nbsp;&nbsp;
								<Tooltip className="item" content="Columns" placement="bottom" >
								   <Button type="text" onClick={() => this.setState({ columnsDialog: true, searchDialog: false })}>
										<i className="lnr-list  font-size-lg" />
									</Button>
								</Tooltip>
								&nbsp;&nbsp;
								<Tooltip className="item" content="Filters" placement="bottom" >
									<Button type="text" onClick={() => this.setState({ columnsDialog: false, searchDialog: true })}>
										<i className="lnr-funnel font-size-lg" />
									</Button>
								</Tooltip>

								<span className="d-inline-block mb-2 mr-2">
									<Modal isOpen={this.state.columnsDialog} slide="right" size="sm" className="right" backdrop={true}>
										<ModalHeader>
											Additional Fields
												  <span className="modal_pull_right"><button className="close" onClick={() => this.setState({ columnsDialog: false })}>&times;</button></span>
										</ModalHeader>

										<ModalBody>
											<FormGroup>
												<CustomInput type="checkbox" id="exampleCustomCheckbox7" name="tab_cat" label="Category" checked={this.state.tab_cat} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox6" name="tab_state" label="State" checked={this.state.tab_state} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox5" name="tab_wrdate" label="Warranty Date" checked={this.state.tab_wrdate} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox4" name="tab_space" label="Space" checked={this.state.tab_space} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox1" name="tab_company" label="Company" checked={this.state.tab_company} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox2" name="tab_vendor" label="Vendor" checked={this.state.tab_vendor} onClick={this.onAddFields.bind(this)} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox3" name="tab_mainteam" label="Maintenance Team" checked={this.state.tab_mainteam} onClick={this.onAddFields.bind(this)} />
												<hr />
											</FormGroup>
										</ModalBody>

									</Modal>
								</span>

								<span className="d-inline-block mb-2 mr-2">
									<Modal isOpen={this.state.filter_modal} size="sm" className="form_mod" backdrop={true}>
										<ModalHeader>
											Save Filter
												  <span className="modal_pull_right3"><button className="close" onClick={() => this.setState({ filter_modal: false })}>&times;</button></span>
										</ModalHeader>

										<ModalBody>
											<FormGroup>
												<Label className="text-capitalize">Name</Label>
												<Input type="text" className="mat_form" name="filtername" onChange={this.setfiltername.bind(this)} bsSize="sm" />
											</FormGroup>
											<FormGroup className="pull-right">
												<CustomInput type="checkbox" id="exampleCustomCheckbox65" name="customCheckbox" label="Is Default" value="" onClick={this.setisdeffilter.bind(this)} />
											</FormGroup>
											<br /><br />
											<FormGroup className="text-center">
												<Button type="primary" disabled={this.state.filterSave} onClick={this.onFilterCreate}>
													{this.state.filterSave ? 'Saving...' : 'Save'}
												</Button>
												&nbsp;
																		<Button type="danger" onClick={() => this.setState({ filter_modal: false, filtername: '', is_filter_default: false })} >
													Cancel
																		</Button>
											</FormGroup>
										</ModalBody>

									</Modal>
								</span>

								<span className="d-inline-block mb-2 mr-2">
									<Modal isOpen={this.state.searchDialog} slide="right" size="sm" className="right1" backdrop={true}>
										<ModalHeader>
											Filters
												  <span className="modal_pull_right1"><button className="close" onClick={() => this.setState({ searchDialog: false, searchcolumn: '', searchfor: '', searchvalue: '' })}>&times;</button></span>
										</ModalHeader>

										<ModalBody className="right1_body">
											<span className="text-info"><i className="pe-7s-filter font-size-sm" style={{ fontWeight: 'bold', fontSize: '16px' }}> </i></span>&nbsp;<span className="custom_view_head">Add Filter</span><br /><br />
											<FormGroup>
												<Select onChange={this.setcolumn} value={this.state.searchcolumn}>
													{
														selcolumns.map(el => {
															return <Select.Option key={el.value} label={el.label} value={el.value} />
														})
													}
												</Select>
											</FormGroup>

											<FormGroup>
												{this.state.searchcolumn == 'state' ?
													<Select onChange={this.setselsearchvalue.bind(this)}>
														{
															selstates.map(el => {
																return <Select.Option key={el.value} label={el.label} value={el.value} />
															})
														}
													</Select>
													: ''}
											</FormGroup>

											<FormGroup>
												{this.state.searchcolumn != 'state' && this.state.searchcolumn != '' ?
													<div style={{ padding: '10px 14px' }}>
														<CustomInput type="radio" id="exampleCustomRadio6" name="customRadio" checked={this.state.searchfor == 'ilike' ? true : ''} label="Contains" value="ilike" onClick={this.setsearchfor.bind(this)} />
														{this.state.searchcolumn != 'state' && this.state.searchfor == 'ilike' ?
															<Input id="search_text" className="mat_form" onChange={this.setsearchvalue.bind(this)} />
															: ''}
														<CustomInput type="radio" id="exampleCustomRadio" name="customRadio" checked={this.state.searchfor == 'not ilike' ? true : ''}
															label="Does not contain" value="not ilike" onClick={this.setsearchfor.bind(this)} />
														{this.state.searchcolumn != 'state' && this.state.searchfor == 'not ilike' ?
															<Input id="search_text" className="mat_form" onChange={this.setsearchvalue.bind(this)} />
															: ''}

														<CustomInput type="radio" id="exampleCustomRadio3" name="customRadio" checked={this.state.searchfor == '=' ? true : ''}
															label="Is Equal to" value="=" onClick={this.setsearchfor.bind(this)} />
														{this.state.searchcolumn != 'state' && this.state.searchfor == '=' ?
															<Input id="search_text" className="mat_form" onChange={this.setsearchvalue.bind(this)} />
															: ''}

														<CustomInput type="radio" id="exampleCustomRadio4" name="customRadio" checked={this.state.searchfor == '!=' ? true : ''}
															label="Is Not Equal to" value="!=" onClick={this.setsearchfor.bind(this)} />
														{this.state.searchcolumn != 'state' && this.state.searchfor == '!=' ?
															<Input id="search_text" className="mat_form" onChange={this.setsearchvalue.bind(this)} />
															: ''}
														<CustomInput type="radio" id="exampleCustomRadio5" name="customRadio" checked={this.state.searchfor == '!=false' ? true : ''}
															label="Is Set" value="!=false" onClick={this.setsearchfor.bind(this)} />

														<CustomInput type="radio" id="exampleCustomRadio7" name="customRadio" checked={this.state.searchfor == '=false' ? true : ''}
															label="Is not Set" value="=false" onClick={this.setsearchfor.bind(this)} />


													</div>
													: ''}
											</FormGroup>
											{this.state.searchcolumn != '' && (this.state.searchfor != '' || this.state.searchcolumn == 'state') ?
												this.state.searchvalue != '' || (this.state.searchfor == '!=false' || this.state.searchfor == '=false') ?
													<div className="text-center">
														<Button block onClick={this.onSearchChange} type="primary">
															<i className="pe-7s-search" />&nbsp;Search
															</Button>
													</div>
													: ''
												: ''}

											{this.state.searchcolumn == '' ?
												<div>
													<span className="text-info"><i className="pe-7s-star font-size-sm" style={{ fontWeight: 'bold', fontSize: '16px' }}> </i></span>&nbsp;<span className="custom_view_head">Saved Filters</span><br /><br />
													<hr style={{ marginBottom: '2px', marginTop: '2px', borderTop: '1px solid #d1dbe5', width: '268px', marginLeft: '-15px' }} />
													{this.state.filtersLoading ?

														<div><center><Loader type="ball-clip-rotate" /></center></div>

														:
														this.state.filters.map((item, index) =>
															<div>
																<div style={{ padding: '4px 1px', display: 'flex', alignItems: 'center' }}>
																	<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onTabChange(item.id, item.domain, item.display_name)}>
																		<div className="list_center_equal">
																			<Tooltip className="item" content={item.display_name} placement="top-center" >
																				<span className="text-info"><i className="pe-7s-filter font-size-sm" style={{ fontWeight: 'bold', fontSize: '15px' }}> </i></span>&nbsp;<span style={{ fontSize: '15px' }} >{item.display_name}</span>
																			</Tooltip>
																		</div>
																	</a>
																	<div style={{ marginLeft: 'auto' }}>
																		<span className="pull-right text-danger" onClick={() => this.setState({ isFilterRemove: true, filter_id: item.id })}><i className="pe-7s-trash font-size-sm" style={{ fontWeight: 'bold', fontSize: '15px' }}> </i></span>
																	</div>

																</div>

																<hr style={{ marginBottom: '2px', marginTop: '2px', borderTop: '1px solid #d1dbe5', width: '268px', marginLeft: '-15px' }} />
															</div>

														)
													}
												</div>
												: ''}

										</ModalBody>

									</Modal>
								</span>


							</div>
						</div>
					</div>


					<Card className="main-card mb-3 card_topbar">
						<CardBody>

							<Col md="12" lg="12">

								{this.state.savedtags.length == 1 ?
									<div style={{ marginBottom: '10px', marginTop: '0px' }}>
										{

											this.state.savedtags.map(tag => {
												return (
													<Tag
														key={tag.key}
														closable={true}
														type="primary"
														closeTransition={false}
														onClose={this.handleCloseSavedTags.bind(this, tag)}>{tag.name}</Tag>
												)
											})
										}
									</div>
									: ''}

								{this.state.tags.length > 0 ?
									<div style={{ marginBottom: '10px', marginTop: '0px' }}>
										{
											this.state.tags.map(tag => {
												return (
													<Tag
														key={tag.key}
														closable={true}
														type="default"
														closeTransition={false}
														onClose={this.handleClose.bind(this, tag)}>{tag.name}</Tag>
												)
											})
										}

										<span className="pull-right">

											<Button type="success" size="small" onClick={() => this.setState({ filter_modal: true })}>
												<i className="lnr-checkmark-circle  font-size-sm" />&nbsp;Save Filters
									</Button>
											&nbsp;
								     <Button type="danger" size="small" onClick={() => this.handleClearFilters()}>
												<i className="lnr-cross-circle  font-size-sm" />&nbsp;Clear Filters
									</Button>

										</span>
									</div>
									: ''}
							</Col>


							<Loading loading={this.state.dataLoading} />
							<Table
								style={{ width: '100%', border: '0px' }}
								columns={columns}
								fit={true}
								data={this.state.equipdata}
								border={false}
								emptyText={this.state.loadingTxt}
								height={700}
								maxHeight={900}
								onSelectChange={(selection) => { this.onSelectData(selection) }}
								onSelectAll={(selection) => { this.onSelectData(selection) }}
							/>

							<div id="print_report">
								{this.state.selecteddata.map((item, index) => {

									return (<React.Fragment>
										<table width="33%" style={{ float: 'left', pageBreakInside: 'avoid', pageBreakAfter: 'always' }}>
											<tbody>
												<tr>
													<td style={{ lineHeight: '13px' }} >
														<QRCode value={item.name} renderAs='svg' includeMargin={true} level='H' size='230' /><br />
														<span style={{ marginLeft: '64px' }}>{item.name}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</React.Fragment>);

								})}

							</div>
							<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>

						</CardBody>
					</Card>

				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
