import React, { Fragment, Component } from "react";
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst';
import * as GC from '../../tie/GlobalConstants';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Helmet } from 'react-helmet';
import { Redirect, Link } from 'react-router-dom';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import swal from 'sweetalert';
import SweetAlert from 'sweetalert-react';
import {
	Card, CardBody, CustomInput, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Table, Label, Input
} from 'reactstrap';
import Loader from 'react-loaders';
import $ from 'jquery';
import { Dialog, Button, Steps, Select, Cascader, DatePicker } from 'element-react';
import 'element-theme-default';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactFileReader from 'react-file-reader';
import locale from '../../locale';
const loaddiv = { marginTop: '150px', marginBottom: '150px', verticalAlign: 'middle' }

export default class AddTicket extends Component {

	constructor(props) {
		super(props);
		this.state = {
			cats: [],
			subcats: [],
			active: 0,
			contact_mode: 'web',
			sub_cat_id: '',
			equip_id: '',
			selsearchsubtext: '',
			companyLoading : false,
			companies : [],
			cmp_id : Sanitize.getStoreageItem('company_id'),
			catLoading: false,
			subcatLoading: false,
			tic_subject: '',
			equipLoading: false,
			fileType: '',
			tic_company: Sanitize.getStoreageItem('company_name'),
			tic_company_id: Sanitize.getStoreageItem('company_id'),
			tab_raised_by: 'self',
			tic_pername: Sanitize.getStoreageItem('company_name'),
			tic_per_contact: Sanitize.getStoreageItem('company_mobile') != 'false' ? Sanitize.getStoreageItem('company_mobile') : '',
			tic_permail: Sanitize.getStoreageItem('company_mail'),
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			report_date: new Date(),
			cat_id: '',
			selspaces: [],
			dataLoading: false,
			isConfirm: false,
			buildings: [],
			files: '',
			photoname: '',
			equipments: [],
			ticketSave: false,
			selsearchtext: '',
			isTicketLink: false,
			fileSize: '',
			issue_type: 'request',
			regions: [],
			floors: [],
			rooms: []
		}

		this.onInputChange = this.onInputChange.bind(this);
		this.onAddFields = this.onAddFields.bind(this);
		this.setcontactmode = this.setcontactmode.bind(this);
		this.handleSubCatChange = this.handleSubCatChange.bind(this);
		this.handlephotoRemove = this.handlephotoRemove.bind(this);
		this.handleEquipChange = this.handleEquipChange.bind(this);
		this.setissuetype = this.setissuetype.bind(this);
		this.handleCatChange = this.handleCatChange.bind(this);
		this.onSelectSpaces = this.onSelectSpaces.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onClose = this.onClose.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.onTicketCreate = this.onTicketCreate.bind(this);
		this.onCompanyChange = this.onCompanyChange.bind(this);
	}
	
	onCompanyChange(company) {

		this.setState({ cmp_id: company });
		this.state.cmp_id = company;
		this.getbuildings();

	}


	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}

	onCancel() {

		this.setState({ active: 0 });

		this.setState({
			selspaces: [],
			cat_id: '',
			tic_subject: '',
			tic_pername: Sanitize.getStoreageItem('company_name'),
			tic_per_contact: Sanitize.getStoreageItem('company_mobile') != 'false' ? Sanitize.getStoreageItem('company_mobile') : '',
			tic_permail: Sanitize.getStoreageItem('company_mail'),
			sub_cat_id: '',
			issue_type: 'request',
			files: '',
			selsearchtext: '',
			contact_mode: 'web',
			isConfirm: false,
			subcats: []
		});
	}
	onSelectSpaces(spaces) {
		console.log(spaces);
		this.setState({ selspaces: spaces });
	}

	handlephotoRemove() {

		this.setState({ files: '', photoname: '' });

	}


	handleFiles(files) {
		console.log(files);
		this.setState({ files: files.base64, photoname: files.fileList[0]['name'], fileSize: files.fileList[0]['size'], fileType: files.fileList[0]['type'] });
		this.bytesToSize(this.state.fileSize);
	}

	onInputChange(e) {

		this.setState({ [e.target.name]: e.target.value });
	}

	handleCatChange(column) {
		this.setState({ cat_id: column });
		this.getsubcategories(column);
	}

	handleSubCatChange(column) {
		this.setState({ sub_cat_id: column });
	}

	handleEquipChange(column) {
		this.setState({ equip_id: column });
	}

	async getFloorData(parentid) {

		var ftree = [];
		var floors = this.state.floors;
		var rtree = [];

		for (var i = 0; i < floors.length; i++) {
			rtree = await this.getRoomData(floors[i].id);
			
			if (rtree.length == 0) {
				rtree = false;
			}
			
			if (parentid == floors[i].parent_id[0] && floors[i].space_name != '') {
				ftree.push({ "label": floors[i].space_name, "value": floors[i].id, "type": "floor", "children": rtree });
			}

		}
		
		return ftree;

	}
	
	async getRoomData(parentid) {

		var ftree = [];
		var rooms = this.state.rooms;
		var rtree = [];

		for (var i = 0; i < rooms.length; i++) {
			rtree = await this.getSpaceData(rooms[i].id);
			if (rtree.length == 0) {
				rtree = false;
			}
			if (parentid == rooms[i].parent_id[0] && rooms[i].space_name != '') {
				ftree.push({ "label": rooms[i].space_name, "value": rooms[i].id, "type": "room", "children": rtree });
			}

		}
		return ftree;

	}

	async getSpaceData(parentid) {

		var ftree = [];
		var spaces = this.state.rooms;

		for (var i = 0; i < spaces.length; i++) {

			if (parentid == spaces[i].parent_id[0] && spaces[i].space_name != '') {
				await ftree.push({ "label": spaces[i].space_name, "value": spaces[i].id, "type": "space" });
			}

		}
		return ftree;

	}


	getDataName(id, type) {

		if (type == 'equip') {
			var index = this.state.equipments.findIndex(function (obj) {
				return (obj.id == id);
			});
			var d = this.state.equipments;

			var name = '';

			if (d[index]) {
				name = d[index].name;

			}

			return name;

		}
		else if (type == 'cat') {


			var index = this.state.cats.findIndex(function (obj) {
				return (obj.id == id);
			});
			var d = this.state.cats;

			var name = '';

			if (d[index]) {
				name = d[index].name;

			}

			return name;

		}
		else if (type == 'subcat') {

			var index = this.state.subcats.findIndex(function (obj) {
				return (obj.id == id);
			});
			var d = this.state.subcats;

			var name = '';

			if (d[index]) {
				name = d[index].name;

			}

			return name;


		}
		else if (type == 'space') {

			var assetid = this.state.selspaces[this.state.selspaces.length - 1];

			var index = this.state.buildings.findIndex(function (obj) {
				return (obj.id == assetid);
			});
			var d = this.state.buildings;

			var index1 = this.state.floors.findIndex(function (obj) {
				return (obj.id == assetid);
			});
			var d1 = this.state.floors;

			var index2 = this.state.rooms.findIndex(function (obj) {
				return (obj.id == assetid);
			});
			var d2 = this.state.rooms;

			var name = '';

			if (d[index]) {
				name = d[index].space_name;

			}
			else if (d1[index1]) {

				name = d1[index1].space_name;
			}
			else if (d2[index2]) {

				name = d2[index2].space_name;
			}


		
			return name;


		}

	}


	async getFloorsTree() {

		var fulltree = [];

		this.setState({ regions: [] });

		var ftree = [];

		var buildings = this.state.buildings;


		for (var i = 0; i < buildings.length; i++) {

			ftree = await this.getFloorData(buildings[i].id);
			
			if (ftree.length == 0) {
				ftree = false;
			}

			if (buildings[i].space_name != '') {

				fulltree.push({ "label": buildings[i].space_name, "value": buildings[i].id, "type": "building", "children": ftree });

			}

		}
		//this.state.regions = fulltree;
		this.setState({ regions: fulltree });
		console.log(this.state.regions);
	}



	componentDidMount() {

		this.getcategories();
		this.getequipments();
		this.getbuildings();
		this.getCompanies();

	}

	/*  componentDidUpdate(){
	  
	   /*  if(this.tic_subject){
			//this.tic_subject.focus();
			$("#tic_subject").focus();
	   }
	    
     } */
	 
	 async getCompanies() {
		this.setState({ companyLoading: true });
		this.setState({ companies: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var cid = Sanitize.getStoreageItem('company_ids');
		var cuid = Sanitize.getStoreageItem('company_id');

		var data = { "token": token, "cid": cid }
		await Reqst.axPost('getusercompanies', data).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ companyLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ companyLoading: false });

				/* var data = [];

				for (var i = 0; i < res.length; i++) {

					if (res[i].id != cuid) {

						data.push(res[i]);
					}

				} */
				this.setState({ companies: res });
			}
		});
	}

	getequipments() {
		this.setState({ equipLoading: true });
		this.setState({ equipments: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}

		var data = { "token": token, "query": query };
		Reqst.axPost('getticketequipment', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ equipLoading: false });
				this.setState({ equipments: res });
			}
		});

	}

	getcategories() {
		this.setState({ catLoading: true, loadingTxt: 'Loading...' });
		this.setState({ cats: [], subcats: [], sub_cat_id: '' });
		var token = Sanitize.getStoreageItem('accessToken');

		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}

		var data = { "token": token, "query": query };
		Reqst.axPost('getticketcategory', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ catLoading: false });
				this.setState({ cats: res });
				this.setState({ cat_id: res[0].id });
				this.getsubcategories(res[0].id);
			}
		});

	}

	async getbuildings() {
		this.setState({ dataLoading: true });
		this.setState({ loadTree: 'Loading...' });
		this.setState({ buildings: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var spcat = 'Building';
		var company = this.state.cmp_id;
		await Reqst.axGet('getspacecategorybycompany/' + token + '/' + spcat + '/' + company).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ loadTree: '' });
				if (res) {
					this.setState({ buildings: res });
					if(res.length>0){
					 this.setState({ selspaces: [res[0].id] });
					}
					this.getfloors();
				}
			}
		});
	}

	async getfloors() {
		this.setState({ dataLoading: true });
		this.setState({ floors: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var spcat = 'Floor';
		var company = this.state.cmp_id;
		await Reqst.axGet('getspacecategorybycompany/' + token + '/' + spcat + '/' + company).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				if (res) {
					this.setState({ floors: res });
					this.getrooms();
				}

			}
		});
	}

	async getrooms() {
		this.setState({ dataFullLoading: true });
		this.setState({ rooms: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var company = this.state.cmp_id;
		await Reqst.axGet('getchildspacecategorybycompany/' + token + '/' + company).then((result) => {

			let res = result['data'];
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataFullLoading: false });
				alert(result['data'].data);
			}
			else {

				if (res) {
					this.state.rooms = res;
					this.setState({ rooms: res });
					this.getFloorsTree();
					this.setState({ dataFullLoading: false });
				}

			}
		});
	}


	getsubcategories(catid) {
		if (catid != '') {
			this.setState({ subcatLoading: true });
			this.setState({ subcats: [] });
			var token = Sanitize.getStoreageItem('accessToken');

			var searchtext = this.state.selsearchsubtext;
			//var catid = this.state.cat_id;
			console.log(catid);
			var query = '["parent_category_id", "=", ' + catid + ']';
			if (searchtext != '') {
				query = '"&",["name", "ilike","' + searchtext + '" ],["parent_category_id", "=", ' + catid + ']';
			}

			var data = { "token": token, "query": query };
			Reqst.axPost('getticketsubcategory', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ subcatLoading: false });
					this.setState({ subcats: res });
					//this.setState({sub_cat_id:res[0].id});
				}
			});
		}

	}


	getsubcategoriesbyid(catid) {
		if (catid != '') {
			this.setState({ subcatLoading: true });
			this.setState({ subcats: [] });
			var token = Sanitize.getStoreageItem('accessToken');

			var searchtext = this.state.selsearchsubtext;
			//var catid = this.state.cat_id;
			console.log(catid);
			var query = '["parent_category_id", "=", ' + catid + ']';
			if (searchtext != '') {
				query = '"&",["name", "ilike","' + searchtext + '" ],["parent_category_id", "=", ' + catid + ']';
			}

			var data = { "token": token, "query": query };
			Reqst.axPost('getticketsubcategory', data).then((result) => {

				let res = result['data'];
				if (res.code == '401') {

					this.getRefreshToken();

				}
				else {
					this.setState({ subcatLoading: false });
					this.setState({ subcats: res });
				}
			});
		}

	}



	getRefreshToken() {

		this.setState({ catLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ catLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				this.getcategories();
				this.getbuildings();
				this.getequipments();
			}
		}).catch(error => {
			this.setState({ catLoading: false });
			//console.log(error.response.status);

		});

	}

	onSelSearch(type, query) {
		this.setState({ selsearchtext: '' });
		this.setState({ selsearchsubtext: '' });

		if (type == 'cat') {
			this.setState({ cat_id: '' });
			this.setState({ subcats: [] });
			this.setState({ sub_cat_id: '' });
			if (this.state.cat_id == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getcategories();
			}
		}
		else if (type == 'subcat') {

			this.setState({ sub_cat_id: '' });
			if (this.state.sub_cat_id == '') {
				this.setState({
					selsearchsubtext: query
				});
				this.state.selsearchsubtext = query;
				this.getsubcategories(this.state.cat_id);
			}


		}
		else if (type == 'equipment') {
			this.setState({ equip_id: '' });
			if (this.state.equip_id == '') {
				this.setState({
					selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getequipments();
			}
		}

	}

	onTicketCreate() {
		this.setState({ ticketSave: true });
		var token = Sanitize.getStoreageItem('accessToken');

		var typecat = 'asset';

		if (this.state.equip_id != '') {

			typecat = 'equipment';

		}

		var assetid = 'false';
		if (this.state.selspaces.length > 0) {

			assetid = this.state.selspaces[this.state.selspaces.length - 1];

		}
		var values = { "subject": this.state.tic_subject, "channel": this.state.contact_mode, "asset_id": assetid, "company_id": parseInt(this.state.cmp_id), "description": this.state.tic_desc, "email": this.state.tic_permail, "equipment_id": this.state.equip_id, "issue_type": this.state.issue_type, "mobile": this.state.tic_per_contact, "person_name": this.state.tic_pername, "raised_by": this.state.tab_raised_by, "category_id": this.state.cat_id, "sub_category_id": this.state.sub_cat_id, "type_category": typecat };

		var data = { "token": token, "values": values };

		Reqst.axPost('ticketcreate', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {

				if (this.state.files != '') {

					var tid = res[0];
					this.onDocumentCreate(tid);
				}
				else {
					this.setState({ ticketSave: false });

					// swal("Success","Thank you, Your Ticket has been Submitted Successfully..","success");
					this.setState({ isConfirm: true });
					//this.onCancel();
				}
			}
		});

	}

	getnovalues(str) {

		if (str != '' && str != null) {
			return str;
		}
		else {

			return 'Not Assigned';
		}

	}

	bytesToSize(bytes) {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		var size = Math.round(bytes / Math.pow(1024, i), 2);
		var mes = sizes[i];
		if (parseInt(size) > 2 && mes == 'MB') {

			alert('File Size More than 2MB ');

		}
		else {



		}
	};


	onDocumentCreate(tid) {
		this.setState({ ticketSave: true });
		var token = Sanitize.getStoreageItem('accessToken');
		var cat = 2;
		var date = this.state.cdate;
		var name = this.state.tic_subject;
		var fname = date + '-' + name;
		var fdata = this.state.files;
		var type = this.state.fileType;
		var remfile = 'data:' + type + ';base64,';
		var filedata = fdata.replace(remfile, "");
		var values = { "datas": filedata, "datas_fname": this.state.photoname, "ir_attachment_categ": cat, "name": fname, "company_id": parseInt(this.state.cmp_id), "res_model": "website.support.ticket", "res_id": parseInt(tid) };

		var data = { "token": token, "values": values };

		Reqst.axPost('documentcreate', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ ticketSave: false });
				//swal("Success","Thank you, Your Ticket has been Created Successfully..","success");
				this.setState({ isConfirm: true });
				//this.onCancel();
			}
		});

	}

	/* onAddFields(type,e){
		
		if(e.target.checked){
			
			this.setState({[e.target.name]: type});
			
			if(type=='others'){
				
				this.setState({
					tic_pername :'',
			        tic_per_contact : '',
			        tic_permail : ''
				});
			
			}
			else{
		
				this.setState({
					tic_pername :Sanitize.getStoreageItem('company_name'),
			        tic_per_contact : Sanitize.getStoreageItem('company_mobile')!='false' ? Sanitize.getStoreageItem('company_mobile') : '',
			        tic_permail : Sanitize.getStoreageItem('company_mail'),
				});
			
			}
		}
		else{
			
			this.setState({[e.target.name]: type});
		}
	} */

	onAddFields(type) {

		this.setState({ tab_raised_by: type });

		if (type == 'others') {

			this.setState({
				tic_pername: '',
				tic_per_contact: '',
				tic_permail: ''
			});

		}
		else {

			this.setState({
				tic_pername: Sanitize.getStoreageItem('company_name'),
				tic_per_contact: Sanitize.getStoreageItem('company_mobile') != 'false' ? Sanitize.getStoreageItem('company_mobile') : '',
				tic_permail: Sanitize.getStoreageItem('company_mail'),
			});

		}

	}

	setcontactmode(column) {
		this.setState({ contact_mode: column });
	}

	setissuetype(column) {
		this.setState({ issue_type: column });
	}

	next() {

		let active = this.state.active + 1;
		if (active > 4) {
			active = 0;
		}

		if (active == '1') {

			if (this.state.tic_pername != '' && this.state.selspaces.length > 0) {

				this.setState({ active });

			}
			else {
				if (this.state.tic_pername == '') {
					toast.error("Name Required !", {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				}
				if (this.state.selspaces.length == '0') {
					toast.error("Space Required !", {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				}

			}
		}
		else if (active == '2') {

			if (this.state.issue_type != '' && this.state.cat_id != '' && this.state.sub_cat_id != '' && this.state.tic_subject != '') {

				this.setState({ active });

			}
			else {

				if (this.state.issue_type == '') {
					toast.error("Space Required !", {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				}
				if (this.state.cat_id == '') {
					toast.error("Category Required !", {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				}
				if (this.state.sub_cat_id == '') {
					toast.error("Sub Category Required !", {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				}
				if (this.state.tic_subject == '') {
					toast.error("Subject Required !", {
						position: toast.POSITION.BOTTOM_RIGHT
					});
				}


			}

		}
	}

	prev() {
		let active = this.state.active - 1;
		if (active < 0) {
			active = 0;
		}
		this.setState({ active });
	}

	onClose() {

		this.setState({ isConfirm: false });

		this.setState({ isTicketLink: true });

	}



	render() {

		if (this.state.isTicketLink && !this.state.isConfirm) {
			return (<Redirect to="/helpdesk/tickets" />);
		}

		const modes = [
			{
				value: 'web',
				label: 'Web'
			},
			{
				value: 'email',
				label: 'Email'
			},
			{
				value: 'phone',
				label: 'Phone'
			},
			{
				value: 'mobile app',
				label: 'Mobile app'
			}
		];

		const issuetypes = [
			{
				value: 'request',
				label: 'Request'
			},
			{
				value: 'complaint',
				label: 'Complaint'
			},
			{
				value: 'incident',
				label: 'Incident'
			},
			{
				value: 'EHS',
				label: 'EHS'
			}
		];

		var confirmtext = 'Thank you, Your Ticket has been Submitted Successfully..Do you want to create another ticket?';

		const { report_date } = this.state;

		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Tickets | Raise a Ticket </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<ToastContainer />
					<div className="drawer_left_menu pull-left">
						<PageTitleAlt3
							heading=""
							subheading=""
							icon=""
							menuLevel1="Tickets"
							menuLevel2={locale.t('el.menus.raiseTicket')}
							menuLevel1link="#/helpdesk/tickets"
							isbreadcumb={true}
						/>
					</div>
					<SweetAlert
						title="Thank you, Your Ticket has been Submitted Successfully.."
						confirmButtonColor=""
						show={this.state.isConfirm}
						text="Do you want to create another ticket?"
						showCancelButton={true}
						onConfirm={() => this.onCancel()}
						onCancel={() => this.onClose()} />
					<Card className="mb-3">
						<CardBody>
							<Col lg="12" sm="12" xs="12">
								<Steps space={400} active={this.state.active} finishStatus="success">
									<Steps.Step title={locale.t('el.tickets.requestInformation')}>
									</Steps.Step>
									<Steps.Step title={locale.t('el.tickets.ticketDescription')}>
									</Steps.Step>
									<Steps.Step title={locale.t('el.tickets.summary')}>
									</Steps.Step>
								</Steps>
							</Col>
							<br />
							{this.state.active == '0' ?
								<div>
								<Card className="mb-2">
						           <CardBody>
								      <div className="card-title text-uppercase">
											Requestor Details
									  </div>
									<Row>
										<Col lg="12" sm="12" xs="12" className="">


											<FormGroup>
												<Label className="text-capitalize">{locale.t('el.tickets.raisedBy')} </Label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
												 <Button.Group>
													<Button type={this.state.tab_raised_by == 'self' ? 'primary' : ''} onClick={() => this.onAddFields('self')}>Self</Button>
													<Button type={this.state.tab_raised_by == 'others' ? 'primary' : ''} onClick={() => this.onAddFields('others')}>Others</Button>
												</Button.Group>
											</FormGroup>

										</Col>

										<Col lg="6" sm="12" xs="12">
											<FormGroup>
												<Label className="text-capitalize">{locale.t('el.tickets.name')} <span className="text-danger">*</span></Label>
												<Input type="text" className="mat_form" name="tic_pername" value={this.state.tic_pername} onChange={this.onInputChange.bind(this)} bsSize="sm" required />
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">{locale.t('el.tickets.email')} <span className="text-danger"></span></Label>
												<Input type="text" className="mat_form" name="tic_permail" value={this.state.tic_permail} onChange={this.onInputChange.bind(this)} bsSize="sm" />
											</FormGroup>

										</Col>
										<Col lg="6" sm="12" xs="12">
											<FormGroup>
												<Label className="text-capitalize">{locale.t('el.tickets.mobile')} <span className="text-danger"></span></Label>
												<Input type="email" className="mat_form" name="tic_per_contact" value={this.state.tic_per_contact} onChange={this.onInputChange.bind(this)} bsSize="sm" />
											</FormGroup>

											<Label className="text-capitalize">{locale.t('el.tickets.mode')}</Label>
											<Select onChange={this.setcontactmode} value={this.state.contact_mode} style={{ marginTop: '-4px' }}>
												{
													modes.map(el => {
														return <Select.Option key={el.value} label={el.label} value={el.value} />
													})
												}
											</Select>

										</Col>
									  </Row>
						            </CardBody>
								   </Card>
								   <Card className="mb-2">
						             <CardBody>
										  <div className="card-title text-uppercase">
												Location Details
										  </div>
										<Row>
											<Col lg="6" sm="12" xs="12">
												<FormGroup>
												<Label className="text-capitalize">{locale.t('el.tickets.company')} <span className="text-danger">*</span></Label>
													<Select onChange={this.onCompanyChange} value={this.state.cmp_id} loading={this.state.companyLoading}>
														{
															this.state.companies.map(el => {
																return <Select.Option key={el.id} label={el.name} value={el.id} selected={this.state.cmp_id==el.id ? true : false} />
															})
														}
													</Select>
													
												</FormGroup>

											</Col>
											<Col lg="6" sm="12" xs="12">
												<Label className="text-capitalize">{locale.t('el.tickets.space')} <span className="text-danger">*</span></Label><br />
												{this.state.dataFullLoading || this.state.dataLoading ?
													<div className="text-center"><Loader type="ball-clip-rotate" /></div>
												:
												<Cascader
													placeholder=""
													options={this.state.regions}
													changeOnSelect={true}
													filterable={true}
													clearable={true}
													value={this.state.selspaces}
													onChange={(value) => { this.onSelectSpaces(value) }}

												/>
												}
											</Col>

										</Row>
									 </CardBody>
								   </Card>
								</div>
								: this.state.active == '1' ?
										<div>
											<Row>
												<Col lg="6" sm="12" xs="12">
													<FormGroup>
														<Label className="text-capitalize">{locale.t('el.tickets.issueType')} <span className="text-danger">*</span></Label>
														<Select onChange={this.setissuetype} value={this.state.issue_type}>
															{
																issuetypes.map(el => {
																	return <Select.Option key={el.value} label={el.label} value={el.value} />
																})
															}
														</Select>
													</FormGroup>
													<FormGroup>
														<Label className="text-capitalize">{locale.t('el.tickets.category')} <span className="text-danger">*</span></Label>
														<Select onChange={this.handleCatChange} value={this.state.cat_id} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this, 'cat')} loading={this.state.catLoading}>
															{
																this.state.cats.map(el => {
																	return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
														</Select>
													</FormGroup>

													<FormGroup>
														<Label className="text-capitalize">{locale.t('el.tickets.subCategory')} <span className="text-danger">*</span></Label>
														<Select onChange={this.handleSubCatChange} value={this.state.sub_cat_id} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this, 'subcat')} loading={this.state.subcatLoading}>
															{
																this.state.subcats.map(el => {
																	return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
														</Select>
													</FormGroup>

													<FormGroup>
														<Label className="text-capitalize">{locale.t('el.tickets.image')} <span className="text-danger"></span></Label>

														<ReactFileReader handleFiles={this.handleFiles} fileTypes='image/*' base64={true}>
															{this.state.files != '' ?
																<img src={this.state.files} style={{ border: '1px solid #cccccc', width: '145px', cursor: 'pointer' }} /> :

																<i className="pe-7s-cloud-upload" style={{ fontSize: '145px', cursor: 'pointer' }} ></i>

															}

														</ReactFileReader>
														<div className="el-upload__tip"></div>
														{this.state.files != '' ?
															<ul className="el-upload-list el-upload-list--text">
																<li className="el-upload-list__item is-success">
																	<a className="el-upload-list__item-name">
																		<i className="el-icon-document"></i> &nbsp; {this.state.photoname}
																	</a>
																	<label className="el-upload-list__item-status-label">
																		<i className="el-icon-upload-success el-icon-circle-check"></i>
																	</label>
																	<i className="el-icon-close" onClick={this.handlephotoRemove}></i>
																</li>
															</ul>
															: ''}
													</FormGroup>

												</Col>
												<Col lg="6" sm="12" xs="12">


													<FormGroup>
														<Label className="text-capitalize">Subject <span className="text-danger">*</span></Label>
														<Input type="text" className="mat_form" name="tic_subject" id="tic_subject" value={this.state.tic_subject} ref={(input) => { this.tic_subject = input; }} onChange={this.onInputChange.bind(this)} bsSize="sm" required style={{ marginTop: '4px' }} />
													</FormGroup>

													<FormGroup>
														<Label className="text-capitalize">Equipment (Optional)<span className="text-danger"></span></Label>
														<Select onChange={this.handleEquipChange} value={this.state.equip_id} filterable={true} clearable={true} remote={true} remoteMethod={this.onSelSearch.bind(this, 'equipment')} loading={this.state.equipLoading}>
															{
																this.state.equipments.map(el => {
																	return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
														</Select>
													</FormGroup>

													<FormGroup>
														<Label className="text-capitalize">Description</Label>
														<Input type="text" className="mat_form" name="tic_desc" value={this.state.tic_desc} onChange={this.onInputChange.bind(this)} bsSize="sm" style={{ marginTop: '5px' }} />
													</FormGroup>

												</Col>
											</Row>
										</div>
										: this.state.active == '2' ?
											<div>

												<Row>
													<Col md="6" lg="4" className="custom_col_left">
														<Card className="mb-2" style={{ minHeight: '97.3%' }}>
															<CardBody>
																<div className="">
																	<span className="card-title text-uppercase"> Requestor Information </span>

																</div>
																<br />
																<Row>
																	<Col lg="6" sm="12" xs="12">
																		<FormGroup>
																			<Label className="text-capitalize">Name</Label>
																			<p><b>{this.getnovalues(this.state.tic_pername)}</b></p>
																		</FormGroup>


																		<FormGroup>
																			<Label className="text-capitalize">Mobile</Label>
																			<p><b>{this.getnovalues(this.state.tic_per_contact)}</b></p>
																		</FormGroup>

																	</Col>

																	<Col lg="6" sm="12" xs="12">

																		<FormGroup>
																			<Label className="text-capitalize">Email</Label>
																			<p><b>{this.getnovalues(this.state.tic_permail)}</b></p>
																		</FormGroup>

																		<FormGroup>
																			<Label className="text-capitalize">Mode</Label>
																			<p><b style={{ textTransform: 'capitalize' }}>{this.getnovalues(this.state.contact_mode)}</b></p>
																		</FormGroup>

																	</Col>

																</Row>
															</CardBody>
														</Card>
													</Col>
													<Col md="6" lg="4" className="custom_col_left custom_col_right">
														<Card className="mb-2" style={{ minHeight: '97.3%' }}>
															<CardBody>
																<div className="">
																	<span className="card-title text-uppercase"> Location Information </span>

																</div>

																<br />
																<Row>
																	<Col lg="6" sm="12" xs="12">
																		<FormGroup>
																			<Label className="text-capitalize">Company</Label>
																			<p><b>{this.getnovalues(this.state.tic_company)}</b></p>
																		</FormGroup>

																	</Col>

																	<Col lg="6" sm="12" xs="12">

																		<FormGroup>
																			<Label className="text-capitalize">Space</Label>
																			<p><b style={{ textTransform: 'capitalize' }}>{this.getDataName('no', 'space')}</b></p>
																		</FormGroup>

																	</Col>

																</Row>
															</CardBody>
														</Card>
													</Col>
													<Col md="6" lg="4" className="custom_col_right">
														<Card className="mb-2">
															<CardBody>
																<div className="">
																	<span className="card-title text-uppercase"> Ticket Information </span>

																</div>

																<br />
																<Row>
																	<Col lg="6" sm="12" xs="12">
																		<FormGroup>
																			<Label className="text-capitalize">Issue Type</Label>
																			<p><b style={{ textTransform: 'capitalize' }}>{this.getnovalues(this.state.issue_type)}</b></p>
																		</FormGroup>


																		<FormGroup>
																			<Label className="text-capitalize">Category</Label>
																			<p><b style={{ textTransform: 'capitalize' }}>{this.getDataName(this.state.cat_id, 'cat')}</b></p>
																		</FormGroup>

																		<FormGroup>
																			<Label className="text-capitalize">Subcategory</Label>
																			<p><b style={{ textTransform: 'capitalize' }}>{this.getDataName(this.state.sub_cat_id, 'subcat')}</b></p>
																		</FormGroup>

																		<FormGroup>
																			<Label className="text-capitalize">Image</Label>
																			<p><b>{this.getnovalues(this.state.photoname)}</b></p>
																		</FormGroup>

																	</Col>

																	<Col lg="6" sm="12" xs="12">

																		<FormGroup>
																			<Label className="text-capitalize">Subject</Label>
																			<p><b style={{ textTransform: 'capitalize' }}>{this.getnovalues(this.state.tic_subject)}</b></p>
																		</FormGroup>

																		<FormGroup>
																			<Label className="text-capitalize">Equipment</Label>
																			<p><b style={{ textTransform: 'capitalize' }}>{this.getnovalues(this.state.contact_mode)}</b></p>
																		</FormGroup>

																		<FormGroup>
																			<Label className="text-capitalize">Description</Label>
																			<p><b>{this.getnovalues(this.state.tic_desc)}</b></p>
																		</FormGroup>

																	</Col>

																</Row>
															</CardBody>
														</Card>
													</Col>

												</Row>

											</div>
											: ''}
							<br />
							<div className="pull-right">
								{this.state.active != '0' && this.state.active != '2' ? <Button onClick={() => this.prev()}><i className="el-icon-arrow-left"></i>&nbsp;Prev</Button> : ''}
								{this.state.active != '2' ? <Button onClick={() => this.next()}>Next&nbsp;<i className="el-icon-arrow-right"></i></Button> : ''}
								{this.state.active == '2' ?

									<div>
										<Button type="primary" disabled={this.state.ticketSave} onClick={this.onTicketCreate} >{this.state.ticketSave ? 'Submitting...' : 'Submit'}</Button> &nbsp; <Button type="danger" onClick={() => this.onCancel()}>Cancel</Button> &nbsp; <Button onClick={() => this.prev()}><i className="el-icon-arrow-left"></i>&nbsp;Prev</Button>
									</div>
									:
									''
								}
							</div>

						</CardBody>
					</Card>
				</ReactCSSTransitionGroup>
			</Fragment>

		)
	}
}
