export default {
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Clear'
    },
    datepicker: {
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      year: '',
      month1: 'Jan',
      month2: 'Feb',
      month3: 'Mar',
      month4: 'Apr',
      month5: 'May',
      month6: 'Jun',
      month7: 'Jul',
      month8: 'Aug',
      month9: 'Sep',
      month10: 'Oct',
      month11: 'Nov',
      month12: 'Dec',
      // week: 'week',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    select: {
      loading: 'Loading',
      noMatch: 'No matching data',
      noData: 'No data',
      placeholder: 'Select'
    },
    cascader: {
      noMatch: 'No matching data',
      loading: 'Loading',
      placeholder: 'Select'
    },
    pagination: {
      goto: 'Go to',
      pagesize: '/page',
      total: 'Total {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Message',
      confirm: 'OK',
      cancel: 'Cancel',
      error: 'Illegal input'
    },
    upload: {
      delete: 'Delete',
      preview: 'Preview',
      continue: 'Continue'
    },
    table: {
      emptyText: 'No Data',
      confirmFilter: 'Confirm',
      resetFilter: 'Reset',
      clearFilter: 'All',
      sumText: 'Sum',
      Number: 'Number',
      Name: 'Name',
      Category: 'Category',
    },
    tree: {
      emptyText: 'No Data'
    },
    transfer: {
      noMatch: 'No matching data',
      noData: 'No data',
      titles: ['List 1', 'List 2'], // to be translated
      filterPlaceholder: 'Enter keyword', // to be translated
      noCheckedFormat: '{total} items', // to be translated
      hasCheckedFormat: '{checked}/{total} checked' // to be translated
    },
    notfound: {
      noData: 'No Data Found',
      noWork: 'No Work Orders Found..'
    },
    menus: {
      home: 'Home',
      overview: 'Overview',
      assets: 'Assets',
      spaces: 'Spaces',
      equipment: 'Equipment',
      helpdesk: 'Helpdesk',
      dashboard: 'Dashboard',
      raiseTicket: 'Raise a Ticket',
      tickets: 'Tickets',
      maintenance: 'Maintenance',
      workOrders: 'Work Orders',
      ppmViewer: 'PPM Viewer',
      tanker: 'Tanker',
      addTransaction: 'Add an Transaction',
      tankers: 'Tankers',
      humanResources: 'Human Resources',
      employee: 'Employee',
      shift: 'Shift',
      attendance: 'Attendance',
      team: 'Team',
      inventory: 'Inventory',
      parts: 'Parts',
      tools: 'Tools',
      contacts: 'Contacts',
      vendors: 'Vendors',
      customers: 'Customers',
    },
    dashboard: {
      tickesPerformance: 'TICKETS PERFORMANCE',
      open: 'Open',
      late: 'LATE',
      inprogress: 'Inprogress',
      closed: 'Closed',
      completed: "Completed",
      low: 'Low',
      normal: 'Normal',
      high: 'High',
      ticketsPriority: 'TICKETS BY PRIORITY (%)',
      ticketsCategory: 'TICKETS BY CATEGORY (%)',
      workStatus: 'Work Orders By State (%)',
      workType: 'Work Orders By Maintenance Type (%)',
      reactive: 'Reactive',
      preventive: 'Preventive',
      onCondition: 'On-Condition',
      predictive: 'Predictive',
      workOverdues: 'Work Orders Overdues By Maintenance Type',
      equipmentStatus: 'EQUIPMENTS BY STATUS',
      workBlock: 'WORK ORDERS BY BLOCK',
      workPerform: 'Work Orders Performance',
      eqStatus: 'Equipments By Status',
    },
    overview: {
      sites: 'Sites',
      sitesummary: 'Site Summary',
      energyConsumption: 'Energy Consumption',
      overtext: 'Overview',
      buildings: 'Buildings',
      totalArea: 'Total Area',
      squareFeet: 'sq.ft',
      siteName: 'Site Name',
      indoorQuality: 'Indoor Air Quality (AQI)',
      temperature: 'Temperature (°C)',
      humidity: 'Humidity (r.H.)',
      energy: 'Energy (Kwh)',
      pressure: 'Pressure (hPa)',
      area: 'Area (sq.ft)',
    },
    space: {
      siteOverview: 'Site Overview',
      sqfeet: 'Square Feet',
      sitePerformance: 'Site Performance',
      strategicCerifications: 'Strategic Certifications',
      indoorQuality: 'Indoor Air Quality',
      energyConsumption: 'Energy Consumption',
      workOrders: 'Work Orders',
      temperature: 'Temperature',
      tickets: 'Tickets',
      humidity: 'Humidity',
      pressure: 'Pressure',
      lux: 'Lux',
      current: 'Current',
      assetManagement: 'Asset Management',
      complianceStatus: 'Compliance Status',
      certificateStatus: 'Certificate Status',
      currentPoints: 'Current Points',
      auditedOn: 'Audited On',
      checklists: 'Checklists',
      category: 'Category',
      casethres: 'Case/Threshold',
      type: 'Type',
      maxPoints: 'Max Points',
      yes: 'Yes',
      mayBe: 'May Be',
      no: 'No',
    },
    equipment: {
      title: 'Equipment',
      number: 'Number',
      name: 'Name',
      category: 'Category',
      state: 'State',
      warrantyDate: 'Warranty Date',
      summary: 'Summary',
      maintenance: 'Maintenance',
      readings: 'Readings',
      analytics: 'Analytics',
      auditlog: 'Audit Log',
      documents: 'Documents',
      modelCode: 'Model Code',
      serialNo: 'Serial No',
      manufacturer: 'Manufacturer',
      vendor: 'Vendor',
      purchaseDate: 'Purchase Date',
      installmentdate: 'Installment Date',
      purchasevalue: 'Purchase Value',
      criticality: 'Criticality',
      description: 'Description',
      relatedEquipments: 'RELATED EQUIPMENTS',
      sendMessage: 'SEND MESSAGE',
      writeSomething: 'Write Something..',
      reactive: 'REACTIVE',
      preventive: 'PREVENTIVE',
      onCondition: 'ON-CONDITION',
      predictive: 'PREDICTIVE',
      orderType: 'Total Orders By Maintenance Type (%)',
      orderOverdue: 'Orders Overdues By Maintenance Type',
      new: 'New',
      progress: 'Progress',
      closed: 'Closed',
      lastValue: 'Last Value',
      lastUpdated: 'Last Updated',
      noOfAlarms: 'No. of Alarms',
      manage: 'Manage',
    },
    tickets: {
      requestInformation: 'Requestor Information',
      locationInformation: 'Location Information',
      ticketDescription: 'Ticket Description',
      summary: 'Summary',
      raisedBy: 'Raised By',
      self: 'Self',
      others: 'Others',
      name: 'Name',
      email: 'Email',
      mobile: 'Mobile',
      mode: 'Mode',
      company: 'Company',
      space: 'Space',
      issueType: 'Issue Type',
      subject: 'Subject',
      category: 'Category',
      optional: 'Optional',
      subCategory: 'Subcategory',
      description: 'Description',
      image: 'Image',
      prev: 'Prev',
      next: 'Next',
      required: 'Required',
      ticketNumber: 'Ticket Number',
      priority: 'Priority',
      maintenanceTeam: 'Maintenance Team',
      orderStatus: 'Order Status',
      slaTime: 'SLA Time',
      state: 'State',
      space: 'Space',
      auditlog: 'Audit Log',
      documents: 'Documents',
      subCat: 'Sub Category',
      partner: 'Partner',
      email: 'Email',
      personName: 'Person Name',
      approval: 'Approval',
      supportRating: 'Support Rating',
      tags: 'Tags',
      supportComment: 'Support Comment',
      technician: 'Technician',
      maintenanceOrder: 'Maintenance Orders',
      orderStatus: 'Order Status',
      status: 'Status',
      rule: 'Rule',
      timeRemaining: 'Time Remaining',
      ticketCategory: 'Ticket Category',
      ticketDescription: 'Ticket Description',
    },
    workOrders: {
      reference: 'Reference',
      description: 'Description',
      maintenance: 'Maintenance',
      priority: 'Priority',
      assignTo: 'Assign To',
      status: 'Status',
      dueDate: 'Due Date',
      parts: 'Parts',
      tools: 'Tools',
      timeSheet: 'Time Sheet',
      auditLog: 'Audit Log',
      questionGroup: 'Question Group',
      question: 'Question',
      answer: 'Answer',
      answerBy: 'Answered By',
      answerOn: 'Answered On',
    },
    calendar: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      agenda: 'Agenda'
    }
  }
};
