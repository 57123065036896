import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import * as GC from '../../tie/GlobalConstants';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst'
import Loader from 'react-loaders';
import $ from 'jquery';
import { Redirect, Link } from 'react-router-dom';
import nodata from '../../assets/utils/images/02b0c5209c.svg';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'react-apexcharts';
import Circle from 'react-circle';
import CountUp from 'react-countup';
import Select from 'react-select';
import { Radar } from 'react-chartjs-2';
import locale from '../../locale';

import {
	Row, Col,
	Container,
	Card, CardBody, CardHeader,
	CardTitle
} from 'reactstrap';


const loaddiv = { marginTop: '100px', marginBottom: '100px', verticalAlign: 'middle' }
const options = [
	{ value: '1', label: 'Today' },
	{ value: '2', label: 'Last Week' },
	{ value: '3', label: 'Last 30 Days' },
	{ value: '4', label: 'Last 3 Months' },
	{ value: '5', label: 'Last Year' },
];

export default class OrdersDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pacount: 0,
			pmcount: 0,
			occount: 0,
			bmcount: 0,
			stateLoading: false,
			priorityLoading: false,
			categoryLoading: false,
			equipLoading: false,
			maintain_teams: [],
			maintain_teams_data: [],
			maintain_spaces: [],
			maintain_spaces_data: [],
			category_name: [],
			category_count: [],
			spacedata: [],
			spaceLoading: false,
			queryname: '',
			equipdata: [],
			isOrderLink: false,
			query: '',
			selectedOption: '',
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			orderLateLoading: false,
			statedata: [],
			prioritydata: [],
			categorydata: [],
			ordersLatedata: []
		};

		this.onOrderLink = this.onOrderLink.bind(this);

	}




	componentDidMount() {
		this.getTicketByState();
		this.getTicketByPriority();
		this.getTicketByCategory();
		//this.getmaintainorderdata();
		//this.getmaintainorderlatedata();
		//this.getmaintainequipdata();
		//this.getmaintainspacedata();

	}

	getTicketCompanyStateCount(status) {

		var data = this.state.statedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'open') {
				if ((data[i].state_id[1] == 'Open'))
					result += data[i].__count;
			}
			else if (status == 'progress') {
				if ((data[i].state_id[1] == 'In Progress'))
					result += data[i].__count;
			}
			else if (status == 'done') {
				if (((data[i].state_id[1] == 'Staff Closed') || (data[i].state_id[1] == 'Customer Closed')))
					result += data[i].__count;
			}

		}

		return result;
	}

	getTicketCompanyPriorityCount(status) {

		var data = this.state.prioritydata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'Low') {
				if ((data[i].priority_id[1] == 'Low'))
					result += data[i].__count;
			}
			else if (status == 'Normal') {
				if ((data[i].priority_id[1] == 'Normal'))
					result += data[i].__count;
			}
			else if (status == 'High') {
				if (((data[i].priority_id[1] == 'High')))
					result += data[i].__count;
			}

		}

		return result;
	}



	onOrderLink(type) {

		var query = '';

		/*   this.setState({query:''}); 
		 this.setState({queryname:''}); 
		 this.setState({isOrderLink:false});  */

		if (type == 'open_orders') {

			query = '"|",["state","=","released"],["state","=","ready"]';
		}
		else if (type == 'late_orders') {

			var cdate = this.state.cdate;
			query = '["date_scheduled","<=","' + cdate + '"],"&","&",["state","!=","draft"],["state","!=","done"],["state","!=","cancel"]';
		}
		else if (type == 'progress_orders') {

			query = '"|",["state","=","assigned"],["state","=","in_progress"]';
		}
		else if (type == 'completed_orders') {

			query = '["state","=","done"]';
		}

		this.setState({ query: query });
		this.setState({ queryname: type });
		this.setState({ isOrderLink: false });

	}

	handleChange = (selectedOption) => {
		this.setState({ selectedOption });
	};

	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}


	getmaintainOrderStateCount(status) {

		var data = this.state.ordersdata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (status == 'open') {
				if ((((data[i].state == 'released') || (data[i].state == 'ready'))))
					result += data[i].__count;
			}
			else if (status == 'progress') {
				if ((((data[i].state == 'assigned') || (data[i].state == 'in_progress') || (data[i].state == 'pause'))))
					result += data[i].__count;
			}
			else if (status == 'done') {
				if (((data[i].state == 'done')))
					result += data[i].__count;
			}
		}

		return result;
	}


	getmaintainOrderStateLateCount(mt) {

		var data = this.state.ordersLatedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			if (mt != 'oc') {
				if (((data[i].maintenance_type == mt)))
					result += data[i].__count;
			}
			else if (mt == 'oc') {
				if ((((data[i].maintenance_type == mt) || (data[i].maintenance_type == 'pr'))))
					result += data[i].__count;
			}
		}

		return result;
	}

	getmaintainOrderStateLateCountByState() {

		var data = this.state.ordersLatedata;

		var result = 0;



		for (var i = 0; i < data.length; i++) {

			result += data[i].__count;
		}

		return result;
	}

	getmaintainOrdersCount() {

		var data = this.state.ordersdata;

		var pa = 0; var bm = 0; var pm = 0; var oc = 0;

		for (var i = 0; i < data.length; i++) {
			if (((data[i].maintenance_type == 'pr') || (data[i].maintenance_type == 'oc')))
				oc += data[i].__count;
			else if ((data[i].maintenance_type == 'bm'))
				bm += data[i].__count;
			else if ((data[i].maintenance_type == 'pm'))
				pm += data[i].__count;
			else if ((data[i].maintenance_type == 'pr'))
				pa += data[i].__count;

		}

		this.setState({ pacount: pa, bmcount: bm, pmcount: pm, occount: oc });
	}

	getmaintainEquipCount(status) {

		var data = this.state.equipdata;

		var result = 0;

		for (var i = 0; i < data.length; i++) {
			if ((((data[i].state == status))))
				result += data[i].__count;
		}
		return result;
	}

	getmaintainSpaceCount() {

		var data = this.state.spacedata;

		var count = []; var space = [];

		for (var i = 0; i < data.length; i++) {

			if (data[i].mroord_count != 0) {

				count.push(data[i].mroord_count);
				space.push(data[i].space_name);
			}

		}

		this.setState({ maintain_spaces: space, maintain_spaces_data: count });
	}



	/* async getTicketByState() {
		this.setState({ stateLoading: true });
		this.setState({ statedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["state_id"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ stateLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						statedata: res
					});
					//this.getmaintainOrdersCount();
				}
			}
		});
	} */

	async getTicketByCategory() {
		this.setState({ categoryLoading: true });
		this.setState({ categorydata: [], category_name: [], category_count: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["category_id"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ categoryLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ categoryLoading: false });
				alert(result['data'].data);
			}
			else {
				if (res) {
					this.setState({
						categorydata: res
					});
					this.getmaintainCategoryCount();
				}
			}
		});
	}

	getmaintainCategoryCount() {

		var data = this.state.categorydata;

		var count = []; var names = [];

		for (var i = 0; i < data.length; i++) {
			if (data[i].category_id[1]) {
				count.push(data[i].__count);
				names.push(data[i].category_id[1]);
			} else {
				count.push(data[i].__count);
				names.push('N/A');
			}

		}

		this.setState({ category_name: names, category_count: count });
	}

	async getTicketByPriority() {
		this.setState({ priorityLoading: true });
		this.setState({ prioritydata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["priority_id"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ priorityLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ priorityLoading: false });
				alert(result['data'].data);
			}
			else {
				if (res) {
					this.setState({
						prioritydata: res
					});
					//this.getmaintainOrdersCount();
				}
			}
		});
	}


	async getTicketByState() {
		this.setState({ stateLoading: true });
		this.setState({ statedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'website.support.ticket';
		var groupby = '["state_id"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ stateLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ stateLoading: false });
				alert(result['data'].data);
			}
			else {
				if (res) {
					this.setState({
						statedata: res
					});
					//this.getmaintainOrdersCount();
				}
			}
		});
	}



	async getmaintainorderdata() {
		this.setState({ orderLoading: true });
		this.setState({ ordersdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.order';
		var groupby = '["maintenance_type", "state"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ orderLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						ordersdata: res
					});
					this.getmaintainOrdersCount();
				}
			}
		});
	}

	async getmaintainequipdata() {
		this.setState({ equipLoading: true });
		this.setState({ equipdata: [], maintain_teams: [], maintain_teams_data: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.equipment';
		var groupby = '["state"]';
		await Reqst.axGet('getdashbaordcharts/' + token + '/' + model + '/' + groupby).then((result) => {

			let res = result['data'];

			this.setState({ equipLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						equipdata: res
					});
					//this.getmaintainTeamCount();
				}
			}
		});
	}

	async getmaintainspacedata() {
		this.setState({ spaceLoading: true });
		this.setState({ spacedata: [], maintain_spaces: [], maintain_spaces_data: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.equipment.location';
		var groupby = '["space_name"]';
		var domain = '["asset_category_id","ilike","Building"]';
		//var domain = '';
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getdashboardspaces', data).then((result) => {

			let res = result['data'];

			this.setState({ spaceLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						spacedata: res
					});
					this.getmaintainSpaceCount();
				}
			}
		});
	}

	async getmaintainorderlatedata() {
		this.setState({ orderLateLoading: true });
		this.setState({ ordersLatedata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var model = 'mro.order';
		var groupby = '["maintenance_type", "state"]';
		var cdate = this.state.cdate;
		var domain = '["date_scheduled","<=","' + cdate + '"],"&","&",["state","!=","draft"],["state","!=","done"],["state","!=","cancel"]';
		var data = { "token": token, "model": model, "group": groupby, "domain": domain };
		await Reqst.axPost('getdashbaordlatecharts', data).then((result) => {

			let res = result['data'];

			this.setState({ orderLateLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						ordersLatedata: res
					});
				}
			}
		});
	}




	getRefreshToken() {
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		console.log(reftoken);
		if (reftoken != null) {
			this.setState({ dataLoading: true });
			Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
				let res = result['data'];
				if (res) {
					this.setState({ dataLoading: false });
					Sanitize.setStorage('accessToken', res.access_token);
					Sanitize.setStorage('refreshToken', res.refresh_token);
					//this.setState({accTok:res.access_token,reTok:res.refresh_token});
					this.getTicketByState();
					this.getTicketByPriority();
					this.getTicketByCategory();
				}
			}).catch(error => {
				this.setState({ dataLoading: false });
				//console.log(error.response.status);

			});
		}

	}

	newpercalculate(total, given) {
		var total = parseInt(total);
		var given = parseInt(given);
		var perc = "";
		if (isNaN(total) || isNaN(given)) {
			perc = 0;
		} else {
			perc = ((given / total) * 100);
		}

		perc = Math.floor(perc);

		return perc;
	}

	upperCase(str) {
		if (str == '' || str == null || str == ' ')
			return '';
		else
			return str.toString().toUpperCase();
	}



	render() {


		if (this.state.isOrderLink) {

			var query = this.state.query;

			var queryname = this.state.queryname;

			return (<Redirect to={{
				pathname: '/helpdesk/workorders',
				state: { modelkeyid: 'dashboard_order', value: queryname, query: query }
			}}
			/>);

		}


		const radardata = {
			labels: this.state.category_name,
			title: 'Todays Consumption',
			datasets: [
				{
					label: 'Tickets',
					backgroundColor: 'rgba(179,181,198,0.2)',
					borderColor: 'rgba(179,181,198,1)',
					pointBackgroundColor: 'rgba(179,181,198,1)',
					pointBorderColor: '#fff',
					pointHoverBackgroundColor: '#fff',
					pointHoverBorderColor: 'rgba(179,181,198,1)',
					data: this.state.category_count
				}
			]
		};

		const radaroptions = {
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						var label = data.datasets[tooltipItem.datasetIndex].label || '';

						if (label) {
							label += ': ';
						}
						label += Math.round(tooltipItem.yLabel * 100) / 100;
						return label;
					}
				}
			}
		}

		const { selectedOption } = this.state;

		var pacount = this.state.pacount;
		var bmcount = this.state.bmcount;
		var pmcount = this.state.pmcount;
		var occount = this.state.occount;

		var totpiecount = parseInt(pacount) + parseInt(bmcount) + parseInt(pmcount) + parseInt(occount);

		var paperc = this.newpercalculate(totpiecount, pacount);
		var bmperc = this.newpercalculate(totpiecount, bmcount);
		var pmperc = this.newpercalculate(totpiecount, pmcount);
		var ocperc = this.newpercalculate(totpiecount, occount);


		const piedata = {
			labels: [
				'Reactive' + ' (' + bmcount + ')',
				'Preventive' + ' (' + pmcount + ')',
				'On-Condition' + ' (' + occount + ')',
				'Predictive' + ' (' + pacount + ')'
			],
			datasets: [{
				data: [bmcount, pmcount, occount, pacount],
				backgroundColor: [
					'#80d4ff',
					'#ef869e',
					'#ff8566',
					'#8dace7'
				],
				hoverBackgroundColor: [
					'#80d4ff',
					'#ef869e',
					'#ff8566',
					'#8dace7'
				],
				hoverLabels: [
					'Reactive',
					'Preventive',
					'On-Condition',
					'Predictive'
				]
			}]
		};

		const pieoptions = {

			responsive: true,
			legend: {
				position: 'top',
			},
			tooltips: {
				callbacks: {
					label: function (tooltipItem, data) {
						var dataset = data.datasets[tooltipItem.datasetIndex];

						var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
							return previousValue + currentValue;
						});
						var currentValue = dataset.data[tooltipItem.index];
						var currentValueName = dataset.hoverLabels[tooltipItem.index];
						var percentage = Math.floor(((currentValue / total) * 100) + 0.5);
						return currentValueName + ' ' + percentage + "%";
					}
				}
			},
			toolbar: {
				show: true,
				tools: {
					download: true,
					selection: true,
					zoom: true,
					zoomin: true,
					zoomout: true,
					pan: true,
					customIcons: []
				},
				autoSelected: 'zoom'
			},

		};


		var pabarcount = this.getmaintainOrderStateLateCount('pr');
		var bmbarcount = this.getmaintainOrderStateLateCount('bm');
		var pmbarcount = this.getmaintainOrderStateLateCount('pm');
		var ocbarcount = this.getmaintainOrderStateLateCount('oc');

		const barchartoptions2 = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: ['Reactive', 'Preventive', 'On-Condition', 'Predictive']
			},

		};


		const barchartoptions3 = {
			chart: {
				id: 'apexchart-example-3',

			},
			dataLabels: {
				enabled: false,

			},
			plotOptions: {
				bar: {
					vertical: true
				},

			},

			xaxis: {
				categories: ['Maintenance', 'Operative', 'Warehouse', 'Breakdown', 'Scrapped']
			},

		};

		const barchartoptions4 = {
			chart: {
				id: 'apexchart-example-3'
			},
			dataLabels: {
				enabled: false
			},
			plotOptions: {
				bar: {
					vertical: true
				}
			},
			xaxis: {
				categories: this.state.maintain_spaces
			},

		};



		const barchartseries2 = [{
			name: 'Overdue',
			data: [bmbarcount, pmbarcount, ocbarcount, pabarcount]
		}];


		var whcount = this.getmaintainEquipCount('wh');
		var brcount = this.getmaintainEquipCount('br');
		var mncount = this.getmaintainEquipCount('mn');
		var opcount = this.getmaintainEquipCount('op');
		var sccount = this.getmaintainEquipCount('sc');

		const barchartseries3 = [{
			name: 'Equipments',
			data: [mncount, opcount, whcount, brcount, sccount],
			colors: ['#80d4ff', '#ef869e', '#80d4ff', '#80d4ff', '#80d4ff'],
		}];

		const barchartseries4 = [{
			name: 'Workorders',
			data: this.state.maintain_spaces_data
		}];



		const piechartseries = [bmcount, pmcount, occount, pacount];

		const dougnoutoptins = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},

			labels: ['Reactive', 'Preventive', 'On-Condition', 'Predictive'],
			colors: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			],
			hover: [
				'#80d4ff',
				'#ef869e',
				'#ff8566',
				'#8dace7'
			]

		};

		var opencount = this.getTicketCompanyStateCount('open');
		var progresscount = this.getTicketCompanyStateCount('progress');
		var closedcount = this.getTicketCompanyStateCount('done');

		var lowcount = this.getTicketCompanyPriorityCount('Low');
		var normalcount = this.getTicketCompanyPriorityCount('Normal');
		var highcount = this.getTicketCompanyPriorityCount('High');

		var lateordcount = {/*this.getmaintainOrderStateLateCountByState()*/ };

		var totordcount = parseInt(opencount) + parseInt(progresscount) + parseInt(closedcount)

		var openperc = this.newpercalculate(totordcount, opencount);
		var progressperc = this.newpercalculate(totordcount, progresscount);
		var closedperc = this.newpercalculate(totordcount, closedcount);
		console.log(progressperc);
		var lateperc = this.newpercalculate(totordcount, lateordcount);

		const piechartseries1 = [lowcount, normalcount, highcount];

		const dougnoutoptins1 = {
			chart: {
				id: 'apexchart-example-3',
				toolbar: {
					show: true,
					tools: {
						download: true,
						selection: true,
						zoom: true,
						zoomin: true,
						zoomout: true,
						pan: true,
						customIcons: []
					},
					autoSelected: 'zoom'
				},
			},
			dataLabels: {
				enabled: true
			},
			legend: {
				position: 'left'
			},
			labels: ['Low', 'Normal', 'High'],
			colors: [
				'#ff6666',
				'#80d4ff',
				'#b4e34f'
			],
			hover: [
				'#ff6666',
				'#80d4ff',
				'#b4e34f'
			]

		};





		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Dashboard </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					<div>

						<Card className="mb-2" style={{ marginBottom: '7px' }}>
							<CardHeader className="card-header-tab z-index-6">
								<div className="card-title text-uppercase">

									Tickets Performance
                            </div>
								<div className="btn-actions-pane-right text-capitalize">
									<span className="d-inline-block ml-2" style={{ width: 200 }}>
										<Select
											value={selectedOption}
											onChange={this.handleChange}
											options={options}
										/>
									</span>
								</div>
							</CardHeader>
							{this.state.stateLoading ?
								<div> <center><Loader type="ball-clip-rotate" /></center> </div>
								:

								opencount != 0 && progresscount != 0 && closedcount != 0 ?
									<Row className="no-gutters">
										<Col sm="6" md="4" xl="4">
											<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('open_orders')}>
												<div className="card  shadow bg-transparent widget-chart text-left">
													<div className="icon-wrapper rounded-circle">
														<div className="icon-wrapper-bg opacity-9 bg-danger" />
														<i className="lnr-enter text-white" />
													</div>
													<div className="widget-chart-content">
														<div className="widget-title opacity-5 text-uppercase">
															{locale.t('el.dashboard.open')}
														</div>
														<div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
															<div className="widget-chart-flex align-items-center">
																<div className="widget-numbers text-danger">
																	<CountUp start={0}
																		end={opencount}
																		separator=""
																		decimals={0}
																		decimal=","
																		prefix=""
																		useEasing={false}
																		suffix=""
																		duration="1" />
																</div>
																<div className="">
																	<Circle
																		animate={true} // Boolean: Animated/Static progress
																		animationDuration="10s" // String: Length of animation
																		responsive={false} // Boolean: Make SVG adapt to parent size
																		size="46" // String: Defines the size of the circle.
																		lineWidth="30" // String: Defines the thickness of the circle's stroke.
																		progress={openperc} // String: Update to change the progress and percentage.
																		progressColor="var(--danger)" // String: Color of "progress" portion of circle.
																		bgColor="#ecedf0" // String: Color of "empty" portion of circle.
																		textColor="#6b778c" // String: Color of percentage text color.
																		textStyle={{
																			fontSize: '6rem' // CSSProperties: Custom styling for percentage.
																		}}
																		percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
																		roundedStroke={true} // Boolean: Rounded/Flat line ends
																		showPercentage={true} // Boolean: Show/hide percentage.
																		showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</a>
											<div className="divider m-0 d-md-none d-sm-block" />

										</Col>
										<Col sm="6" md="4" xl="4">
											<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('progress_orders')}>
												<div className="card  shadow bg-transparent widget-chart text-left">
													<div className="icon-wrapper rounded-circle">
														<div className="icon-wrapper-bg opacity-9 bg-info" />
														<i className="lnr-pencil text-white" />
													</div>
													<div className="widget-chart-content">
														<div className="widget-title opacity-5 text-uppercase">
															{locale.t('el.dashboard.inprogress')}
														</div>
														<div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
															<div className="widget-chart-flex align-items-center">
																<div className="widget-numbers text-info">
																	<CountUp start={0}
																		end={progresscount}
																		separator=""
																		decimals={0}
																		decimal=","
																		prefix=""
																		useEasing={false}
																		suffix=""
																		duration="1" />
																</div>
																<div className="">
																	<Circle
																		animate={true} // Boolean: Animated/Static progress
																		animationDuration="10s" // String: Length of animation
																		responsive={false} // Boolean: Make SVG adapt to parent size
																		size="46" // String: Defines the size of the circle.
																		lineWidth="30" // String: Defines the thickness of the circle's stroke.
																		progress={progressperc} // String: Update to change the progress and percentage.
																		progressColor="var(--info)" // String: Color of "progress" portion of circle.
																		bgColor="#ecedf0" // String: Color of "empty" portion of circle.
																		textColor="#6b778c" // String: Color of percentage text color.
																		textStyle={{
																			fontSize: '6rem' // CSSProperties: Custom styling for percentage.
																		}}
																		percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
																		roundedStroke={true} // Boolean: Rounded/Flat line ends
																		showPercentage={true} // Boolean: Show/hide percentage.
																		showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</a>
											<div className="divider m-0 d-md-none d-sm-block" />
										</Col>
										<Col sm="6" md="4" xl="4">
											<a href="javascript:;" style={{ textDecoration: 'none', color: '#495057' }} onClick={() => this.onOrderLink('completed_orders')}>
												<div className="card shadow bg-transparent widget-chart text-left">
													<div className="icon-wrapper rounded-circle">
														<div className="icon-wrapper-bg opacity-9 bg-success" />
														<i className="lnr-checkmark-circle text-white" />
													</div>
													<div className="widget-chart-content">
														<div className="widget-title opacity-5 text-uppercase">
															{locale.t('el.dashboard.closed')}
														</div>
														<div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
															<div className="widget-chart-flex align-items-center">
																<div className="widget-numbers text-success">
																	<CountUp start={0}
																		end={closedcount}
																		separator=""
																		decimals={0}
																		decimal=","
																		prefix=""
																		useEasing={false}
																		suffix=""
																		duration="1" />
																</div>
																<div className="">
																	<Circle
																		animate={true} // Boolean: Animated/Static progress
																		animationDuration="10s" // String: Length of animation
																		responsive={false} // Boolean: Make SVG adapt to parent size
																		size="46" // String: Defines the size of the circle.
																		lineWidth="30" // String: Defines the thickness of the circle's stroke.
																		progress={closedperc} // String: Update to change the progress and percentage.
																		progressColor="var(--success)" // String: Color of "progress" portion of circle.
																		bgColor="#ecedf0" // String: Color of "empty" portion of circle.
																		textColor="#6b778c" // String: Color of percentage text color.
																		textStyle={{
																			fontSize: '6rem' // CSSProperties: Custom styling for percentage.
																		}}
																		percentSpacing={5} // Number: Adjust spacing of "%" symbol and number.
																		roundedStroke={true} // Boolean: Rounded/Flat line ends
																		showPercentage={true} // Boolean: Show/hide percentage.
																		showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</a>
											<div className="divider m-0 d-md-none d-sm-block" />
										</Col>
									</Row>

									:

									<div className="text-center col-md-12"><img src={nodata} width="150" height="150" /> <br /><b>No Data Found..</b></div>
							}

						</Card>

						<Row>
							<Col lg="6" sm="12" xs="12" className="custom_col_left" >

								<Card className="widget-chart mb-2" style={{ minHeight: '100%', maxHeight: '100%' }}>
									<div className="widget-chat-wrapper-outer">
										<div className="widget-chart-content">

											<div className="text-left">
												<span className="card-title text-uppercase"> {locale.t('el.dashboard.ticketsPriority')}  </span>

											</div>
											{this.state.priorityLoading ?
												<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
												:

												<div className="text-left" >
													{lowcount == 0 && normalcount == 0 && highcount == 0 ?

														<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>

														:
														<div className="pie" style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
															<Chart options={dougnoutoptins1} series={piechartseries1} type="pie" id="apexchart-example-3" width="100%" />

														</div>
													}
												</div>
											}
										</div>
									</div>
								</Card>
							</Col>
							<Col lg="6" sm="12" xs="12" className="custom_col_right" >

								<Card className="widget-chart mb-2" style={{ minHeight: '100%', maxHeight: '100%' }}>
									<div className="widget-chat-wrapper-outer">
										<div className="widget-chart-content">

											<div className="text-left">
												<span className="card-title text-uppercase"> {locale.t('el.dashboard.ticketsCategory')}  </span>

											</div>
											{this.state.categoryLoading ?
												<div style={loaddiv}> <center><Loader type="ball-clip-rotate" style={loaddiv} /></center> </div>
												:

												<div className="text-left" >
													{this.state.categorydata.length > 0 ?
														<div>
															<Radar data={radardata} options={radaroptions} />

														</div>

														:
														<div className="text-center col-md-12"><img src={nodata} width="230" height="230" /> <br /><b>No Data Found..</b></div>
													}
												</div>
											}
										</div>
									</div>
								</Card>
							</Col>
						</Row>

					</div>

				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
