import React, { Component, Fragment } from 'react';
import locale from '../../locale';
import { withRouter } from 'react-router-dom';

import MetisMenu from 'react-metismenu';

import { MainNav, ComponentsNav, FormsNav, WidgetsNav, ChartsNav, VendorNav, CustomerNav, OrdersNav, hrNav } from './NavItems';

class Nav extends Component {

    state = {};

    render() {
		
		var path = this.props.location.pathname;
	
        return (
            <Fragment>
                <h5 className="app-sidebar__heading"></h5>
				<div className="metismenu vertical-nav-menu">
				  <ul className="metismenu-container">
					<li className="metismenu-item">
						<a className={path == '/' || path == '/strategiccertifications' || path == '/metricslist' ? 'metismenu-link active' : 'metismenu-link'} href="#/"><i className="metismenu-icon pe-7s-home"></i>Home</a>
					</li>
				  </ul>
                </div>
                <h5 className="app-sidebar__heading"></h5>
				<div className="metismenu vertical-nav-menu">
				  <ul className="metismenu-container">
					<li className="metismenu-item">
						<a className={path == '/assets/spaces' || path == '/assets/equipments' || path == '/assets/equipment/add' ? 'metismenu-link active' : 'metismenu-link'} href="#/assets/spaces"><i className="metismenu-icon pe-7s-folder"></i>Assets</a>
					</li>
				  </ul>
                </div>
                <h5 className="app-sidebar__heading"></h5>
				<div className="metismenu vertical-nav-menu">
				  <ul className="metismenu-container">
					<li className="metismenu-item">
						<a className={path == '/dashboard/tickets' || path == '/helpdesk/newticket' ||  path == '/helpdesk/tickets' ? 'metismenu-link active' : 'metismenu-link'} href="#/dashboard/tickets"><i className="metismenu-icon pe-7s-mail-open"></i>Helpdesk</a>
					</li>
				  </ul>
                </div>
				 <h5 className="app-sidebar__heading"></h5>
				<div className="metismenu vertical-nav-menu">
				  <ul className="metismenu-container">
					<li className="metismenu-item">
						<a className={path == '/dashboard/workorders' || path == '/helpdesk/workorders' ||  path == '/maintenance/ppmviewer' ? 'metismenu-link active' : 'metismenu-link'} href="#/dashboard/workorders"><i className="metismenu-icon pe-7s-paint"></i>Maintenance</a>
					</li>
				  </ul>
                </div>
            
                <h5 className="app-sidebar__heading"></h5>
				<div className="metismenu vertical-nav-menu">
				  <ul className="metismenu-container">
					<li className="metismenu-item">
						<a className={path == '/dashboard/tankers' || path == '/tankers/addtransaction' ||  path == '/tankers/list' ? 'metismenu-link active' : 'metismenu-link'} href="#/dashboard/tankers"><i className="metismenu-icon pe-7s-box1"></i>Tanker</a>
					</li>
				  </ul>
                </div>
                <h5 className="app-sidebar__heading"></h5>
				<div className="metismenu vertical-nav-menu">
				  <ul className="metismenu-container">
					<li className="metismenu-item">
						<a className={path == '/dashboard/humanresources' || path == '/hr/employees' ||  path == '/hr/shift' ||  path == '/hr/attendance' ||  path == '/hr/team' ? 'metismenu-link active' : 'metismenu-link'} href="#/dashboard/humanresources"><i className="metismenu-icon pe-7s-user"></i>Human Resource</a>
					</li>
				  </ul>
                </div>
                <h5 className="app-sidebar__heading"></h5>
				<div className="metismenu vertical-nav-menu">
				  <ul className="metismenu-container">
					<li className="metismenu-item">
						<a className={path == '/inventory/parts' || path == '/inventory/tools' ? 'metismenu-link active' : 'metismenu-link'} href="#/inventory/parts"><i className="metismenu-icon pe-7s-usb"></i>Inventory</a>
					</li>
				  </ul>
                </div>
                <h5 className="app-sidebar__heading"></h5>
				<div className="metismenu vertical-nav-menu">
				  <ul className="metismenu-container">
					<li className="metismenu-item">
						<a className={path == '/vendors' || path == '/customers' ? 'metismenu-link active' : 'metismenu-link'} href="#/vendors"><i className="metismenu-icon pe-7s-id"></i>Contacts</a>
					</li>
				  </ul>
                </div>
             
               
            </Fragment>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

export default withRouter(Nav);