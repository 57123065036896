import React, { Fragment, Component } from "react";
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst';
import * as GC from '../../tie/GlobalConstants';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Helmet } from 'react-helmet';
import { Redirect, Link } from 'react-router-dom';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import swal from 'sweetalert';
import SweetAlert from 'sweetalert-react';
import {
	Card, CardBody, CustomInput, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Table, Label, Input
} from 'reactstrap';
import Loader from 'react-loaders';
import $ from 'jquery';
import { Dialog, Button, Steps, Select, Cascader, DatePicker } from 'element-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import locale from '../../locale';
const loaddiv = { marginTop: '150px', marginBottom: '150px', verticalAlign: 'middle' }

export default class AddEquipment extends Component {

	constructor(props) {
		super(props);
		this.state = {
			cats: [],
			isConfirm : false,
			isEquipLink : false,
			assetLoading : false,
			teamLoading : false,
			partnersLoading : false,
			vendorsLoading : false,
			catLoading : false,
			equipSave : false,
			cats : [],
			assets : [],
			mteams : [],
			partners : [],
			vendors : [],
			selsearchtext : '',
			space_id : '',
			mteam_id : '',
			vendor_id : '',
			partner_id : '',
			cat_id : '',
			eq_installdate : '',
			eq_name : '',
			eq_modcode : '',
			eq_purdate : '',
			eq_purval : '',
			eq_sno : '',
			eq_critical : '',
			eq_disc : '',
		}

		this.onInputChange = this.onInputChange.bind(this);
		this.handleSpaceChange = this.handleSpaceChange.bind(this);
		this.handleTeamChange = this.handleTeamChange.bind(this);
		this.handleCriticalChange = this.handleCriticalChange.bind(this);
		this.handlePartnerChange = this.handlePartnerChange.bind(this);
		this.handleVendorChange = this.handleVendorChange.bind(this);
		this.onEquipCreate = this.onEquipCreate.bind(this);
		this.handleCatChange = this.handleCatChange.bind(this);
		
	}
	
	handleSpaceChange(column) {
		this.setState({space_id: column});
	}
	
	handleTeamChange(column) {
		this.setState({mteam_id: column});
	}
	
	handleCriticalChange(column) {
		this.setState({eq_critical: column});
	}
	
	handlePartnerChange(column) {
		this.setState({partner_id: column});
	}
	
	handleVendorChange(column) {
		this.setState({vendor_id: column});
	}
	
	handleCatChange(column) {
		this.setState({cat_id: column});
	}
	
	onEquipCreate() {
		
			if(this.state.eq_name != '' && this.state.eq_sno != '' && this.state.eq_modcode != '' && this.state.space_id != ''){
				
					this.setState({ equipSave: true });
					var token = Sanitize.getStoreageItem('accessToken');

					var values = { "name": this.state.eq_name, "serial": this.state.eq_sno,"model": this.state.eq_modcode,"category_id": parseInt(this.state.cat_id),"location_id": parseInt(this.state.space_id),"maintenance_team_id": parseInt(this.state.mteam_id),"manufacturer_id": parseInt(this.state.partner_id),"vendor_id": parseInt(this.state.vendor_id),"purchase_date": this.state.eq_purdate,"purchase_value": parseFloat(this.state.eq_purval),"criticality": this.state.eq_critical,"start_date": this.state.eq_installdate,"equipment_number":this.state.eq_disc,"warranty_start_date": this.state.eq_wrstdate,"warranty_end_date":this.state.eq_wrenddate};

					var data = { "token": token, "values": values };

					Reqst.axPost('equipmentcreate', data).then((result) => {

						let res = result['data'];
						if (res.code == '401') {

							this.getRefreshToken();

						}
						else if(result['data'].code == '500'){
				
							this.setState({ equipSave: false });
							alert(result['data'].data);
			            }
						else {
								this.setState({ equipSave: false });
								this.setState({ isConfirm: true });
						}
					});
				
			}
			else{
				
					  if (this.state.eq_name == '') {
							toast.error("Name Required !", {
								position: toast.POSITION.BOTTOM_RIGHT
							});
						}
						if (this.state.eq_sno == '') {
							toast.error("Serial No Required !", {
								position: toast.POSITION.BOTTOM_RIGHT
							});
						}
						if (this.state.eq_modcode == '') {
							toast.error("Model code Required !", {
								position: toast.POSITION.BOTTOM_RIGHT
							});
						}
						if (this.state.space_id == '') {
							toast.error("Location Required !", {
								position: toast.POSITION.BOTTOM_RIGHT
							});
						}
			}

	}
	
	onCancel() {

		this.setState({
			selsearchtext : '',
			space_id : '',
			mteam_id : '',
			vendor_id : '',
			partner_id : '',
			cat_id : '',
			eq_installdate : '',
			eq_name : '',
			eq_modcode : '',
			eq_purdate : '',
			eq_wrstdate : '',
			eq_wrenddate : '',
			eq_purval : '',
			eq_sno : '',
			eq_critical : '',
			eq_disc : '',
			isConfirm : false,
		});
		
		document.getElementById("equip_add").reset();
	}

	onInputChange(e) {

		this.setState({ [e.target.name]: e.target.value });
	}

	onClose() {

		this.setState({ isConfirm: false });

		this.setState({ isEquipLink: true });

	}
	
	onSelSearch(type,query) {
		this.setState({selsearchtext: ''});

	  if(type=='space'){
		  
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getlocations();
		
	  }
	  else if(type=='mteam'){
			
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getteams();
		
	  }
	  else if(type=='partner'){
			
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getPartners();
		
	  }
	   else if(type=='vendor'){
			
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getVendors();
		
	  }
	  else if(type=='cat'){
			
				this.setState({
				  selsearchtext: query
				});
				this.state.selsearchtext = query;
				this.getcats();
		
	  }

	
  }


	componentDidMount() {

		this.getPartners();
		this.getVendors();
		this.getlocations();
		this.getteams();
		this.getcats();

	}
	
	getlocations(){
		
		
			this.setState({assetLoading:true});
			this.setState({assets : []});
			var token = Sanitize.getStoreageItem('accessToken');
		
		    var searchtext = this.state.selsearchtext;
			var query = 'no';
			if(searchtext!=''){
			  query = searchtext;
			}
			
	
		    var data = {"token":token,"query":query};
			Reqst.axPost('getspacessearch',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({assetLoading:false});
					this.setState({assets : res});
					
				}
			});
	
	}
	
	getteams(){
		this.setState({teamLoading:true});
		this.setState({mteams : []});
		var token = Sanitize.getStoreageItem('accessToken');
		
		var searchtext = this.state.selsearchtext;
			var query = 'no';
			if(searchtext!=''){
			  query = searchtext;
			}


		 var data = {"token":token,"query":query};
			Reqst.axPost('getmaintenanceteamsearch',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({teamLoading:false});
					
				    this.setState({mteams : res});
				
				}
			});
		
	}
	
	getcats(){
		this.setState({catLoading:true});
		this.setState({cats : []});
		var token = Sanitize.getStoreageItem('accessToken');
		
		var searchtext = this.state.selsearchtext;
			var query = 'no';
			if(searchtext!=''){
			  query = searchtext;
			}


		 var data = {"token":token,"query":query};
			Reqst.axPost('getequipmentcategorysearch',data).then((result) => {
				
				let res = result['data'];
				if(res.code=='401'){
					
					this.getRefreshToken();
					
				}
				else{
					this.setState({catLoading:false});
					
				    this.setState({cats : res});
				
				}
			});
		
	}
	
	
	
	async getPartners() {
		this.setState({ partnersLoading: true });
		this.setState({ partners: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}

		await Reqst.axGet('getequipmentpartners/' + token + '/' + query).then((result) => {

			let res = result['data'];

			this.setState({ partnersLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						partners: res
					});
				}
			}
		});
	}

	async getVendors() {
		this.setState({ vendorsLoading: true });
		this.setState({ vendors: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		var searchtext = this.state.selsearchtext;
		var query = 'no';
		if (searchtext != '') {
			query = searchtext;
		}

		await Reqst.axGet('getequipmentpartners/' + token + '/' + query).then((result) => {

			let res = result['data'];

			this.setState({ vendorsLoading: false });
			if (result['data'].code == '401') {

				this.getRefreshToken();

			}
			else {
				if (res) {
					this.setState({
						vendors: res
					});
				}
			}
		});
	}
	
	getRefreshToken() {

		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.getPartners();
				this.getVendors();
				this.getlocations();
				this.getteams();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}
	
	render() {

	   if (this.state.isEquipLink && !this.state.isConfirm) {
			return (<Redirect to="/assets/equipments" />);
		}
		
			const modes = [
			{
				value: 'web',
				label: 'Web'
			},
			{
				value: 'email',
				label: 'Email'
			},
			{
				value: 'phone',
				label: 'Phone'
			},
			{
				value: 'mobile app',
				label: 'Mobile app'
			}
		];
		
		const criticals = [
			{
				value: '0',
				label: 'General'
			},
			{
				value: '1',
				label: 'Important'
			},
			{
				value: '2',
				label: 'Very Important'
			},
			{
				value: '3',
				label: 'Critical'
			}
		];


	

		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Equipments | Add an Equipment </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<ToastContainer />
					<div className="drawer_left_menu pull-left">
						<PageTitleAlt3
							heading=""
							subheading=""
							icon=""
							menuLevel1="Equipments"
							menuLevel2="Add an Equipment"
							menuLevel1link="#/assets/equipments"
							isbreadcumb={true}
						/>
					</div>
					<SweetAlert
						title="Equipment has been Added Successfully.."
						confirmButtonColor=""
						show={this.state.isConfirm}
						text="Do you want to create another Equipment?"
						showCancelButton={true}
						onConfirm={() => this.onCancel()}
						onCancel={() => this.onClose()} />
					
					<Card className="mb-3">
						  <CardBody>
						     <form action="" method="post" encType="multipart/form-data" id="equip_add" >
									<Row>
										
										<Col lg="6" sm="12" xs="12">
											<FormGroup>
												<Label className="text-capitalize">Name<span className="text-danger">*</span></Label>
												<Input type="text" className="mat_form" name="eq_name" onChange={this.onInputChange.bind(this)} bsSize="sm" required />
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">Serial No<span className="text-danger">*</span></Label>
												<Input type="text" className="mat_form" name="eq_sno" onChange={this.onInputChange.bind(this)} bsSize="sm"  required />
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">Location<span className="text-danger">*</span></Label>
												 <Select onChange={this.handleSpaceChange} filterable={true} clearable={true} value={this.state.space_id} remote={true} remoteMethod={this.onSelSearch.bind(this,'space')}  loading={this.state.assetLoading}>
															{
															  this.state.assets.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} selected={this.state.space_id==el.id} />
																})
															}
												 </Select>
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">Manufacturer<span className="text-danger"></span></Label>
												<Select onChange={this.handlePartnerChange} filterable={true} clearable={true} remote={true} value={this.state.partner_id} remoteMethod={this.onSelSearch.bind(this,'partner')}  loading={this.state.partnersLoading}>
															{
															  this.state.partners.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
												 </Select>
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">Purchase Date<span className="text-danger"></span></Label>
												<Input type="date" className="mat_form" name="eq_purdate"  onChange={this.onInputChange.bind(this)} bsSize="sm" />
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">Criticality</Label>
												<Select onChange={this.handleCriticalChange} value={this.state.eq_critical}>
													{
														criticals.map(el => {
															return <Select.Option key={el.value} label={el.label} value={el.value} />
														})						
												    }
												</Select>
											</FormGroup>
											
											<FormGroup>
												<Label className="text-capitalize">Warranty Validity Start Date<span className="text-danger"></span></Label>
												<Input type="date" className="mat_form" name="eq_wrstdate" onChange={this.onInputChange.bind(this)} bsSize="sm" />
											</FormGroup>

										</Col>
										<Col lg="6" sm="12" xs="12">
											<FormGroup>
												<Label className="text-capitalize">Model Code<span className="text-danger">*</span></Label>
												<Input type="email" className="mat_form" name="eq_modcode" onChange={this.onInputChange.bind(this)} bsSize="sm" required />
											</FormGroup>
											<FormGroup style={{ marginTop: '-5px'}}>
												<Label className="text-capitalize">Category</Label>
												<Select onChange={this.handleCatChange} filterable={true} clearable={true} value={this.state.cat_id} remote={true} remoteMethod={this.onSelSearch.bind(this,'cat')}  loading={this.state.catLoading}>
															{
															  this.state.cats.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
												 </Select>
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">Maintenance Team<span className="text-danger"></span></Label>
												<Select onChange={this.handleTeamChange} filterable={true} clearable={true} value={this.state.mteam_id} remote={true} remoteMethod={this.onSelSearch.bind(this,'mteam')}  loading={this.state.teamLoading}>
															{
															  this.state.mteams.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
												 </Select>
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">Vendor<span className="text-danger"></span></Label>
												<Select onChange={this.handleVendorChange} filterable={true} clearable={true} value={this.state.vendor_id} remote={true} remoteMethod={this.onSelSearch.bind(this,'vendor')}  loading={this.state.vendorsLoading}>
															{
															  this.state.vendors.map(el => {
																 return <Select.Option key={el.id} label={el.name} value={el.id} />
																})
															}
												 </Select>
											</FormGroup>
											<FormGroup>
												<Label className="text-capitalize">Purchase Value<span className="text-danger"></span></Label>
												<Input type="number" className="mat_form" name="eq_purval" onChange={this.onInputChange.bind(this)} bsSize="sm" />
											</FormGroup>
											<FormGroup style={{ marginTop: '22px'}}>
												<Label className="text-capitalize">Installment Date<span className="text-danger"></span></Label>
												<Input type="date" className="mat_form" name="eq_installdate" onChange={this.onInputChange.bind(this)} bsSize="sm" />
											</FormGroup>
											
											<FormGroup>
												<Label className="text-capitalize">Warranty Validity End Date<span className="text-danger"></span></Label>
												<Input type="date" className="mat_form" name="eq_wrenddate" onChange={this.onInputChange.bind(this)} bsSize="sm" />
											</FormGroup>


										</Col>
										<Col lg="12" sm="12" xs="12">
											<FormGroup>
												<Label className="text-capitalize">Description<span className="text-danger"></span></Label>
												<Input type="email" className="mat_form" name="eq_disc" onChange={this.onInputChange.bind(this)} bsSize="sm" />
											</FormGroup>
											
										</Col>
									  </Row>
						          
							<br />
							<div className="pull-right">
								
										<Button type="primary" disabled={this.state.equipSave} onClick={this.onEquipCreate} >{this.state.equipSave ? 'Saving...' : 'Save'}</Button> &nbsp; <Button type="danger" onClick={() => this.onCancel()}>Cancel</Button>
									
							</div>
							
							</form>

						</CardBody>
					</Card>
				</ReactCSSTransitionGroup>
			</Fragment>

		)
	}
}
