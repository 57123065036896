import React, {Fragment} from 'react';

import {
    Popover,
    Nav, NavLink, Col, Row, NavItem, Button
} from 'reactstrap';

import bg4 from '../../../assets/utils/images/dropdown-header/city5.jpg';

import {
    faAngleUp,

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class FooterMegaMenu extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.state = {
            popoverOpen: false,
            popoverOpen2: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    toggle2() {
        this.setState({
            popoverOpen2: !this.state.popoverOpen2
        });
    }

    state = {};

    render() {
        return (
            <Fragment>
                <Nav className="header-megamenu">
                    <NavItem>
                        <NavLink href="javascript:void(0);" onClick={this.toggle} id="PopoverMegaMenuFooter">Footer Menu
                            <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleUp}/>
                        </NavLink>
                    </NavItem>
                    <Popover className="rm-max-width" placement="top-start" fade={false} hideArrow trigger="legacy" isOpen={this.state.popoverOpen}
                             target="PopoverMegaMenuFooter"
                             toggle={this.toggle}>
                        <div className="dropdown-mega-menu dropdown-mega-menu-sm">
                            <div className="grid-menu grid-menu-2col">
                                <Row className="no-gutters">
                                    <Col xl="6" sm="6">
                                        <Nav vertical>
                                            <NavItem className="nav-item-header">
                                                Overview
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    <i className="nav-link-icon lnr-inbox"> </i>
                                                    <span>Contacts</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    <i className="nav-link-icon lnr-book"> </i>
                                                    <span>Incidents</span>
                                                    <div className="ml-auto badge badge-pill badge-danger">5</div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    <i className="nav-link-icon lnr-picture"> </i>
                                                    <span>Companies</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink disabled href="javascript:void(0);">
                                                    <i className="nav-link-icon lnr-file-empty"> </i>
                                                    <span>Dashboards</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                    <Col xl="6" sm="6">
                                        <Nav vertical>
                                            <NavItem className="nav-item-header">
                                                Sales &amp; Marketing
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    Queues
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">Resource Groups</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    Goal Metrics
                                                    <div className="ml-auto badge badge-warning">3</div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink href="javascript:void(0);">
                                                    Campaigns
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Popover>
                    
                </Nav>
            </Fragment>
        )
    }
}

export default FooterMegaMenu;
