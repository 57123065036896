import React, { Component, Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Helmet } from 'react-helmet';
import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import Sanitize from '../../tie/Sanitize';
import * as Reqst from '../../tie/Reqst';
import * as GC from '../../tie/GlobalConstants';
import Loader from 'react-loaders';
import Circle from 'react-circle';
//import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import $ from 'jquery';
import SweetAlert from 'sweetalert-react';
import ReactTooltip from 'react-tooltip';
import QRCode from 'qrcode.react';
import locale from '../../locale';
import { Redirect, Link } from 'react-router-dom';
import {
	toast,
	Bounce
} from 'react-toastify';
import {
	Row, Col,
	Container,
	CardHeader,
	Card,
	CardBody,
	Progress, Label,
	ButtonGroup,
	ListGroup,
	Nav, NavItem, NavLink,
	Modal, ModalHeader, ModalBody, ModalFooter,
	ListGroupItem, CardFooter,
	CustomInput, InputGroupButtonDropdown, InputGroup, Input, InputGroupAddon, InputGroupText,
	UncontrolledButtonDropdown, FormGroup, UncontrolledDropdown
} from 'reactstrap';
import { Loading, Table, Tag, Select, Dialog, Button, Dropdown, Pagination, Message, Tooltip } from 'element-react';

import 'element-theme-default';
import cx from 'classnames';

export default class Tickets extends Component {
	constructor(props) {
		super(props);

		this.state = {
			equipdata: [],
			filterlist: [],
			filters: [],
			filters1: [],
			savedtags: [],
			activeSearch: false,
			isFilterRemove: false,
			totalcount: 0,
			idd: '',
			loadingTxt: 'No Data Found',
			todosPerPage: 50,
			dropdownOpen: false,
			selecteddata: [],
			searchArea: false,
			tab_assignto: true,
			filtersDialog: false,
			columnsDialog: false,
			filtersLoading: false,
			searchDialog: false,
			activePage: 1,
			offset: 0,
			searchvalue: '',
			searchcolumn: '',
			tab_maintenance: true,
			tab_sla: true,
			tab_priority: true,
			tab_state: true,
			filter_modal: false,
			tab_duration: true,
			activeTab: '',
			filterSave: false,
			is_filter_default: false,
			filter_id: '',
			columns: [],
			searchfor: '',
			tags: [],
			isupdate: false,
			currentPage: 1,
			accTok: Sanitize.getStoreageItem('accessToken'),
			reTok: Sanitize.getStoreageItem('refreshToken'),
			dataLoading: false,
			cdate: (new Date().getFullYear()) + '-' + (this.addzero(new Date().getMonth() + 1)) + '-' + (this.addzero(new Date().getDate())) + ' ' + (this.addzero(new Date().getHours())) + ':' + (this.addzero(new Date().getMinutes())) + ':' + (this.addzero(new Date().getSeconds())),
			_isMounted: false,
		};

		this.offsetcount = this.offsetcount.bind(this);
		this.offsetcountfilter = this.offsetcountfilter.bind(this);
		this.setrpp = this.setrpp.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.setcolumn = this.setcolumn.bind(this);
		this.setsearchvalue = this.setsearchvalue.bind(this);
		this.setfilterrppfiltersaved = this.setfilterrppfiltersaved.bind(this);
		this.setsearchfor = this.setsearchfor.bind(this);
		this.offsetcountfiltersaved = this.offsetcountfiltersaved.bind(this);
		this.setisdeffilter = this.setisdeffilter.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
		this.onTabChange = this.onTabChange.bind(this);
		this.toggle = this.toggle.bind(this);
		this.showSearchArea = this.showSearchArea.bind(this);
		this.setfilterrpp = this.setfilterrpp.bind(this);
		this.setfiltername = this.setfiltername.bind(this);
		this.onAddFields = this.onAddFields.bind(this);
		this.handleClearFilters = this.handleClearFilters.bind(this);
		this.onFilterCreate = this.onFilterCreate.bind(this);
		this.onRemoveFilter = this.onRemoveFilter.bind(this);
		this.printDocument = this.printDocument.bind(this);
		this.onSelectData = this.onSelectData.bind(this);
	}

	onTabChange(tab, query, name) {

		this.setState({ activeTab: tab });
		query = query.substr(1).slice(0, -1);

		if (query != '["maintenance_type", "=","bm" ]' && query != '') {
			query = '"&",' + query + ',["maintenance_type", "=","bm" ]'
		}
		this.getfilterdatabyquery(query);
		this.getdatafiltercountbyquery(query);

		var orgdata = [{ "key": tab, "name": name, "query": query }];

		this.setState({ tags: [] });


		this.setState({ savedtags: orgdata });

		console.log(this.state.savedtags);

	}

	printDocument() {

		//$(".print-report").hide();
		var content = document.getElementById("print_report");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}

	onSelectData(data) {
		this.setState({
			selecteddata: data
		});
	}

	showSearchArea() {

		this.setState({
			searchArea: !this.state.searchArea
		});

	}


	handlePageChange(pageNumber) {

		this.setState({ activePage: pageNumber });
		this.state.currentPage = Number(pageNumber);
	}

	checkColumnExists(column) {

		var bstate = false;
		var needle = column;
		for (var i = 0; i < this.state.tags.length; i++) {
			// look for the entry with a matching `code` value
			if (this.state.tags[i].column == needle) {
				// we found it
				// obj[i].name is the matched result
				bstate = true;
				break;
			}
			else {
				bstate = false;
			}
		}
		return bstate;
	}

	onInitialFilter() {


		if (this.props.location.state.modelkeyid == 'site_view') {


			var query = this.props.location.state.query;
			var query2 = this.props.location.state.query2;
			var name = this.props.location.state.queryname;
			var name2 = this.props.location.state.queryname2;

			var orgdata = [{ "key": "1", "name": name, "column": "Company", "value": "1", "query": query }, { "key": "3", "name": name2, "column": "State", "value": "3", "query": query2 }];

			this.state.tags = orgdata;
			this.setState({ tags: orgdata });

			this.setState({ searchcolumn: '', searchfor: '', searchvalue: '' });

			this.getfilterdata();
			this.getdatafiltercount();

		}
		else {

			var orgdata = [];

			var tags = this.state.tags;

			var column = this.props.location.state.modelkeyid;
			var value = this.props.location.state.value;

			var cond = 'ilike';

			if (column != '' && cond != '') {


				var custval = '"' + value + '"';

				var custfname = this.custfiltname(cond);

				if (column == 'state') {

					cond = '=';

					custfname = 'equal to';
				}

				if (cond == '!=false') {

					cond = '!=';
					value = 'false';
					custval = 'false';
					custfname = 'is set';
				}
				else if (cond == '=false') {

					cond = '=';
					value = 'false';
					custval = 'false';
					custfname = 'is not set';

				}

				var len = tags.length + 1;

				var cuscolname = this.custcolumn(column);

				var query = '["' + column + '", "' + cond + '",' + custval + ' ]';

				this.state.tags.push({ "key": len, "name": cuscolname + ' ' + custfname + ' ' + '"' + value + '"', "column": column, "value": value, "query": query });

				orgdata = this.state.tags;

				console.log(orgdata);

				this.setState({ tags: orgdata });

				this.setState({ searchcolumn: '', searchfor: '', searchvalue: '' });

				this.getfilterdata();
				this.getdatafiltercount();

			}

		}



	}

	onSearchChange() {

		var orgdata = [];

		this.setState({ savedtags: [] });

		var tags = this.state.tags;

		var column = this.state.searchcolumn;
		var value = this.state.searchvalue;

		var cond = this.state.searchfor;

		if (column != '' && (cond != '' || column == 'mro_state' || column == 'maintenance_type')) {

			/* if(cond!='!=false' && cond!='=false' && value==''){
				
				swal("Value Missing","Type any Value","error");
				
			}
			else{ */

			var custval = '"' + value + '"';

			var custfname = this.custfiltname(cond);

			if (column == 'mro_state' || column == 'maintenance_type') {

				cond = '=';

				custfname = 'equal to';
			}

			if (cond == '!=false') {

				cond = '!=';
				value = 'false';
				custval = 'false';
				custfname = 'is set';
			}
			else if (cond == '=false') {

				cond = '=';
				value = 'false';
				custval = 'false';
				custfname = 'is not set';

			}

			var len = tags.length + 1;

			var cuscolname = this.custcolumn(column);

			var query = '["' + column + '", "' + cond + '",' + custval + ' ]';

			this.state.tags.push({ "key": len, "name": cuscolname + ' ' + custfname + ' ' + '"' + value + '"', "column": column, "value": value, "query": query });

			orgdata = this.state.tags;

			console.log(orgdata);

			this.setState({ tags: orgdata });

			this.setState({ searchcolumn: '', searchfor: '', searchvalue: '', activeTab: '' });

			this.getfilterdata();
			this.getdatafiltercount();

		}

		/* }
		else{
			
			swal("Column Missing","Select any Column or Type","error");
			
		} */


	}


	custcolumn(col) {

		var orgcol = '';

		if (col == 'ticket_number') {

			orgcol = 'Ticket Number';
		}
		else if (col == 'state_id') {

			orgcol = 'State';
		}
		else if (col == 'equipment_location_id') {

			orgcol = 'Space';
		}
		else if (col == 'maintenance_team_id') {

			orgcol = 'Maintenance Team';
		}
		else if (col == 'company_id') {

			orgcol = 'Company';
		}
		else if (col == 'maintenance_type') {

			orgcol = 'Maintenance';
		}
		else if (col == 'mro_state') {

			orgcol = 'MO Status';
		}


		return orgcol;

	}

	custfiltname(fname) {

		var orgfname = '';

		if (fname == 'ilike') {

			orgfname = 'contains';
		}
		else if (fname == 'not ilike') {

			orgfname = 'not contains';
		}
		else if (fname == '=') {

			orgfname = 'equal to';
		}
		else if (fname == '!=') {

			orgfname = 'not equal to';
		}

		return orgfname;

	}





	setcolumn(column) {
		this.setState({ searchfor: '' });
		this.setState({ searchvalue: '' });
		this.setState({ searchcolumn: column });
	}

	setsearchvalue(e) {

		this.setState({ searchvalue: e.target.value });
	}

	setfiltername(e) {

		this.setState({ filtername: e.target.value });
	}

	setsearchfor(e) {

		if (e.target.checked) {

			this.setState({ searchfor: e.target.value });
		}
		else {

			this.setState({ searchfor: '' });
		}
	}


	setisdeffilter(e) {

		if (e.target.checked) {

			this.setState({ is_filter_default: true });
		}
		else {

			this.setState({ is_filter_default: false });
		}
	}

	onAddFields(e) {

		if (e.target.checked) {

			this.setState({ [e.target.name]: true });
		}
		else {

			this.setState({ [e.target.name]: false });
		}
	}

	setselsearchvalue(value) {

		this.setState({ searchvalue: value });
	}



	addzero(day) {

		var temp = '' + day;
		if (temp.length == 1) {

			temp = "0" + temp;
			return temp;
		}
		else {

			return temp;
		}

	}



	/* componentDidMount(){
		this.state._isMounted = true;
	 if (this.state._isMounted) {
		this.getdata();
	 }
		
	} */

	/* componentWillMount() {
		//this.state._isMounted = false;
		this.getdata();
	} */

	componentDidMount() {
		//this.state._isMounted = false;

		console.log(this.props.location.state);
		if (this.props.location.state) {

			this.onInitialFilter();
			console.log(this.props.location.state.modelkeyid);

		}
		else {
			this.getFilters();
			//this.offsetcount(this.state.currentPage);
			//this.getdatacount();
		}

		$("#print_report").hide();
	}



	getdateofdt(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear();
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;
			return strTime;
		}
		else {

			return '';
		}

	}

	getdateofdttime(date) {

		if (date != '' && date != null) {
			var date1 = new Date(date);
			var year = date1.getFullYear().toString().substr(2, 2);
			var month = (date1.getMonth() + 1);
			var day = date1.getDate()
			var strTime = day + '/' + month + '/' + year;

			var hours = date1.getHours();
			var minutes = date1.getMinutes();
			var ampm = hours >= 12 ? 'pm' : 'am';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime1 = hours + ':' + minutes + ' ' + ampm;

			return strTime + ' ' + strTime1;
		}
		else {

			return '';
		}

	}

	handleClose(tag) {
		const { tags } = this.state;

		tags.splice(tags.map(el => el.key).indexOf(tag.key), 1);

		this.setState({ tag });
		this.setState({ searchcolumn: '', searchfor: '', searchvalue: '' });
		this.getfilterdata();
		this.getdatafiltercount();
		$("#search_text").val('');
	}

	handleCloseSavedTags(tag) {

		this.setState({ savedtags: [], activeTab: '' });
		this.offsetcount(this.state.currentPage);
		this.getdatacount();
	}





	handleClearFilters() {
		this.setState({ tags: [] });
		this.setState({ searchcolumn: '', searchfor: '', searchvalue: '' });
		this.offsetcount(this.state.currentPage);
		this.getdatacount();
		$("#search_text").val('');
	}

	setrpp(rpp) {


		this.setState({ todosPerPage: rpp });
		this.offsetcount1(this.state.currentPage, rpp);

	}

	setfilterrpp(rpp) {


		this.setState({ todosPerPage: rpp });
		this.offsetcount2(this.state.currentPage, rpp);

	}

	setfilterrppfiltersaved(rpp) {


		this.setState({ todosPerPage: rpp });
		this.offsetcount3(this.state.currentPage, rpp);

	}

	onUpdate(id) {

		this.state.idd = id;
		this.setState({ isUpdate: true });
	}

	offsetcount1(page, rpp) {

		var pc = parseInt(page) + 1;

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		this.setState({ offset: offset });

		this.getdatadyn1(offset, rpp);

	}

	offsetcount2(page, rpp) {

		var pc = parseInt(page) + 1;

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		this.setState({ offset: offset });

		this.getfilterdatapagination1(offset, rpp);

	}

	offsetcount3(page, rpp) {

		var pc = parseInt(page) + 1;

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		this.setState({ offset: offset });

		this.getfilterdatabyquery_offsetrpp(offset, rpp);

	}

	getdatadyn1(offset, rpp) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var total = this.state.totalcount;

		var limit = rpp;

		var xoffset = '';


		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		Reqst.axGet('gettickets/' + token + '/' + rpp + '/' + offset).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {

				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
				this.setState({ dataLoading: false, activeTab: '' });
			}
		});
	}

	offsetcount(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		var total = this.state.totalcount;
		this.setState({ activePage: page });

		this.setState({ offset: offset });

		this.getdatadyn(offset);

	}

	offsetcountfilter(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		var total = this.state.totalcount;
		this.setState({ activePage: page });

		this.setState({ offset: offset });

		this.getfilterdatapagination(offset);

	}

	offsetcountfiltersaved(page) {

		var rpp = this.state.todosPerPage;

		var pc = parseInt(page);

		var offset = parseInt(rpp) * parseInt(pc);

		this.state.currentPage = page;

		var total = this.state.totalcount;
		this.setState({ activePage: page });

		this.setState({ offset: offset });

		this.getfilterdatabyquery_offset(offset);

	}



	getfilterdatapagination1(offset, rpp) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var query = [];

		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = iscombine + qdata + ',["maintenance_type", "=","bm" ]';
		console.log(qdata);
		if (qdata == '') {
			qdata = '["maintenance_type", "=","bm" ]'
		}

		var total = this.state.totalcount;

		var limit = rpp;

		var xoffset = '';


		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}
		var data = { "token": token, "limit": rpp, "offset": offset, "query": qdata };
		Reqst.axPost('getticketsfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}

	getfilterdatapagination(offset) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var query = [];

		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = iscombine + qdata + ',["maintenance_type", "=","bm" ]';
		console.log(qdata);
		if (qdata == '') {
			qdata = '["maintenance_type", "=","bm" ]'
		}

		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;

		var xoffset = '';


		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		var data = { "token": token, "limit": this.state.todosPerPage, "offset": offset, "query": qdata };
		Reqst.axPost('getticketsfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}



	getdatadyn(offset) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;

		var xoffset = '';


		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		Reqst.axGet('gettickets/' + token + '/' + limit + '/' + offset).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});
	}

	getdata() {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('gettickets/' + token + '/' + this.state.todosPerPage + '/' + this.state.offset).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false, loadingTxt: '' });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});
	}

	onRemoveFilter() {
		this.setState({ filtersLoading: true, isFilterRemove: false });
		var token = Sanitize.getStoreageItem('accessToken');
		var id = this.state.filter_id;
		Reqst.axGet('removefilter/' + token + '/' + id).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ filtersLoading: false });
				this.getFilters();
				swal("Success", "Filter Removed Successfully..", "success");

			}
		});
	}



	checkIfArrayIsUnique() {
		var tags = this.state.tags;

		for (var i = 0; i < tags.length; i++) {
			for (var j = 0; j < tags.length; j++) {
				if (i != j) {
					if (tags[i]['column'] == tags[j]['column']) {
						return true;
						break;// means there are duplicate values
					}
				}
			}
		}
		return false; // means there are no duplicate values.
	}

	getfilterdata() {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var query = [];

		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = iscombine + qdata + ',["maintenance_type", "=","bm" ]';
		console.log(qdata);
		if (qdata == '') {
			qdata = '["maintenance_type", "=","bm" ]'
		}
		var data = { "token": token, "limit": this.state.todosPerPage, "offset": this.state.offset, "query": qdata };
		Reqst.axPost('getticketsfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}

	getfilterdatabyquery(query) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');


		var data = { "token": token, "limit": this.state.todosPerPage, "offset": this.state.offset, "query": query };
		Reqst.axPost('getticketsfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}


	getfilterdatabyquery_offset(offset) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var querydata = this.state.savedtags;

		var query = querydata[0].query;

		var total = this.state.totalcount;

		var limit = this.state.todosPerPage;

		var xoffset = '';


		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		var data = { "token": token, "limit": this.state.todosPerPage, "offset": offset, "query": query };
		Reqst.axPost('getticketsfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}

	getfilterdatabyquery_offsetrpp(offset, rpp) {
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ equipdata: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var querydata = this.state.savedtags;

		var query = querydata[0].query;

		var total = this.state.totalcount;

		var limit = rpp;

		var xoffset = '';


		if (offset > total && offset != limit) {

			offset = offset - limit;
			offset = offset + 1;

		}

		var data = { "token": token, "limit": rpp, "offset": offset, "query": query };
		Reqst.axPost('getticketsfiterdata', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ equipdata: res });
				if (res.length > 0) {
					this.setState({ loadingTxt: '' });
				}
				else {
					this.setState({ loadingTxt: 'No Data Found' });
				}
			}
		});

	}



	getdatafiltercountbyquery(query) {
		this.setState({ dataLoading: true });
		this.setState({ totalcount: 0 });
		var token = Sanitize.getStoreageItem('accessToken');

		var data = { "token": token, "query": query };
		Reqst.axPost('getticketsfiltercount', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ totalcount: res.total[0].__count });
			}
		});
	}

	getdatafiltercount() {
		this.setState({ dataLoading: true });
		this.setState({ totalcount: 0 });
		var token = Sanitize.getStoreageItem('accessToken');
		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = iscombine + qdata + ',["maintenance_type", "=","bm" ]';
		if (qdata == '') {
			qdata = '["maintenance_type", "=","bm" ]'
		}
		var data = { "token": token, "query": qdata };
		Reqst.axPost('getticketsfiltercount', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ totalcount: res.total[0].__count });
			}
		});
	}

	getdatacount() {
		this.setState({ dataLoading: true });
		this.setState({ totalcount: 0 });
		var token = Sanitize.getStoreageItem('accessToken');
		Reqst.axGet('getticketscount?id=' + token).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ dataLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ dataLoading: false });
				this.setState({ totalcount: res.total[0].__count });
			}
		});
	}

	/* getdydata(state,instance){
		this.setState({dataLoading:true,loadingTxt:'Loading...'});
		this.setState({equipdata : []});
		Reqst.axPost('getequipmentsdynamic',{
		  page: state.page,
		  pageSize: state.pageSize,
		  sorted: state.sorted,
		  filtered: state.filtered,
		  acctok : Sanitize.getStoreageItem('accessToken')
		  
		}).then((result) => {
			
			let res = result['data'];
			console.log(res);
			if(res.code=='401'){
				
				this.getRefreshToken();
				
			}
			else{
			    this.setState({dataLoading:false,loadingTxt:''});
				this.setState({equipdata : res.rows,pages: res.pages});
			}
		});
	} */

	getToggleColumns() {

		if (this.state.tab_vendor) {

			$(".tab_vendor").show();
		}
		else {

			$(".tab_vendor").hide();
		}
		if (this.state.tab_mainteam) {

			$(".tab_mainteam").show();
		}
		else {

			$(".tab_mainteam").hide();
		}
		if (this.state.tab_company) {

			$(".tab_company").show();
		}
		else {

			$(".tab_company").hide();
		}



	}

	getpriorityname(pr) {

		if (pr == '0') {

			return <div><span className="badge badge-dot badge-dot-sm badge-info">&nbsp;</span><span>&nbsp;Low</span></div>;

		}
		else if (pr == '1') {

			return <div><span className="badge badge-dot badge-dot-sm badge-warning">&nbsp;</span><span>&nbsp;Normal</span></div>;

		}
		else if (pr == '2') {

			return <div><span className="badge badge-dot badge-dot-sm badge-orange">&nbsp;</span><span>&nbsp;High</span></div>;

		}
		else if (pr == '3') {

			return <div><span className="badge badge-dot badge-dot-sm badge-danger">&nbsp;</span><span>&nbsp;Breakdown</span></div>;

		}



	}

	getprioritynamebyname(pr) {

		if (pr == 'Low') {

			return <div><span className="badge badge-dot badge-dot-sm badge-info">&nbsp;</span><span>&nbsp;Low</span></div>;

		}
		else if (pr == 'Normal') {

			return <div><span className="badge badge-dot badge-dot-sm badge-warning">&nbsp;</span><span>&nbsp;Normal</span></div>;

		}
		else if (pr == 'High') {

			return <div><span className="badge badge-dot badge-dot-sm badge-orange">&nbsp;</span><span>&nbsp;High</span></div>;

		}
		else if (pr == 'Breakdown') {

			return <div><span className="badge badge-dot badge-dot-sm badge-danger">&nbsp;</span><span>&nbsp;Breakdown</span></div>;

		}



	}

	getmtname(mt) {

		var orgstate = '';
		if (mt == 'bm') {

			orgstate = 'Corrective';

		}
		else if (mt == 'pm') {

			orgstate = 'Preventive';

		}
		else if (mt == 'oc') {

			orgstate = 'On Condition';

		}
		else if (mt == 'pr') {

			orgstate = 'Periodic';

		}

		return orgstate;

	}

	getTimeFromFloat(decimalTimeString) {

		var isimagine = '';

		if (decimalTimeString < 0) {

			decimalTimeString = Math.abs(decimalTimeString);
			isimagine = '-';
		}
		var decimalTime = parseFloat(decimalTimeString);
		decimalTime = decimalTime * 60 * 60;
		var hours = Math.floor((decimalTime / (60 * 60)));
		decimalTime = decimalTime - (hours * 60 * 60);
		var minutes = Math.floor((decimalTime / 60));
		decimalTime = decimalTime - (minutes * 60);
		var seconds = Math.round(decimalTime);
		if (hours < 10) {
			hours = "0" + hours;
		}
		if (minutes < 10) {
			minutes = "0" + minutes;
		}
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		var result = isimagine + "" + hours + ":" + minutes;

		return result;


	}

	getTimeIsActive(decimalTimeString) {

		if (decimalTimeString < 0) {

			return <span className="text-danger" style={{ fontSize: '13px' }}>Late</span>;
		}
		else {

			return <span className="text-success" style={{ fontSize: '13px' }}>Active</span>;
		}
	}

	getstatelabel(staten) {


		if (staten == 'Open') {

			return <span className="badge badge-danger">Open</span>;

		}
		else if (staten == 'In Progress') {

			return <span className="badge badge-info">In Progress</span>;

		}
		else if (staten == 'Staff Closed') {

			return <span className="badge badge-success">Staff Closed</span>;

		}

		else {

			return '';

		}

	}

	getstatename(staten) {

		var orgstate = '';
		if (staten == 'draft') {

			orgstate = 'DRAFT';

		}
		else if (staten == 'released') {

			orgstate = 'WAITING PARTS';

		}
		else if (staten == 'ready') {

			orgstate = 'READY';

		}
		else if (staten == 'assigned') {

			orgstate = 'ASSIGNED';

		}
		else if (staten == 'in_progress') {

			orgstate = 'IN PROGRESS';

		}
		else if (staten == 'pause') {

			orgstate = 'PAUSE';

		}
		else if (staten == 'done') {

			orgstate = 'DONE';

		}
		else if (staten == 'cancel') {

			orgstate = 'CANCELED';

		}

		return orgstate;

	}



	getRefreshToken() {

		this.setState({ dataLoading: true });
		var reftoken = Sanitize.getStoreageItem('refreshToken');
		Reqst.axGet('refreshtoken?id=' + reftoken).then((result) => {
			let res = result['data'];
			if (res) {
				this.setState({ dataLoading: false });
				Sanitize.setStorage('accessToken', res.access_token);
				Sanitize.setStorage('refreshToken', res.refresh_token);
				//this.setState({accTok:res.access_token,reTok:res.refresh_token});
				this.offsetcount(this.state.currentPage);
				this.getdatacount();
				this.getFilters();
			}
		}).catch(error => {
			this.setState({ dataLoading: false });
			//console.log(error.response.status);

		});

	}

	getFilters() {
		//this.setState({columnsDialog:false,searchDialog:true});
		//if(!this.state.filtersLoaded){
		this.setState({ filtersLoading: true, });
		this.setState({ dataLoading: true, loadingTxt: 'Loading...' });
		this.setState({ filters: [] });
		this.setState({ filters1: [] });
		var token = Sanitize.getStoreageItem('accessToken');

		var data = { "token": token, "model": "website.support.ticket" };
		Reqst.axPost('getfilters', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else if(result['data'].code == '500'){
				
				this.setState({ filtersLoading: false });
				alert(result['data'].data);
			}
			else {
				this.setState({ filtersLoading: false });

				this.setState({ filtersLoaded: true });

				if (res.length > 0) {

					var data = [];

					for (var i = 0; i < res.length; i++) {

						if (res[i].is_default) {
							data.push(res[i]);
						}
					}

					console.log(data);

					if (data.length > 0) {

						this.onTabChange(data[0].id, data[0].domain, data[0].display_name);
					}
					else {
						this.offsetcount(this.state.currentPage);
						this.getdatacount();
						//this.setState({dataLoading:false,loadingTxt:''});

					}


				}
				else {

					this.offsetcount(this.state.currentPage);
					this.getdatacount();
					//this.setState({dataLoading:false,loadingTxt:''});

				}
				/* console.log(res.length);
				if(res.length>4){
					
					var data = [];
					for(var i=4;i<res.length;i++){
						
						data.push(res[i]);
					}
					
					this.setState({filters1:data});
					
					var firstdata = [];
					for(var i=0;i<4;i++){
						
						firstdata.push(res[i]);
					}
					
					this.setState({filters:firstdata});
				} */
				//else{
				this.setState({ filters: res });

				//}
			}
		});
		//}

	}

	directtoanother() {

		this.setState({ isDirect: true });

	}


	onFilterCreate() {
		this.setState({ filterSave: true });
		var token = Sanitize.getStoreageItem('accessToken');

		var query = [];

		var tags = this.state.tags;
		var qdata = '';
		if (tags.length > 0) {

			for (var i = 0; i < tags.length; i++) {

				//query.push(tags[i]['query']);
				qdata += tags[i]['query'] + ',';

			}

		}
		var iscombine = '';

		if (this.checkIfArrayIsUnique()) {

			iscombine = '"&",';
		}

		qdata = qdata.replace(/,*$/, "");
		qdata = '[' + iscombine + qdata + ']';
		console.log(qdata);
		if (qdata == '') {
			qdata = 'no'
		}
		var data = { "token": token, "name": this.state.filtername, "modelid": "website.support.ticket", "isdefault": this.state.is_filter_default, "qdata": qdata };
		Reqst.axPost('filtercreate', data).then((result) => {

			let res = result['data'];
			if (res.code == '401') {

				this.getRefreshToken();

			}
			else {
				this.setState({ filterSave: false });
				this.setState({ filtername: '', is_filter_default: false, filter_modal: false });
				this.setState({ filtersLoaded: false });
				this.getFilters();
			}
		});

	}


	render() {

		if (this.state.isUpdate) {

			return (<Redirect to={'/helpdesk/ticket/view/' + this.state.idd} />);

		}

		if (this.state.isDirect && this.props.location.state && this.props.location.state.modelkeyid == 'site_view') {

			return (<Redirect to={{
				pathname: '/assets/spaces',
				state: { modelkeyid: 'sites', site_id: this.props.location.state.uid, site_name: this.props.location.state.value }
			}}
			/>);

		}



		const seloptions = [
			{
				value: '25',
				label: '25'
			},
			{
				value: '50',
				label: '50'
			},
			{
				value: '75',
				label: '75'
			},
			{
				value: '100',
				label: '100'
			}
		];

		const selstates = [
			{
				value: 'draft',
				label: 'DRAFT'
			},
			{
				value: 'released',
				label: 'WAITING PARTS'
			},
			{
				value: 'ready',
				label: 'READY TO MAINTENANCE'
			},
			{
				value: 'assigned',
				label: 'ASSIGNED'
			},
			{
				value: 'in_progress',
				label: 'IN PROGRESS'
			},

			{
				value: 'pause',
				label: 'PAUSE'
			},
			{
				value: 'done',
				label: 'DONE'
			},
			{
				value: 'cancel',
				label: 'CANCELED'
			}
		];

		const selmtypes = [
			{
				value: 'bm',
				label: 'Corrective'
			},
			{
				value: 'pm',
				label: 'Preventive'
			},
			{
				value: 'oc',
				label: 'On Condition'
			},
			{
				value: 'pr',
				label: 'Periodic'
			}

		];



		const selcolumns = [
			{
				value: '',
				label: 'Select'
			},
			{
				value: 'ticket_number',
				label: locale.t('el.tickets.ticketNumber')
			},
			{
				value: 'priority_id',
				label: locale.t('el.tickets.priority')
			},
			{
				value: 'state_id',
				label: locale.t('el.tickets.state')
			},
			{
				value: 'equipment_location_id',
				label: locale.t('el.tickets.space')
			},
			{
				value: 'maintenance_team_id',
				label: locale.t('el.tickets.maintenanceTeam')
			},
			{
				value: 'company_id',
				label: locale.t('el.tickets.company')
			},
			{
				value: 'mro_state',
				label: 'MO Status'
			}
		];



		var columns = [
			{
				type: 'selection',
				width: 20,
			},
			{
				label: locale.t('el.tickets.ticketNumber'),
				prop: "ticket_number",
				resizable: true,
				fixed: 'left',
				sortable: true,
				align: 'left',
				minWidth: 130,
				render: (row, column, index) => {
					return <a href={"#/helpdesk/ticket/view/" + row.id} style={{ color: '#1f2d3d', textDecoration: 'none' }}><div>
						<Tooltip className="item" content={row.ticket_number} placement="bottom" >
							<span style={{ fontSize: '15px', fontWeight: 'bold', color: '#1f2d3d', textDecoration: 'none' }}>{row.ticket_number}</span>
						</Tooltip>
					</div></a>;
				}
			},
			{
				label: locale.t('el.tickets.subject'),
				prop: "subject",
				sortable: true,
				resizable: true,
				minWidth: 190,
				render: (row, column, index) => {
					return <span style={{ whiteSpace: 'pre-wrap' }}>{row.subject}</span>;
				}
			},



		];


		if (this.state.tab_priority) {
			columns.push({
				label: locale.t('el.tickets.priority'),
				prop: "priority_id.1",
				resizable: true,
				sortable: true,
				minWidth: 80,
				render: (row, column, index) => {
					return <span>{this.getprioritynamebyname(row.priority_id[1])}</span>;
				}
			},

			);
		}

		if (this.state.tab_assignto) {
			columns.push({
				label: locale.t('el.tickets.maintenanceTeam'),
				prop: "maintenance_team_id.1",
				sortable: true,
				resizable: true,
				className: 'tab_wardate',
				minWidth: 125,
				render: (row, column, index) => {
					return <div><i className="pe-7s-users font-size-sm"></i>&nbsp;<span>{row.maintenance_team_id[1] ? row.maintenance_team_id[1] : 'Not Assigned'}</span></div>;
				}
			},
			);
		}


		if (this.state.tab_state) {
			columns.push({
				label: locale.t('el.tickets.orderStatus'),
				prop: "mro_state",
				resizable: true,
				sortable: true,
				minWidth: 105,
				render: (row, column, index) => {
					return <Tooltip className="item" content={this.getstatename(row.mro_state)} placement="bottom" >
						<span>{this.getstatename(row.mro_state)}</span>
					</Tooltip>;
				}
			},
			);
		}

		if (this.state.tab_sla) {
			columns.push({
				label: "SLA Time",
				prop: "sla_timer",
				resizable: true,
				sortable: true,
				minWidth: 85,
				render: (row, column, index) => {
					return <div><span>{this.getTimeFromFloat(row.sla_timer)}</span><br /><span>{this.getTimeIsActive(row.sla_timer)}</span></div>;
				}
			},
			);
		}


		if (this.state.tab_duration) {
			columns.push({
				label: locale.t('el.tickets.state'),
				prop: "state_id.1",
				sortable: true,
				resizable: true,
				minWidth: 115,
				render: (row, column, index) => {
					return <span>{this.getstatelabel(row.state_id[1])}</span>;
				}
			},

			);
		}

		if (this.state.tab_create_on) {
			columns.push({
				label: "Created On",
				prop: "create_date",
				sortable: true,
				minWidth: 140,
				className: 'tab_company',
				resizable: true,
				render: (row, column, index) => {
					return <span>{this.getdateofdt(row.create_date)}</span>;
				}

			},
			);
		}

		if (this.state.tab_company) {
			columns.push({
				label: "Company",
				prop: "company_id.1",
				minWidth: 140,
				sortable: true,
				className: 'tab_mainteam',
				resizable: true,

			},
			);
		}

		if (this.state.tab_space) {
			columns.push({
				label: "Space",
				prop: "equipment_location_id.1",
				className: 'tab_vendor',
				sortable: true,
				minWidth: 140,
				resizable: true,
				render: (row, column, index) => {
					return <Tooltip className="item" content={row.equipment_location_id[1]} placement="bottom" >
						<span>{row.equipment_location_id[1]}</span>
					</Tooltip>;
				}
			},
			);
		}

		if (this.state.tab_ptype) {
			columns.push({
				label: "Problem Type",
				prop: "help_problem_id.1",
				className: 'tab_vendor',
				sortable: true,
				minWidth: 140,
				resizable: true,
			},
			);
		}

		if (this.state.tab_cause) {
			columns.push({
				label: "Cause",
				prop: "cause",
				className: 'tab_vendor',
				sortable: true,
				minWidth: 140,
				resizable: true,
				render: (row, column, index) => {
					return <span data-tip={row.cause}>{row.cause}<ReactTooltip /></span>;
				}
			},
			);
		}




		return (
			<Fragment>
				<Helmet>
					<title>{GC.APPTITLE} | Tickets </title>
				</Helmet>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>

					<SweetAlert
						title="Are you sure you want to Remove this Filter?"
						confirmButtonColor=""
						show={this.state.isFilterRemove}
						text=""
						showCancelButton
						onConfirm={() => this.onRemoveFilter()}
						onCancel={() => this.setState({ isFilterRemove: false })} />

					<div className="topbar-header">
						<div className="topbar-header__content">


							<PageTitleAlt3
								heading=""
								subheading=""
								icon=""
								menuLevel1={this.props.location.state ? this.props.location.state.value : 'Tickets'}
								menuLevel2={!this.props.location.state ? '' : 'Tickets'}
								menuLevel3=""
								menuLevel1link={this.props.location.state ? 'javascript:;' : false}
								menuLevel1click={() => this.props.location.state ? this.directtoanother() : ''}
								isbreadcumb={true}
							/>


							<div className="topbar_buttons">

								<div>


									<Pagination
										layout="prev,sizes,total,pager, next"
										total={this.state.totalcount}
										pageCount={3}
										currentPage={this.state.activePage}
										small={true}
										pageSizes={[25, 50, 75, 100]}
										pageSize={this.state.todosPerPage}
										onCurrentChange={this.state.tags.length > 0 ? this.offsetcountfilter : this.state.savedtags.length == 1 ? this.offsetcountfiltersaved : this.offsetcount}
										onSizeChange={this.state.tags.length > 0 ? this.setfilterrpp : this.state.savedtags.length == 1 ? this.setfilterrppfiltersaved : this.setrpp} />


								</div>
								&nbsp;
											{this.state.selecteddata.length > 0 ?

									<Button type="text" onClick={() => this.printDocument()}>
										<i className="lnr-printer  font-size-lg" />
									</Button>

									: ''}
								&nbsp;
								<Tooltip className="item" content="Columns" placement="bottom" >
								   <Button type="text" onClick={() => this.setState({ columnsDialog: true, searchDialog: false })}>
										<i className="lnr-list  font-size-lg" />
									</Button>
								</Tooltip>
								&nbsp;&nbsp;
								<Tooltip className="item" content="Filters" placement="bottom" >
									<Button type="text" onClick={() => this.setState({ columnsDialog: false, searchDialog: true })}>
										<i className="lnr-funnel font-size-lg" />
									</Button>
								</Tooltip>

								<span className="d-inline-block mb-2 mr-2">
									<Modal isOpen={this.state.columnsDialog} slide="right" size="sm" className="right" backdrop={true}>
										<ModalHeader>
											Additional Fields
												  <span className="modal_pull_right"><button className="close" onClick={() => this.setState({ columnsDialog: false })}>&times;</button></span>
										</ModalHeader>

										<ModalBody>
											<FormGroup>

												<CustomInput type="checkbox" id="exampleCustomCheckbox6" name="tab_state" label="State" checked={this.state.tab_state} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox7" name="tab_priority" label="Priority" checked={this.state.tab_priority} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox5" name="tab_assignto" label="Maintenance Team" checked={this.state.tab_assignto} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox4" name="tab_duration" label="Mo Status" checked={this.state.tab_duration} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox2" name="tab_create_on" label="Created On" checked={this.state.tab_create_on} onClick={this.onAddFields.bind(this)} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox1" name="tab_company" label="Company" checked={this.state.tab_company} onClick={this.onAddFields.bind(this)} style={{ textTransform: 'capitalize' }} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox3" name="tab_space" label="Space" checked={this.state.tab_space} onClick={this.onAddFields.bind(this)} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox11" name="tab_ptype" label="Problem Type" checked={this.state.tab_ptype} onClick={this.onAddFields.bind(this)} />
												<hr />
												<CustomInput type="checkbox" id="exampleCustomCheckbox12" name="tab_sla" label="SLA Time Remaining" checked={this.state.tab_sla} onClick={this.onAddFields.bind(this)} />
												<hr />

											</FormGroup>
										</ModalBody>

									</Modal>
								</span>


								<span className="d-inline-block mb-2 mr-2">
									<Modal isOpen={this.state.filter_modal} size="sm" className="form_mod" backdrop={true}>
										<ModalHeader>
											Save Filter
												  <span className="modal_pull_right3"><button className="close" onClick={() => this.setState({ filter_modal: false })}>&times;</button></span>
										</ModalHeader>

										<ModalBody>
											<FormGroup>
												<Label className="text-capitalize">Name</Label>
												<Input type="text" className="mat_form" name="filtername" onChange={this.setfiltername.bind(this)} bsSize="sm" />
											</FormGroup>
											<FormGroup className="pull-right">
												<CustomInput type="checkbox" id="exampleCustomCheckbox65" name="customCheckbox" label="Is Default" value="" onClick={this.setisdeffilter.bind(this)} />
											</FormGroup>
											<br /><br />
											<FormGroup className="text-center">
												<Button type="primary" disabled={this.state.filterSave} onClick={this.onFilterCreate}>
													{this.state.filterSave ? 'Saving...' : 'Save'}
												</Button>
												&nbsp;
																		<Button type="danger" onClick={() => this.setState({ filter_modal: false, filtername: '', is_filter_default: false })} >
													Cancel
																		</Button>
											</FormGroup>
										</ModalBody>

									</Modal>
								</span>

								<span className="d-inline-block mb-2 mr-2">
									<Modal isOpen={this.state.searchDialog} slide="right" size="sm" className="right1" backdrop={true}>
										<ModalHeader>
											Filters
												  <span className="modal_pull_right1"><button className="close" onClick={() => this.setState({ searchDialog: false, searchcolumn: '', searchfor: '', searchvalue: '' })}>&times;</button></span>
										</ModalHeader>

										<ModalBody className="right1_body">
											<span className="text-info"><i className="pe-7s-filter font-size-sm" style={{ fontWeight: 'bold', fontSize: '16px' }}> </i></span>&nbsp;<span className="custom_view_head">Add Filter</span><br /><br />

											<FormGroup>
												<Select onChange={this.setcolumn} value={this.state.searchcolumn}>
													{
														selcolumns.map(el => {
															return <Select.Option key={el.value} label={el.label} value={el.value} />
														})
													}
												</Select>
											</FormGroup>

											<FormGroup>
												{this.state.searchcolumn == 'mro_state' ?
													<Select onChange={this.setselsearchvalue.bind(this)}>
														{
															selstates.map(el => {
																return <Select.Option key={el.value} label={el.label} value={el.value} />
															})
														}
													</Select>
													: ''}
											</FormGroup>

											<FormGroup>
												{this.state.searchcolumn == 'maintenance_type' ?
													<Select onChange={this.setselsearchvalue.bind(this)}>
														{
															selmtypes.map(el => {
																return <Select.Option key={el.value} label={el.label} value={el.value} />
															})
														}
													</Select>
													: ''}
											</FormGroup>



											<FormGroup>
												{this.state.searchcolumn != 'mro_state' && this.state.searchcolumn != 'maintenance_type' && this.state.searchcolumn != '' ?
													<div style={{ padding: '10px 14px' }}>
														<CustomInput type="radio" id="exampleCustomRadio6" name="customRadio" checked={this.state.searchfor == 'ilike' ? true : ''} label="Contains" value="ilike" onClick={this.setsearchfor.bind(this)} />
														{this.state.searchcolumn != 'state' && this.state.searchfor == 'ilike' ?
															<Input id="search_text" className="mat_form" onChange={this.setsearchvalue.bind(this)} />
															: ''}
														<CustomInput type="radio" id="exampleCustomRadio" name="customRadio" checked={this.state.searchfor == 'not ilike' ? true : ''}
															label="Does not contain" value="not ilike" onClick={this.setsearchfor.bind(this)} />
														{this.state.searchcolumn != 'state' && this.state.searchfor == 'not ilike' ?
															<Input id="search_text" className="mat_form" onChange={this.setsearchvalue.bind(this)} />
															: ''}

														<CustomInput type="radio" id="exampleCustomRadio3" name="customRadio" checked={this.state.searchfor == '=' ? true : ''}
															label="Is Equal to" value="=" onClick={this.setsearchfor.bind(this)} />
														{this.state.searchcolumn != 'state' && this.state.searchfor == '=' ?
															<Input id="search_text" className="mat_form" onChange={this.setsearchvalue.bind(this)} />
															: ''}

														<CustomInput type="radio" id="exampleCustomRadio4" name="customRadio" checked={this.state.searchfor == '!=' ? true : ''}
															label="Is Not Equal to" value="!=" onClick={this.setsearchfor.bind(this)} />
														{this.state.searchcolumn != 'state' && this.state.searchfor == '!=' ?
															<Input id="search_text" className="mat_form" onChange={this.setsearchvalue.bind(this)} />
															: ''}
														<CustomInput type="radio" id="exampleCustomRadio5" name="customRadio" checked={this.state.searchfor == '!=false' ? true : ''}
															label="Is Set" value="!=false" onClick={this.setsearchfor.bind(this)} />

														<CustomInput type="radio" id="exampleCustomRadio7" name="customRadio" checked={this.state.searchfor == '=false' ? true : ''}
															label="Is not Set" value="=false" onClick={this.setsearchfor.bind(this)} />


													</div>
													: ''}
											</FormGroup>
											{this.state.searchcolumn != '' && (this.state.searchfor != '' || this.state.searchcolumn == 'mro_state' || this.state.searchcolumn == 'maintenance_type') ?
												this.state.searchvalue != '' || (this.state.searchfor == '!=false' || this.state.searchfor == '=false') ?
													<div className="text-center">
														<Button block onClick={this.onSearchChange} type="primary">
															<i className="pe-7s-search" />&nbsp;Search
															</Button>
													</div>
													: ''
												: ''}


											{this.state.searchcolumn == '' ?
												<div>

													<span className="text-info"><i className="pe-7s-star font-size-sm" style={{ fontWeight: 'bold', fontSize: '16px' }}> </i></span>&nbsp;<span className="custom_view_head">Saved Filters</span><br /><br />
													<hr style={{ marginBottom: '2px', marginTop: '2px', borderTop: '1px solid #d1dbe5', width: '268px', marginLeft: '-15px' }} />
													{this.state.filtersLoading ?

														<div><center><Loader type="ball-clip-rotate" /></center></div>

														:
														this.state.filters.map((item, index) =>

															<div>
																<div style={{ padding: '4px 1px', display: 'flex', alignItems: 'center' }}>
																	<a href="javascript:;" style={{ textDecoration: 'none', color: '#111517' }} onClick={() => this.onTabChange(item.id, item.domain, item.display_name)}>
																		<div className="list_center_equal">
																			<Tooltip className="item" content={item.display_name} placement="top-center" >
																				<span className="text-info"><i className="pe-7s-filter font-size-sm" style={{ fontWeight: 'bold', fontSize: '15px' }}> </i></span>&nbsp;<span style={{ fontSize: '15px' }} >{item.display_name}</span>
																			</Tooltip>
																		</div>
																	</a>
																	<div style={{ marginLeft: 'auto' }}>
																		<span className="pull-right text-danger" onClick={() => this.setState({ isFilterRemove: true, filter_id: item.id })}><i className="pe-7s-trash font-size-sm" style={{ fontWeight: 'bold', fontSize: '15px' }}> </i></span>
																	</div>

																</div>

																<hr style={{ marginBottom: '2px', marginTop: '2px', borderTop: '1px solid #d1dbe5', width: '268px', marginLeft: '-15px' }} />
															</div>

														)
													}
												</div>
												: ''}

										</ModalBody>

									</Modal>
								</span>


							</div>


						</div>
					</div>

					<Card className="main-card mb-3 card_topbar">
						<CardBody>

							<Col md="12" lg="12">

								{this.state.savedtags.length == 1 ?
									<div style={{ marginBottom: '10px', marginTop: '0px' }}>
										{

											this.state.savedtags.map(tag => {
												return (
													<Tag
														key={tag.key}
														closable={true}
														type="primary"
														closeTransition={false}
														onClose={this.handleCloseSavedTags.bind(this, tag)}>{tag.name}</Tag>
												)
											})
										}
									</div>
									: ''}

								{this.state.tags.length > 0 ?
									<div style={{ marginBottom: '10px', marginTop: '0px' }}>
										{
											this.state.tags.map(tag => {
												return (
													<Tag
														key={tag.key}
														closable={true}
														type="default"
														closeTransition={false}
														onClose={this.handleClose.bind(this, tag)}>{tag.name}</Tag>
												)
											})
										}



										<span className="pull-right">

											<Button type="success" size="small" onClick={() => this.setState({ filter_modal: true })}>
												<i className="lnr-checkmark-circle  font-size-sm" />&nbsp;Save Filters
									</Button>
											&nbsp;
								     <Button type="danger" size="small" onClick={() => this.handleClearFilters()}>
												<i className="lnr-cross-circle  font-size-sm" />&nbsp;Clear Filters
									</Button>

										</span>
									</div>
									: ''}
							</Col>


							<Loading loading={this.state.dataLoading} />
							<Table
								style={{ width: '100%', border: '0px' }}
								columns={columns}
								fit={true}
								data={this.state.equipdata}
								border={false}
								emptyText={this.state.loadingTxt}
								height={700}
								maxHeight={900}
								onSelectChange={(selection) => { this.onSelectData(selection) }}
								onSelectAll={(selection) => { this.onSelectData(selection) }}
							/>

							<div id="print_report">
								{this.state.selecteddata.map((item, index) => {

									return (<React.Fragment>
										<table width="33%" style={{ float: 'left', pageBreakInside: 'avoid', pageBreakAfter: 'always' }}>
											<tbody>
												<tr>
													<td style={{ lineHeight: '13px' }} >
														<QRCode value={item.ticket_number} renderAs='svg' includeMargin={true} level='H' size='230' /><br />
														<span style={{ marginLeft: '64px' }}>{item.ticket_number}</span>
													</td>
												</tr>
											</tbody>
										</table>
									</React.Fragment>);

								})}

							</div>
							<iframe name="print_frame" id="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
						</CardBody>
					</Card>

				</ReactCSSTransitionGroup>
			</Fragment>
		)
	}
}
