import React, {Fragment} from 'react';
import {Route} from 'react-router-dom';
import '../../assets/base.scss';

import Dashboard from '../Dashboards/Analytics/';
import Sites from '../../views/Spaces/SitesList';
import Equipments from '../../views/Equipments';
import Locations from '../../views/Spaces/View';
import ViewEquipment from '../../views/Equipments/ViewEquipment';
import AddEquipment from '../../views/Equipments/AddEquipment';
import WorkOrders from '../../views/WorkOrders';
import PPMCalendar from '../../views/Calendar';
import ViewOrder from '../../views/WorkOrders/ViewOrder';
import Tickets from '../../views/Tickets';
import ViewTicket from '../../views/Tickets/ViewTicket';
import AddTicket from '../../views/Tickets/AddTicket';
import OrdersDashboard from '../../views/Dashboard/OrdersDashboard';
import TicketsDashboard from '../../views/Dashboard/ticketsDashboard';
import OrdersDash from '../../views/Dashboard/OrdersOnlyDash';
import Analytics from '../../views/Analytics';
import Certifications from '../../views/Analytics/Certifications';
import Sensors from '../../views/Analytics/Sensors';
import Employees from '../../views/Employees';
import Vendors from '../../views/Vendors';
import Customers from '../../views/Customers';
import CustomerMetrics from '../../views/Analytics/CustomerMetrics';
import FunctionalMetrics from '../../views/Analytics/FunctionalMetrics';
import PhysicalPerformance from '../../views/Analytics/PhysicalPerformance';
import FinancialPerformance from '../../views/Analytics/FinancialPerformance';
import StrategicCertifications from '../../views/Spaces/StrategicCertifications';
import Metrics from '../../views/Spaces/Metrics';
import ViewEmployee from '../../views/Employees/ViewEmployee';

// Layout
import AppHeader from '../../Layout/AppHeader/';
import AppSidebar from '../../Layout/AppSidebar/';
import AppFooter from '../../Layout/AppFooter/';

// Theme Options

import ThemeOptions from '../../Layout/ThemeOptions/';

const Routes = ({match}) => (
    <Fragment>
        <ThemeOptions/>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
                <div className="app-main__inner">

                    {/* Buttons */}
					
					<Route exact path={"/"} component={Sites}/>
					<Route path={"/assets/equipments"} component={Equipments}/>
					<Route path={"/assets/spaces"} component={Locations}/>
					<Route path={"/assets/certifications"} component={Certifications}/>
					<Route path={"/helpdesk/workorders"} component={WorkOrders}/>
					<Route path={"/helpdesk/tickets"} component={Tickets}/>
					<Route path={"/helpdesk/newticket"} component={AddTicket}/>
					<Route path={"/assets/equipment/view/:id"} component={ViewEquipment}/>
					<Route path={"/assets/equipment/add"} component={AddEquipment}/>
					<Route path={"/helpdesk/workorder/view/:id"} component={ViewOrder}/>
					<Route path={"/helpdesk/ticket/view/:id"} component={ViewTicket}/>
					<Route path={"/maintenance/ppmviewer"} component={PPMCalendar}/>
					<Route path={"/dashboard/workorders"} component={OrdersDashboard}/>
					<Route path={"/dashboard/tickets"} component={TicketsDashboard}/>
					<Route path={"/assets/analytics"} component={Analytics}/>
					<Route path={"/sensors"} component={Sensors}/>
					<Route path={"/hr/employees"} component={Employees}/>
					<Route path={"/hr/employee/view/:id"} component={ViewEmployee}/>
					<Route path={"/vendors"} component={Vendors}/>
					<Route path={"/customers"} component={Customers}/>
					<Route path={"/metrics/customer"} component={CustomerMetrics}/>
					<Route path={"/metrics/functional"} component={FunctionalMetrics}/>
					<Route path={"/performance/physical"} component={PhysicalPerformance}/>
					<Route path={"/performance/financial"} component={FinancialPerformance}/>
					<Route path={"/strategiccertifications"} component={StrategicCertifications}/>
					<Route path={"/metricslist"} component={Metrics}/>
					
                </div>
                <AppFooter/>
            </div>
        </div>
    </Fragment>
);

export default Routes;